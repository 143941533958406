import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components/macro';
import _ from "lodash";
import { nanColor } from '../config';

import { scaleLinear } from 'd3';
import { zebrafishToLinear } from '@components/universe/utils/computation';

import CoronaSegment from './CoronaSegment';
import FieldDetail from './FieldDetail';
import { StyleContext } from '@theme/AppStyles';
import {VertexProps} from "@components/universe/types";

let debug = false;
const debugComponentName = "Vertex";
const Vertex = (props:VertexProps) => {
  const {
    vertex,
    radius,
    vertexAesSettings,
    settings,
    secondaryColor = null,
    hideValues= false,
    inGraph = true,
    isNeighbor = false,
    narrowRing = false,
    secondaryFields = null
  } = props;
  // debug = ['Indoxacarb', 'CAY10571'].includes(vertex?.name);
  debug && console.log(debugComponentName + ' | props', props);
  const [vertexScales, setVertexScales] = useState<any>();
  const [coronaFields, setCoronaFields] = useState([]);
  const [imageUrl, setImageUrl] = useState<string>();
  const [structureScale, setStructureScale] = useState<number>(1);
  const [appStyle] = useContext(StyleContext);
  const { mode, response_type } = settings;

  function handleStructureMouseEnter() {
    setStructureScale(2);
  }

  function handleStructureMouseLeave() {
    setStructureScale(1);
  }

  function getColor(segment) {
    return _.get(vertexAesSettings?.fill, segment?.fieldName, nanColor);
  }

  useEffect(() => {
    if (vertexAesSettings?.image) {
      const svg_url = 'data:image/svg+xml;base64,' + btoa(vertexAesSettings.image);
      setImageUrl(svg_url);
    } else {
      setImageUrl('/img/icons/MissingStructure.svg');
    }
  }, [vertexAesSettings]);

  useEffect(() => {
    const coronaRadiusScale = scaleLinear()
        .domain([0, 1])
        .range([radius * 1.07, radius * 1.5]);

    setVertexScales((scales) => ({
      ...scales,
      coronaRadiusScale,
    }));
  }, [radius]);

  useEffect(() => {
    if (vertexScales) {
      let min = 0;
      let max = 1;
      if (narrowRing) {
        min = 0;
        max = 0.5;
      }
      const tmpCoronaFields = settings.coronaFields.map((d) => {
        return {
          ...d,
          innerRadius: vertexScales.coronaRadiusScale(min),
          outerRadius: vertexScales.coronaRadiusScale(max),
        };
      });
      setCoronaFields(tmpCoronaFields);
    }
  }, [vertexScales, settings]);

  // console.log("Vertex | mode", mode, " response_type", response_type);
  const barScale = (v) => {
    if ( v === null) return 0.0;
    if (mode === "zebrafish") return zebrafishToLinear(v);
    if (response_type === "%") return Math.min(1.0, Math.max(0.0, v/100.0));
    if (response_type === "%_inverse") return Math.min(1.0, Math.max(0.0, 1.0 - v/100.0));
    return Math.min(1.0, Math.max(0.0, v));
  }

  if (debug) {
    console.log(debugComponentName + " | coronaRingFields",  settings.coronaFields);
    console.log(debugComponentName + " | vertexAesSettings", vertexAesSettings);
    console.log(debugComponentName + " | vertex", vertex);
    console.log(debugComponentName + " | coronaFields", coronaFields);
    console.log(debugComponentName + " | secondaryFields", secondaryFields);
    console.log("");
  }

  if (vertex === null || vertex === undefined) {
    console.error('Vertex called with null or undefined');
    return null;
  }
  // console.log(secondaryFields);


  return (
    <Container
      className='vertex'
      transform={inGraph ? `translate(${vertex.x} ${vertex.y})` : ``}
    >
      <g className='vertex-corona'>
        {coronaFields.map((segment, index) => {
          return (
            <g key={segment.fieldType + '_' + index}>
              <CoronaSegment
                color={getColor(segment)}
                segment={segment}
              />
            </g>
          );
        })}
        {!hideValues && coronaFields.map((segment) => {
            return (
              <g key={segment.fieldType}>
                <FieldDetail
                  field={vertex.data[segment.fieldType]}
                  segment={segment}
                  secondaryColor={secondaryColor}
                  secondaryField={
                    secondaryFields
                      ? secondaryFields[segment.fieldType]
                      : null
                  }
                  barScale={barScale}
                  isNeighbor={isNeighbor}
                />
              </g>
            );
          })}
      </g>
      <ScaleGroup structureScale={structureScale}>
        <circle
          className='vertex-base'
          cx='0'
          cy='0'
          r={radius}
          stroke='none'
          fill={appStyle.palette.backgroundSecondary}
          fillOpacity={structureScale === 1 ? 1.0 : 0.9}
        ></circle>
        <g
          className='structure'
          onMouseEnter={handleStructureMouseEnter}
          onMouseLeave={handleStructureMouseLeave}
        >
          {imageUrl && (
            <image
              x={-0.75 * radius}
              y={-0.75 * radius}
              width={1.5 * radius}
              height={1.5 * radius}
              href={imageUrl}
            />
          )}
        </g>
      </ScaleGroup>
    </Container>
  );
};

export default Vertex;

const Container: any = styled.g`
`;

const ScaleGroup: any = styled.g`
  transform: scale(${({ structureScale }: any) => structureScale});
  transition: transform 0.2s ease;
`;
