import React, { useContext, useState, Fragment, useEffect } from 'react';
import { PlotPanel } from './plot-panel/PlotPanel';
import { ScreenshotContext } from '@components/screenshot/screenshot.context';
import { FORMATS, IDimension } from '@components/screenshot/screenshot.service';
import { PlotBase, PlotConfig } from './plot.types';
import {
  getDatavizTheme,
  getThumbnailTheme,
  SCREENSHOT_PALETTE,
} from './plot-panel/plot.themes';
import { PlotContext } from './plot-panel/plot.context';
import { debounce } from '@utils/debounce';
import { createPortal } from 'react-dom';
import { StyleSheetManager } from 'styled-components';
import styled from 'styled-components/macro';

export const ScreenshotFrame = () => {
  const { plot } = useContext(PlotContext);
  const { capture, reference, registerAction } = useContext(ScreenshotContext);

  const [dimension, setDimension] = useState({
    width: 100,
    height: 75,
  } as IDimension);

  const [theme, setTheme] = useState(getDatavizTheme({}, SCREENSHOT_PALETTE));

  useEffect(() => {
    debounce('GenerateThumbnail', () => generateThumbnail(plot), 800);
  }, [plot]);

  registerAction('plot-screenshot-resize', async (dimensions) => {
    // give the plot time to resize
    setDimension(dimensions);
    await new Promise((response) => setTimeout(() => response(null), 600));
  });

  const generateThumbnail = async (config: PlotConfig<PlotBase>) => {
    if (!reference.current) return;

    const hasAxes = plot.config.base !== 'volcano';

    const thumbnailTheme = getThumbnailTheme(hasAxes);
    const thumbnailSize = { width: 100, height: 75 };
    const format = FORMATS.find((format) => format.extension === 'png');
    setTheme(thumbnailTheme);

    config.thumbnail = await capture(thumbnailSize, format);

    setTheme(getDatavizTheme({}, SCREENSHOT_PALETTE));
  };

  return (
    <IFrame
      id={'print'}
      style={{
        width: `${dimension.width}px`,
        height: `${dimension.height}px`,
        position: 'fixed',
        background: 'white',
        pointerEvents: 'none',
        opacity: 0.0,
        top: '-100%',
      }}
    >
      <PlotPanel datavizTheme={theme} reference={reference} />
    </IFrame>
  );
};

const MEDIA_LANDSCAPE = `
  @page {
    size: letter landscape;
    margin: 0.1in;
  }
`;

let index = 1;
const IFrame = ({ children, ...props }) => {
  const [contentRef, setContentRef] = useState(null);
  const mountNode = contentRef?.contentWindow?.document?.body;

  return (
    <iframe title={'Frame-' + index++} {...props} ref={setContentRef}>
      {mountNode &&
        createPortal(
          <Fragment>
            <style type='text/css'>{MEDIA_LANDSCAPE}</style>
            <StyleSheetManager target={mountNode}>
              <ClearMargin>{children}</ClearMargin>
            </StyleSheetManager>
            ,
          </Fragment>,
          mountNode
        )}
    </iframe>
  );
};

const ClearMargin = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;
