import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import BasicTable from "@as_core/tables/BasicTable";
import {Cancel} from "@mui/icons-material";
import IconButton from "@ui-components/controls/IconButton";
import {getInvitationStatus, InvitationT} from "@utils/api/useInvitations";
import HoverInfoText from "@as_core/account/fields/HoverInfoText";
import {getObjectValueByKey} from "@utils/misc";

type ColumnT = {
  value: string;
  label: string;
  type?: string;
  width?: number;
}

const columns: {[key: string]: ColumnT[]} = {
  'Share Repository': [
    {label: 'Email', value: 'invitee.authEmail', type: 'string', width: 280},
    {label: 'Created', value: 'created', type: 'date', width: 90},
    {label: 'Status', value: 'status', type: 'status', width: 90},
    {label: 'Access Type', value: 'accessType', type: 'access', width: 90},
    {label: '', value: 'cancel', type: 'action', width: 40}
  ],
  'Share Subscription': [
    {label: 'Email', value: 'invitee.authEmail', type: 'string', width: 280},
    {label: 'Created', value: 'created', type: 'date', width: 90},
    {label: 'Status', value: 'status', type: 'status', width: 90},
    {label: '', value: 'cancel', type: 'action', width: 40}
  ]
};

function getTableRows(invitations:any[], handleCancelInvite: (id:string) => void, inviteType = 'Share Repository') {
  const rows = [];
  invitations.forEach((i)=>{
    const row = {};
    columns[inviteType].forEach((c) => {
      if (c.type === 'date') {
        const value = getObjectValueByKey(i, c.value, '');
        if (value === '') return value;
        row[c.value] = new Date(value).toLocaleDateString();
      } else if (c.type === 'status') {
        const status = getInvitationStatus(i);
        if (status.actionDate !== '') {
          const date = new Date(status.actionDate);
          const text = status.action + " on " + date.toLocaleDateString() + ':' + date.toLocaleTimeString();
          row[c.value] = <Item>{status.action}<HoverInfoText text={text}/></Item>;
        } else {
          row[c.value] = status.action;
        }
      } else if (c.type === 'access') {
        row[c.value] = getObjectValueByKey(i, 'invitationDetails.accessType', '');
      } else if (c.type === 'action') {
        if (c.value === 'cancel') {
          row[c.value] =
            <IconButton
              tooltip={'Cancel Invitation'}
              icon={<Cancel />}
              onClick={()=>handleCancelInvite(i.id)}
            />
        }
      } else {
        row[c.value] = getObjectValueByKey(i, c.value, '');
      }
    })
    rows.push(row);
  })
  return rows;
}

interface InvitesTableI {
  invitations: InvitationT[],
  handleCancelInvite: (id:string)=>void,
  inviteType?: string
}

const InvitesTable = (props:InvitesTableI) => {
  const {invitations, handleCancelInvite, inviteType='Share Repository'} = props;
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const nonCancelled = invitations.filter((i: any) => !i.cancelled);
    setRows(getTableRows(nonCancelled, handleCancelInvite, inviteType));
  }, [invitations])

  return(
    <InvitesContainer>
      <BasicTable fields={columns[inviteType]} rows={rows}/>
    </InvitesContainer>
  )
}
export default InvitesTable;

const InvitesContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;

const Item=styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
`;