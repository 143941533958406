import React, { Fragment, useState } from 'react';
import styled from 'styled-components/macro';
import { CompactPicker } from 'react-color';
import { Box, Popover } from '@mui/material';
type Props = {
  color: string;
  onChange?: (color: string) => void;
};

export const ColorPicker = ({ color, onChange }: Props) => {
  const [selected, setSelected] = useState(color);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleChange = (value) => {
    setSelected(value);
    onChange && onChange(value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Swatch onClick={handleClick} color={color} />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderRadius: 0,
          },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            mt: '10px',
            '&::before': {
              backgroundColor: 'white',
              content: '""',
              display: 'block',
              position: 'absolute',
              width: 12,
              height: 12,
              top: -6,
              transform: 'rotate(45deg)',
              left: 'calc(50% - 6px)',
            },
          }}
        />
        <CompactPicker color={selected} onChange={handleChange} />
      </Popover>
    </Fragment>
  );
};

const Swatch = styled.button`
  background-color: ${(props) => props.color};
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
`;
