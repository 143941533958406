import { useState } from 'react';
import styled from 'styled-components/macro';

import { FlexRow } from '@components/layout/FlexStyles';
import SearchIcon from '@mui/icons-material/Search';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

interface DropdownTextSearchProps {
  searchText: string;
  searchResults: any;
  onChange: (v) => void;
  onSelect: (v) => void;
  width?: number;
  hideIcon?: boolean;
}

const SelectorTextSearch = (props: DropdownTextSearchProps) => {
  const [inputValue, setInputValue] = useState('');
  const containerWidth = props?.width ? props.width : 250;

  const handleChange = (event, value) => {
    setInputValue('');
    props.onSelect(value);
  };

  const handleInputChange = (event, value) => {
    setInputValue(value);
    if (value.length > 2) {
      props.onChange(value);
    }
  };

  return (
    <SelectorContainer>
      <StyledAutocomplete
        value={props.searchText}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        id='selector-text-search'
        options={props.searchResults}
        sx={{ width: containerWidth }}
        blurOnSelect
        renderInput={(params) => (
          <FlexRow v_centered>
            { props?.hideIcon ? null: <StyledSearchIcon /> }
            <StyledTextField
              {...params}
              variant='standard'
              label='search ...'
            />
          </FlexRow>
        )}
      />
    </SelectorContainer>
  );
};

export default SelectorTextSearch;

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  padding: 0;
`;

const StyledAutocomplete = styled(Autocomplete)`
  font-size: 10px;
  & .MuiFormLabel-root {
    font-size: 12px;
    padding: 0;
    color: ${(p) => p.theme.palette.accentSecondary};
  }
  & .MuiInput-underline:hover {
    border-bottom: 1px solid ${(p) => p.theme.palette.accentSecondary};
  }
  & .MuiInput-underline:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: 1px solid ${(p) => p.theme.palette.accentSecondary};
  }
  & .MuiInput-underline.Mui-focused:after {
    border-bottom: 1px solid ${(p) => p.theme.palette.accentPrimary};
  }
  & .MuiInput-underline.Mui-focused:hover {
    border-bottom: 1px solid ${(p) => p.theme.palette.accentPrimary};
  }
  & .MuiAutocomplete-paper {
    color: red;
    font-size: 10px;
  }
  & .MuiAutocomplete-option.Mui-focused {
    font-size: 10px;
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  display: flex;
  padding-right: 5px;
  color: ${(p) => p.theme.palette.accentPrimary};
`;

const StyledTextField = styled(TextField)`
  display: flex;
  width: 100%;
`;
