import { useState } from 'react';
import styled from 'styled-components/macro';
import { IconButton, InputAdornment } from '@mui/material';
import { VisibilityRounded, VisibilityOffRounded } from '@mui/icons-material';
import PasswordStrengthBar from './password/PasswordStrengthBar';
import PasswordReason from '@as_core/account/fields/password/PasswordReason';
import InputField from '@as_core/controls/inputs/InputField';

interface propsI {
  id: string;
  label?: string;
  value: string;
  onChange: any;
  placeHolder?: string;
  activeUpdate?: boolean;
  width?: number;
  error?: boolean;
  handleBlur?: () => void;
}

const InputNewPassword = (props: propsI) => {
  const { value, onChange, label, error, handleBlur } = props;
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisibility((prevState) => !prevState);
  };

  return (
    <TextFieldContainer>
      <PasswordStrengthBar password={value} />
      <InputField
        error={Boolean(error)}
        value={value}
        label={label}
        type={passwordVisibility ? 'text' : 'password'}
        onChange={(e) => onChange(e.target.value)}
        onBlur={handleBlur}
        autoComplete='new-password'
        InputProps={{
          autoComplete: 'new-password',
          form: {
            autocomplete: 'off',
          },
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={togglePasswordVisibility}
                onMouseDown={togglePasswordVisibility}
              >
                {passwordVisibility ? (
                  <VisibilityRounded />
                ) : (
                  <VisibilityOffRounded />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {error ? <PasswordReason password={value} /> : null}
    </TextFieldContainer>
  );
};

export default InputNewPassword;

const TextFieldContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
