import styled from 'styled-components/macro';

const PanelChildren = (props) => {
  return(
    <Children>
      {props.children}
    </Children>
  )
}
export default PanelChildren;

interface ExtraProps {
  scrollY?: boolean;
  hasBorder?: boolean;
}

const Children = styled.div<ExtraProps>`
  border-radius: ${(p) => p.theme.borderRadius};
  height:85%;
  overflow-y: ${(p) => {
  return p.scrollY ? 'scroll' : 'none';
}};
`;