import _ from 'lodash';
import React from 'react';
import { useAsync } from 'react-use';
import { useThrottleRequests } from './useThrottleRequests';
import { Compound5QueryT, CompoundT } from '../type';
import { AxiosResponse } from 'axios';
import { Compounds } from './api/';
import { ResponseT } from './api/base';

export interface CompoundUploadQueryT {
  data: Compound5QueryT;
  rowIndex: number;
  libraryId?: string;
}

export interface CompoundUploadRequestT {
  response?: AxiosResponse<ResponseT<CompoundT>>;
  error?: Error;
  index: number;
}

export default function useCompoundUpload(token:string, queries) {
  const { throttle, updateThrottle } = useThrottleRequests<any>();
  const [progressMessage, setProgressMessage] = React.useState('');

  useAsync(async () => {
    if (!queries) return;

    setProgressMessage(`uploading Compounds...`);

    // For each entry in query
    const requestsToMake = queries
      .filter((query) => !_.isEmpty(query.data.cfields))
      .map(({ data, rowIndex }, index) => async () => {
        if (_.isEmpty(data.cfields)) {
          console.log('SKIP BAD Request');
          return {};
        }
        try {
          setProgressMessage(`Uploading ${index} of ${queries.length}`);
          const response = await Compounds.upload(token, data);

          updateThrottle.requestSucceededWithData({
            response: response,
            index: rowIndex,
          } as CompoundUploadRequestT);
        } catch (error) {
          console.error(`failed to load ${index}`, error);
          updateThrottle.requestSucceededWithData({
            error,
            index: rowIndex,
          } as CompoundUploadRequestT);
        }
      });

    await updateThrottle.queueRequests(requestsToMake);

    setProgressMessage('');
  }, [queries, updateThrottle, setProgressMessage]);

  return { throttle, progressMessage };
}
