import React from 'react';
import styled from 'styled-components/macro';
import circleRenderer from '@components/compounds/valueRenderers/circleRenderer';

const LegendCellHealth = () => {
  const values = [0.0, 0.5, 1.0];
  return (
    <Legend>
      <LegendText>Low Cellular Stress</LegendText>
      <LegendCircles>
        { values.map((v, i) => {
          return(
            <Cell key={i}>
              { circleRenderer({cellData:v}) }
            </Cell>
          )
        })}
      </LegendCircles>
      <LegendText>High Cellular Stress</LegendText>
    </Legend>
  );
};

export default LegendCellHealth;

const Legend = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 3px;
  margin-right: 4px;
  height: 40px;
  width: max-content;
  border-radius: 8px;
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary };
`;

const LegendText = styled.div`
  display: flex;
  padding: 3px;
  flex-wrap: nowrap;
  color: ${(p) => p.theme.palette.textSecondary};
  font-size: ${(p) => p.theme.sizes.xsmall};
`;

const LegendCircles = styled.div`
  margin: 0 2px;
  display: flex;
`;

const Cell = styled.div`
  align-items: center;
  margin: 0 2px;
  width: 30px;
  height: 30px;
`;