import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const ExitAreaIcon = ({
    size = 24,
  }) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={colorHighlight} d="M15.3,11.5l-3.9-3.9c-0.2-0.2-0.4-0.2-0.6,0l-0.5,0.5c-0.2,0.2-0.2,0.4,0,0.6l2,2c0.3,0.3,0.1,0.7-0.3,0.7H2.8
	c-0.2,0-0.4,0.2-0.4,0.4v0.7c0,0.2,0.2,0.4,0.4,0.4h9.1c0.4,0,0.5,0.4,0.3,0.7l-1.8,1.8c-0.2,0.2-0.2,0.4,0,0.6l0.5,0.5
	c0.2,0.2,0.4,0.2,0.6,0l3.9-3.9C15.6,12.2,15.6,11.8,15.3,11.5z"/>
        <path fill={color} d="M16.7,2.5H7.5c-2.8,0-5.1,2.3-5.1,5.1v1.9c0,0.3,0.2,0.5,0.5,0.5h0.6c0.3,0,0.5-0.2,0.5-0.5V7.7c0-2,1.6-3.6,3.6-3.6h9.3
	c2,0,3.6,1.6,3.6,3.6v8.8c0,2-1.6,3.6-3.6,3.6H7.5c-2,0-3.6-1.6-3.6-3.6v-1.9c0-0.3-0.2-0.5-0.5-0.5H2.8c-0.3,0-0.5,0.2-0.5,0.5v1.9
	c0,2.8,2.3,5.1,5.1,5.1h9.3c2.8,0,5.1-2.3,5.1-5.1V7.7C21.8,4.8,19.6,2.5,16.7,2.5z"/>
      </svg>
    </>
  )
}

export default ExitAreaIcon;