import React from 'react';
import { createRoot } from 'react-dom/client'
import { StyleProvider } from "@theme/AppStyles";
import { BrowserRouter } from 'react-router-dom';

import { Provider } from "react-redux";
import store from './store';

const render = () => {
    const App = require('./App').default;

    const container = document.getElementById('root');
    const root = createRoot(container)

    root.render(
        <Provider store={store}>
            <StyleProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </StyleProvider>
        </Provider>
    );
};

render();
