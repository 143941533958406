import React, { useState } from 'react';
import styled from 'styled-components/macro';
import StationaryModal from '@components/elements/StationaryModal';
import InputText from '@components/controls/InputText';
import TextButton from '@components/controls/TextButton';
import InputField from '../inputs/InputField';

type Props = {
  title: string;
  message: string;
  alertOpen: boolean;
  closeAlert: () => void;
  onConfirm: () => void;
  width?: number;
  height?: number;
  opacity?: number;
};

const debug = false;
const AlertDeleteConfirm = (props: Props) => {
  const {
    title,
    message,
    alertOpen,
    closeAlert,
    onConfirm,
    width = 300,
    height = 200,
    opacity = 1.0,
  } = props;
  const [deleteConfirm, setDeleteConfirm] = useState('');
  debug &&
    console.log(
      'AlertDialog | title:',
      title,
      ' message:',
      message,
      ' alertOpen',
      alertOpen
    );

  // HANDLERS
  const _handleInputChange = (event) => {
    const v = event.target.value;

    const updatedValue = v.toUpperCase();
    debug && console.log('AlertDeleteConfirm | v', v, updatedValue);
    setDeleteConfirm(updatedValue);
  };
  const _handleCancel = () => {
    setDeleteConfirm('');
    closeAlert();
  };
  const _handleDelete = () => {
    setDeleteConfirm('');
    onConfirm();
    closeAlert();
  };

  return (
    <StationaryModal
      title={title}
      modalOpen={alertOpen}
      onCloseClick={closeAlert}
      opacity={opacity}
      width={width}
      height={height}
    >
      <Content>
        <Message height={height} width={width}>
          {message}
        </Message>
        <DeleteConfirm>
          <InputField
            value={deleteConfirm}
            label='Confirm Delete'
            onChange={_handleInputChange}
            size='small'
          />
        </DeleteConfirm>
        <ActionButtons>
          <TextButton text={'CANCEL'} onClick={_handleCancel} height={24} />
          <TextButton
            text={'DELETE'}
            onClick={_handleDelete}
            isWarning={true}
            isDisabled={deleteConfirm !== 'DELETE'}
            height={24}
          />
        </ActionButtons>
      </Content>
    </StationaryModal>
  );
};

export default AlertDeleteConfirm;

const Content = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  align-content: space-between;
`;

const Message = styled.div<{ height: number; width: number }>`
  display: flex;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
  width: calc(${(p) => p.width} - 100) px;
  height: calc(${(p) => p.height} - 150) px;
  border-radius: 10px;
  background-color: ${(p) => p.theme.palette.backgroundQuaternary};
  color: ${(p) => p.theme.palette.textPrimary};
`;

const DeleteConfirm = styled.div``;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;
