import React from 'react';
import styled from 'styled-components/macro';

const debug = false;
const RadioButton = ({ id, label, value, checked, onClick }) => {
  debug &&
    console.log(
      'RadioButton | id:',
      id,
      ' value:',
      value,
      ' checked:',
      checked,
      ' label:',
      label
    );
  return (
    <Container>
      <Input
        type='radio'
        id={`radiobutton-${id}`}
        value={value}
        checked={checked}
        onChange={onClick}
      />
      <Label>{label}</Label>
    </Container>
  );
};

export default RadioButton;

const Container: any = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
`;

const Label: any = styled.label`
  display: flex;
  color: ${(p) => p.theme.palette.titleSecondary};
  font-size: 14px;
  pointer-events: all;
  cursor: pointer;

  &::before {
    left: 0.1rem;
    margin-top: 7px;
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.4rem;
    border: 1px solid ${(p) => p.theme.palette.titlePrimary};
    border-radius: 50%;
    background-color: ${(p) => p.theme.palette.backgroundPrimary};
    content: '';
  }
`;

const Input: any = styled.input`
  display: flex;
  pointer-events: all;

  &:checked + label::before {
    background-color: ${(p) => p.theme.palette.titlePrimary};
  }
`;
