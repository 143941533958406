import React from 'react';
import { rgb, hsl } from 'd3-color';
import { Theme } from '@nivo/core';
import { BarDatum, BarTooltipProps } from '@nivo/bar';
import { TooltipContainer } from '@dataviz/TooltipContainer';
import { BoxPlotTooltipProps } from '@nivo/boxplot';

export const getBoxplotTooltip = (theme: Theme) => {
  return (props: BoxPlotTooltipProps) => {
    const { formatted, label, color } = props;
    const { n, mean, extrema } = formatted;

    const nodeColor = rgb(color);
    const baseColor = hsl(nodeColor.formatHsl());
    baseColor.l = Math.max(0.7, baseColor.l + 0.2);

    return (
      <TooltipContainer>
        <p style={{ color: baseColor.toString(), fontSize: 14 }}>
          <b> {label}</b>
        </p>
        <p>{'Min: ' + parseFloat(Number(extrema[0]).toFixed(3))}</p>
        <p>{'Mean: ' + parseFloat(Number(mean).toFixed(3))}</p>
        <p>{'Max: ' + parseFloat(Number(extrema[1]).toFixed(3))}</p>
        <p>{'n: ' + parseFloat(Number(n).toFixed(0))}</p>
      </TooltipContainer>
    );
  };
};
