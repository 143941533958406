import { AxiosRequestConfig } from 'axios';
import { Fields } from './api/fields.api';
import { CompoundFieldT} from "@stores/fields";
import { handleError } from '@utils/misc';
import useCognito from "@as_core/account/useCognito";

export interface FieldsResponseT {
  errors: Array<string>;
  data: Array<CompoundFieldT>;
}

export interface ResponseT {
  errors: Array<string>;
  data: Array<any>;
}

const useFields = () => {
  const { getToken } = useCognito();
  const getAllFields = async (token: string): Promise<FieldsResponseT> => {
    let resp;

    try {
      resp = await Fields.all(token);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getFields ERRORS', resp.data.errors);
    }
    return resp.data;
  };

  const deleteField = async (token: string, params): Promise<ResponseT> => {
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await Fields.remove(token, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('deleteField ERRORS', resp.data.errors);
    }
    return resp.data;
  };

  const getSpecificsFields = async (inputData) => {
    const token = await getToken();
    let resp;
    try {
      resp = await Fields.match(inputData, token);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getFields ERRORS', resp.data.errors);
    }
    return resp.data;
  };

  return {
    getAllFields,
    deleteField,
    getSpecificsFields
  };
};
export default useFields;
