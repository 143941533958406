import {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import styled from "styled-components/macro";
import HoverInfoText from "@as_core/account/fields/HoverInfoText";
import TextButton from "@as_core/controls/buttons/TextButton";
import UploadIcon from "@components/icons/upload.icon";

const addUnique = (array1: any[], array2: any[]) => {
  let array1Names = array1.map((a) => a.name);
  let newArray = array1;
  array2.forEach((f) => {
    if (!array1Names.includes(f.name)) {
      newArray.push(f)
    }
  })
  return newArray;
}

type PropsT = {
  handleUpload: (f: any) => void;
}

const debug = false;
const FileUpload = (props:PropsT) => {
  const [uploadFiles, setUploadFiles] = useState<any[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState<any[]>([]);
  const [isDataUpdated, setIsDataUpdated] = useState<boolean>(false);
  const { acceptedFiles,
          fileRejections,
          getRootProps
  } = useDropzone({
    accept: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
  });

  useEffect(() => {
    debug && console.log("UploadComponent | useEffect | acceptedFiles:",
      acceptedFiles.length, 'rejectedFiles', fileRejections.length);
    let updated = false;
    if (acceptedFiles.length) {
      updated = true;
      setUploadFiles(prevState => addUnique(prevState, acceptedFiles));
    }
    if (fileRejections.length) {
      updated = true;
      setRejectedFiles(fileRejections);
    }
    if (updated) setIsDataUpdated(true);
  }, [acceptedFiles, fileRejections]);

  useEffect(() => {
    setIsDataUpdated(false);
  }, [isDataUpdated]);

  debug && console.log('uploadFiles', uploadFiles, 'rejectedFiles', rejectedFiles);

  return (
    <Container className='containerInUploadComponent'>
      <DropZoneContainer>
        <StyledDropZone {...getRootProps({ className: 'dropzone' })}>
          <Row><StyledFileUploadIcon sx={{fontSize: '96px'}}/></Row>
          <Row>Drag and drop files</Row>
          <Row>-OR-</Row>
          <Row><Button>Click to Select files</Button></Row>
        </StyledDropZone>
      </DropZoneContainer>
      <FilesContainer>
        <FileRow><FileHeader>Files to Upload:</FileHeader>
          { uploadFiles.length ?
            <>
                {
                  uploadFiles.map((f) => (
                    <FileItem><FileName>{f.name}</FileName></FileItem>
                  ))
                }

            </>
            :
            null
          }
        </FileRow>
        { rejectedFiles.length ?
          <FileRow>
            <FileHeader>Ignored Files:</FileHeader>
            {
              rejectedFiles.map((f) => (
                <FileItem>
                  <FileName>{f.file.name}</FileName><HoverInfoText text='Files must be spreadsheet'/>
                </FileItem>
              ))
            }
          </FileRow>
          :
          null
        }
      </FilesContainer>
      <FileRow>
        <TextButton
          icon={<UploadIcon />}
          width={200}
          label={'Upload Files'}
          onClick={()=>props.handleUpload(uploadFiles)}
          isDisabled={uploadFiles.length===0}
        />
      </FileRow>
    </Container>
  );
}

export default FileUpload;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const DropZoneContainer = styled.div`
  display: flex;
  width: 300px;
  height: 200px;
  padding: 5px;
`;

const StyledDropZone = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 15px;
  border: 2px dashed white;
`;

const Row = styled.div`
  display: flex;
  color: ${(p) => p.theme.palette.textPrimary};
  font-size: 18px;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100px;
  width: calc(100% - 20px);
  margin-top: 10px;
  font-size: 12px;
`;

const FileRow = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  justify-content: start;
  width: max-content;
  margin-bottom: 5px;
`;

const FileItem = styled.div`
  display: flex;
  font-size: 14px;
  padding: 0 5px;
  border-radius: 15px;
  margin: 0 2px;
  background: ${(p) => p.theme.palette.textPrimary};
  color: ${(p) => p.theme.palette.backgroundPrimary};
`;

const FileHeader = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: bold;
  padding-right: 5px;
  width: 125px;
  color: ${(p) => p.theme.palette.accentSecondary};
`;

const FileName = styled.div`
  display: flex;
`;

const StyledFileUploadIcon = styled(DriveFolderUploadIcon)`
  color: ${(p) => p.theme.palette.accentSecondary};
`;

const Button = styled.button`
  cursor: pointer;
  color: ${(p) => p.theme.palette.textPrimary};
  font-weight: bold !important;
  font-size: 16px;
  height: 32px;
  width: 250px;
  background: ${(p) => p.theme.palette.accentSecondary};
  border-radius: 15px;
  :hover {
    background: ${(p) => p.theme.palette.accentPrimary};
    color: ${(p) => p.theme.palette.backgroundPrimary};
  }
`;