import { UserT } from '@stores/UserContext';
import useCognito from "@as_core/account/useCognito";
import useCache from "@as_core/hooks/useCache";
import {AxiosRequestConfig} from "axios";
import {authClient} from "@utils/api/base";

// generalized ApiResponse for Invitations API Calls
type ApiResponseT<T> = {
  data: {
    errors: string[],
    data: T
  }
  status: number, 
  statusText: string
}

type ActionInfoT = {
  action: string;
  actionDate: string;
}

// type of the invitation object
export type InvitationT = {
  system: string;
  invitationType: 'Share Repository' | 'Share Subscription';
  invitationDetails: {[key: string]: any};
  invitor: {authId: string, authEmail: string};
  invitee: {authEmail: string};
  actions: ActionInfoT[];
}

// api call structure
const Invitations = {
  get: (token:string, config) => authClient(token).get('/invitations', config),
  getByRepo: (token: string, repoId:string) => authClient(token).get(`/invitations/org/${repoId}`, null),
  getBySub: (token: string, subId:string) => authClient(token).get(`/invitations/subscription/${subId}`, null),
  create: (token:string, config) => authClient(token).post('/invitations/create', config),
  update: (token:string, action: string, data) => authClient(token).put(`/invitations/update/${action}`, data),
  delete: (token:string, uuid: string) => authClient(token).post('/invitations/delete', uuid),
};

// code for testing if invitation is active (no valid response)
export function isInvitationActive(invitation: InvitationT) {
  if (!invitation.actions.length) return true;
  const lastAction = invitation.actions[invitation.actions.length - 1];
  return !(['cancel', 'accept'].includes(lastAction.action));
}

export function getInvitationStatus(invitation: InvitationT): ActionInfoT {
  if (!invitation.actions.length) return {action: "pending", actionDate: ""};
  return invitation.actions[invitation.actions.length - 1];
}


// useInvitations hook to make the UI cleaner
const useInvitations = () => {
  const {getToken} = useCognito();
  const invitationsCache = useCache<InvitationT[]>();

  const getByRepository = async (
    repoId: string,
    useCache: boolean = true
  ): Promise<InvitationT[]> => {
    if (useCache) {
      const value = invitationsCache.get(repoId);
      if (value !== null) {
        return value;
      }
    }

    let resp: ApiResponseT<InvitationT[]>;
    try {
      resp = await Invitations.getByRepo(getToken(), repoId);
    } catch (err) {
      alert(err);
      console.error('Invitation decline', resp.data.errors);
      return null;
    }
    if (resp.data.errors.length > 0) {
      alert(resp.data.errors);
      console.error('Invitation getByRepo', resp.data.errors);
      return []
    }
    invitationsCache.add(repoId, resp.data.data);
    return resp.data.data;
  };

  const getBySubscription = async (
    subId: string,
    useCache: boolean = true
  ): Promise<InvitationT[]> => {
    if (useCache) {
      const value = invitationsCache.get(subId);
      if (value !== null) {
        return value;
      }
    }

    let resp: ApiResponseT<InvitationT[]>;
    try {
      resp = await Invitations.getBySub(getToken(), subId);
    } catch (err) {
      alert(err);
      console.error('Invitation decline', resp.data.errors);
      return null;
    }
    if (resp.data.errors.length > 0) {
      alert(resp.data.errors);
      console.error('Invitation getByRepo', resp.data.errors);
      return []
    }
    invitationsCache.add(subId, resp.data.data);
    return resp.data.data;
  };

  const getByInvitee = async (
    email: string
  ) => {
    const params = {'inviteeAuthEmail': email.toLowerCase()}
    const config: AxiosRequestConfig ={params};
    let resp;

    try {
      resp = await Invitations.get(getToken(), config);
    } catch (err) {
      alert(err)
      console.error('Invitation decline', resp.data.errors);
      return null;
    }
    if (resp.data.errors.length > 0) {
      alert(resp.data.errors);
      console.error('Invitation getByInvitee', resp.data.errors);
    }
    return resp.data.data;
  };

  const inviteUser = async (
    invitor: UserT,
    inviteEmail: string,
    inviteType: 'Share Repository' | 'Share Subscription',
    inviteDetails: {[key: string]: string}
  ) => {
    let resp;

    try {
      resp = await Invitations.create(
        getToken(),
        {
          system: '3RnD',
          invitor: {
            authId: invitor.authId,
            authEmail: invitor.authEmail
          },
          invitee: {
            authEmail: inviteEmail.toLowerCase()
          },
          invitationType: inviteType,
          invitationDetails: inviteDetails
        },
      );
    } catch (err) {
      alert(err)
      console.error('Invitation decline', resp.data.errors);
      return null;
    }
    if (resp.data.errors.length > 0) {
      alert(resp.data.errors);
      console.error('Invitation create', resp.data.errors);
    }
    invitationsCache.reset();
    return resp.data.data;
  };

  const cancelInvite = async (
    inviteId: string
  ) => {
    let resp;
    try {
      resp = await Invitations.update(
        getToken(),
        'cancel',
      { id: inviteId }
      )
    } catch (err) {
      alert(err)
      console.error('Invitation decline', resp.data.errors);
      return null;
    }
    if (resp.data.errors.length > 0) {
      alert(resp.data.errors);
      console.error('Invitation cancel', resp.data.errors);
    }
    invitationsCache.reset();
    return resp.data.data;
  };

  const respondToInvite = async (
    action: string,
    invitee: UserT,
    inviteId: string
  ) => {
    let resp;
    try {
      resp = await Invitations.update(
        getToken(),
        action,
        {
          id: inviteId,
          inviteeAuthEmail: invitee.authEmail,
          inviteeAuthId: invitee.authId
        }
      )
    } catch (err) {
      alert(err)
      console.error('Invitation decline', resp.data.errors);
      return null;
    }
    if (resp.data.errors.length > 0) {
      alert(resp.data.errors);
      console.error('Invitation decline', resp.data.errors);
    }
    return resp.data.data;
  };

  return {
    getByRepository,
    getBySubscription,
    getByInvitee,
    inviteUser,
    cancelInvite,
    respondToInvite
  };
};

export default useInvitations;
