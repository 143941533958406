import React from "react";
import styled from "styled-components/macro";
import CircleRenderer from "@components/compounds/valueRenderers/circleRenderer";
import {truncateString} from "@utils/misc";
import zebraRenderer from "@components/compounds/valueRenderers/zebraRenderer";
import MolImgTile from "@components/compounds/images/MolImgTile";
import {Tooltip} from "@mui/material";

export const dataRenderer = (value: number|string|object, renderer: string, index: number) => {
  // console.log(value, renderer);
  if (renderer === 'string') {
    if (value.toString().length < 30) return value;
    return <Tooltip title={value.toString()}>{truncateString(value, 30)}</Tooltip>;
  }
  if (renderer === 'float') {
    return Number(value).toFixed(2).toString();
  }
  if (renderer === 'integer') {
    return value.toString();
  }
  if (renderer === 'multiselect') {
    const values = value.toString().split(';');
    return(
      <MultiValues>
        {values.map((v)=>
          <SingleValue>{v}</SingleValue>
        )}
      </MultiValues>
    )
  }
  if (renderer === 'circle') {
    return <CircleRenderer cellData={value} />;
  }
  if (renderer === 'zebrafish') {
    return zebraRenderer(
      {
        'className': 'zf_legend'+index,
        'cellData': value,
        'column': { barsHorizontal: false}
      });
  }
  if (renderer === 'mol_svg') {
    console.log('mol_svg', value, Object(value));
    return <MolImgTile width={200} mol_svg={Object(value)} hover={false} />
  }
  console.error('dataRenderer | unknown renderer', renderer);
}

const MultiValues = styled.div`
  display: flex;
  padding: 3px 0;
  flex-direction: column;
`;

const SingleValue = styled.div`
  display: flex;
  white-space: nowrap;
  height: max-content;
  padding: 0 3px;
  margin-bottom: 4px;
  border-radius: 7px;
  background-color: ${(p) => p.theme.palette.accentPrimary};
  color: ${(p) => p.theme.palette.textPrimary};
`;