import { useContext } from 'react';
import styled from 'styled-components/macro';
import { UserContext } from '@stores/UserContext';
import PageContainer from '@as_core/pages/PageContainer';
import {FlexBanner} from '@as_core/elements/flexStyles';
import {useNavigate, useParams} from "react-router-dom";
import HeaderMenu from "@as_core/controls/HeaderMenu";
import SubscriptionView from "@business/subscriptions/components/SubscriptionView";
import SubscriptionsAvailable from "@business/subscriptions/SubscriptionsAvailable";
import SubscriptionOrder from "@business/subscriptions/SubscriptionOrder";
import {MenuContext} from "@stores/MenuContext";

type actionT = {
  action: string,
  label: string
}

const MENU_ACTIONS : Array<actionT> = [
  { action: 'current', label: 'Current Subscription'},
  { action: 'available', label: 'Available Subscriptions'},
  { action: 'order', label: 'Order Subscription'}
]

const debug = false;
const PgUserSubscriptions = () => {
  let { action } = useParams();
  if (action === undefined) action = MENU_ACTIONS[0].action;
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);
  debug && console.log('PgUserSubscriptions: action:', action, ' user:', user);

  if (activeMenuId !== 'subscriptions') setActiveMenuId('subscriptions');

  const handleMenuClick = (a) => {
    console.log('handleMenuClick', a);
    navigate(`/subscriptions/${a}`)
  }

  return (
    <PageContainer title='User Subscriptions' scroll={false} padding={0}>
      <FlexBanner>
        { MENU_ACTIONS.map((m, index) => (
          <HeaderMenu
            key={`headermenu_subscriptions_${index}`}
            label = {m.label}
            isActive = {m.action===action}
            onClick = {() => handleMenuClick(m.action)}
          />
        ))}
      </FlexBanner>
      <ActionContainer>

        { action === 'current' ?
            <SubscriptionView />
          : action === 'order' ?
            <SubscriptionOrder />
          :
            <SubscriptionsAvailable />
        }
      </ActionContainer>
    </PageContainer>
  );
};

export default PgUserSubscriptions;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 120px);
  padding-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
`;
