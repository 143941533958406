import React from 'react';
import styled from 'styled-components/macro';
import {RemoveCircle} from "@mui/icons-material";

const SelectedCompoundsPanel = ({selectedCompounds}) => {

  function handleDelete(id) {
    selectedCompounds.delete(id);
  }

  return (
    <Container>
      <CompoundList>
        {selectedCompounds.data.map((v) => (
            <ListItem>
              <DeleteCompound onClick={()=>handleDelete(v)}><RemoveCircle sx={{ fontSize: 20}}/></DeleteCompound>
              <CompoundItem>{v}</CompoundItem>
            </ListItem>
        ))}
      </CompoundList>
    </Container>
  );
};

export default SelectedCompoundsPanel;

const Container: any = styled.div`
  display: flex;
  height: 230px;
  flex-direction: column;
  margin: 5px;
  font-size: 18px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const CompoundList = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;
  font-size: 12px;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const ListItem = styled.div`
  display: flex;
  max-width: 300px;
  flex-direction: row;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const CompoundItem = styled.div`
  display: flex;
  font-size: 12px;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const DeleteCompound = styled.div`
  display: flex;
  width: 24px;
  color: ${(p)=> p.theme.palette.accentSecondary};
  font-size: 14px;
  font-weight: bold;
  :hover {
    color: ${(p) => p.theme.palette.accentPrimary};
  }
`;

