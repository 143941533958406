import PageContainerNew from '@as_core/pages/PageContainer';
import ZoomCircle from "../sandbox/ZoomCircle";

const PgSandbox = () => {

  return (
    <PageContainerNew title='Sandbox' >
      <ZoomCircle />
    </PageContainerNew>
  );
};

export default PgSandbox;
