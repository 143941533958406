import React, {useState} from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';
import BasicTable from '@as_core/tables/BasicTable';
import RequestFormDetails from "@subApps/orders/components/Assays/RequestFormDetails";
import {TableFieldsT} from "@subApps/orders/utils";
import {RequestFormT} from "@subApps/orders/hooks/useRequestForms";
import MenuButton from "@components/elements/MenuButton";
import ViewIcon from "@as_core/icons/view.icon";
import HoverTextTruncate from "@as_core/elements/HoverTextTruncate";
import StationaryModal from "@as_core/elements/StationaryModal";

const tableFields: {[key: string]: TableFieldsT[]} = {
  'short': [
    {value: 'view', label: '', type: 'action', width: 40},
    {value: 'file_name', label: 'File', type: 'file', width: 150},
  ],
  'long': [
    {value: 'view', label: '', type: 'action', width: 40},
    {value: 'original_file_name', label: 'Uploaded File Name', type: 'string', width: 150},
    {value: 'file_name', label: 'Saved File Name', type: 'string', width: 150},
  ]
};

const getTableRows = (requestForms: RequestFormT[], fields: TableFieldsT[], handleViewClick: (id:string)=>void) => {
  const rows = [];
  requestForms.forEach((form) => {
    const row = [];
    fields.forEach((f:TableFieldsT) => {
      if (f.type === 'action') {
        row[f.value] = <MenuButton
          onClick={() => handleViewClick(form?.id)}
          icon={<ViewIcon/>}
          text={'View Plate Map Details'}
          tooltipPlacement={'bottom'}
        />;
      } else if (f.type === 'string_truncate') {
        row[f.value] = <HoverTextTruncate text={form.original_file_name} />
      } else {
        row[f.value] = _.get(form, f.value, '');
      }
    });
    rows.push(row);
  })
  return rows;
}

type PropsT = {
  requestForms: RequestFormT[];
  short?: boolean;
  header?: boolean;
}


const debug = false;
const RequestFormsTable = (props:PropsT) => {
  const { requestForms, short=true, header= false } = props;
  debug && console.log('requestFormsTable | requestForms: ', requestForms);
  const [selectedRequestForm, setSelectedRequestForm] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleClick = (requestFormId: string) => {
    debug && console.log('handleClick: ', requestFormId,);
    const matched:RequestFormT = requestForms.find((o) => o.id === requestFormId);
    if (matched) {
      setSelectedRequestForm(matched);
      setIsModalOpen(true);
    } else {
      console.error(`RequestsTable | unable to locate request by id ${requestFormId})`);
    }
  };

  return (
    <RequestTableContainer>
      { requestForms !== null && requestForms.length !== 0 ?
        <BasicTable
          header={header}
          fields={short ? tableFields['short']: tableFields['long']}
          rows={getTableRows(requestForms, short ? tableFields['short']: tableFields['long'], handleClick)}
          border={false}
        />
        :
        <></>
      }
      <StationaryModal
        title={'Request Form Details'}
        opacity={0.95}
        height={280}
        width={500}
        modalOpen={isModalOpen}
        onCloseClick={() => setIsModalOpen(false)}
      >
        <RequestFormDetails
          requestForm={selectedRequestForm}
          handleBack={()=>setSelectedRequestForm(null)}
        />
      </StationaryModal>
    </RequestTableContainer>
  );
};

export default RequestFormsTable;


const RequestTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
