import styled from "styled-components/macro";
import {AccountTree, GroupAdd} from "@mui/icons-material";
import HoverInfoText from "@as_core/account/fields/HoverInfoText";
import Button from "@ui-components/controls/Button";
import {getObjectValueByKey, truncateString} from "@utils/misc";
import {InvitationT} from "@utils/api/useInvitations";

function getCreatedDate(invitation: InvitationT) {
  const value = getObjectValueByKey(invitation, 'created', '');
  if (value === '') return value;
  return new Date(value).toLocaleDateString();
}

function getShowValue(invitation: InvitationT, field: string, subscription: string) {
  let value = getObjectValueByKey(invitation, field, 'Unknown');
  if (value === 'Unknown') return value;
  if (field === 'invitationDetails.accessType') {
    // if basic -- regardless of accessType -- it will be readOnly
    if (subscription === 'basic') value = 'readOnly';
    return value;
  }
}

function getValueTruncate(invitation: InvitationT, field: string) {
  const value = getObjectValueByKey(invitation, field, 'Unknown');
  return truncateString(value, 32);
}

interface PropsT {
  type: string;
  invite: any;
  handleInviteResponse: (response:string, inviteType: string, id:string) => void;
  subscription: string;
}

const debug = false;
const InvitationCard = (props:PropsT) => {
  const { invite } = props;
  const { invitationType:inviteType } = invite;
  debug && console.log('props', props);

  return(
    <InviteCardContainer>
      <Row>
        { inviteType === 'Share Repository' ?
          <ShareRepoIcon sx={{fontSize: 32}}/>
          :
          <ShareSubIcon sx={{fontSize: 32}}/> }
        <Title>{inviteType} Invitation</Title>
      </Row>
      { inviteType === 'Share Repository' ?
      <Row>
        <TextAccent>Invitation to join: </TextAccent>
        <Text>{getValueTruncate(invite, 'invitationDetails.repoName')}</Text>
        <HoverInfoText text={getObjectValueByKey(invite, 'invitationDetails.repoId', 'Unknown') + ' (' +
          getObjectValueByKey(invite, 'invitationDetails.orgId', 'Unknown') + ')'}/>
      </Row>
        : null }
      <Row>
        <TextAccent>Invited by:</TextAccent>
        <Text>{getObjectValueByKey(invite, 'invitor.authEmail', 'Unknown')}</Text>
      </Row>
      <Row>
        <TextAccent>Invited at:</TextAccent>
        <Text>{getCreatedDate(invite)}</Text>
        { inviteType === 'Share Repository' ?
          <>
            <Spacer />
            <TextAccent>Access Type:</TextAccent>
            <Text>{getShowValue(invite, 'invitationDetails.accessType', props.subscription)}</Text>
          </>
        : null }
      </Row>
      <Actions>
        <Button
          label={'Accept'}
          width={100}
          onClick={()=>props.handleInviteResponse('accept', invite.invitationType, invite.id)}
          />
        <Button
          label={'Decline'}
          width={100}
          onClick={()=>props.handleInviteResponse('decline', invite.invitationType, invite.id)}
        />
      </Actions>
    </InviteCardContainer>
  )
}
export default InvitationCard

const InviteCardContainer = styled.span`
  display: flex;
  font-size: 12px;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  margin: 5px;
  width: 325px;
  height: 150px;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  background: ${(p) => p.theme.palette.backgroundPrimary};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${(p) => p.theme.palette.textPrimary};
`;

const ShareRepoIcon = styled(AccountTree)`
  display: flex;
  font-size: 32px;
  margin-right: 5px;
  color: ${(p) => p.theme.palette.accentPrimary};
`;

const ShareSubIcon = styled(GroupAdd)`
  display: flex;
  font-size: 32px;
  margin-right: 5px;
  color: ${(p) => p.theme.palette.accentPrimary};
`;

const Text = styled.div`
  display: flex;
  white-space: nowrap;
  font-size: 12px;
  margin-left: 5px;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const TextAccent = styled(Text)`
  color: ${(p) => p.theme.palette.accentSecondary};
`;


const Actions = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  width: 100%;
  justify-content: space-around;
`;

const Spacer = styled.div<{width?: number}>`
  display: flex;
  width: ${(p) => p.width}px;
`;
Spacer.defaultProps = {
  width: 10
}