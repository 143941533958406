import { useState } from 'react';
import { PlotBase, PlotConfig } from '../../../plot.types';
import { debounce } from '@utils/debounce';
import { LayerCard } from '../LayerCard';
import { DEFAULT_TITLE_SIZE } from '@dataviz/constants';
import InputField from '@as_core/controls/inputs/InputField';

type TitleCardProps = {
  plot: PlotConfig<PlotBase>;
  onChange?: (config: PlotConfig<PlotBase>) => void;
};

export const TitleCard = ({ plot, onChange }: TitleCardProps) => {
  const [title, setTitle] = useState(plot.name);

  const [expanded, setExpanded] = useState(false);

  const updateTitle = (event) => {
    plot.name = event.target.value;
    setTitle(plot.name);
    debounce('TitleCard', () => onChange(plot), 200);
  };

  const updateFontSize = (event) => {
    const size = Number.parseInt(event.target.value);
    const newPlot = {
      ...plot,
      config: { ...plot.config, titleFontSize: size },
    };
    onChange(newPlot);
  };

  const basicControls = (
    <InputField
      label='title'
      id='title'
      size='small'
      value={title}
      onChange={updateTitle}
    />
  );

  const advancedControls = (
    <InputField
      value={plot.config.titleFontSize || DEFAULT_TITLE_SIZE}
      label='font size'
      onChange={updateFontSize}
      type='number'
      margin='dense'
      size='small'
    />
  );

  return (
    <LayerCard title={'Title'} onChange={setExpanded}>
      {basicControls}
      {expanded && advancedControls}
    </LayerCard>
  );
};
