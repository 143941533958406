import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const CompoundUploadIcon = ({
    size = 24,
  }) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path stroke={color} strokeWidth={10} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
              d="M61,367.7l194.1,112l194.1-112v-224L255,31.6l-194,112V367.7z"/>
        <circle fill={color}  cx="255" cy="31.4" r="25.8"/>
        <circle fill={color}  cx="61" cy="144.5" r="25.8"/>
        <circle fill={color}  cx="449.1" cy="141.2" r="25.8"/>
        <circle fill={color}  cx="255" cy="479.9" r="25.8"/>
        <circle fill={color}  cx="449.1" cy="367.4" r="25.8"/>
        <ellipse transform="matrix(6.652053e-02 -0.9978 0.9978 6.652053e-02 -309.6626 403.8109)"
                 fill={color}  cx="61" cy="367.4" rx="25.8" ry="25.8"/>
        <path stroke={colorHighlight} strokeWidth={10} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10} d="M329.6,255.4c-4.1,0-7.4,3.3-7.4,7.4v31c0,11.3-9.2,20.5-20.5,20.5h-91.5c-11.3,0-20.5-9.2-20.5-20.5v-31
        c0-4.1-3.3-7.4-7.4-7.4c-4.1,0-7.4,3.3-7.4,7.4v31c0,19.5,15.8,35.2,35.2,35.2h91.5c19.5,0,35.2-15.8,35.2-35.2v-31
        C337,258.7,333.7,255.4,329.6,255.4z"/>
        <path fill={colorHighlight} d="M217,223.7l31.6-31.6v92.7c0,4.1,3.3,7.4,7.4,7.4s7.4-3.3,7.4-7.4v-92.7l31.6,31.6c2.9,2.8,7.6,2.7,10.4-0.2
        c2.8-2.9,2.8-7.4,0-10.2l-44.2-44.2c-2.9-2.9-7.5-2.9-10.4,0l-44.2,44.2c-2.8,2.9-2.7,7.6,0.2,10.4
        C209.6,226.5,214.2,226.5,217,223.7z"/>
      </svg>
    </>
  )
}

export default CompoundUploadIcon;