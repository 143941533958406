import React from 'react';
import { LayerCard } from '../LayerCard';
import {
  HistogramBase,
  PlotConfig,
} from '@plotting/single-plot-view/plot.types';
import { VariableSelect } from '@plotting/controls/VariableSelect';
import { Slider, Typography } from '@mui/material';

type HistogramCardProps = {
  plot: PlotConfig<HistogramBase>;
  onChange: (newPlotConfig: PlotConfig<HistogramBase>) => void;
};

export const HistogramCard = ({ plot, onChange }: HistogramCardProps) => {

  const updateValue = (columnName: string) => {
    const newPlotConfig: PlotConfig<HistogramBase> = {
      ...plot,
      config: { ...plot.config, valueColumn: columnName },
    };
    onChange(newPlotConfig);
  };

  const updateBinNumber = (e: any, binNumber: number) => {
    const newPlotConfig: PlotConfig<HistogramBase> = {
      ...plot,
      config: { ...plot.config, binNumber },
    };
    onChange(newPlotConfig);
  };

  return (
    <>
      <LayerCard title={'Histogram'} disableExpand>
        <VariableSelect
          id='variable-value'
          label={'Numeric Variable'}
          value={plot.config.valueColumn}
          options={plot.columns}
          onChange={updateValue}
        />
        <br />
        <Typography
          color={'textSecondary'}
          sx={{ marginBottom: '0px', fontSize: 14 }}
        >
          Number of bins
        </Typography>
        <Slider
          defaultValue={plot.config.binNumber}
          step={1}
          marks
          size='small'
          min={5}
          max={50}
          valueLabelDisplay='auto'
          onChange={updateBinNumber}
        />
      </LayerCard>
    </>
  );
};
