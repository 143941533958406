import React from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';

import MenuButton from '@components/elements/MenuButton';
import {FlexBanner, FlexBannerGroup, FlexItem} from "@components/layout/FlexStyles";
import EditIcon from '@components/icons/edit.icon';
import NearNeighborsIcon from '@components/icons/nearNeighbors.icon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { FieldSliceT } from '@stores/fields';
import BackArrowIcon from "@components/icons/backArrow.icon";
import {useNavigate} from "react-router-dom";
import InputSearchText from "@as_core/controls/inputs/InputSearchText";

const debug = false;
const DetailBanner = ({
  view,
  searchText,
  updateSearch,
  handleKeyDown,
  handleSetView,
  handleNotesChange,
  compound,
  neighbors,
}) => {
  const { fields: allFields } = useSelector(
    (state: RootState) => state.fields as FieldSliceT
  );

  const getTitle = () => {
    const name = _.get(compound, allFields['compound_name']?.data_key, '');
    const casrn = _.get(compound, allFields['casrn']?.data_key, '');
    let title = name;
    if (casrn !== '') {
      title = title + ' (' + casrn + ')';
    }
    return title;
  };

  const navigate = useNavigate();
  const handleBack = () => {
    debug && console.log('CompoundCompareData | Handle Back');
    navigate(-1);
  };

  debug && console.log("DetailBanner | neighbors:", neighbors)

  return (
    <FlexBanner>
      <FlexBannerGroup>
        <MenuButton
            text={'Return to prior view'}
            onClick={handleBack}
            icon={<BackArrowIcon size={32} />}
            width={220}
            height={40}
        />
        <Title>{getTitle()}</Title>
      </FlexBannerGroup>
      <FlexBannerGroup>
        <FlexItem width={'500px'}>
          <InputSearchText
              value={searchText}
              setValue={updateSearch}
              onKeyDown={handleKeyDown}
              showLabel={false}
          />
        </FlexItem>
      </FlexBannerGroup>
      <FlexBannerGroup>
        <MenuButton
            onClick={neighbors?.length ? () => handleSetView('neighbors') : () => {}}
            notificationCount={neighbors?.length}
            icon={<NearNeighborsIcon />}
            isActive={view === 'neighbors'}
            text={'Tanimoto Neighbors'}
            tooltipPlacement={'bottom'}
        />
        <MenuButton
          onClick={handleNotesChange}
          icon={<EditIcon />}
          isActive={view === 'notes'}
          text={'Notes'}
          tooltipPlacement={'bottom'}
        />
      </FlexBannerGroup>
    </FlexBanner>
  );
};

export default DetailBanner;

const Title = styled.h2`
  padding-left: 10px;
  white-space: nowrap;
  font-size: 22px;
  font-family: ${(p) => p.theme.fonts.main};
  font-weight: ${(p) => p.theme.weights.heavy};
  color: ${(p) => p.theme.palette.titlePrimary};
`;
