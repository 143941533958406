import {
  PlotData,
  Dictionary,
  PlotBase,
  PlotConfig,
} from './single-plot-view/plot.types';


// Function to find all the available columns in the dataset of a plot config
export const getPlotVariables = (
  config: PlotConfig<PlotBase>
): Array<string> => {
  return config.data ? (getKeys(config.data, {}) as Array<string>) : [];
};

//
// Functions to manipulate data
//
export const matches = (candidate: Dictionary, source: Dictionary) => {
  const keys = Object.keys(source);
  if (keys.length === 0) {
    return true;
  } else {
    return keys.every((key) => {
      return candidate[key] === source[key];
    });
  }
};

const getKeys = (
  data: PlotData,
  source: Dictionary
): Array<string | number> => {
  const filtered = data.filter((entry) => matches(entry, source));
  const keys = [];
  filtered.forEach((record) => {
    Object.getOwnPropertyNames(record).forEach((key) => {
      if (!keys.includes(key)) keys.push(key);
    });
  });
  return sortValues(keys);
};


export const sortValues = (
  values: Array<string | number>
): Array<string | number> => {
  const byType = (a, b) => {
    if (typeof a === 'number' && typeof b === 'number') {
      return a - b;
    } else {
      return a < b ? -1 : 1;
    }
  };
  return values.sort(byType) as Array<string | number>;
};



