import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { TextSmallItalics} from '@components/elements/TextStyles';

import { DragDropContext } from 'react-beautiful-dnd';
import ColumnDND from './ColumnDND';
import { RootState } from '../../../store';
import { DnDStateT } from '../../../type';
import { updateCompoundView } from '@stores/compoundViews';
import { v4 as uuidv4 } from 'uuid';
import useCognito from "@as_core/account/useCognito";

const AVAILABLE_FIELDS_ID = 'availableFields';
const INITCVID = { uuid: uuidv4(), title: '', viewType: 0, fieldIds: [] };

const intialState = (): DnDStateT => {
  return {
    fields: {},
    columnOrder: [],
    columns: {},
  };
};

interface ConfigDNDT {
  configId: string,
  width?: number,
  height?: number
}

const debug = false;

const ConfigDND = (props:ConfigDNDT) => {
  const { configId, width=800, height=400 } = props;
  // const configId = 'myCompounds';  // DHR 2023-07-05  set this to static -- need to add more views in the future.
  const dispatch = useDispatch();
  const { getToken } = useCognito();
  const [state, setState] = useState<DnDStateT>(intialState);
  const [compoundViewItem, setCompoundViewItem] = useState(INITCVID);

  debug && console.log("PgConfigDND | configId:", configId);

  const { fieldIds: allFieldIds, fields: allFields } = useSelector(
    (state: RootState) => state.fields
  );
  debug && console.log("PgConfigDND {allFieldIds, allFields}", allFieldIds, allFields);

  const { views } = useSelector((state: RootState) => state.compoundViews);
  debug &&  console.log("PgConfigDND {views}", views);

  useEffect(() => {
    setCompoundViewItem(_.get(views, configId, INITCVID));
  }, [views, configId]);
  debug && console.log("PgConfigDND {compoundViewItem}", compoundViewItem);

  const userFieldIds = useCallback(() => {
    return _.get(compoundViewItem, 'fieldIds', [])
        .filter((item) => allFieldIds.includes(item));
  }, [compoundViewItem]);

  const availableFieldIds = useCallback(() => {
    return [...allFieldIds]
      .filter((fieldId) => allFields[fieldId].cfields === true)
      .filter((fieldId) => {
        return userFieldIds().indexOf(fieldId) === -1;
      }, []);
  }, [allFieldIds, userFieldIds, allFields]);
  debug && console.log("PgConfigDND | availableFieldIds:", availableFieldIds);
  debug && console.log("PgConfigDND | userFieldIds:", userFieldIds());

  useEffect(() => {
    const newState: DnDStateT = {
      fields: { ...allFields },
      columnOrder: [AVAILABLE_FIELDS_ID, configId],
      columns: {
        availableFields: {
          uuid: AVAILABLE_FIELDS_ID,
          title: 'Available Fields',
          fieldIds: availableFieldIds(),
        },
        [configId]: compoundViewItem,
      },
    };
    setState(newState);
  }, [userFieldIds, compoundViewItem, availableFieldIds, configId, allFields]);
  debug &&  console.log("PgConfigDND | state:", state);

  const onDragStart = () => {
    console.log("onDragStart");
  };
  const onDragEnd = ({ destination, source, draggableId }) => {
    if (!destination) {
      return;
    }
    // No change in column or position
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const start = state.columns[source.droppableId];
    const finish = state.columns[destination.droppableId];

    if (start === finish) {
      // Move in same column
      const newFieldIds = Array.from(start.fieldIds);
      newFieldIds.splice(source.index, 1); // Remove old location
      newFieldIds.splice(destination.index, 0, draggableId); // insert to new location

      const newColumn = {
        ...start,
        fieldIds: newFieldIds,
      };

      setState((state) => {
        return {
          ...state,
          columns: {
            ...state.columns,
            [newColumn.uuid]: newColumn,
          },
        };
      });

      if (newColumn.uuid !== AVAILABLE_FIELDS_ID) {
        dispatch(updateCompoundView(getToken(), newColumn));
      }
      return;
    } else {
      // Move from one column to another
      const startFieldIds = Array.from(start.fieldIds);
      const finishFieldIds = Array.from(finish.fieldIds);
      startFieldIds.splice(source.index, 1); // Remove old location
      finishFieldIds.splice(destination.index, 0, draggableId); // insert to new location

      const newStartColumn = {
        ...start,
        fieldIds: startFieldIds,
      };
      const newFinishColumn = {
        ...finish,
        fieldIds: finishFieldIds,
      };

      setState((state) => {
        return {
          ...state,
          columns: {
            ...state.columns,
            [newStartColumn.uuid]: newStartColumn,
            [newFinishColumn.uuid]: newFinishColumn,
          },
        };
      });

      if (newStartColumn.uuid === configId) {
        dispatch(updateCompoundView(getToken(), newStartColumn));
      }
      if (newFinishColumn.uuid === configId) {
        dispatch(updateCompoundView(getToken(), newFinishColumn));
      }
      return;
    }
  };

  return (
      <DNDContainer height={height} width={width}>
        <Row>
          <Item width={"max-content"}>
            <TextSmallItalics color={'accentSecondary'}>
              Configure Table View by Dragging Properties to/from Right Column
            </TextSmallItalics>
          </Item>
        </Row>
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <Row>
            {state.columnOrder.map((columnId) => (
              <ColumnDND
                title={columnId === 'availableFields' ? 'Available Fields' : 'Configured Table Columns'}
                key={columnId}
                columnId={columnId}
                state={state}
                height={height}
                hasSearch={columnId === 'availableFields'}
              />
            ))}
          </Row>
        </DragDropContext>
      </DNDContainer>
  );
};

export default ConfigDND;

const DNDContainer = styled.div<{width: number, height: number}>`
  display: flex;
  flex-direction: column;
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Item = styled.div<{width}>`
  display: flex;
  width: ${(p) => p.width };
`;
