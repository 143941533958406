import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components/macro';
import PgHome from '@pages/PgHome';
import PgAdmin from '@subApps/admin/PgAdmin';
import PgDashboard from '@pages/PgDashboard';
import PgCompoundsAdd from '@pages/PgCompoundsAdd';
import PgCompoundsDetail from '@pages/PgCompoundsDetail';
import PgCompounds from '@pages/PgCompounds';
import PgCompoundsFavorites from '@pages/PgCompoundsFavorites';
import PgFavoritesView from '@pages/PgFavoritesView';
import PgFieldEditor from '@pages/PgFieldEditor';
// import PgUploadTest from '@pages/PgUploadTest'
import PgInfo from "@pages/PgInfo";
import PgLabNotes from '@pages/PgLabNotes';
import PgOurBiology from '@pages/PgOurBiology';
import PgPlatesCompare from '@pages/PgPlatesCompare';
import PgPlots from '../subApps/plotting/PgPlots';
import PgSearch from '@pages/PgSearch';
import PgUniverse from '@pages/PgUniverse';
import PgUniverseUser from '@pages/PgUniverseUser';
import PgUserData from '../subApps/dataUpload/PgUserData';
import PgUserAccount from '../subApps/account/PgUserAccount';
import PgUserSubscriptions from '@pages/PgUserSubscriptions';
import PgOrders from "../subApps/orders/PgOrders";
import PgRequests from "../subApps/orders/PgRequests";
import PgUserRegistration from '@pages/PgUserRegistration';
import PgUploadV2 from '@pages/PgUploadDataV2';
import PgSandbox from '@pages/PgSandbox';
import PgDataAnalytics from "@pages/PgDataAnalytics"

import PgDevTest from '@pages/dev/PgDevTest';
import PgAuthentication from '@pages/public/PgAuthentication';
import PgUiHuman from '@pages/PgUiHuman';

const RoutesProtected = () => {
  return (
    <PageContent>
      <Routes>
        <Route path='/' element={<Navigate replace to='/home' />} />
        <Route path='/home' element={<PgHome />} />
        <Route path='/admin/:tab' element={<PgAdmin />} />
        <Route path='/dashboard' element={<PgDashboard />} />
        <Route path='/compounds/:library/:view' element={<PgCompounds />} />
        <Route path='/compounds/add' element={<PgCompoundsAdd />} />
        <Route path='/compounds/detail/:id' element={<PgCompoundsDetail />} />
        <Route path='/compounds/favorites' element={<PgCompoundsFavorites />} />
        <Route path='/favorites/:favId/:view' element={<PgFavoritesView />} />
        <Route path='/favorites/:library/:view' element={<PgFavoritesView />} />
        <Route path='/fields' element={<PgFieldEditor />} />
        {/* <Route path='/upload_test' element={<PgUploadTest />} /> */}
        <Route path='/search' element={<PgSearch />} />
        <Route path='/plates' element={<PgPlatesCompare />} />
        <Route path='/plots' element={<PgPlots />} />
        <Route path='/plots/:plotId' element={<PgPlots />} />
        <Route path='/universe' element={<PgUniverse />} />
        <Route path='/universe/:universe' element={<PgUniverse />} />
        <Route path='/universe/user' element={<PgUniverseUser />} />
        <Route path='/universe/user/:uuid' element={<PgUniverseUser />} />
        <Route path='/import/:tab' element={<PgUserData />} />
        <Route path='/import/V2' element={<PgUploadV2 />} />
        <Route path='/data-analysis' element={<PgDataAnalytics />} />
        <Route path='/info/:action' element={<PgInfo />} />
        <Route path='/labnotes' element={<PgLabNotes />} />
        <Route path='/sandbox' element={<PgSandbox />} />
        <Route path='/devtest' element={<PgDevTest />} />
        <Route path='/subscriptions' element={<PgUserSubscriptions />} />
        <Route path='/subscriptions/:action' element={<PgUserSubscriptions />} />
        <Route path='/subscriptions/:action/:state' element={<PgUserSubscriptions />} />
        <Route path='/account/:view' element={<PgUserAccount />} />
        <Route path='/account/:view/:tab' element={<PgUserAccount />} />
        <Route path='/user/Registration' element={<PgUserRegistration />} />
        <Route path='/user/:action' element={<PgAuthentication />} />
        <Route path='/biology' element={<PgOurBiology />} />
        <Route path='/biology/:section/:subsection' element={<PgOurBiology />} />
        <Route path='/orders/:action' element={<PgOrders />} />
        <Route path='/requests/:id' element={<PgRequests />} />
        <Route path='/ui-human' element={<PgUiHuman />} />
        <Route path='/*' element={<Navigate replace to='/' />} />
      </Routes>
    </PageContent>
  );
};

export default RoutesProtected;

const PageContent = styled.div`
  position: absolute;
  margin: 0;
  top: 0;
  left: 50px;
  width: calc(100vw - 50px);
  height: 100vh;
  background: ${(p) => p.theme.palette.backgroundPrimary};
`;
