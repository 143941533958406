import React, {useState, useEffect, useContext} from 'react';
import styled from "styled-components/macro";
import useRepos, {RepositoryDetailsT, UserRepositoriesT} from './useRepos';
import ErrorMessages, {MessageItem} from "@as_core/elements/UserMessages";
import DataLoading from "@as_core/elements/DataLoading";
import DataFolderIcon from "@as_core/icons/dataFolder.icon";
import DataFolderSharedIcon from "@as_core/icons/dataFolderShared.icon";
import RepoDetail from "@subApps/account/repositoryManagement/components/RepoDetail";
import {UserContext} from "@stores/UserContext";
import RepoTableSelect from "@subApps/account/repositoryManagement/components/RepoTableSelect";
import {FlexBanner} from "@as_core/elements/flexStyles";
import HeaderMenu from "@as_core/controls/HeaderMenu";
import {useNavigate, useParams} from "react-router-dom";
import RepoCreate from "@subApps/account/repositoryManagement/components/RepoCreate";
import useUserAppInfo, {getMaxRepositories} from "@utils/useUserAppInfo";

// ####################################################################
type actionT = {
  key: string;
  label: string;
  icon?: any;
};

const menuActions: Array<actionT> = [
  { key: 'owned', label: 'Owned Repositories', icon: <DataFolderIcon /> },
  { key: 'shared', label: 'Repositories with Shared Access', icon: <DataFolderSharedIcon /> },
];

function getSummaryValue(userRepoSummary:UserRepoSummaryT, type: string, maxAllowed = 0) {
  if (type === 'owned') {
    return ' (' + userRepoSummary[type] + ` of ${maxAllowed})`;
  }
  return ' (' + userRepoSummary[type] + ')';
}

export type UserRepoSummaryT = {
  owned: number;
  shared: number;
}

const debug = false;
const RepoManagement = () => {
  const { getUserRepositories } = useRepos();
  const { getUserRepositories:getUpdatedUserRepositories } = useUserAppInfo();
  const params = useParams();
  const { tab } = params;
  const {user, setUser} = useContext(UserContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userRepoSummary, setUserRepoSummary] = useState<UserRepoSummaryT>({owned: 0, shared: 0});
  const [selectedRepo, setSelectedRepo] = useState<RepositoryDetailsT>(null);
  const [repos, setRepos] = useState<UserRepositoriesT>({owned: [], shared: []});
  const [doRefresh, setDoRefresh] = useState<boolean>(false);
  debug && console.log('RepoManagement | repos', repos, 'selectedRepo', selectedRepo);

  const handleSelectChange = (id: string) => {
    if (id === null) {
      setSelectedRepo(null);
    } else if (selectedRepo === null || id !== selectedRepo.uuid) {
      const newSelectedRepo = repos[tab].find((r:RepositoryDetailsT) => r.uuid === id);
      setSelectedRepo(newSelectedRepo);
    }
  }

  // change in repository state -- add or delete so push refresh
  const handleRefresh = (changedId: string, changeType: string) => {
    debug && console.log('handleRefresh | changedId', changedId, 'changeType', changeType);
    if (['repoCreated', 'repoDeleted'].includes(changeType)) setSelectedRepo(null);
    setDoRefresh((prev) => !prev);
  }

  useEffect(() => {
    setIsLoading(true);
    getUserRepositories().then((repos)=>{
      debug && console.log('getUserRepositories | repos', repos);
      setRepos(repos);
      const updatedRepoSummary = {
        owned: repos?.owned ? repos.owned.length : 0,
        shared: repos?.shared ? repos.shared.length: 0
      }
      setUserRepoSummary(updatedRepoSummary);
      // reset tab if needed
      if (tab === 'owned' && updatedRepoSummary.owned === 0 && updatedRepoSummary.shared !== 0) {
        navigate('/account/repositories/shared');
      }
      if (selectedRepo !== null) {  // refresh selected with new data
        const newSelectedRepo = repos[tab].find((r:RepositoryDetailsT) => r.uuid === selectedRepo.uuid);
        setSelectedRepo(newSelectedRepo);
      }
      if (selectedRepo?.uuid === user.appInfo.repositories.current?.uuid) {
        getUpdatedUserRepositories().then((repos) => {
          setUser({...user, appInfo: {...user.appInfo, repositories: repos}});
          setIsLoading(false);
        })
      } else {
        setIsLoading(false);
      }

    })
  }, [doRefresh]);

  useEffect(() => {
    setSelectedRepo(null);
  }, [tab])

  const activeRepoId = user.appInfo.repositories.current?.uuid;
  const maxAllowed = getMaxRepositories(user);
  const tableRepos = Object.hasOwn(repos, tab) ? repos[tab]: [];
  debug && console.log('tab', tab, 'current', userRepoSummary[tab], 'maxAllowed', maxAllowed);

  return (
    <Container>
      <FlexBanner>
        {menuActions.map((action) => (
          <HeaderMenu
            key={`user_data_${action.key}`}
            label={action.label + getSummaryValue(userRepoSummary, action.key, maxAllowed)}
            icon={action.icon}
            isActive={action.key === tab}
            isDisabled={userRepoSummary[action.key] === 0}
            onClick={userRepoSummary[action.key] > 0 ? () => navigate(`/account/repositories/${action.key}`) : ()=>{}}
          />
        ))}
      </FlexBanner>
      { isLoading ?
        <DataLoading />
        :
        <>
          { userRepoSummary['owned'] + userRepoSummary['shared'] > 0 ?
            <RepoTableSelect
              type={tab}
              activeRepoId = {activeRepoId}
              selectedRepoId = {selectedRepo !== null ? selectedRepo?.uuid : ''}
              repos = {tableRepos}
              onSelectChange = {handleSelectChange}
            />
          :
            <>
              { maxAllowed == 0 ?
                  <ErrorMessages messages={[
                    <MessageItem>You do not have any available personal data repositories or repositories that have
                      been shared with you.</MessageItem>,
                    <MessageItem color={'primary'}>Please consider subscribing to 3RnD to enable this functionality and analyze your own data.</MessageItem>
                  ]}
                  />
              :
                  <ErrorMessages messages={[
                    <MessageItem>You do not have any available personal data repositories or repositories that have
                      been shared with you.</MessageItem>,
                    <MessageItem>Under your current subscription you can create up to {maxAllowed} repositories using the button below.</MessageItem>,
                  ]}
                  />
              }
            </>
          }
          { tab === 'owned' && userRepoSummary[tab] < maxAllowed ? <RepoCreate onChange = {handleRefresh}/> : null }
          { selectedRepo !== null ?
            <RepoDetail
              key={'repo_details'}
              repo={selectedRepo}
              pushRefresh={handleRefresh}
            /> : null}
        </>
      }
    </Container>
  );
};

export default RepoManagement;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const Content = styled(Container)<{hasBorder?: boolean}>`
  height: max-content;
  border: ${(p) => p.hasBorder? 1 : 0}px solid ${(p) => p.theme.palette.accentSecondary};
`;
Content.defaultProps = {
  hasBorder: false
}