import React, { useState, useEffect } from 'react';
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

interface AutocompleteCustoProps {
  options: any[];
  placeholder: string;
  onColumnSelect: (selectedColumn: any) => void; // Add the callback prop
  value: any; // The currently selected value or null if nothing is selected
}

export default function AutocompleteCusto({ options, placeholder, onColumnSelect, value: externalValue }: AutocompleteCustoProps) {
  // State to control the selected value
  const [value, setValue] = useState<any | null>(null);

  // Effect to set the external value when it changes, such as when entering edit mode
  useEffect(() => {
    if (externalValue) {
      setValue(externalValue);
    } else {
      setValue(null);
    }
  }, [externalValue]);

  // Callback function to handle the column selection
  const handleSelection = (event: any, newValue: any) => {
    onColumnSelect(newValue);
    setValue(newValue);
  };

  return (
    <Autocomplete
      id="combo-box-demo"
      options={options}
      getOptionLabel={(option) => option.label}
      value={value} // Controlled value
      onChange={handleSelection} // Update the value state when a new selection is made
      style={{ width: 300, height: 38 }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={placeholder}
          sx={{
            backgroundColor: "white",
            borderRadius: "7px",
            marginTop: "-10px",
            "& .MuiAutocomplete-inputRoot": {
              "& input::placeholder": {
                fontSize: "13px",
                color: "black",
                opacity: 1,
                fontWeight: 400,
              }
            }
          }}
        />
      )}
    />
  );
}
