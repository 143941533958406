import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { UserContext } from '@stores/UserContext';
import useInvitations from '@utils/api/useInvitations';
import InvitesTable from '../../shared/InvitesTable';
import Button from '@ui-components/controls/Button';
import {
  AccountDetailContainer,
  Title,
  Center,
  Message,
  HorizontalLine,
} from '../../shared/flexStyles';
import { isValidEmail } from '@as_core/account/utils';
import { errorMessages } from '@as_core/account/forms/config';
import ErrorMessage from '@as_core/account/fields/ErrorMessage';
import {BasicTextInput} from "@as_core/account/forms/FormStyles";
import {RepositoryAccessSelectOptions, AccessRepositoryT, RepositoryDetailsT} from "../useRepos";
import SelectorDropdown from "@as_core/controls/inputs/SelectorDropdown";
import {isInvitationActive} from "@utils/api/useInvitations";

// build list of active repository invitees and users to exclude duplicate invitations.
function getActiveUsers(repo: RepositoryDetailsT, invitations:any[]): string[] {
  return [...repo.invitedUsers.map((u) => u.authEmail), ...invitations.map((i) => i.invitee.authEmail)];
}

type PropsT = {
  repo: RepositoryDetailsT;
};

const debug = false;
const RepoInvites = (props: PropsT) => {
  const { repo} = props;
  const [invitations, setInvitations] = useState([]);
  const [message, setMessage] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [accessType, setAccessType] = useState<AccessRepositoryT>('readOnly');
  const [invitationsUpdated, setInvitationsUpdated] = useState<boolean>(false);
  const { inviteUser, cancelInvite, getByRepository } = useInvitations();
  const [invitedEmail, setInvitedEmail] = useState('');
  const [errorInvite, setErrorInvite] = useState<string>();

  const { user } = useContext(UserContext);

  useEffect(() => {
    setErrorInvite('');
    setInvitedEmail('');
    getByRepository(repo.uuid).then((res) => {
      debug && console.log(res);
      const activeInvitations = res.filter((i:any) => isInvitationActive(i));
      setInvitations(activeInvitations);
      if (!activeInvitations.length)
        setMessage('No current active invitations');
      setInvitationsUpdated(false);
    });
  }, [repo, invitationsUpdated]);

  // create a new invitation for this repo
  const sendInvite = () => {
    const activeUserInvites = getActiveUsers(repo, invitations);
    setIsProcessing(true);
    if (invitedEmail && !isValidEmail(invitedEmail)) {
      setErrorInvite(errorMessages.invalidEmailFormat);
    } if (invitedEmail === user.authEmail) {
      setInvitedEmail('');
      setErrorInvite('Cannot invite yourself');
    } if (activeUserInvites.includes(invitedEmail)) {
      setInvitedEmail('');
      setErrorInvite('Invitee already has an active invitation or in repository user list.');
    } else {
      inviteUser(
        user,
        invitedEmail,
        'Share Repository',
        {'repoId': repo.uuid, 'repoName': repo.name, 'accessType': accessType}).then(() => {
        setMessage(`Invitation sent to ${invitedEmail}`);
        setInvitedEmail('');
        setInvitationsUpdated(true);
      });
    }
    setIsProcessing(false);
  };

  const handleCancelInvite = (inviteId: string) => {
    setIsProcessing(true);
    cancelInvite(inviteId).then(async () => {
      setInvitationsUpdated(true);
      setMessage(`Invitation Cancelled`);
      setIsProcessing(false);
    });
  };
  debug && console.log('InvitationCards | invitations', invitations);

  return (
    <AccountDetailContainer key={'repo-invites'}>
      <HorizontalLine />
      <Center>
        <InviteBox>
          <ReadOnlyContainer>
            <SelectorDropdown
              label={'Access Level'}
              value={accessType}
              options={RepositoryAccessSelectOptions}
              setValue={(v)=>setAccessType(v)}
              width={150}
            />
          </ReadOnlyContainer>
          <BasicTextInput
            key={'newInvitee'}
            value={invitedEmail}
            placeholder={'Enter email to share repository ...'}
            width={'375px'}
            onChange={(e)=>setInvitedEmail(e.target.value)}
          />
          <Button
            label={'Invite User'}
            disabled={invitedEmail === ''}
            width={150}
            onClick={sendInvite}
            inProcess={isProcessing}
          />
        </InviteBox>
      </Center>
      <Center>
        {errorInvite ? (
          <ErrorMessage message={errorInvite} />
        ) : (
          <Message>{message}</Message>
        )}
      </Center>
      {invitations.length ? (
        <>
          <Title>Active Invitations</Title>
          <InvitesTable
            invitations={invitations}
            handleCancelInvite={handleCancelInvite}
          />
        </>
      ) : null}
    </AccountDetailContainer>
  );
};

export default RepoInvites;

const InviteBox = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 575px;
  height: 20px;
  margin: 8px 0;
  gap: 8px;
`;

const ReadOnlyContainer = styled.div`
  display: flex;
  width: 150px;
`;


