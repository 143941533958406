import _ from 'lodash';
import styled from 'styled-components/macro';
import { Checkbox } from '@mui/material';

interface ExtraProps {
  compound?: any;
  field_id?: string;
  fieldSrc?: string;
  border?: boolean;
}

export const Row = styled.tr<ExtraProps>`
  margin-bottom: 3px;
  ${(p) => p.border && `border-bottom: 1px solid p.theme.palette.accentSecondary`};
  &:nth-child(even) {
    background-color: ${(p) => p.theme.palette.backgroundTertiary};
  }
  background-color: ${({ compound, field_id, theme, fieldSrc }) => {
    return _.get(compound, `${fieldSrc}.${field_id}.source_id`, '-') === 'new'
      ? theme.palette.accentPrimary
      : 'inherits';
  }};
  color: ${({ compound, field_id, theme, fieldSrc }) => {
    return _.get(compound, `${fieldSrc}.${field_id}.source_id`, '-') === 'new'
      ? theme.palette.textPrimary
      : 'inherits';
  }};
`;

export const Header = styled.th<{ parent?: string }>`
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  font-size: 14px;
  text-align: center;
  padding: 5px;
  padding-left: ${(p) => (p.parent === 'compoundSet' ? '0px' : '5px')};
  width: ${(p) => (p.parent === 'compoundSet' ? '0px' : 'auto')};
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const Cell = styled.td<{ parent?: string }>`
  height: ${(p) => p.theme.sizes.xlarge};
  min-width: ${(p) => (p.parent === 'compoundSet' ? '0px' : '120px')};
  max-width: ${(p) => (p.parent === 'compoundSet' ? '0px' : '400px')};
  padding-left: ${(p) => (p.parent === 'compoundSet' ? '0px' : p.theme.sizes.xsmall)};
  vertical-align: middle;
  text-align: center;
  font-size: 12px;
  font-weight: ${(p) => p.theme.weights.light};
`;

export const No = styled(Checkbox)`
  vertical-align: middle;
  color: ${(p) => p.theme.palette.backgroundQuaternary};
`;
