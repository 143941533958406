import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import * as XLSX from 'xlsx';

import { DataSetT } from '@components/dataSets/dataSet.types';

import {
  PlotConfig,
  PlotBase,
  ScatterPlotScale,
  PlotData,
} from '@subApps/plotting/single-plot-view/plot.types';
import { getBasicPlotConfig } from '@plotting/plot-config-modal/build-plot-config';

const getDrcPlotConfig = (
  userId: string,
  compounds: Array<string>,
  sources: Array<string>,
  dataset: PlotData
): any => {
  let plotConfig = getBasicPlotConfig(compounds, sources, dataset);
  plotConfig.userId = userId;
  plotConfig.name = 'Dose Response';
  return plotConfig;
};

export const createDrcPlotFromDataSet = (
  userId,
  dataSet: DataSetT
): PlotConfig<PlotBase> => {
  console.log('createDrcPlotFromDataSet | dataSet', dataSet);
  let drcPlot = getDrcPlotConfig(
    userId,
    dataSet.compoundIds,
    dataSet.sourceIds,
    dataSet.data
  );
  console.log('createDrcPlotFromDataSet | drcPlot', drcPlot);
  drcPlot.name = 'Dose Response (' + dataSet.name + ')';
  drcPlot.dataFormatted = dataSet.dataFormatted;
  // scatter plotting
  drcPlot.config = {
    "base": "drc",
    "authors": [ userId ],
    "groupByColumn": "sample",
    "doseColumn": "dose",
    "responseColumn": "response_%"
  };
  console.log('createDrcPlotFromDataSet | drcPlot', drcPlot);
  return drcPlot;
};

const defaultDrcScales = (): Array<ScatterPlotScale> => {
  return [
    {
      id: 'default',
      x: {
        intervals: 5,
        format: '~g',
        scale: {
          type: 'log',
          min: 'auto',
          max: 'auto',
        },
      },
      y: {
        intervals: 5,
        format: '~g',
        scale: {
          type: 'linear',
          min: 'auto',
          max: 'auto',
        },
      },
    },
  ];
};

export const defaultDataSet = (userId): DataSetT => {
  return {
    uuid: uuidv4(),
    createdBy: userId,
    name: '',
    type: 'Generic',
    compoundIds: [],
    sourceIds: [],
    data: [],
    columns: [],
    nColumns: 0,
    nRows: 0,
    plotId: '',
    universeId: '',
  };
};

export const createDoseResponseDataFromExcel = (workbook, columns) => {
  let data = {};
  // console.log("createDoseResponseDataFromExcel | columns:", columns);
  columns.forEach((col) => {
    if (col.field_id !== null) {
      data[col.field_id] = col.data;
    }
  });
  // console.log("createDoseResponseDataFromExcel | data:", data);
  let doseResponse = {};
  data['compound'].forEach((value, index) => {
    if (value !== undefined) {
      const sampleId = data['sample'][index];
      const dose = data['dose'][index];
      const response = data['response_%'][index];
      if (!(value in doseResponse)) {
        doseResponse[value] = {};
      }
      if (!(sampleId in doseResponse[value])) {
        doseResponse[value][sampleId] = {
          compound_id: value,
          sample_id: sampleId,
          dose_units: 'uM',
          response_type: '%',
          data: [],
        };
      }
      doseResponse[value][sampleId]['data'].push({
        dose: dose,
        response: response,
      });
    }
  });
  // now set to array
  let newDataSet = [];
  for (const cpd in doseResponse) {
    for (const sample in doseResponse[cpd]) {
      newDataSet.push(doseResponse[cpd][sample]);
    }
  }
  return newDataSet;
};

// take a standard JSON data table format and convert to CSV format
export const getCsvDataFromTableData = (data) => {
  let csvRows = [];
  csvRows.push(data.columns.join(','));
  for (const row of data.rows) {
    const values = data.columns.map((col) => row[col]);
    csvRows.push(values.join(','));
  }
  return csvRows.join('\n');
};

export function downloadCSV(csvData, filename) {
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'data.csv';
  document.body.appendChild(a);
  a.click();

  // Cleanup: remove the dynamically created anchor tag and URL object
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

// extract the curve fit results to a standard JSON data table format
export const getDoseResponseResultsAsTableData = (dataSet) => {
  let dataMapping = [
    {
      parameters: [
        { column: 'IC50/EC50/ED50', value: 'ED50' },
        { column: 'Slope', value: 'slope' },
        { column: 'Lower Bound', value: 'lower' },
        { column: 'Upper Bound', value: 'upper' },
      ],
    },
    {
      statistics: [
        { column: 'Std. Error', value: 'Std. Error' },
        { column: 'Std. Error (upper)', value: 'Upper' },
        { column: 'Std. Error (lower)', value: 'Lower' },
      ],
    },
  ];

  // console.log("getDoseResponseResultsAsTableData | dataSet:", dataSet);
  // set the dataColumns
  let dataColumns = ['Compound Name', 'Sample Id', 'Fit Type'];
  dataMapping.forEach((set) => {
    for (const type of Object.keys(set)) {
      for (const item of set[type]) {
        if (!dataColumns.includes(item.column)) {
          dataColumns.push(item.column);
        }
      }
    }
  });
  // console.log("getDoseResponseResultsAsTableData | dataColumns:", dataColumns);
  let dataRows = [];
  dataSet.dataFormatted.forEach((cpd) => {
    let newRow = {};
    for (const fit of Object.keys(cpd.results)) {
      newRow['Compound Name'] = cpd.compound_id;
      newRow['Sample Id'] = cpd.sample_id;
      newRow['Fit Type'] = fit;
      const resultsData = cpd.results[fit];
      dataMapping.forEach((set) => {
        for (const type of Object.keys(set)) {
          const resultsType = resultsData[type];
          for (const item of set[type]) {
            newRow[item.column] = _.get(resultsType, item.value, '');
          }
        }
      });
      dataRows.push(newRow);
    }
  });
  // console.log("getDoseResponseResultsAsTableData | dataRows:", dataRows);
  return { columns: dataColumns, rows: dataRows };
};

export const createTargetPanelDataFromExcel = (workbook, columns) => {
  //console.log("createTargetDataFromExcel | columns:", columns);
  let newColumns = [];
  let newData = {};
  columns.forEach((col) => {
    //console.debug("createTargetDataFromExcel | col:");
    if (col.field_id !== null) {
      newColumns.push(col.field_id);
      newData[col.field_id] = col.data;
    }
  });
  //console.log("createTargetPanelDataFromExcel | newColumns: ", newColumns, "newData:", newData);
  let data = [];
  for (let i = 0; i < newData[newColumns[0]].length; i++) {
    let newRow = {};
    for (const col of newColumns) {
      if (newData[col][i] !== undefined) newRow[col] = newData[col][i];
    }
    // console.log(newRow);
    // skip empty rows
    if (Object.keys(newRow).length) data.push(newRow);
  }
  return { columns: newColumns, rows: data };
};

const dataColumnsFromExcel = (columns) => {
  let dataColumns = [];
  columns.forEach((c) => {
    dataColumns.push({
      label: c.colName,
      value: c.colName,
      type: c.colType,
    });
  });
  return dataColumns;
};

export const dataMappedFromExcel = (workbook, columns) => {
  const sheetName = workbook.workbook.SheetNames[0];
  let dataXLS = XLSX.utils.sheet_to_json(workbook.workbook.Sheets[sheetName], {
    header: 1,
  });
  // console.log('dataMappedFromExcel | dataXLS:', dataXLS);
  // console.log('dataMappedFromExcel | columns:', columns);
  let data = [];
  for (let i = 1; i < Object.values(dataXLS).length; i++) {
    if (Object.values(dataXLS[i]).length) {
      let newRow = {};
      for (let j = 0; j < Object.values(columns).length; j++) {
        if (
          columns[j]['selected'] &&
          columns[j].hasOwnProperty('field_id') &&
          columns[j]['field_id'] !== ''
        ) {
          newRow[columns[j]['field_id']] = dataXLS[i][j];
        }
      }
      data.push(newRow);
    }
  }
  return data;
};

export const dataGenericFromExcel = (workbook) => {
  const sheetName = workbook.workbook.SheetNames[0];
  let dataXLS = XLSX.utils.sheet_to_json(workbook.workbook.Sheets[sheetName], {
    header: 1,
  });
  let data = [];

  const headers = dataXLS[0];
  for (let i = 1; i < Object.values(dataXLS).length; i++) {
    // make sure not an empty row
    if (Object.values(dataXLS[i]).length) {
      // console.log('dataXLS[', i, ']:', dataXLS[i]);
      let newRow = {};
      for (let j = 0; j < Object.values(dataXLS[i]).length; j++) {
        newRow[headers[j]] = dataXLS[i][j];
      }
      data.push(newRow);
    }
  }
  return data;
};

export const createGenericDataSetFromExcel = (
  userId,
  workbook,
  columns
): DataSetT => {
  let dataSet = defaultDataSet(userId);
  dataSet.columns = dataColumnsFromExcel(columns);
  dataSet.name = workbook.filename;
  dataSet.nColumns = dataSet.columns.length;
  dataSet.data = dataGenericFromExcel(workbook);
  dataSet.nRows = dataSet.data.length;
  return dataSet;
};

export const createDoseResponseDataSetFromExcel = (
  userId,
  workbook,
  columns
): DataSetT => {
  let dataSet = createGenericDataSetFromExcel(userId, workbook, columns);
  dataSet.type = 'Dose Response';
  dataSet.dataFormatted = createDoseResponseDataFromExcel(workbook, columns);
  dataSet.nColumns = dataSet.columns.length;
  dataSet.data = dataMappedFromExcel(workbook, columns);
  dataSet.nRows = dataSet.data.length;
  return dataSet;
};

export const createTargetPanelDataSetFromExcel = (
  formatType,
  userId,
  workbook,
  columns
): DataSetT => {
  let dataSet = defaultDataSet(userId);
  console.log('formatType', formatType, 'userId', userId, 'columns', columns);
  dataSet.name = workbook.filename;
  dataSet.type = 'Target Panel';
  let dataColumns = ['compound', 'smiles'];
  const data = dataMappedFromExcel(workbook, columns);

  if (formatType === 'narrow') {
    // need to combine on 'compound'
    let pivotedData = {};
    let targetColumns = [];
    for (const row of data) {
      const compound_id = row['compound'].toString(); // treat as string in case these are just serial #'s
      if (!Object.hasOwn(pivotedData, compound_id)) {
        pivotedData[compound_id] = {
          compound: compound_id,
          smiles: row['smiles'],
        };
      }
      pivotedData[compound_id][row['target']] = row['assay'];
      if (!targetColumns.includes(row['target']))
        targetColumns.push(row['target']);
    }
    console.log('pivotedData', pivotedData);
    targetColumns.sort((a, b) =>
      a.toLowerCase().localeCompare(b.toLowerCase())
    );
    dataColumns.push(...targetColumns);
    dataSet.data = Object.values(pivotedData);
  } else {
    dataSet.data = data;
    let targetColumns = [];
    for (const row of dataSet.data) {
      for (const key of Object.keys(row)) {
        if (!['compound', 'smiles'].includes(key)) {
          if (!targetColumns.includes(key)) targetColumns.push(key);
        }
      }
    }
    targetColumns.sort((a, b) =>
      a.toLowerCase().localeCompare(b.toLowerCase())
    );
    dataColumns.push(...targetColumns);
  }
  let dataSetColumns = [];
  for (const col of dataColumns) {
    dataSetColumns.push({
      label: col,
      value: col,
      type: ['compound', 'smiles'].includes(col) ? 'string' : 'float',
    });
  }
  dataSet.columns = dataSetColumns;
  dataSet.nColumns = dataColumns.length;
  dataSet.nRows = dataSet.data.length;
  return dataSet;
};
