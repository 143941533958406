import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const ThirdPartyDataIcon = ({
    size = 24,
  }) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path stroke={colorHighlight} strokeWidth={20} strokeLinecap={"round"} strokeLinejoin={"round"} strokeMiterlimit={10}
              d="M152.1,358.1L256,418.8l103.9-60.7V238.3L256,177.6l-103.9,60.7V358.1z"/>
        <path fill={color} d="M316.5,49.1c0-7.8-6.4-14.2-14.2-14.2h-92.7c-7.8,0-14.2,6.4-14.2,14.2v18c0,3.6,1.4,6.9,3.6,9.5
			c-2.3,2.5-3.6,5.8-3.6,9.5V105c0,3.6,1.4,6.9,3.6,9.5c-2.3,2.5-3.6,5.8-3.6,9.5v18c0,7.8,6.4,14.2,14.2,14.2h92.7
			c7.8,0,14.2-6.4,14.2-14.2v-18c0-3.6-1.4-6.9-3.6-9.5c2.3-2.5,3.6-5.8,3.6-9.5V86c0-3.6-1.4-6.9-3.6-9.5c2.3-2.5,3.6-5.8,3.6-9.5
			V49.1z M307.1,141.9c0,2.6-2.1,4.7-4.7,4.7h-92.7c-2.6,0-4.7-2.1-4.7-4.7v-18c0-2.6,2.1-4.7,4.7-4.7h92.7c2.6,0,4.7,2.1,4.7,4.7
			V141.9z M307.1,105c0,2.6-2.1,4.7-4.7,4.7h-92.7c-2.6,0-4.7-2.1-4.7-4.7V86c0-2.6,2.1-4.7,4.7-4.7h92.7c2.6,0,4.7,2.1,4.7,4.7V105
			z M307.1,67.1c0,2.6-2.1,4.7-4.7,4.7h-92.7c-2.6,0-4.7-2.1-4.7-4.7v-18c0-2.6,2.1-4.7,4.7-4.7h92.7c2.6,0,4.7,2.1,4.7,4.7V67.1z"
        />
        <rect fill={colorHighlight} x="211.5" y="127"  width="9.5" height="9.5"/>
        <rect fill={colorHighlight} x="211.5" y="89.4" width="9.5" height="9.5"/>
        <rect fill={colorHighlight} x="211.5" y="52.3" width="9.5" height="9.5"/>
        <rect fill={colorHighlight} x="230.4" y="127"  width="9.5" height="9.5"/>
        <rect fill={colorHighlight} x="230.4" y="89.4" width="9.5" height="9.5"/>
        <rect fill={colorHighlight} x="230.4" y="52.3" width="9.5" height="9.5"/>
        <path fill={color} d="M507.4,351.2c0-7.8-6.4-14.2-14.2-14.2h-92.7c-7.8,0-14.2,6.4-14.2,14.2v18c0,3.6,1.4,6.9,3.6,9.5
			c-2.3,2.5-3.6,5.8-3.6,9.5V407c0,3.6,1.4,6.9,3.6,9.5c-2.3,2.5-3.6,5.8-3.6,9.5v18c0,7.8,6.4,14.2,14.2,14.2h92.7
			c7.8,0,14.2-6.4,14.2-14.2v-18c0-3.6-1.4-6.9-3.6-9.5c2.3-2.5,3.6-5.8,3.6-9.5v-18.9c0-3.6-1.4-6.9-3.6-9.5
			c2.3-2.5,3.6-5.8,3.6-9.5V351.2z M497.9,443.9c0,2.6-2.1,4.7-4.7,4.7h-92.7c-2.6,0-4.7-2.1-4.7-4.7v-18c0-2.6,2.1-4.7,4.7-4.7
			h92.7c2.6,0,4.7,2.1,4.7,4.7V443.9z M497.9,407c0,2.6-2.1,4.7-4.7,4.7h-92.7c-2.6,0-4.7-2.1-4.7-4.7v-18.9c0-2.6,2.1-4.7,4.7-4.7
			h92.7c2.6,0,4.7,2.1,4.7,4.7V407z M497.9,369.2c0,2.6-2.1,4.7-4.7,4.7h-92.7c-2.6,0-4.7-2.1-4.7-4.7v-18c0-2.6,2.1-4.7,4.7-4.7
			h92.7c2.6,0,4.7,2.1,4.7,4.7V369.2z"/>
        <rect fill={colorHighlight} x="402.3" y="429.1" width="9.5" height="9.5"/>
        <rect fill={colorHighlight} x="402.3" y="391.5" width="9.5" height="9.5"/>
        <rect fill={colorHighlight} x="402.3" y="354.4" width="9.5" height="9.5"/>
        <rect fill={colorHighlight} x="421.2" y="429.1" width="9.5" height="9.5"/>
        <rect fill={colorHighlight} x="421.2" y="391.5" width="9.5" height="9.5"/>
        <rect fill={colorHighlight} x="421.2" y="354.4" width="9.5" height="9.5"/>
        <path fill={color} d="M125.7,349.9c0-7.8-6.4-14.2-14.2-14.2H18.8c-7.8,0-14.2,6.4-14.2,14.2v18c0,3.6,1.4,6.9,3.6,9.5
			c-2.3,2.5-3.6,5.8-3.6,9.5v18.9c0,3.6,1.4,6.9,3.6,9.5c-2.3,2.5-3.6,5.8-3.6,9.5v18c0,7.8,6.4,14.2,14.2,14.2h92.7
			c7.8,0,14.2-6.4,14.2-14.2v-18c0-3.6-1.4-6.9-3.6-9.5c2.3-2.5,3.6-5.8,3.6-9.5v-18.9c0-3.6-1.4-6.9-3.6-9.5
			c2.3-2.5,3.6-5.8,3.6-9.5V349.9z M116.3,442.6c0,2.6-2.1,4.7-4.7,4.7H18.8c-2.6,0-4.7-2.1-4.7-4.7v-18c0-2.6,2.1-4.7,4.7-4.7h92.7
			c2.6,0,4.7,2.1,4.7,4.7V442.6z M116.3,405.7c0,2.6-2.1,4.7-4.7,4.7H18.8c-2.6,0-4.7-2.1-4.7-4.7v-18.9c0-2.6,2.1-4.7,4.7-4.7h92.7
			c2.6,0,4.7,2.1,4.7,4.7V405.7z M116.3,367.8c0,2.6-2.1,4.7-4.7,4.7H18.8c-2.6,0-4.7-2.1-4.7-4.7v-18c0-2.6,2.1-4.7,4.7-4.7h92.7
			c2.6,0,4.7,2.1,4.7,4.7V367.8z"/>
        <rect fill={colorHighlight} x="20.6" y="427.7" width="9.5" height="9.5"/>
        <rect fill={colorHighlight} x="20.6" y="390.1" width="9.5" height="9.5"/>
        <rect fill={colorHighlight} x="20.6" y="353" width="9.5" height="9.5"/>
        <rect fill={colorHighlight} x="39.6" y="427.7" width="9.5" height="9.5"/>
        <rect fill={colorHighlight} x="39.6" y="390.1" width="9.5" height="9.5"/>
        <rect fill={colorHighlight} x="39.6" y="353" width="9.5" height="9.5"/>
        <path fill={colorHighlight} d="M304.4,284.2h-33.1v-34.4c0-7.6-6.4-15.3-15.3-15.3l0,0c-7.6,0-15.3,6.4-15.3,15.3v33.1h-33.1
			c-7.6,0-15.3,6.4-15.3,15.3c0,7.6,6.4,15.3,15.3,15.3h33.1v33.1c0,7.6,6.4,15.3,15.3,15.3l0,0c7.6,0,15.3-6.4,15.3-15.3v-33.1
			h33.1c7.6,0,15.3-6.4,15.3-15.3C320.9,290.5,312,284.2,304.4,284.2z"/>
      </svg>
    </>
  )
}

export default ThirdPartyDataIcon;