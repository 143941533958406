import styled from "styled-components/macro";
import {CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";

export type StatusMapT = {
  label: string,
  checked: boolean
}

type PropsT = {
  statusMap: StatusMapT[]
}

const UserRequestToDo = (props:PropsT) => {
  const {statusMap} = props;
  return(
    <ToDoContainer>
      <Item><Highlight>To Do:</Highlight></Item>
      {statusMap.map((item) => (
        <Item>
          { item.checked ?
            <CheckBox />
            :
            <CheckBoxOutlineBlank />
          }
          <input type={'checkbox'} checked={item.checked}/>
          <Label>{item.label}</Label>
        </Item>
      ))}
    </ToDoContainer>
  )
}
export default UserRequestToDo;

const ToDoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  margin: 5px;
  padding: 5px;
  background: ${(p)=>p.theme.palette.backgroundPrimary };
`;

const Item = styled.div`
  display: flex;
  color: ${(p) => p.theme.palette.accentSecondary};
  margin-right: 5px;
`;

const Label = styled(Item)`
  color: ${(p) => p.theme.palette.textSecondary};
`;

const Highlight = styled(Label)`
  color: ${(p) => p.theme.palette.textPrimary};
  font-size: 14px;
  font-weight: bold;
`;