import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import styled from 'styled-components/macro';
import CompoundTile from '@components/compounds/view/CompoundTile';

import useWindowDimensions from '@as_core/hooks/useWindowDimensions';

const debug = false;
const CompoundTiles = ({
  data,
  allFields,
  selected,
  setSelected,
  pinnedData,
  setPinnedData,
  handleRedirect,
  handleEndReached,
}) => {
  const [pinnedKeys, setPinnedKeys] = useState<string[]>([]);
  const { width } = useWindowDimensions();
  const tileWidth = 300;
  const numBoxesWide = Math.floor((width - 50) / (tileWidth + 45));
  const scrollWidth = numBoxesWide * (tileWidth + 45) + 10;

  const notPinnedData = useMemo(() => {
    return data.filter(
      (obj) =>
        !pinnedData.some((pinned) => pinned?.searchKey === obj?.searchKey)
    );
  }, [pinnedData, data]);

  const containerRef = useRef(null);

  const handleScroll = useCallback(() => {
    const container = containerRef.current;

    if (container) {
      const isAtBottom =
        container.scrollHeight - container.scrollTop === container.clientHeight;

      if (isAtBottom) {
        // Load more data here
        debug && console.log('Reached the bottom! Load more data...');
        handleEndReached();
      }
    }
  }, [handleEndReached]);

  // update pinnedKeys for display of the pinned icon
  useEffect(() => {
    const newPinnedKeys = pinnedData.map((r) => r.searchKey);
    setPinnedKeys(newPinnedKeys);
  }, [pinnedData]);

  const allData = useMemo(
    () => [...pinnedData, ...notPinnedData],
    [pinnedData, notPinnedData]
  );

  // trigger load more when down to end of scroll
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <Container ref={containerRef}>
      <ScrollBox width={scrollWidth}>
        {allData?.map((comp) => {
          return (
            <CompoundTile
              key={comp._id}
              width='unset'
              margin='unset'
              compound={comp}
              allFields={allFields}
              handleRedirect={handleRedirect}
              setSelected={setSelected}
              isChecked={selected.includes(comp.searchKey)}
              setPinnedData={setPinnedData}
              isPinned={pinnedKeys.includes(comp.searchKey)}
            />
          );
        })}
      </ScrollBox>
    </Container>
  );
};

export default CompoundTiles;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 5px;
  width: calc(100vw - 62px);
  height: calc(100vh - 101px);
  overflow-y: auto;
  overflow-x: hidden;
`;

const ScrollBox = styled.div<{ width: number }>`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 20px;
  margin: 0 5px;

  @media (max-width: 1500px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1150px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
