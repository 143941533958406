import {FieldsTypeT, getObjectValue} from "@subApps/admin/components/ObjectDetails";

export function getTableRows(
  objectList:any[],
  columns:FieldsTypeT[],
  handleClick?: (action: string, v: string)=>void
) {
  const rows = [];
  objectList.forEach((o) => {
    let row = {};
    columns.forEach((c) => {
      row[c.value] = getObjectValue(o, c, handleClick);
    });
    rows.push(row);
  })
  return rows;
}