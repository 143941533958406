import _ from 'lodash';
import React from 'react';
import styled from 'styled-components/macro';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AddIcon from '@mui/icons-material/Add';
import { compoundObjectNameField } from '@utils/misc';

const ObjectlistRenderer = (props) => {
  const { compound, fields, fieldId, propId, handleChange } = props;

  //console.log('ObjectlistRenderer fieldId: ', fieldId, ' propId: ', propId);

  const getItems = React.useCallback(
    (
      propId: string
    ): { fieldSrc: string; valueSrc: string; name: string }[] => {
      const ids: string = _.get(compound, propId, '');
      if (ids.length > 0) {
        return ids.split(',').map((item) => ({
          fieldSrc: item.split('.')[0],
          valueSrc: item,
          name: _.get(
            compound,
            `${item}.${compoundObjectNameField[item.split('.')[0]]}.value`,
            'Unknown'
          ),
        }));
      } else {
        return [];
      }
    },
    [compound]
  );

  const handleViewScreen = ({ fieldSrc, valueSrc, name }) => {
    const title = `${fields[fieldId].long_name}: ${name}`;
    handleChange({
      action: 'SETVIEW',
      payload: { view: 'object', fieldSrc, valueSrc, title },
    });
  };

  const handleAddScreen = ({ fieldId, propId }) => {
    handleChange({
      action: 'ADDOBJECT',
      payload: { fieldId, propId },
    });
  };

  return (
    <Container>
      <Table>
        <tbody>
          {getItems(propId).map((item) => (
            <Row key={item.valueSrc}>
              <Cell
                key={item.valueSrc}
                onClick={() =>
                  handleViewScreen({
                    fieldSrc: item.fieldSrc,
                    valueSrc: item.valueSrc,
                    name: item.name,
                  })
                }
              >
                {item.name}
                <StyledArrowIcon key={item.valueSrc} fontSize='small' />
              </Cell>
            </Row>
          ))}
          <Row>
            <Cell onClick={() => handleAddScreen({ fieldId, propId })}>
              <StyledAddIcon fontSize='small' />
              {`Add ${fields[fieldId].long_name}`}
            </Cell>
          </Row>
        </tbody>
      </Table>
    </Container>
  );
};

export default ObjectlistRenderer;

interface ExtraProps {
  compound?: any;
  field_id?: string;
}

const StyledArrowIcon = styled(KeyboardArrowRightIcon)`
  margin-left: ${(p) => p.theme.sizes.xsmall};
  color: ${(p) => p.theme.palette.accentPrimary};
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
  border-radius: ${(p) => p.theme.borderRadius};
  vertical-align: middle;
`;

const StyledAddIcon = styled(AddIcon)`
  margin-right: ${(p) => p.theme.sizes.xxsmall};
  color: ${(p) => p.theme.palette.titlePrimary};
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
  border-radius: ${(p) => p.theme.borderRadius};
  vertical-align: middle;
`;

const Row = styled.tr<ExtraProps>`
  margin-bottom: 3px;
`;

const Cell = styled.td<ExtraProps>`
  height: ${(p) => p.theme.sizes.xlarge};
  padding-left: ${(p) => p.theme.sizes.xxsmall};
  vertical-align: middle;
  font-size: ${(p) => p.theme.sizes.small};
  font-weight: ${(p) => p.theme.weights.light};
  cursor: pointer;

  &:hover {
    background-color: ${(p) => p.theme.palette.backgroundTertiary};
  }
`;

const Container = styled.div`
  border-radius: ${(p) => p.theme.borderRadius};
  border-left: ${(p) => p.theme.borders.thin};
  border-right: ${(p) => p.theme.borders.thin};
`;

const Table = styled.table`
  table-layout: fixed;
  width: 100%;
`;
