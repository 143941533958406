import { AxiosRequestConfig } from "axios";
import {authServicesClient, ResponseT} from "@utils/api/base";
import { handleError } from "@utils/misc";
import useCognito from "@as_core/account/useCognito";

// the api settings
export const RequestForms = {
  all: (token: string, config: AxiosRequestConfig) => authServicesClient(token).get('/request_forms', config),
  byRequestId: (token: string, requestId: string) => authServicesClient(token).get(`/request_forms/request_id/${requestId}`),
  get: (token: string, requestFormId: string) => authServicesClient(token).get(`/request_forms/${requestFormId}`),
  getFile: (token: string, requestFormId: string) => authServicesClient(token).get(`/request_forms/${requestFormId}/file`),
  create: (token: string, config: AxiosRequestConfig) => authServicesClient(token).post('/request_forms', config),
  createWithFile: (token: string, config: AxiosRequestConfig) => authServicesClient(token).post('/request_forms/with_file', config),
  update: (token: string, id: number, config: AxiosRequestConfig) => authServicesClient(token).put(`/request_forms/${id}`, config),
  delete: (token: string, id: number) => authServicesClient(token).delete(`/request_forms/${id}`),
};

// the Request Object Type
export type RequestFormT = {
  id: string;
  name: string;
  location: string;
  file_name: string;
  original_file_name: string;
  request_id: string;
}

const useRequestForms = () => {
  const { getToken } = useCognito();

  const getRequestForms = async (params: any): Promise<RequestFormT[]> => {
    const config: AxiosRequestConfig = { params };
    let resp: any;

    try {
      resp = await RequestForms.all(getToken(), config);
    } catch (err) {
      handleError(err);
      return null;
    }
    return resp.data;
  };

  const getRequestFormsByRequestId = async (requestId: string): Promise<RequestFormT[]> => {
    let resp: any;

    try {
      resp = await RequestForms.byRequestId(getToken(), requestId);
    } catch (err) {
      handleError(err);
      return null;
    }
    return resp.data;
  };

  const getRequestForm = async (
    requestFormId: string,
  ): Promise<ResponseT<RequestFormT>> => {
    let resp: any;

    try {
      resp = await RequestForms.get(getToken(), requestFormId);
    } catch (err) {
      handleError(err);
      return null;
    }

    return resp.data;
  };

  const getRequestFormFile = async (
    requestFormId: string,
  ): Promise<ResponseT<string>> => {
    let resp: any = null;
    try {
      resp = await RequestForms.getFile(getToken(), requestFormId);
    } catch (err) {
      console.error("gerRequestFormFile", err);
      return null;
    }
    return resp;
  };

  const createRequestForm = async (params: any) => {
    const config: AxiosRequestConfig = params;
    let resp: any;

    try {
      resp = await RequestForms.create(getToken(), config);
    } catch (err) {
      handleError(err);
      return null;
    }

    return resp.data;
  };

  const createRequestFormWithFile = async (params: any): Promise<RequestFormT> => {
    const config: AxiosRequestConfig = params;
    let resp: any;

    try {
      resp = await RequestForms.createWithFile(getToken(), config);
    } catch (err) {
      handleError(err);
      return null;
    }

    return resp.data;
  };

  const updateRequestForm = async (requestId: number, updates: {[key: string]: any}) => {
    const config: AxiosRequestConfig = updates;
    let resp: any;

    try {
      resp = await RequestForms.update(getToken(), requestId, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    return resp.data;
  };

  const deleteRequestForm = async (requestId: number) => {
    let resp: any;

    try {
      resp = await RequestForms.delete(getToken(), requestId);
    } catch (err) {
      handleError(err);
      return null;
    }

    return resp.data;
  };

  return {
    getRequestForms,
    getRequestFormsByRequestId,
    getRequestForm,
    getRequestFormFile,
    createRequestForm,
    createRequestFormWithFile,
    updateRequestForm,
    deleteRequestForm
  };
};

export default useRequestForms;
