import styled from "styled-components/macro";
const Title = (props) => {
  return(
    <TitleContainer
      variant = { props?.variant ? props.variant : 'normal'}
    >
      {props.text}
    </TitleContainer>
  )
}

export default Title;

const TitleContainer = styled.div<{variant: string}>`
  display: flex;
  color: ${(p) => p.theme.palette.textPrimary};
  font-size: ${(p) => p.variant === 'normal' ? 18 : 12}px;
`;