import {useState} from "react";
import { AxiosRequestConfig } from 'axios';
import { handleError } from '@utils/misc';
import { ProductsCategoriesFull } from "./products.local";
import {ProductCategoryT} from "./products.types";
import useCognito from "@as_core/account/useCognito";
import {authCoreClient} from "@utils/api/base";

export type StripePriceT = {
  id: string,
  object: string,
  active: boolean,
  billing_scheme: string,
  created: Date,
  currency: string,
  custom_unit_amount: any,
  livemode: boolean,
  lookup_key: string,
  metadata: {},
  nickname: string,
  product: string,
  recurring: boolean,
  tax_behavior: string,
  tiers_mode: any,
  transform_quantity: any,
  type: any,
  unit_amount: number,
  unit_amount_decimal: string
}

export type StripeProductT = {
  id: string,
  object: "product",
  active: boolean,
  attributes: any[],
  created: number,
  default_price: string,
  description: string,
  features: string,
  images: any[],
  livemode: boolean,
  metadata: {
    catalogNumber?: string,
    catalogType?: string,
    categoryCode?: string,
    pricingQuantity?: string,
    pricingType?: string,
    system?: string,
    unitOrderMinSize?: string,
    unitSize?: string
  },
  price?: StripePriceT,
  name: string,
  package_dimensions: any,
  shippable: any,
  statement_descriptor: any,
  tax_code: string,
  type: string,
  unit_label: string,
  "updated": Date,
  "url": string
}

export const Products = {
  all: (token:string, config: AxiosRequestConfig) => authCoreClient(token).get('/products', config),
  prices: (token:string, config: AxiosRequestConfig) => authCoreClient(token).get('/products/prices', config),
};

const debug = false;
const useStripeProducts = () => {
  // const system = APP_CONFIG.system;
  const { getToken } = useCognito();
  const [stripeProducts, setStripeProducts] = useState<StripeProductT[]>([]);
  const [stripeProductsLoaded, setStripeProductsLoaded] = useState<boolean>(false);
  const [stripePrices, setStripePrices] = useState<StripePriceT[]>([]);
  const [stripePricesLoaded, setStripePricesLoaded] = useState<boolean>(false);

  const getStripeProducts = async (): Promise<Array<StripeProductT>> => {
    if (stripeProductsLoaded) {
      return stripeProducts;
    } else {
      let resp;
      try {
        // const params = {query: 'meta[\"system\"]:' + system}
        const params = {limit: 200}
        const config: AxiosRequestConfig = {params};
        resp = await Products.all(getToken(), config);
        debug && console.log('Stipe API Response', resp.data);
        const products = resp.data.data
        products.sort((a:StripeProductT, b:StripeProductT) =>
          a.metadata?.catalogNumber && a.metadata.catalogNumber.localeCompare(b.metadata?.catalogNumber));
        // console.log('sorted products', products);
        const prices = await getStripePrices();
        products.forEach((product:StripeProductT) =>  {
          product.price = prices.find((price: StripePriceT) => price.id === product.default_price);
        });
        setStripeProducts(products);
        setStripeProductsLoaded(true);
      } catch (err) {
        handleError(err);
        return null;
      }
      return resp.data.data;
    }
  };

  const getStripePrices = async (): Promise<Array<StripePriceT>> => {
    if (stripePricesLoaded) {
      return stripePrices;
    } else {
      let resp;
      try {
        const params = {limit: 200}
        const config: AxiosRequestConfig = {params};
        resp = await Products.prices(getToken(), config);
        debug && console.log('Stipe API Response | response', resp.data);
        setStripePrices(resp.data.data);
        setStripePricesLoaded(true);
      } catch (err) {
        handleError(err);
        return null;
      }
      return resp.data.data;
    }
  };

  const getAllProducts = async (orgType): Promise<Array<StripeProductT>> => {
    const orgFilter = orgType === 'academic' ? orgType: 'commercial';
    debug && console.log('getAllProducts | orgType:', orgType, 'orgFilter', orgFilter);
    const products = await getStripeProducts();
    return products
        .filter((product) => product.metadata?.pricingType && product.metadata?.pricingType.includes(orgFilter));
  };

  const getProductCategories = async (): Promise<Array<ProductCategoryT>> => {
    return ProductsCategoriesFull;
  };

  return {
    getProductCategories,
    getAllProducts,
  };
};

export default useStripeProducts;
