import React, { useRef, useEffect, useState, useContext } from 'react';
import { initCanvas } from '../utils/graphics';
import {drawVertices, drawShadowVertices, getVerticesByIds} from '../utils/vertex';
import {convertArrayToObject} from "@components/universe/Vector/VectorPane";
import { StyleContext } from '@theme/AppStyles';
import {labelColors} from "@components/universe/universe.config";

/**
 * Draw the single circle - colored nodes (drawVertices) and the hovered/selected nodes (drawShadowVertices
 * @param iteration
 * @param data
 * @param settings
 * @param pinnedCompounds
 * @param aesSettings
 * @param transform
 * @param showVertices
 * @param selectedCompounds
 * @constructor
 */
const Canvas2DPane = ({ iteration, data, settings, pinnedCompounds, aesSettings, transform,
                        showVertices, selectedCompounds }) => {
  const [appStyle] = useContext(StyleContext);
  const [ pinnedVertices, setPinnedVertices ] = useState<any[]>([]);

  // convenience to set shorter parameter names -- still need the useEffect to trigger off settings
  const { width, height } = settings.dimensions;

  const canvasRef = useRef<HTMLCanvasElement>();
  const [ctx, setCtx] = useState(null);

  useEffect(() => {
    initCanvas(canvasRef.current, width, height);
    const context = canvasRef.current.getContext('2d');
    setCtx(context);
  }, [settings.dimensions]);

  useEffect(() => {
    if (ctx) {
      ctx.save();
      ctx.clearRect(0, 0, width, height);
      ctx.translate(transform.x, transform.y);
      ctx.scale(transform.k, transform.k);
      drawShadowVertices(
        ctx,
        data.vertices,
        pinnedCompounds.data,
        settings.hoveredVertex,
        settings.selectedVertex,
        settings.hoveredNeighborVertices,
        settings.selectedNeighborVertices,
        settings.radiusFactor,
        settings.selectedDatasetId,
        aesSettings,
        appStyle.palette,
        selectedCompounds
      );
      if (showVertices) {
        drawVertices(
          ctx,
          data.vertices,
          settings.hoveredVertex,
          settings.selectedVertex,
          settings.hoveredNeighborVertices,
          settings.selectedNeighborVertices,
          settings.nodeColorBy,
          settings.radiusFactor,
          aesSettings
        );
      }
      ctx.restore();
    }
  }, [
    iteration,
    ctx,
    data.vertices,
    settings.dimensions,
    settings.selectedVertex,
    settings.hoveredVertex,
    settings.hoveredNeighborVertices,
    settings.nodeColorBy,
    settings.radiusFactor,
    settings.selectedDatasetId,
    transform,
    showVertices,
  ]);

  //console.log('RENDER: Canvas2DPane');
  return <canvas ref={canvasRef}></canvas>;
};

export default Canvas2DPane;
