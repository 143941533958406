import _ from 'lodash';
import { mean, scaleLinear, extent } from 'd3';
import { DistributionsT} from "@components/universe/types";


// zebra fish transform to take BMD to [0, 1] <= [0.01, 100.0]
export const zebrafishToLinear = (v) => {
  if (v === "NT" || v === "NA") return 0.0;
  return (Math.min(1.0, Math.max(0.0, (2.0 - Math.log10(v))/4.0)));
}


// density computation
const kernelEpanechnikov = (k) => (v) =>
  Math.abs((v /= k)) <= 1 ? (0.75 * (1 - v * v)) / k : 0;

const kernelDensityEstimator = (kernel, X:number[]) => (V:number[]) =>
  X.map((x) => [
    x,
    mean(V, function (v) {
      return kernel(x - v);
    }),
  ]);

export const calculateDensity = (data:number[], reduction = 20) => {
  const ticks = scaleLinear().domain(extent(data)).ticks(reduction);
  const kde = kernelDensityEstimator(
    kernelEpanechnikov(Math.max(...data) / data.length),
    ticks
  );
  return kde(data);
};

let debug = false;
const extractProperty = (data, property) => {
  debug && console.log("distribution:extractProperty {property}", property);
  debug && console.log("distribution:extractProperty {data}", data);
  const arr = [];
  data.forEach((d) => {
    const propertyData = d?.chem_data;
    debug && console.log("distribution:propertyData", propertyData);
    const content = _.get(propertyData, property, undefined);
    if (content) arr.push(content);
  });
  debug && console.log("distribution:extractProperty {arr}", arr);
  return arr;
};

export function getDensities(vertices):DistributionsT {
  return {
    exact_mol_wt: calculateDensity(extractProperty(vertices, 'exact_mol_wt')),
    lipinski_hbd: calculateDensity(extractProperty(vertices, 'lipinski_hbd')),
    lipinski_hba: calculateDensity(extractProperty(vertices, 'lipinski_hba')),
    polar_surface_area: calculateDensity(extractProperty(vertices, 'polar_surface_area')),
    crippen_log_p: calculateDensity(extractProperty(vertices, 'crippen_log_p')),
    aromatic_rings: calculateDensity(extractProperty(vertices, 'aromatic_rings')),
    heavy_atom_count: calculateDensity(extractProperty(vertices, 'heavy_atom_count')),
    rotatable_bonds: calculateDensity(extractProperty(vertices, 'rotatable_bonds'))
  }
}

