import { Margin } from '@nivo/core';
import {
  ResponsiveScatterPlot,
  ScatterPlotDatum,
  ScatterPlotRawSerie,
  ScatterPlotTooltipProps,
} from '@nivo/scatterplot';
import { ThemeContext } from 'styled-components';
import { Theme } from '@nivo/core';
import { useContext } from 'react';
import { getDatavizTheme } from '@plotting/single-plot-view/plot-panel/plot.themes';
import { getCurveLayer } from './getCurveLayer';
import { ScaleSpec } from '@nivo/scales';
import { COLORS } from '@utils/scales/color/ColorSchemes';
import { scaleOrdinal } from 'd3-scale';
import {
  DEFAULT_LEGEND_CONFIG,
  DEFAULT_X_AXIS_STYLE,
  DEFAULT_Y_AXIS_STYLE,
} from '@dataviz/constants';
import { TooltipContainer } from '@dataviz/TooltipContainer';
import { DrcCurve } from './dose-response-curve.types';

const DEFAULT_MARGIN = {
  top: 60,
  right: 140,
  bottom: 90,
  left: 116,
};

type DoseResponseCurveProps = {
  data: ScatterPlotRawSerie<ScatterPlotDatum>[];
  curveData: DrcCurve[];
  title?: string;
  titleSize?: number;
  datavizTheme?: Theme;
  margin?: Margin;
  isLegendEnabled?: boolean;
};

export const DoseResponseCurve = ({
  data,
  curveData,
  title,
  titleSize,
  datavizTheme,
  margin = DEFAULT_MARGIN,
  isLegendEnabled,
}: DoseResponseCurveProps) => {
  // Dataviz theme can be passed as a prop (for the screenshotting feature for instance) OR built here
  const { palette } = useContext(ThemeContext);
  const finalDatavizTheme = datavizTheme ?? getDatavizTheme({}, palette);

  const plotTitleStyle = {
    fontFamily: finalDatavizTheme.fontFamily,
    fontSize: titleSize || 14,
    fill: finalDatavizTheme.textColor,
    textAnchor: 'middle',
  } as const;

  const plotTitle = ({ innerWidth }) => {
    return (
      <text x={innerWidth / 2} y={-margin.top / 2} style={plotTitleStyle}>
        {title}
      </text>
    );
  };

  const clip = ({ innerWidth, innerHeight }) => {
    return (
      <defs>
        <clipPath id='clip'>
          <rect x={0} y={0} width={innerWidth} height={innerHeight} />
        </clipPath>
      </defs>
    );
  };

  const xScale: ScaleSpec = {
    type: 'log',
    min: 'auto',
    max: 'auto',
  };

  const yScale: ScaleSpec = {
    type: 'linear',
    min: 0,
    max: 'auto',
  };

  const groups = [...new Set(data.map((d) => d.id))];
  const defaultColors = COLORS.find((col) => col.id === 'aseda');
  const defaultColorScale = scaleOrdinal<string>()
    .domain(groups.map((g) => String(g)))
    .range(defaultColors.scheme);

  const curveLayer = getCurveLayer(curveData, defaultColorScale);
  // const lines = ExprLayer(plot, datavizTheme);

  const getDrcTooltip = ({
    node,
  }: ScatterPlotTooltipProps<ScatterPlotDatum>) => {
    return (
      <TooltipContainer>
        <p style={{ color: node.color, fontSize: 14 }}>
          <b>{node.id}</b>
        </p>
        <p>{'dose: ' + parseFloat(Number(node.formattedX).toFixed(3))}</p>
        <p>{'response: ' + parseFloat(Number(node.formattedY).toFixed(3))}</p>
      </TooltipContainer>
    );
  };

  return (
    <ResponsiveScatterPlot
      data={data}
      margin={margin || DEFAULT_MARGIN}
      theme={finalDatavizTheme}
      layers={[
        plotTitle,
        'legends',
        'grid',
        'axes',
        'nodes',
        clip,
        curveLayer,
        // lines,
        'markers',
        'mesh',
      ]}
      xScale={xScale}
      yScale={yScale}
      enableGridX={true}
      enableGridY={true}
      animate={false}
      colors={[...defaultColors.scheme]}
      tooltip={getDrcTooltip}
      axisBottom={{ ...DEFAULT_X_AXIS_STYLE, legend: 'Dose' }}
      axisLeft={{ ...DEFAULT_Y_AXIS_STYLE, legend: 'Response' }}
      legends={isLegendEnabled ? [DEFAULT_LEGEND_CONFIG] : undefined}
    />
  );
};
