import { InputLabel, Select, SelectProps } from '@mui/material';
import React from 'react';
import styled from 'styled-components/macro';

interface ISelectField extends SelectProps {
  id?: string;
  children?: any;
}

const SelectField = (props: ISelectField) => {
  const { label, children, labelId, onChange } = props;

  return (
    <React.Fragment>
      <InputLabel id={labelId}>{label}</InputLabel>
      <StyledSelect onChange={onChange} id={labelId} label={label} {...props}>
        {children}
      </StyledSelect>
    </React.Fragment>
  );
};

export default SelectField;

const StyledSelect = styled(Select)`
  width: 100%;
  border-radius: 10px !important;
  font-size: 14px;
`;
