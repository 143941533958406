import React, {useState, useEffect} from "react";
import styled from 'styled-components/macro';
import _ from 'lodash';
import {OrderT} from "@business/types";
import MenuButton from "@components/elements/MenuButton";
import BackArrowIcon from "@components/icons/backArrow.icon";
import {renderOrderCost, renderOrderDetails} from "@business/orders/components/utils";
import {FlexRow, FlexItem} from "@as_core/elements/flexStyles";
import useRequests, {RequestT} from "@subApps/orders/hooks/useRequests";
import RequestsTable from "@subApps/orders/components/Common/RequestsTable";
import {ListLoader} from "@as_core/elements/listStyles";
import ErrorMessages, {MessageItem} from "@as_core/elements/UserMessages";

type FieldType = {
  label?: string;
  key?: string;
  type: string;
  mode?: 'success' | 'canceled' | 'all'
}

const orderFields: FieldType[] = [
  {
    label: 'Created',
    key: 'created',
    type: 'date',
    mode:  'all'
  },
  {
    label: 'Status',
    key: 'status',
    type: 'string',
    mode: 'all'
  },
  {
    label: 'Type',
    key: 'type',
    type: 'string',
    mode: 'all'
  },
  {
    label: 'Total Cost',
    key: 'cost',
    type: 'cost',
    mode: 'success'
  },
  {
    label: 'Payment Type',
    key: 'payment_type',
    type: 'string',
    mode: 'success'
  },
  {
    label: 'Payment Date',
    key: 'payment.created',
    type: 'date',
    mode: 'success'
  },
  {
    label: 'Fulfillment Requests',
    key: 'requests',
    type: 'array_count',
    mode: 'all'
  }
];

interface PropsI {
  order: OrderT;
  viewMode?: string;
  handleBack: ()=>void;
  topBuffer?: number;
}

const debug = false;
const ViewOrderDetails = (props: PropsI) => {
  const {order, viewMode='success', handleBack, topBuffer=100} = props;
  const {getRequestsByIds} = useRequests();
  const [isRequestsLoading, setIsRequestsLoading] = useState<boolean>(true);
  const [requests, setRequests] = useState<RequestT[]>([]);
  const [requestsUpdated, setRequestsUpdated] = useState<boolean>(false);
  debug && console.log('ViewOrderDetails | order:', order);

  useEffect(() => {
    if (order !== null) {
      const requests = order.requests;
      getRequestsByIds(requests).then((res) => {
        debug && console.log('ViewOrderDetails | res:', res);
        setRequests(res);
        setIsRequestsLoading(false);
      })
    } else {
      setIsRequestsLoading(false);
    }
  }, [order, requestsUpdated]);

  const getValue = (order: OrderT, item: FieldType) => {
    if (item?.type === 'boolean') {
      return _.get(order, item.key, '').toString();
    } else if (item?.type === 'date') {
      const value = _.get(order, item.key, '');
      if (value === '') return '';
      const utc = new Date(value);
      return utc.toLocaleDateString() + ' ' + utc.toLocaleTimeString();
    } else if (item?.type === 'order') {
      return renderOrderDetails(order);
    } else if (item?.type === 'cost') {
      return renderOrderCost(order);
    } else if (item?.type === 'array_count') {
      return order.requests.length;
    } else {
      return _.get(order, item.key, '');
    }
  };

  const displayValue = (order: OrderT, item: FieldType, index: number) => {
    if (item?.type === 'line') {
      return <HorizontalLine key={index} />;
    }
    if (item.mode === 'all' || viewMode === item.mode) {
      return (
        <UserInformation key={index}>
          <Label>{item.label}:</Label>
          <Value>{getValue(order, item)}</Value>
        </UserInformation>
      );
    }
    return null;
  };

  return (
    <ViewContainer topBuffer={topBuffer}>
      <DataRowContainer>
        <MenuButton
          onClick={handleBack}
          icon={<BackArrowIcon />}
          text={'Back to Orders List'}
          tooltipPlacement={'bottom'}
        />
        <OrderHeader>{order !== null ? order?.name: ''}</OrderHeader>
      </DataRowContainer>
      { order === null ?
        <ErrorMessages messages={[
          <MessageItem>Error loading order</MessageItem>
        ]}/>
      : <>
          <TitleRow>Order Details</TitleRow>
          <DataRowContainer>
            <ReportOrderContainer width={375}>
              {Object.keys(order) ? (
                orderFields.map((item, index) => displayValue(order, item, index))
              ) : (
                <></>
              )}
            </ReportOrderContainer>
            <FlexItem>
              {renderOrderDetails(order)}
            </FlexItem>
          </DataRowContainer>
          <TitleRow>Fulfillment Details</TitleRow>
          { isRequestsLoading ?
            <FlexRow width={'1200px'} height={'100px'} v_centered h_centered>
              <ListLoader />
            </FlexRow>
            :
            <DataRowContainer>
              { requests !== null ?
              <RequestsTable
                requests={requests}
                requestsUpdated={()=>setRequestsUpdated(prevState => !prevState)}
              />
                :
              <></>
              }
            </DataRowContainer>
          }
        </>
      }
    </ViewContainer>
  );
};

export default ViewOrderDetails;

const ViewContainer = styled.div<{topBuffer?: number}>`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${(p)=>p.topBuffer}px);
  width: max-content;
  padding: 0 10px;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-track {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
`;

ViewContainer.defaultProps = {
  topBuffer: 100
}

const ReportOrderContainer = styled.div<{width:number}>`
  display: flex;
  flex-direction: column;
  width: ${(p)=>p.width}px;
  height: max-content;
`;

const DataRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
`;

const TitleRow = styled.div`
  width: 100%;
  font-size: 16px;
  margin: 10px 0 0 20px;
  color: ${(p) => p.theme.palette.accentSecondary};
`;

const OrderHeader = styled(TitleRow)`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: ${(p) => p.theme.palette.accentPrimary};
`;

const HorizontalLine = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
`;

const UserInformation = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  display: flex;
  width: 150px;
  margin-right: 5px;
  justify-content: flex-end;
  font-size: 14px;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const Value = styled.div`
  display: flex;
  width: 200px;
  font-size: 14px;
`;
