import _ from 'lodash';
import React, { useContext } from 'react';
import { RootState } from '../../../../../store';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import SectionHeading from './SectionHeading';
import SectionRow from './SectionRow';
import { UserContext } from '@stores/UserContext';

const PropertyTable = ({
  compound,
  searchText,
  height,
  fieldSrc,
  sections,
  handleChange,
}) => {
  const { user } = useContext(UserContext);

  // ###########################################################################
  // Load Data
  // ###########################################################################
  const {
    fields,
    fieldIds,
    loading: fieldsLoading,
  } = useSelector((state: RootState) => state.fields);

  //console.log("PropertyTable {dataFields}:", dataFields);

  const getFieldIds = React.useCallback(
    (section) => {
      if (section === 'No Category') {
        return fieldIds.filter(
          (f) =>
            (fields[f].category === undefined || fields[f].category === '') &&
            _.get(fields[f], fieldSrc) === true &&
            _.get(fields[f], 'long_name', '').toLowerCase().includes(searchText)
        );
      } else {
        return fieldIds.filter(
          (f) =>
            _.get(fields[f], fieldSrc) === true &&
            _.get(fields[f], 'category') === section &&
            _.get(fields[f], 'long_name', '').toLowerCase().includes(searchText)
        );
      }
    },
    [fieldSrc, fields, fieldIds, searchText]
  );

  return (
    <Container>
      <ScrollContainer height={height}>
        <Table>
          {fieldsLoading ? (
            <tbody></tbody>
          ) : (
            <tbody>
              {sections.map((section, sectionIdx) => {
                const fieldIds = getFieldIds(section);
                return (
                  <React.Fragment key={sectionIdx}>
                    <SectionHeading key={section}>{section}</SectionHeading>
                    {fieldIds.map((fieldId) => (
                      <SectionRow
                        key={fieldId}
                        field_id={fieldId}
                        data_key={fields[fieldId].data_key}
                        fields={fields}
                        compound={compound}
                        userId={user.authId}
                        handleChange={handleChange}
                      />
                    ))}
                  </React.Fragment>
                );
              })}
            </tbody>
          )}
        </Table>
      </ScrollContainer>
    </Container>
  );
};

export default PropertyTable;

const Container = styled.div`
  position: relative;
  max-width: 800px;
  min-width: 300px;
`;

const ScrollContainer = styled.div<{ height: number }>`
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  height: ${(p) => p.height}px;
  margin-left: 9px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  & ::-webkit-scrollbar {
    display: none;
  }
`;

const Table = styled.table`
  table-layout: fixed;
  width: 100%;
`;
