import React from 'react';
import styled from 'styled-components/macro';
import {VertexPinProps} from "@components/universe/types";
import {nanColor} from "@components/universe/config";

let debug = false;
const debugComponentName = "VertexPin";
const VertexPin = (props:VertexPinProps) => {
  const {
    vertex,
    color = '#999999',
    smallPin = true
  } = props;
  debug && console.log(debugComponentName + ' | props', props);

  if (vertex === null || vertex === undefined) {
    console.error('Vertex called with null or undefined');
    return null;
  }

  const scale = smallPin ? 0.2 : 0.7;
  const translate = {x: smallPin ? -2 : 0, y: smallPin ? 70 : 10};

  const getPin = () => {
    return(
      <g transform={`scale(${scale}) translate(${translate.x} ${translate.y})`}>
        <path
          d="M19.4,6L14,0.6c-0.9-0.9-2.3-0.8-3.1,0s-0.9,2.2,0,3.1l0.4,0.4L8.1,7.3C5.3,6.3,2.2,7,0.2,9.1
	c-0.2,0.2-0.2,0.6,0,0.8l4.5,4.5L0.9,18l0,0L0,20l1.9-0.9l0,0l3.7-3.7l4.5,4.5c0.2,0.2,0.5,0.2,0.8,0c2.1-2.1,2.8-5.3,1.8-7.9
	l3.2-3.2l0.4,0.4c0.9,0.9,2.3,0.8,3.1,0C20.2,8.2,20.2,6.9,19.4,6z"
          fill={color || nanColor}
          stroke={'#555555'}
        />
      </g>
    )
  }

  // console.log(transform.k, scale);

  return (
    <Container
      className='vertex-pin'
      transform={`translate(${vertex.x + 7} ${vertex.y - 26})`}
    >
      <g className='vertex-pin'>
        <g key={'pin_' + vertex.id}>
          { getPin() }
        </g>
      </g>
    </Container>
  );
};

export default VertexPin;

const Container: any = styled.g`
`;
