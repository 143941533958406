import React from 'react';

import { IconButton, Button, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  openState: boolean;
  handleClose: any;
  handleUndo: any;
};

const DeleteSnackbar = (props: Props) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={props.openState}
      autoHideDuration={6000}
      onClose={props.handleClose}
      message='List Deleted'
      action={
        <React.Fragment>
          <Button color='secondary' size='small' onClick={props.handleUndo}>
            UNDO
          </Button>
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={props.handleClose}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};

export default DeleteSnackbar;
