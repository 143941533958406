import React, { useState } from 'react';
import { Paper, PaperProps } from '@mui/material';
import styled from 'styled-components/macro';
import { IExportRatio } from '@components/screenshot/screenshot.service';

interface SelectSizeProps {
  value: IExportRatio;
  disabled: boolean;
  sizes: Array<IExportRatio>;
  onChange?: (type: IExportRatio) => void;
}

export const SelectRatio = ({
  value,
  sizes,
  disabled,
  onChange,
}: SelectSizeProps) => {
  const [selected, setSelected] = useState(value);

  const selectType = (type) => {
    setSelected(type);
    onChange && onChange(type);
  };

  const typeList = sizes.map((size, index) => (
    <SizeCard
      selected={size.id === selected.id}
      ratio={size.ratio}
      elevation={3}
      key={index}
      disabled={disabled}
      square
      onClick={() => !disabled && selectType(size)}
    >
      <TypeCardText>{size.label}</TypeCardText>
    </SizeCard>
  ));

  return <CardScroll>{typeList}</CardScroll>;
};

const CardScroll = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  text-align: left;
  height: 90px;
`;

interface SelectablePaperProps extends PaperProps {
  ratio: number;
  disabled: boolean;
  selected: boolean;
}
type SelectablePaper = (props: SelectablePaperProps) => JSX.Element;

const SizeCard = styled(Paper as SelectablePaper)`
  height: 70px;
  width: ${(props) => 70 * props.ratio}px;
  margin: 6px 12px 6px 0;
  display: inline-table;
  white-space: normal;
  text-align: center;
  cursor: pointer;
  ${(props) => {
    if (props.disabled)
      return `border: 2px solid ${props.theme.palette.accentSecondary};`;
    if (props.selected)
      return `border: 2px solid ${props.theme.palette.accentPrimary};`;
    return `border: 2px solid ${props.theme.palette.accentSecondary};`;
  }}

  :hover {
    ${(props) => {
      if (props.disabled) return;
      if (props.selected) return;
      return `background-color: ${props.theme.palette.backgroundQuaternary} !important;`;
    }}
  }
`;

const TypeCardText = styled.div`
  position: relative;
  top: 40%;
  transform: translateY(-50%);
  font-size: 16px;
  height: 34px;
`;
