import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const ZebrafishIcon = ({
    size = 24,
  }) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "#F2F2F2" : "#8F8F8F";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={colorHighlight} d="M6.6,12.5c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8S7.5,12.5,6.6,12.5z"/>
        <path fill={colorHighlight} d="M23,10.2H10c-0.2,0-0.3-0.4-0.3-0.8S9.8,8.7,10,8.7h13c0.2,0,0.3,0.4,0.3,0.8S23.1,10.2,23,10.2z"/>
        <path fill={color} d="M23.3,17.9c-1.9,1-4,1-6.9,0c-1.2-0.4-2.1-0.9-2.8-1.3c-0.9-0.5-1.6-0.9-2.4-0.9c-0.2,0-0.4,0-0.5,0.1l-0.2,0
	c-1.3,0.3-3.6,0.8-4.9,0c-0.8-0.5-1.3-1.1-1.7-1.6c-0.3-0.4-0.6-0.7-1-1c-0.4-0.3-0.5-0.4-0.5-0.5c0-0.1,0.1-0.3,0.1-0.4
	c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0l0.7-0.9c0,0,0,0,0,0c-0.1-0.1-0.4-0.4-0.5-0.6c0-0.1,0-0.2,0.1-0.4c0.7-0.9,2.8-2.6,4.1-3
	c1.6-0.6,3.2-1,4.6-1.1c2.2-0.3,8.1-0.1,9.2,0l0.9,0.1c0.4,0,0.9,0.1,1.5,0.1V5.4c-0.5,0-1-0.1-1.4-0.1L21,5.2c-0.7,0-6.9-0.3-9.4,0
	c-1.4,0.2-3.2,0.6-4.8,1.2C5.2,7,3,8.9,2.2,9.9c-0.3,0.4-0.4,0.8-0.4,1.2c0,0.1,0,0.2,0.1,0.2c-0.2,0.1-0.3,0.2-0.4,0.4
	c-0.3,0.4-0.4,0.8-0.3,1.2c0.1,0.4,0.4,0.8,0.9,1.2c0.3,0.2,0.5,0.5,0.8,0.8c0.4,0.5,1,1.2,2,1.8c1.7,1.1,4.3,0.5,5.8,0.2l0.2,0
	c0.6-0.1,1.1,0.1,2.2,0.7c0.8,0.4,1.7,0.9,3,1.4c1.6,0.6,3,0.8,4.3,0.8c1.1,0,2.1-0.2,3.1-0.6V17.9z"/>
      </svg>
    </>
  )
}

export default ZebrafishIcon;