import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const CompareIcon = ({
    size = 24,
  }) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "#F2F2F2" : "#8F8F8F";
  return (
    <>
      <svg width={size} height={size} viewBox="-2 -2 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path fill={color} d="M16.4,3.6c-0.1,0.5-0.4,1-0.7,1.3c2.5,1.2,4.2,3.8,4.2,6.7c0,4.1-3.3,7.5-7.5,7.5c-4.1,0-7.5-3.3-7.5-7.5
          c0-2.9,1.7-5.5,4.2-6.7L8.8,5.7C8.5,6.3,8.6,6.9,9,7c0.4,0.2,0.9-0.2,1.1-0.7l1-2.2c0.1-0.3,0.2-0.6,0.1-0.9c0-0.1,0-0.1-0.1-0.2
          c0,0,0-0.1-0.1-0.1c-0.2-0.2-0.4-0.4-0.7-0.5L7.9,1.8c-0.6-0.2-1.2,0-1.3,0.4C6.6,2.7,7,3.1,7.6,3.3l1,0.3C5.5,5,3.4,8,3.4,11.6
          c0,4.9,4,9,9,9c4.9,0,9-4,9-9C21.3,8.1,19.3,5.1,16.4,3.6z"/>
        </g>
      </svg>
    </>
  )
}

export default CompareIcon;