import React, { useState } from 'react';
import {
  Modal,
  Typography,
  Button,
  IconButton,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomSnackbar from '@components/dataAnalytics/CustomSnackbar';
import useCompounds from '@components/compounds/useCompounds';
import InputField from '@as_core/controls/inputs/InputField';
interface Props {
  open: boolean;
  onClose: () => void;
  onSaveSuccess: (data: any) => void;
  components: any[];
}

const SaveComponentsModal: React.FC<Props> = ({
  open,
  onClose,
  onSaveSuccess,
  components,
}) => {
  const { saveComponents } = useCompounds();

  const [componentName, setComponentName] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>(
    'success'
  );

  // Function to show snackbar messages
  const showSnackbar = (type: 'success' | 'error', message: string) => {
    setSnackbarType(type);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // Function to handle the save button click
  const handleSaveClick = async () => {
    const componentsData = {
      name: componentName,
      components: components,
    };

    try {
      let res = await saveComponents(componentsData);
      showSnackbar('success', 'Components saved successfully!');
      onSaveSuccess(res.data);
    } catch (error) {
      console.error('Failed to save components:', error);
      showSnackbar('error', 'Failed to save components.');
    }

    onClose();
  };

  // Function to handle input changes
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComponentName(event.target.value);
  };

  // Render the modal with a text field to name the components
  return (
    <>
      <Modal open={open} onClose={onClose}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '4px',
            outline: 'none',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
          }}
        >
          <IconButton
            style={{ position: 'absolute', top: '10px', right: '10px' }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant='h6' component='h2'>
            Name your components collection
          </Typography>
          <InputField
            label='Component Name'
            value={componentName}
            onChange={handleInputChange}
            fullWidth
            margin='normal'
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}
          >
            <Button color='primary' onClick={handleSaveClick}>
              Save
            </Button>
            <Button color='secondary' onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <CustomSnackbar
        open={snackbarOpen}
        type={snackbarType}
        message={snackbarMessage}
        handleClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default SaveComponentsModal;
