import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const CompoundUniverseIcon = ({
    size = 24,
  }) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={colorHighlight} d="M12.9,17c-1.7,0-3.3-0.5-4.1-1.5C8,14.5,8,13.2,8.7,12c0.9-1.6,3.1-2.1,4.4-1.5c0.8,0.4,1.4,1.3,0.9,2.6
	c-0.1,0.3-0.5,0.5-0.8,0.4c-0.3-0.1-0.5-0.5-0.4-0.8c0.3-0.8-0.1-1-0.2-1c-0.8-0.4-2.2,0-2.7,1c-0.5,0.8-0.5,1.4,0,2
	c0.9,1,3.3,1.5,5.9,0.6c4.1-1.5,6.1-4.8,5.9-7.3c-0.2-2.2-1.8-3.8-4.7-4.4c-0.3-0.1-0.6-0.4-0.5-0.8c0.1-0.4,0.4-0.6,0.8-0.5
	C20.7,2.9,22.8,5,23,7.7c0.2,3.5-2.8,7.3-7,8.8C14.6,17,14.1,17,12.9,17z" />
        <path fill={color} stroke={color} strokeWidth="0.5" d="M11.9,21.4c-1.3,0-2.5-0.2-3.7-0.5c-2.9-0.8-4.9-2.7-5.4-5.2s0.6-5.1,3-7.1c3-2.4,7.9-3.8,11.3-1.6
	c0.9,0.6,1.4,1.5,1.4,2.5c0,1.6-0.7,3.2-2,4.2c-0.3,0.2-0.7,0.2-0.9-0.1c-0.2-0.3-0.2-0.7,0.1-0.9c0.9-0.8,1.5-1.9,1.5-3.1
	c0-0.6-0.3-1.2-0.8-1.5c-2.7-1.8-7.1-0.7-9.7,1.5c-2,1.7-3,3.8-2.6,5.8s2.1,3.5,4.5,4.2c5.3,1.4,10.7-1.2,10.7-1.2
	c0.3-0.2,0.7,0,0.9,0.3c0.2,0.3,0,0.7-0.3,0.9C17.3,20.7,14.6,21.3,11.9,21.4z" />
        <path fill={color} stroke={color} strokeWidth="0.5"  d="M1.3,8C0.9,8,0.7,7.7,0.7,7.3c0-0.2,0.1-0.3,0.2-0.4C4.5,2.5,9.7,1.6,13.7,2c0.4,0,0.6,0.3,0.6,0.7
	S14,3.3,13.6,3.3C10,2.9,5.2,3.7,1.8,7.7C1.7,7.9,1.5,8,1.3,8z" />
      </svg>

    </>
  )
}

export default CompoundUniverseIcon;