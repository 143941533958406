import React from 'react';
import { Cell, Row } from '../shared/RowElements';

const debug = false;
const ImportDoseResponseReviewRow = (props) => {
  const { data, dataIdx } = props;
  debug &&
    console.log(
      'ImportDoseResponseReviewRow | data:',
      data,
      ' dataIdx:',
      dataIdx
    );

  function getPreview(data) {
    let previewLen = Math.min(4, data.length);
    let preview = '';
    let i = 0;
    data.forEach((pair) => {
      if (i < previewLen) {
        if (i > 0) {
          preview = preview + ', ';
        }
        preview +=
          '(' + Number(pair.dose).toFixed(2) + ', ' + pair.response + ')';
      }
      i++;
    });
    return preview;
  }
  const preview = getPreview(data?.data);
  debug && console.log('ImportDoseResponseReviewRow | preview:', preview);

  return (
    <Row key={dataIdx}>
      <Cell>{data?.compound_id}</Cell>
      <Cell>{data?.sample_id}</Cell>
      <Cell>{data?.data.length}</Cell>
      <Cell>{preview}</Cell>
    </Row>
  );
};

export default ImportDoseResponseReviewRow;
