import React from 'react';
import {FlexRow, FlexItem} from "@components/layout/FlexStyles";
import {TextSmall, TextData, TextDataBold} from "@components/elements/TextStyles";
import styled from 'styled-components/macro';

const curveFitParamFields = [
  { key: 'ED50', label: 'ED50' },
  { key: 'slope', label: 'Slope' },
  { key: 'lower', label: 'Lower' },
  { key: 'upper', label: 'Upper' }
]

const curveFitStatFields = [
  { key: 'Std. Error', label: 'Std.' },
  { key: 'Lower', label: '< bound' },
  { key: 'Upper', label: '> bound' }
]

const debug = false;
const CurveFitResultsRow = (props) => {
  const { item, fitType, index } = props;
  debug && console.log('CurveFitResultsRow | item:', item);

  function getValue(results, key) {
    if (!Object.keys(results).includes(key)) {
      return ''
    }
    const value = results[key];
    if (typeof value === "number") {
      return value.toFixed(2);
    }
    return value
  }

  const mapParameters = (title, data, fields) => {
    return(
      <FlexRow width={'100%'}>
        <FlexItem width={'30px'}><TextDataBold color={'primary'}>{title}</TextDataBold></FlexItem>
        { fields.map((f) => {
          return(
              <>
                <FlexItem><TextDataBold>{f.label}:</TextDataBold></FlexItem>
                <FlexItem><TextData color={'primary'}>{getValue(data, f.key)}</TextData></FlexItem>
              </>
          )
        })}
      </FlexRow>
    )
  }

  if (!item?.results || !item.results.hasOwnProperty(fitType)) {
    return(
        <FlexRow width={'100%'} key={index} h_centered>
          <TextSmall color={'accent'}>No curve fits computed (Select Type and Submit)</TextSmall>
        </FlexRow>
    )
  }

  return(
    <Container>
      <FlexRow width={'100%'} h_centered>
        <TextDataBold color={'accent'}>Compound: {item?.compound_id} | Sample: {item?.sample_id}</TextDataBold>
      </FlexRow>
      { mapParameters('Fit:', item.results[fitType]['parameters'],curveFitParamFields) }
      { mapParameters('Error:', item.results[fitType]['statistics'],curveFitStatFields) }
    </Container>

  );
};

export default CurveFitResultsRow;

const Container = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  justify-items: center;
  align-items: center;
`;
