import React from 'react';
import styled from 'styled-components/macro';
import { BaseLayerType } from '@plotting/single-plot-view/plot.types';
import { PLOT_TYPE_LIST, PlotType } from './plot-type-list';
import { ScrollBox } from '@components/scrollbox/scrollbox';

interface PlotTypeSelectionTrayProps {
  currentPlotType: BaseLayerType;
  onChange: (base: BaseLayerType) => void;
}

export const PlotTypeSelectionTray = ({
  currentPlotType,
  onChange,
}: PlotTypeSelectionTrayProps) => {
  const currentPlotTypeInfo = PLOT_TYPE_LIST.find(
    (p) => p.base === currentPlotType
  );

  const typeList = PLOT_TYPE_LIST.map((type, index) => (
    <TypeCard
      selected={type.base === currentPlotType}
      disabled={type.disabled}
      key={index}
      onClick={() => !type.disabled && onChange(type.base)}
    >
      <TypeCardIcon src={type.icon} alt='not found' />
      <TypeCardText>
        {type.text}
        <TypeCardSubText>{type.subtext}</TypeCardSubText>
      </TypeCardText>
    </TypeCard>
  ));

  return <CardScroll scrollX={true}>{typeList}</CardScroll>;
};

const CardScroll = styled(ScrollBox)`
  padding: 12px 8px;
  white-space: nowrap;
  text-align: left;
`;

const TypeCard = styled.div<{ selected: boolean; disabled: boolean }>`
  height: 100px;
  width: 120px;
  margin: 12px 12px 12px 0;
  display: inline-table;
  white-space: normal;
  text-align: center;
  cursor: pointer;
  border-radius: 15px;
  ${(props) => {
    if (props.selected)
      return `border: 2px solid ${props.theme.palette.accentPrimary};`;
    if (props.disabled)
      return `border: 2px solid ${props.theme.palette.textSecondary};`;
    return `border: 1px solid ${props.theme.palette.accentSecondary};`;
  }}

  :hover {
    ${(props) => {
      if (props.selected) return;
      if (props.disabled)
        return `background-color: ${props.theme.palette.textSecondary} !important;`;
      return `background-color: ${props.theme.palette.backgroundQuaternary} !important;`;
    }}
  }
`;

const TypeCardIcon = styled.img`
  position: relative;
  top: 6px;
  height: 64px;
  width: 64px;
`;

const TypeCardText = styled.div`
  position: relative;
  font-size: 14px;
  padding-top: 12px;
  line-height: 1;
  height: 26px;
`;

const TypeCardSubText = styled.div`
  font-size: 10px;
  line-height: 1;
`;
