import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const DashboardIcon = ({
  size = 24
 }) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <circle cx="6.5" cy="17.5" r="3.75" stroke={colorHighlight} strokeWidth="1.5"/>
        <rect x="2.75" y="2.75" width="7.5" height="7.5" rx="1.25" stroke={color} strokeWidth="1.5"/>
        <rect x="13.75" y="2.75" width="7.5" height="7.5" rx="1.25" stroke={colorHighlight} strokeWidth="1.5"/>
        <rect x="13.75" y="13.75" width="7.5" height="2.5" rx="1.25" stroke={color} strokeWidth="1.5"/>
        <rect x="13.75" y="18.75" width="7.5" height="2.5" rx="1.25" stroke={color} strokeWidth="1.5"/>
      </g>
    </svg>
  )
}
export default DashboardIcon;