import { AxiosRequestConfig } from 'axios';
import { Compounds } from './api/';
import { handleError } from '@utils/misc';
import { CompoundT } from '../type';
import { BasicResponseT} from "@utils/api/base";

const useCompoundsNew = () => {

  const getCompound = async (token, compoundUuid, libraryId): Promise<BasicResponseT<CompoundT>> => {
    const params = { 'library': libraryId}
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await Compounds.get(token, compoundUuid, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getUser ERRORS', resp.data.errors);
    }
    return resp.data;
  };


  return {
    getCompound,
  };
};
export default useCompoundsNew;
