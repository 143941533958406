import styled from 'styled-components';

export const Row = styled.div<{
  align?: string;
  width?: string;
  height?: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.align};
  align-items: center;
  ${(props) =>
    props.width &&
    `
    width: ${/^\d+(?:px|%)/.test(props.width) ? props.width : '100%'};
  `};
  height: ${(props) => props.height};
`;
Row.defaultProps = {
  align: 'center',
  height: 'max-content',
};

export const StyledA = styled.a<{ size?: string; topMargin?: string }>`
  color: ${(p) => p.theme.palette.accentPrimary};
  font-size: ${(p) => p.size};
  margin-top: ${(p) => p.topMargin};
  text-decoration: underline;
  text-decoration-color: ${(p) => p.theme.palette.accentPrimary};
  &:hover {
    color: ${(p) => p.theme.palette.accentSecondary};
    text-decoration-color: ${(p) => p.theme.palette.accentSecondary};
  }
  &:focus {
    outline: none !important;
    border: 0;
  }
`;
StyledA.defaultProps = {
  size: '14px',
  topMargin: '0',
};

export const ForgotPasswordA = styled(StyledA)`
  color: ${(p) => p.theme.palette.textPrimary};
  font-size: ${(p) => p.size};
  text-decoration: underline;
  text-decoration-color: ${(p) => p.theme.palette.accentPrimary};
  &:hover {
    color: ${(p) => p.theme.palette.accentSecondary};
    text-decoration-color: ${(p) => p.theme.palette.accentSecondary};
  }
`;
ForgotPasswordA.defaultProps = {
  size: '12px',
};

export const Logo = styled.div`
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
`;

export const Name = styled.div`
  display: flex;
  font-size: 26px;
  padding-left: 10px;
  color: ${(p) => p.theme.palette.accentPrimary};
`;

export const Title = styled(Name)`
  color: ${(p) => p.theme.palette.textPrimary};
  font-size: 20px;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 100%;
  gap: 16px;
  margin-top: 16px;
`;

export const FormLabel = styled.div`
  width: max-content;
  color: ${(p) => p.theme.palette.textPrimary};
  font-size: 12px;
  margin-top: 10px;
  white-space: nowrap;
`;

export const LoginButton = styled.div`
  display: flex;
  margin-top: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const BasicTextInput = styled.input<{ width?: string; error?: boolean }>`
  width: ${(p) => p.width};
  height: 30px;
  display: flex;
  color: ${(p) => p.theme.palette.textPrimary};
  font-size: 14px;
  justify-content: center;
  padding-left: 10px;
  align-items: center;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  border: 1px solid
    ${(p) => (p.error ? '#F44336' : p.theme.palette.accentSecondary)};
  border-radius: 10px;
  &::placeholder {
    font-style: italic;
    font-size: 12px;
  }
  &:focus {
    outline: none !important;
    border: 1px solid
      ${(p) => (p.error ? '#F44336' : p.theme.palette.textPrimary)};
  }
  // TODO - handle the autofill color
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${(p) => p.theme.palette.backgroundPrimary}
      inset !important;
    -webkit-text-fill-color: ${(p) => p.theme.palette.textPrimary} !important;
  }
`;
BasicTextInput.defaultProps = {
  error: false,
  width: '100%',
};
