import React, {useContext} from "react";
import styled from "styled-components/macro";
import {UserContext} from "@stores/UserContext";
import {getSubscriptionDetails} from "@business/products/Subscriptions";
import HoverTextTruncate from "@as_core/elements/HoverTextTruncate";
import {getObjectValueByKey} from "@utils/misc";
import {getUserSubscriptionType} from "@utils/useUserAppInfo";

const HeaderUserInfo = () => {
  const {user} = useContext(UserContext);
  const userSubscription = getUserSubscriptionType(user.appInfo);
  const subscriptionDetails =getSubscriptionDetails(userSubscription);
  const activeRepository = getObjectValueByKey(user.appInfo, 'repositories.current.repo.name', 'None');

  return(
    <InfoContainer>
      <InfoRow><Label>Current Subscription:</Label><Value>{subscriptionDetails?.name}</Value></InfoRow>
      <InfoRow><Label>Active Repository:</Label><Value color={'primary'}>
        <HoverTextTruncate text={activeRepository} characters={30} showRear={false}/></Value></InfoRow>
    </InfoContainer>
  )
}
export default HeaderUserInfo;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3px;
  border-radius: 15px;
  width: 310px;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 18px;
`;

const Label = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  font-size: 12px;
  width: 130px;
  color: ${(p) => p.theme.palette.accentSecondary};
`;

const Value = styled.div<{color?: string}>`
  display: flex;
  padding-left: 5px;
  white-space: nowrap;
  font-size: 12px;
  width: 160px;
  color: ${(p) => p.color === 'primary' ? p.theme.palette.textPrimary : p.theme.palette.textSecondary};
`;
Value.defaultProps = {
  color: 'secondary'
}