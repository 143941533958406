import React, {useContext} from 'react';
import styled from "styled-components/macro";
import {UserContext} from "@stores/UserContext";
import TextButton from "@components/controls/TextButton/TextButton";
import {useNavigate} from "react-router-dom";

const debug = false;
type OrderButtonPropsT = {
  type: 'basic' | 'starter' | 'academic' | 'commercial'
}

const SubscriptionOrderButton = (props:OrderButtonPropsT) => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  debug && console.log('SubscriptionOrderButton | user:', user);

  return(
    <SubscriptionPriceContainer>
      <TextButton
        label='Subscribe Now'
        onClick={() => navigate('/subscriptions/order')}
        width={120}
        height={25}
      />
    </SubscriptionPriceContainer>
  )
}

export default SubscriptionOrderButton;

const SubscriptionPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  height: 16px;
  width: max-content;
  flex-direction: row;
`;

const PrimaryText = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.palette.textPrimary};
`;

const AccentText = styled(PrimaryText)`
  color: ${(p) => p.theme.palette.accentSecondary};
`;