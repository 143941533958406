import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { apiClient } from '@utils/api/base';
import { AppThunk } from '../store';
import { Compound2 } from '../type';

export interface Compounds {
  errors: Array<string>;
  limit?: number;
  size?: number;
  start_after?: number;
  continues: boolean;
  data: Compound2[];
}

export interface CompoundUpdate {
  _id: string;
  path: string;
  value: any;
}

let initialState: Compounds = {
  errors: [],
  continues: false,
  data: [],
};

const compoundsSlice = createSlice({
  name: 'compounds',
  initialState: initialState,
  reducers: {
    addOne: (state, { payload }: PayloadAction<Compound2>) => {
      state.data.push(payload);
    },
    addMany: (state, action: PayloadAction<Compound2[]>) => {
      state.data.push(...action.payload);
    },
    replace: (state, action: PayloadAction<Compound2[]>) => {
      state.data = action.payload;
    },
    update: (state, { payload }: PayloadAction<CompoundUpdate>) => {
      const cmpToUpdate = state.data.find((cmp) => cmp._id === payload._id);
      if (cmpToUpdate) {
        const { path, value } = payload;
        _.set(cmpToUpdate, path, value);
      }
    },
  },
});

export const { addOne, addMany, replace, update } = compoundsSlice.actions;

export default compoundsSlice.reducer;

export const getCompounds =
  (page: number = 0): AppThunk =>
  async (dispatch) => {
    try {
      const { data } = await apiClient.get('/compounds/');
      dispatch(replace(data.data));
    } catch (err) {
      console.error('getCompounds Failed: ', err);
    }
  };

export const getAnnotations =
  (page: number = 0): AppThunk =>
  async (dispatch) => {
    try {
      const { data } = await apiClient.get('/annotations/');
      dispatch(replace(data.data));
    } catch (err) {
      console.error('getAnnotations Failed: ', err);
    }
  };
