import { UserDataContainer} from "@pages/pages_styles/userData_style";
import styled from 'styled-components/macro';
import CurveFitResultsRow from "./CurveFitResultsRow";
import {ScrollBox} from "@components/elements/ScrollBox";

interface propsT {
  dataSet: any,
  fitType: string,
  height?: number|string
}

const debug = false;
const CurveFitResults = ({dataSet, fitType, height}:propsT) => {
  debug && console.log("CurveFitResults | height:", height, "dataSet: ", dataSet);
  return(
      <StyledScrollBox height={height}>
        <UserDataContainer key={'CurveFitResults'} vertical>
            { dataSet.map((item, index)=>{
              return(
                  <CurveFitResultsRow item={item} fitType={fitType} index={index}/>
              )
            })}
        </UserDataContainer>
      </StyledScrollBox>
  )
}

export default  CurveFitResults;

const StyledScrollBox = styled(ScrollBox)<{height?: number|string}>`
  width: 100%;
  height: ${(p) => p.height};
  justify-content: center;
  overflow-x: hidden;
`;
StyledScrollBox.defaultProps = {
  height: 300
}
