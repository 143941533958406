import React from 'react';
import styled from 'styled-components/macro';
import {BasicTextInput, Logo} from '@as_core/account/forms/FormStyles';
import { APP_CONFIG } from '@app_config/app';
import TextButton from '@components/controls/TextButton/TextButton';
import { CouponT } from "@business/types";
import HoverInfoText from "@as_core/account/fields/HoverInfoText";

interface PropsI {
  coupon: CouponT;
  repoName?: string;
  onRepoNameChange?: (e) => void;
  onRedeem: (e) => void;
}

const debug = false;
const CouponCard = (props:PropsI) => {
  const { coupon, onRedeem, repoName = '', onRepoNameChange = () => {} } = props;

  debug && console.log('CouponCard | coupon:', coupon);

  const handleAdd = (e) => {
    onRedeem(e);
  }

  return(
   <CardContainer>
     <Row>
       <Logo><img src={APP_CONFIG.logo} alt='logo' /></Logo>
     </Row>
     <Row>
       <Label>Coupon Code:</Label>
       <Value>{coupon.code}</Value>
     </Row>
     <Row width={'80%'}>
       <Label>Subscription Level:</Label>
       <Value>{coupon?.productDetails?.name}</Value>
     </Row>
     <Row width={'80%'}>
       <Label>Subscription Details:</Label>
       <Value>{coupon?.productDetails?.description}</Value>
     </Row>
     <Row>
       <Label>Access Length (days):</Label>
       <Value>{coupon.days}</Value>
     </Row>
     <Row>
       <Label>Allowed Subscription Invitations:</Label>
       <Value>{coupon.allowedInvites}</Value>
     </Row>
     <Row>
       <Label>Allowed Personal Repositories:</Label>
       <Value>{coupon?.allowedRepositories ? coupon.allowedRepositories : 1}</Value>
     </Row>
     { repoName !== '' ?
       <Row>
         <Label>Repository Display Name</Label>
         <HoverInfoText text={'This will be the display name for your secure data repository for any uploaded data, analyses, or plotting.'} />
         <BasicTextInput
           width={'250px'}
           value={repoName}
           onChange={onRepoNameChange}
         />
       </Row>
       : null
     }
     <Row>
       <TextButton label={`Redeem Coupon`} width={200} height={28} onClick={handleAdd} />
     </Row>
   </CardContainer>
  )
}

export default CouponCard;

const CardContainer = styled.div`
  display: flex;
  border-radius: 15px;
  width: 600px;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  margin: 10px;
  border: 1px solid ${(p) => p.theme.palette.accentPrimary };
  background-color: ${(p) => p.theme.palette.backgroundTertiary };
`;

const Row = styled.div<{width?: string}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 3px 0;
  ${(p) => p.width && `width: ${p.width};`}
`;

const Label = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: end;
  white-space: nowrap;
  width: 150px;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const Value = styled(Label)`
  white-space: normal;
  justify-content: start;
  padding-left: 5px;
  width: 250px;
  color: ${(p) => p.theme.palette.textPrimary};
`;