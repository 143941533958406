import styled from 'styled-components/macro';
import _ from 'lodash';

import PagePanel from '@components/pages/PagePanel';
import useCompounds from '@components/compounds/useCompounds';
import PropertiesTile from './PropertiesTile';
import PropertiesEditableTile from './PropertiesEditableTile';
import TextButton from '@components/controls/TextButton';
// Icons
import BackArrowIcon from '@components/icons/backArrow.icon';
import NewCompoundIcon from '@components/icons/newCompound.icon';
import DataSaveIcon from '@components/icons/dataSave.icon';
import ExitAreaIcon from '@components/icons/exitArea.icon';
import { useNavigate } from 'react-router-dom';
import MolImgTile from '@components/compounds/images/MolImgTile';
import { setCompoundFieldValue } from '@components/elements/compound/utils';
import { useSelector } from 'react-redux';
import { FlexRow, FlexItem } from '@components/layout/FlexStyles';
import { RootState } from '../../store';
import { FieldSliceT } from '@stores/fields';
import { useEffect, useState } from 'react';

interface ReviewStructurePropsT {
  compound: any;
  compoundMeta: any;
  priorStep: () => void;
}

let buttonWidth = 220;
let buttonHeight = 40;
let buttonMargin = '4px 10px';

const edit_fields = ['compound_name', 'casrn'];

const physchem_fields = [
  'formula',
  'exact_mol_wt',
  'heavy_atom_count',
  'rotatable_bonds',
  'aromatic_rings',
  'lipinski_hba',
  'lipinski_hbd',
  'polar_surface_area',
  'crippen_log_p',
  'c_sp3',
  'fs_p3',
];

const debug = false;
const ReviewStructure = (props: ReviewStructurePropsT) => {
  const { compound, compoundMeta, priorStep } = props;
  const { fields: allFields } = useSelector(
    (state: RootState) => state.fields as FieldSliceT
  );
  debug && console.log('ReviewStructure | compound:', compound);
  debug && console.log('ReviewStructure | compoundMeta:', compoundMeta);
  const navigate = useNavigate();

  // API
  const { updateCompound } = useCompounds();
  const [inputErrors, setInputErrors] = useState([]);

  let mol_svg_json = compoundMeta?.svg;

  const handleChange = (fieldId, value) => {
    debug &&
      console.log(
        'ReviewStructure | handleChange field:',
        fieldId,
        'value:',
        value
      );
    setCompoundFieldValue(compound, fieldId, value, allFields);

    if (inputErrors.find((error) => error.id === fieldId)) {
      setInputErrors((previous) =>
        previous.filter((error) => error.id !== fieldId)
      );
    }
    debug && console.log('ReviewStructure | handleChange compound:', compound);
  };

  const addCompound = async (exit) => {
    debug && console.log('ReviewStructure | addCompound compound:', compound);

    const localErrors = [];
    if (
      !compound?.cfields?.compound_name ||
      compound?.cfields?.compound_name?.value === ''
    ) {
      localErrors.push({
        id: 'compound_name',
        text: 'Compound name is required',
      });
    }

    setInputErrors(localErrors);

    if (localErrors.length < 1) {
      try {
        // 1: save the compound, get the compound uuid
        compound['mol_svg'] = mol_svg_json;
        compound['structure'] = compoundMeta?.structure;
        await updateCompound(compound);
        if (exit) {
          // 3: navigate to the details page
          navigate(`/compounds/user/table`);
        } else {
          priorStep();
        }
      } catch (ex) {
        console.error('ERROR: failed to save compound.', ex);
      }
    }
  };

  return (
    <ReviewStructureContainer>
      <PagePanel width={'855px'} height={'640px'}>
        <Review>
          <ReviewBanner>
            <TextButton
              text={'Edit Structure'}
              onClick={priorStep}
              icon={<BackArrowIcon size={32} />}
              width={buttonWidth}
              height={buttonHeight}
              margin={buttonMargin}
            />
          </ReviewBanner>
          <CompoundInformationContainer>
            <FlexRow>
              <FlexItem width={'50%'}>
                <Column>
                  <Title>Add Compound Information</Title>
                  <PropertiesEditableTile
                    compound={compound}
                    fields={edit_fields}
                    handleChange={handleChange}
                    inputErrors={inputErrors}
                  />
                  <Title>Molecular Structure</Title>
                  <ImgContainer>
                    <MolImgTile mol_svg={mol_svg_json} width={280} />
                  </ImgContainer>
                </Column>
              </FlexItem>
              <FlexItem width={'50%'}>
                <Column>
                  <Title>Molecular Properties</Title>
                  {_.has(compound, 'cfields') ? (
                    <PropertiesTile
                      compound={compound}
                      fields={physchem_fields}
                    />
                  ) : (
                    <></>
                  )}
                </Column>
              </FlexItem>
            </FlexRow>
          </CompoundInformationContainer>
          <ReviewFooter>
            <TextButton
              text={'Save + New Molecule'}
              onClick={() => addCompound(false)}
              icon={
                <>
                  <DataSaveIcon size={28} />
                  <NewCompoundIcon size={24} />
                </>
              }
              width={buttonWidth}
              height={buttonHeight}
              margin={buttonMargin}
              iconRight={true}
            />
            <TextButton
              text={'Save + Exit'}
              onClick={() => addCompound(true)}
              icon={
                <>
                  <DataSaveIcon size={28} />
                  <ExitAreaIcon size={28} />
                </>
              }
              width={buttonWidth}
              height={buttonHeight}
              margin={buttonMargin}
              iconRight={true}
            />
          </ReviewFooter>
        </Review>
      </PagePanel>
    </ReviewStructureContainer>
  );
};

export default ReviewStructure;

const ReviewStructureContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
`;

const ReviewBanner = styled.div`
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ReviewFooter = styled.div`
  width: 100%;
  margin-bottom: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  vertical-align: top;
  height: max-content;
`;

const Title = styled.div`
  display: flex;
  width: 100%;
  font-size: 14px;
  color: ${(p) => p.theme.palette.textPrimary};
`;

const Review = styled.div`
  position: relative;
  width: 850px;
  height: 500px;
  margin: 0 auto;
`;

const CompoundInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImgContainer = styled.div`
  width: 280px;
  height: 280px;
  border-radius: 15px;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
`;
