import React, {useState, useEffect, useContext} from 'react';
import styled from 'styled-components/macro';
import { ListLoader } from '@as_core/elements/listStyles';
import useRequests, {RequestT} from '../../hooks/useRequests';
import { FlexRow } from '@as_core/elements/flexStyles';
import RequestDetails from "@subApps/orders/components/Common/RequestDetails";
import {UserContext} from "@stores/UserContext";
import {filterByConsulting, getStatusCounts} from "@subApps/orders/utils";
import ConsultingProcessMap from "@subApps/orders/components/Consulting/ConsultingProcessMap";
import RequestsTable from "@subApps/orders/components/Common/RequestsTable";

const debug = false;
const ConsultingList = () => {
  const { user} = useContext(UserContext);
  debug && console.log('requestsList | user: ', user);
  const { getUserRequests } = useRequests();
  const [requests, setRequests ] = useState<Array<RequestT>>([]);
  const [counts, setCounts ] = useState<{ [key:string]: number }>({});
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ requestsUpdated, setRequestsUpdated] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      getUserRequests(user.authId).then((requests => {
        debug && console.log('useEffect | requests: ', requests);
        // aggregate them by status
        const consultingRequests = filterByConsulting(requests, true);
        setRequests(consultingRequests);
        setCounts(getStatusCounts(consultingRequests));
        setLoaded(true);
        setIsLoading(false);
      }));
    }
  }, [requestsUpdated]);

  debug && console.log('loaded', loaded, 'isLoading', isLoading, 'requests.length', requests.length);

  return (
    <>
      { loaded && !isLoading ?
        <ListContainer>
          <ConsultingProcessMap
            counts={counts}
          />
          { selectedRequest !== null ?
            <RequestDetails
              request={selectedRequest}
              updateRequest={setSelectedRequest}
              setUpdatedRequestForms={()=>setRequestsUpdated(prevState => !prevState)}
              handleBack={()=>setSelectedRequest(null)}
            />
            :
            <RequestsTable
              requests={requests}
              requestsUpdated={() => setRequestsUpdated(prevState => !prevState)}
              requestType='consulting'
            />
          }
        </ListContainer>
      :
        <FlexRow width={'100%'} height={'200px'} v_centered h_centered>
          <ListLoader />
        </FlexRow>
      }
    </>
  );
};

export default ConsultingList;


const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
