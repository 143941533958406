import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const ThirdPartyAppsIcon = ({
    size = 24,
  }) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={colorHighlight} d="M117.3,149.3H32c-17.7,0-32-14.3-32-32V32C0,14.3,14.3,0,32,0h85.3c17.7,0,32,14.3,32,32v85.3
		C149.3,135,135,149.3,117.3,149.3z M32,21.3c-5.9,0-10.7,4.8-10.7,10.7v85.3c0,5.9,4.8,10.7,10.7,10.7h85.3
		c5.9,0,10.7-4.8,10.7-10.7V32c0-5.9-4.8-10.7-10.7-10.7H32z"/>
        <path fill={color} d="M298.7,149.3h-85.3c-17.7,0-32-14.3-32-32V32c0-17.7,14.3-32,32-32h85.3c17.7,0,32,14.3,32,32v85.3
		C330.7,135,316.3,149.3,298.7,149.3z M213.3,21.3c-5.9,0-10.7,4.8-10.7,10.7v85.3c0,5.9,4.8,10.7,10.7,10.7h85.3
		c5.9,0,10.7-4.8,10.7-10.7V32c0-5.9-4.8-10.7-10.7-10.7H213.3z"/>
        <path fill={color} d="M480,149.3h-85.3c-17.7,0-32-14.3-32-32V32c0-17.7,14.3-32,32-32H480c17.7,0,32,14.3,32,32v85.3
		C512,135,497.7,149.3,480,149.3z M394.7,21.3c-5.9,0-10.7,4.8-10.7,10.7v85.3c0,5.9,4.8,10.7,10.7,10.7H480
		c5.9,0,10.7-4.8,10.7-10.7V32c0-5.9-4.8-10.7-10.7-10.7H394.7z"/>
        <path fill={color} d="M117.3,330.7H32c-17.7,0-32-14.3-32-32v-85.3c0-17.7,14.3-32,32-32h85.3c17.7,0,32,14.3,32,32v85.3
		C149.3,316.3,135,330.7,117.3,330.7z M32,202.7c-5.9,0-10.7,4.8-10.7,10.7v85.3c0,5.9,4.8,10.7,10.7,10.7h85.3
		c5.9,0,10.7-4.8,10.7-10.7v-85.3c0-5.9-4.8-10.7-10.7-10.7H32z"/>
        <path fill={color} d="M298.7,330.7h-85.3c-17.7,0-32-14.3-32-32v-85.3c0-17.7,14.3-32,32-32h85.3c17.7,0,32,14.3,32,32v85.3
		C330.7,316.3,316.3,330.7,298.7,330.7z M213.3,202.7c-5.9,0-10.7,4.8-10.7,10.7v85.3c0,5.9,4.8,10.7,10.7,10.7h85.3
		c5.9,0,10.7-4.8,10.7-10.7v-85.3c0-5.9-4.8-10.7-10.7-10.7H213.3z"/>
        <path fill={color} d="M480,330.7h-85.3c-17.7,0-32-14.3-32-32v-85.3c0-17.7,14.3-32,32-32H480c17.7,0,32,14.3,32,32v85.3
		C512,316.3,497.7,330.7,480,330.7z M394.7,202.7c-5.9,0-10.7,4.8-10.7,10.7v85.3c0,5.9,4.8,10.7,10.7,10.7H480
		c5.9,0,10.7-4.8,10.7-10.7v-85.3c0-5.9-4.8-10.7-10.7-10.7H394.7z"/>
        <path fill={color} d="M117.3,512H32c-17.7,0-32-14.3-32-32v-85.3c0-17.7,14.3-32,32-32h85.3c17.7,0,32,14.3,32,32V480
		C149.3,497.7,135,512,117.3,512z M32,384c-5.9,0-10.7,4.8-10.7,10.7V480c0,5.9,4.8,10.7,10.7,10.7h85.3c5.9,0,10.7-4.8,10.7-10.7
		v-85.3c0-5.9-4.8-10.7-10.7-10.7H32z"/>
        <path fill={color} d="M298.7,512h-85.3c-17.7,0-32-14.3-32-32v-85.3c0-17.7,14.3-32,32-32h85.3c17.7,0,32,14.3,32,32V480
		C330.7,497.7,316.3,512,298.7,512z M213.3,384c-5.9,0-10.7,4.8-10.7,10.7V480c0,5.9,4.8,10.7,10.7,10.7h85.3
		c5.9,0,10.7-4.8,10.7-10.7v-85.3c0-5.9-4.8-10.7-10.7-10.7H213.3z"/>
        <path fill={color} d="M480,512h-85.3c-17.7,0-32-14.3-32-32v-85.3c0-17.7,14.3-32,32-32H480c17.7,0,32,14.3,32,32V480
		C512,497.7,497.7,512,480,512z M394.7,384c-5.9,0-10.7,4.8-10.7,10.7V480c0,5.9,4.8,10.7,10.7,10.7H480c5.9,0,10.7-4.8,10.7-10.7
		v-85.3c0-5.9-4.8-10.7-10.7-10.7H394.7z"/>
        <path fill={colorHighlight} d="M97.7,68H81.9V51.6c0-3.6-3-7.3-7.3-7.3l0,0c-3.6,0-7.3,3-7.3,7.3v15.8H51.6c-3.6,0-7.3,3-7.3,7.3
    c0,3.6,3,7.3,7.3,7.3h15.8v15.8c0,3.6,3,7.3,7.3,7.3l0,0c3.6,0,7.3-3,7.3-7.3V81.9h15.8c3.6,0,7.3-3,7.3-7.3
    C105.6,71,101.3,68,97.7,68z"/>
      </svg>
    </>
  )
}

export default ThirdPartyAppsIcon;