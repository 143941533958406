import { v4 as uuidv4 } from 'uuid';
import { useParams, useLocation } from 'react-router-dom';
import PageContainer from '@as_core/pages/PageContainer';
import CompoundView from '../components/compounds/CompoundView';
import { addFilter, clearAllFilters } from '@stores/filters';
import { useDispatch } from 'react-redux';
import { useContext } from 'react';
import { MenuContext } from '@stores/MenuContext';
import { useEffect } from 'react';

const debug = false;
const PgCompounds = () => {
  const { library, view } = useParams(); // this is the view parameter
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);

  debug && console.log('PgCompounds | library: ', library, ' view:', view);
  const query = useQuery();
  const dispatch = useDispatch();
  const from = query.get('from');
  const filter = query.get('filter');
  const value = query.get('value');

  useEffect(() => {
    if (library === 'aseda') {
      if (activeMenuId !== 'asedaCompounds') setActiveMenuId('asedaCompounds');
    } else if (library === 'aseda_public') {
      if (activeMenuId !== 'asedaCompoundsPublic')
        setActiveMenuId('asedaCompoundsPublic');
    } else {
      if (activeMenuId !== 'userCompounds') setActiveMenuId('userCompounds');
    }
  }, [library]);

  if (filter && value) {
    // handle link-outs from dashboard
    const newFilter = {
      type: 'select',
      uuid: uuidv4(),
      field: filter,
      comparator: 'includes',
      value: [value],
    };
    dispatch(clearAllFilters());
    dispatch(addFilter(newFilter));
  } else {
    if (from === 'menu') {
      // handle entering from side menu when potentially came in from side menu
      dispatch(clearAllFilters());
    }
  }
  const title = library === 'user' ? 'My Compounds' : 'AsedaSciences Compounds';

  return (
    <PageContainer title={title}>
      <CompoundView matchId={'myCompounds'} libraryId={library} view={view} />
    </PageContainer>
  );
};

export default PgCompounds;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
