import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import styles from '../components-style/PagePanel.module.css';
import _ from 'lodash';

import { useSelector } from 'react-redux';
import PageContainer from '@as_core/pages/PageContainer';
import PagePanel from '@components/pages/PagePanel';
import { FlexRow } from '@components/layout/FlexStyles';
import { CategoryTiles } from './dashboard/CategoryTiles';
import { RootState } from 'src/store';

import ScoreDistribution, {
  DistributionEvent,
} from './dashboard/ScoreDistribution';
import TitledHeatMapDemo2 from './dashboard/TitledHeadMapDemo2';
import ZebraFish from './dashboard/ZebraFish';

import ZebraFishDistributionExplorer from '@pages/dashboard/ZebraFishDistributionExplorer';
import CategoryExplorer from '@pages/dashboard/CategoryExplorer';
import { MenuContext } from '@stores/MenuContext';

const PgDashboard = () => {
  const [scoreFieldId, setScoreFieldId] = useState('cell_health_index');
  const { fields } = useSelector((state: RootState) => state.fields);
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);

  useEffect(() => {
    if (activeMenuId !== 'dashboard') setActiveMenuId('dashboard');
  }, [activeMenuId]);

  const handleCategoryClick = (name) => {
    console.log(name);
  };

  const handleScoreDistributionClick = ({ eventType, payload }) => {
    console.log({ eventType, payload });
    switch (eventType) {
      case DistributionEvent.TAB:
        setScoreFieldId(payload);
        break;
      default:
        console.error('Default Switch');
    }
  };

  const getXAxisLabel = () =>
    _.get(fields, `${scoreFieldId}.long_name`, '-') as string;
  // Array.from({length:10},(v,k)=>k+.1)

  return (
    <PageContainer title='Dashboard'>
      <DashboardContainer>
        <PagePanel>
          <CategoryTiles
            onClick={handleCategoryClick}
            field_id='market_segment'
          />
        </PagePanel>
        <PagePanel title='Score Distribution'>
          <ScoreDistribution
            field_id={scoreFieldId}
            boundaries={[0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]}
            library='aseda'
            yAxisLabel='Number of Compounds'
            xAxisLabel={getXAxisLabel()}
            onClick={handleScoreDistributionClick}
          />
        </PagePanel>
        <PagePanel title='Therapeutic Class Summary by Categories'>
          <CategoryExplorer
            categoryGraphType={'barChart'}
            categoryField={'therapeutic_class'}
            initialState={'Oncology'}
            distributionField={'cell_health_index'}
            distributionLabel={'Cell Health Index'}
          />
        </PagePanel>
        <FlexRow>
          <PagePanel
            width={'50%'}
            title='Compounds with Observed Zebrafish Tox Activity (805 tested)'
          >
            <div className={styles.zebrafish}>
              <ZebraFish />
            </div>
          </PagePanel>
          <PagePanel width={'50%'} title='Zebrafish Toxicity of Compounds'>
            <div className={styles.heatmap}>
              <TitledHeatMapDemo2 cellSize={25} />
            </div>
          </PagePanel>
        </FlexRow>
        <PagePanel title='Zebrafish Mortality by Market Segments (805 tested)'>
          <ZebraFishDistributionExplorer />
        </PagePanel>
        <PagePanel title='Industrial Chemicals by Categories'>
          <CategoryExplorer
            categoryGraphType={'barChart'}
            categoryField={'industrial_class'}
            initialState={'Synthetic Dye'}
            distributionField={'cell_health_index'}
            distributionLabel={'Cell Health Index'}
          />
        </PagePanel>
        <PagePanel title='Agricultural Chemicals by Categories'>
          <CategoryExplorer
            categoryGraphType={'pieChart'}
            categoryField={'agricultural_class'}
            initialState={'Insecticide'}
            distributionField={'cell_health_index'}
            distributionLabel={'Cell Health Index'}
          />
        </PagePanel>
      </DashboardContainer>
    </PageContainer>
  );
};

export default PgDashboard;

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-bottom: 10px;
`;
