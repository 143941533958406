import React, { useState } from 'react';
import MenuButton from '@components/elements/MenuButton';
import SettingsIcon from '@components/icons/settings.icon';
import ContinueIcon from '@components/icons/continue.icon';
import UniverseLibrarySelector from '@components/universe/Controls/UniverseLibrarySelector';
import ColorLegend from '@components/universe/Elements/ColorLegend';
import DraggableModal from '@components/elements/DraggableModal';
import SelectRingColorsPanel from '@components/universe/Controls/SelectRingColorsPanel';
import {
  FlexItem,
  FlexColumn,
  FlexBanner,
  FlexBannerGroup,
  FlexSpacer,
} from '@as_core/elements/flexStyles';
import { SettingsT, UniverseBannerProps } from './types';
import {
  inhibitionScale,
  uniScoresColorScale,
  chiScoresColorScale,
  zebrafishScale,
} from '@components/universe/utils/compoundUniverse';
import SelectorDropdown from '@as_core/controls/inputs/SelectorDropdown';
import SelectorTextSearch from '@components/universe/Controls/SelectorTextSearch';
import SelectIcon from '@components/icons/select.icon';
import SelectedCompoundsPanel from '@components/universe/Controls/SelectedCompoundsPanel';
import IconSearch from "@components/icons/IconSearch";

// TODO - set this based on graph network dataset -- it may have alternate forms in future
const edgeOptions = [
  { value: 'biol', label: 'Biological' },
  { value: 'chem', label: 'Chemical' },
];

const UniverseBanner = (props: UniverseBannerProps) => {
  const {
    universeSelector,
    datasets,
    setContinueSim,
    settings,
    updateSettings,
    getSearchResponse,
    onSearchItemSelect,
    selectedCompounds,
  } = props;
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const [isRingColorModalOpen, setIsRingColorModalOpen] = useState(false);
  const [isSelectedModalOpen, setIsSelectedModalOpen] = useState(false);

  const handleUniverseSelect = (v) => {
    universeSelector.setValue(v);
  };

  const handleEdgeTypeSelect = (v) => {
    updateSettings('edgeType', v);
  };
  const handleColorByChange = (v) => {
    updateSettings('nodeColorBy', v);
  };

  const handleSearchTextChange = (v) => {
    setSearchText(v);
    const values = getSearchResponse(v);
    setSearchResults(values);
  };

  const handleSearchSelect = (id) => {
    onSearchItemSelect(id);
    setSearchText('');
    setSearchResults([]);
  };

  /**
   * Get the appropriate color legends to display at right side
   * @param settings
   */
  const getLegend = (settings: SettingsT) => {
    if (settings.response_type === '%') {
      return (
        <FlexItem>
          <ColorLegend
            scale={inhibitionScale}
            title={'Inhibition'}
            lowText={'0%'}
            highText={'100%'}
          />
        </FlexItem>
      );
    } else if (settings.response_type === '%_inverse') {
      return (
        <FlexItem>
          <ColorLegend
            scale={inhibitionScale}
            title={'-Response'}
            lowText={'100%'}
            highText={'0%'}
          />
        </FlexItem>
      );
    } else if (settings.response_type === 'zebrafish') {
      return (
        <FlexItem>
          <ColorLegend
            scale={zebrafishScale}
            title={'BMD'}
            lowText={settings.limits[0].toString()}
            highText={settings.limits[1].toString()}
          />
        </FlexItem>
      );
    } else {
      return (
        <FlexColumn>
          <FlexItem>
            <ColorLegend
              scale={chiScoresColorScale}
              title={'cell health index'}
            />
          </FlexItem>
          <FlexItem>
            <ColorLegend
              scale={uniScoresColorScale}
              title={'other cell measures'}
            />
          </FlexItem>
        </FlexColumn>
      );
    }
  };
  // console.log("UniverseBanner | settings: ", settings);

  return (
    <FlexBanner>
      <FlexBannerGroup>
        {settings.mode !== 'cell_health' ? (
          <FlexItem>
            <MenuButton
              onClick={() =>
                isRingColorModalOpen
                  ? setIsRingColorModalOpen(false)
                  : setIsRingColorModalOpen(true)
              }
              icon={<SettingsIcon />}
              text={'Open/Close Controls'}
              tooltipPlacement={'bottom'}
              margin={'0'}
            />
          </FlexItem>
        ) : (
          <></>
        )}
        <FlexItem>
          <MenuButton
            onClick={() => setContinueSim(true)}
            icon={<ContinueIcon />}
            text={'Continue Expanding Graph'}
            tooltipPlacement={'bottom'}
            margin={'0'}
          />
        </FlexItem>
        {universeSelector ? (
          <FlexItem>
            <SelectorDropdown
              label={'Universe'}
              value={universeSelector.value}
              options={universeSelector.options}
              setValue={handleUniverseSelect}
            />
          </FlexItem>
        ) : null}
        <FlexItem>
          <SelectorDropdown
            label={'Similarity'}
            value={settings.edgeType}
            options={edgeOptions}
            setValue={handleEdgeTypeSelect}
          />
        </FlexItem>
        <FlexItem>
          <SelectorDropdown
            label={'Colored by'}
            value={settings.nodeColorBy}
            optionKey={'field'}
            options={settings.data_fields}
            setValue={handleColorByChange}
          />
        </FlexItem>
        <FlexItem>
          <MenuButton
            onClick={() => setIsSelectedModalOpen((prev) => !prev)}
            notificationCount={selectedCompounds?.data.length}
            icon={<SelectIcon />}
            isActive={isRingColorModalOpen}
            text={'Show/Edit Selected Compounds'}
            tooltipPlacement={'bottom'}
            margin={'0'}
          />
        </FlexItem>
        {datasets && (
          <FlexItem>
            <UniverseLibrarySelector
              datasets={datasets}
              settings={settings}
              updateSettings={updateSettings}
            />
          </FlexItem>
        )}
        <MenuButton
          icon={<IconSearch />}
          onClick={() => {setIsSearchActive(!isSearchActive)}}
          isActive = {isSearchActive}
          text={'Search'}
          tooltipPlacement={'bottom'}
        />
        { isSearchActive ?
          <SelectorTextSearch
            searchText={searchText}
            searchResults={searchResults}
            onChange={handleSearchTextChange}
            onSelect={handleSearchSelect}
            width={250}
            hideIcon={true}
          />
          : null }
      </FlexBannerGroup>
      <FlexBannerGroup>
      </FlexBannerGroup>
      <FlexBannerGroup>
        {getLegend(settings)}
        <FlexSpacer width={5} />
      </FlexBannerGroup>
      <DraggableModal
        title={'Ring Color Selection'}
        modalOpen={isRingColorModalOpen}
        onCloseClick={() => setIsRingColorModalOpen(false)}
        width={350}
        height={270}
      >
        <SelectRingColorsPanel
          settings={settings}
          updateSettings={updateSettings}
        />
      </DraggableModal>
      <DraggableModal
        title={'Selected Compounds'}
        modalOpen={isSelectedModalOpen}
        onCloseClick={() => setIsSelectedModalOpen(false)}
        width={250}
        height={270}
      >
        <SelectedCompoundsPanel selectedCompounds={selectedCompounds} />
      </DraggableModal>
    </FlexBanner>
  );
};

export default UniverseBanner;
