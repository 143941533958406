import { Theme } from '@nivo/core';
import { PlotTheme } from '../plot.types';
import { PaletteT } from '@theme/theme.type';
import { CSSProperties } from 'styled-components';

export const getDatavizTheme = (theme: PlotTheme, palette: PaletteT): Theme => {
  const baseFontSize = theme?.fontSize || 11;
  const defaults = {
    fontSize: baseFontSize,
    fontFamily: 'Arial',
    textColor: palette.textPrimary,
    axis: {
      domain: {
        line: {
          stroke: palette.backgroundTertiary,
          strokeWidth: 1.0,
        },
      },
      ticks: {
        line: {
          stroke: palette.backgroundTertiary,
          strokeWidth: 1.0,
        },
        text: {
          fontSize: Math.round(baseFontSize * 0.8),
        },
      },
      legend: {
        text: {
          fontSize: Math.round(baseFontSize * 1.2),
        },
      },
    },
    grid: {
      line: {
        stroke: palette.backgroundTertiary,
        strokeWidth: 1.0,
      },
    },
    legends: {
      text: {
        fontSize: Math.round(baseFontSize * 1.2),
      },
    },
    labels: {
      text: {
        fill: palette.titlePrimary,
      },
    },
    lines: {
      strokeWidth: 1.0,
    },
    crosshair: {
      line: {
        stroke: palette.titlePrimary,
      },
    },
  };
  return mergeDeep({}, defaults, theme);
};

// Screenshots are always done in Dark Mode, using this color palette.
export const SCREENSHOT_PALETTE = {
  accentPrimary: '#CD1157',
  accentSecondary: '#808095',
  accentTertiary: '#B80F0A',
  backgroundPrimary: '#ffffff',
  backgroundSecondary: '#cecece',
  backgroundTertiary: '#b8b8b8',
  backgroundQuaternary: '#38aacc',
  backgroundAlpha: 'rgba(0, 0, 0, 0.3)',
  titlePrimary: '#000000',
  titleSecondary: '#343434',
  textPrimary: '#000000',
  textSecondary: '#343434',
  textTertiary: '#84859c',
  textQuaternary: '#84859c',
  backgroundBlack: '#000129',
};

export const getThumbnailTheme = (hasAxes = true): Theme => {
  return {
    fontSize: 0,
    fontFamily: 'Arial',
    textColor: SCREENSHOT_PALETTE.textPrimary,
    axis: {
      domain: {
        line: {
          stroke: hasAxes
            ? SCREENSHOT_PALETTE.backgroundTertiary
            : 'transparent',
          strokeWidth: 2.0,
        },
      },
      ticks: {
        line: {
          stroke: SCREENSHOT_PALETTE.backgroundTertiary,
          strokeWidth: 0,
        },
        text: {
          fontSize: 0,
        },
      },
      legend: {
        text: {
          fontSize: 0,
        },
      },
    },
    grid: {
      line: {
        stroke: 'transparent',
        strokeWidth: 0,
      },
    },
    legends: {
      text: {
        fontSize: 0,
      },
    },
    labels: {
      text: {
        fill: SCREENSHOT_PALETTE.titlePrimary,
      },
    },
  };
};

export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}
