import { useState } from 'react';

// Define the type of the object for clarity. Adjust it based on your needs.
type CacheItemT<T> = {
  value: T,
  timeCached: number
}

type CacheObjectT<T> = {
  [key: string]: CacheItemT<T>
};

// The hook's return type
export type UseCacheObjectReturnType<T> = {
  data: CacheObjectT<T>;
  length: () => number;
  add: (key: string, value: T) => void;
  get: (key: string) => T;
  delete: (key: string) => void;
  reset: () => void;
};

// TODO -- implement the time expiration
const useCache = <T extends any>(): UseCacheObjectReturnType<T> => {
  const [data, setData] = useState<CacheObjectT<T>>({});

  const get = (key: string) => {
    if (Object.hasOwn(data, key)) {
      return data[key].value;
    }
    return null;
  };

  const add = (key: string, value: T) => {
    setData({...data, [key]: {value: value, timeCached: 0 }});
  };

  const length = () => {
    return Object.keys(data).length;
  }

  const deleteItem = (key: string) => {
    setData(prev => (
      Object.fromEntries(Object.entries(prev)
        .filter(([k]) => k !== key)
      )));
  };

  const reset = () => {
    setData({});
  };

  return { data, get, add, length, delete: deleteItem, reset };
};

export default useCache;
