import _ from 'lodash';
import React from 'react';
import styled from 'styled-components/macro';
import Editable from '../Editable';
import { roundOf } from '@utils/misc';

const GenericRenderer = (props) => {
  const { compound, fieldId, propId, handleChange } = props;

  const getValue = React.useCallback(
    (propId) => {
      const _value = _.get(compound, propId);
      return _.isNumber(_value)
        ? _.isInteger(_value)
          ? _value
          : roundOf(_value, 2).toFixed(2)
        : _value;
    },
    [compound]
  );

  const [newValue, setNewValue] = React.useState(getValue(propId));
  const inputRef = React.useRef();

  const handleSubmit = () => {
    handleChange({
      action: 'UPDATECOMPOUND',
      payload: { fieldId, propId, newValue },
    });
  };

  const handleReset = () => {
    console.log('RESET');
    setNewValue(getValue(propId));
  };

  return (
    <Editable
      text={newValue}
      placeholder=''
      childRef={inputRef}
      handleSubmit={handleSubmit}
      handleReset={handleReset}
      type='input'
    >
      <StyledInput
        ref={inputRef}
        type='text'
        name={fieldId}
        value={newValue}
        placeholder={newValue}
        onChange={(e) => {
          setNewValue(e.target.value);
        }}
      />
    </Editable>
  );
};

export default GenericRenderer;

const StyledInput = styled.input`
  box-sizing: border-box;
  border: none;
  outline: none;
  font-size: ${(p) => p.theme.sizes.small};
  font-family: ${(p) => p.theme.fonts.main};
  font-weight: ${(p) => p.theme.weights.default};
  color: ${(p) => p.theme.palette.textPrimary};
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: ${(p) => p.theme.palette.backgroundTertiary};
`;
