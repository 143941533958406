import React from 'react';
import styled from 'styled-components/macro';
import { getFieldName } from '@components/elements/compound/utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { FieldSliceT } from '@stores/fields';
import DisplayEditableValue from '@pages/addCompound/DisplayEditableValue';

interface PropertiesEditableTilePropsT {
  compound: any;
  fields: any;
  handleChange: (f, v) => void;
  inputErrors?: Array<{
    id: string;
    text: string;
  }>;
}

const debug = false;
const PropertiesEditableTile = (props: PropertiesEditableTilePropsT) => {
  const { compound, fields, handleChange, inputErrors } = props;
  const { fields: allFields } = useSelector(
    (state: RootState) => state.fields as FieldSliceT
  );
  debug &&
    console.log('PropertiesTile | compound:', compound, ' dataFields:', fields);
  return (
    <PropertiesContainer>
      {fields.map((f, i) => (
        <Item key={i}>
          <CellLabel>{getFieldName(f, allFields)}:</CellLabel>
          <CellValue>
            <DisplayEditableValue
              compound={compound}
              fieldId={f}
              allFields={allFields}
              handleChange={handleChange}
              inputErrors={inputErrors}
            />
          </CellValue>
        </Item>
      ))}
    </PropertiesContainer>
  );
};

export default PropertiesEditableTile;

const PropertiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
`;

const Item = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
`;

const CellLabel = styled.div`
  display: flex;
  padding-right: 5px;
  justify-content: end;
  width: 150px;
  font-size: ${(p) => p.theme.sizes.xsmall};
  color: ${(p) => p.theme.palette.textSecondary};
`;

const CellValue = styled.div`
  display: flex;
  width: 150px;
  font-size: ${(p) => p.theme.sizes.xsmall};
`;
