import { ReactNode } from 'react';
import styled from 'styled-components';

type TooltipContainerProps = {
  children: ReactNode;
  color?: string;
};

export const TooltipContainer = ({
  children,
  color,
}: TooltipContainerProps) => {
  return (
    <TooltipDiv
      data-html2canvas-ignore
      style={{ border: 'solid 1px ' + color, minWidth: 160 }}
    >
      {children}
    </TooltipDiv>
  );
};

const TooltipDiv = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: left;
  border-radius: 2px;
  font-size: 12px;
  padding: 8px;
`;
