// Primary upload component to handle the various steps
import React, {useState} from 'react';
import styled from 'styled-components/macro';
import ImportTargetPanelReview from './ImportTargetPanelReview';
import UploadDataStepper from '../shared/UploadDataStepper';
import ComputeUniverse from './ComputeUniverse';
import ImportTargetPanelFinal from "./ImportTargetPanelFinal";
import useFileImport from '../useFileImport';
import AssignColumnsTableRequired from '../shared/assignColumns/AssignColumnsTableRequired';
import {DataSetT, matchColumnT} from "@components/dataSets/dataSet.types";
import {ImportTargetPanelPropsT} from "./types";
import {defaultDataSet} from "@components/dataSets/dataSet.service";

function getSteps() {
  return ['Upload Spreadsheet', 'Assign Columns', 'Review Data', 'Create Universe', 'Data Saved'];
}

const wideColumnIdentities:Array<matchColumnT> = [
  { value: 'compound', label: 'Compound Name/ID', required: true, matched_column: '' },
  { value: 'smiles', label: 'Structure (SMILES)', required: true, matched_column: '' },
  { value: 'ignore', label: 'Ignored Column(s)', required: false,
    multiple: true, matched_column: []},
  { value: 'assays', label: 'Targets (Assay Response)', required: true,
    unMatched: true,
    multiple: true, matched_column: []},
]

const narrowColumnIdentities:Array<matchColumnT> = [
  { value: 'compound', label: 'Compound Name/ID', required: true, matched_column: '' },
  { value: 'smiles', label: 'Structure (SMILES)', required: true, matched_column: '' },
  { value: 'target', label: 'Target Name', required: true, matched_column: '' },
  { value: 'assay', label: 'Assay Response', required: true, matched_column: ''}
]

const debug = false;
const debugComponentName = 'ImportTargetPanelNarrow';
const ImportTargetPanelNarrow = ({formatType, handleReset}:ImportTargetPanelPropsT) => {
  const [dataSet, setDataSet] = useState<DataSetT>(defaultDataSet(''));
  const [status, setStatus] = useState<string[]>([]);
  const [nextAvailable, setNextAvailable] = useState<boolean>(false);
  const columnIdentities = formatType === 'wide' ? wideColumnIdentities : narrowColumnIdentities;
  const [columnIDs, setColumnIDs] = useState<matchColumnT[]>(columnIdentities);
  const { context } = useFileImport();
  debug && console.log('ImportTargetPanel | step:', context.activeStep);

  // ###########################################################################
  // STEPPER
  // ###########################################################################
  const steps = getSteps();

  if (context.activeStep < 1) {  // handling reset issue
    context.setActiveStep(1);
  }

  function setMatchedColumn(name, value) {
    debug && console.log(debugComponentName + ' | setMatchedColumn:', name, value);
    const updatedColumnIDs = columnIDs;
    const index = columnIdentities.findIndex((c) => c.value === name);
    if (index !== -1) updatedColumnIDs[index].matched_column = value;
    setColumnIDs(updatedColumnIDs);
  }

  const handleNext = () => {
    debug && console.log(debugComponentName + ' | handleNext');
    context.setActiveStep((currStep) => currStep + 1);
  };

  const handleBack = () => {
    debug && console.log(debugComponentName + ' | handleBack');
    context.setActiveStep((currStep) => {
      const nextStep = currStep - 1;
      if (nextStep === 1) {
        // placeholder
      }
      if (nextStep < 1) {
        handleReset();
      }
      return nextStep;
    });
  };

  const updateStatus = (statusMessage:string) => {
    setStatus(prevState => [...prevState, statusMessage]);
  }

  debug && console.log(debugComponentName + ' | activeStep:', context.activeStep);
  return (
    <Container>
      <UploadDataStepper
        handleBack={handleBack}
        handleReset={handleReset}
        handleNext={handleNext}
        hideUpload={true}
        uploadStep={4}
        steps={steps}
        hideNextSteps={[3,4]}
      />
      <StepContainer>
        {(() => {
          switch (context.activeStep) {
            case 1: // assign the column from file to required fields
              return (
                <AssignColumnsTableRequired
                  columnFields={columnIDs}
                  setMatchedColumn={setMatchedColumn}
                  nextAvailable={nextAvailable}
                  setNextAvailable={setNextAvailable}
                />
              );
            case 2: // review the data
              return <ImportTargetPanelReview
                  formatType={formatType}
                  columnFields={columnIDs}
                  dataSet={dataSet}
                  setDataSet={setDataSet}/>;
            case 3: //
              return <ComputeUniverse
                  dataSet={dataSet}
                  handleNext={handleNext}
                  updateStatus={updateStatus}
              />;
            case 4: //
              return <ImportTargetPanelFinal
                  status={status}
              />;
          }
        })()}
      </StepContainer>
    </Container>
  );
};

export default ImportTargetPanelNarrow;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`;

const StepContainer = styled.div`
  display: flex;
  justify-content: center;
  border: ${(p) => p.theme.borders.thin};
  border-radius: ${(p) => p.theme.borderRadius};
  padding: ${(p) => p.theme.sizes.small};
`;
