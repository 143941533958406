import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const NewGraphIcon = ({
    size = 24,
  }) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={colorHighlight} stroke={colorHighlight} strokeWidth= {0.5} d="M8.8,21.3H7.2v-9.5c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v9.5H4.2v-9.5c0-1.2,1-2.2,2.2-2.2s2.2,1,2.2,2.2
V21.3z"/>
      <path fill={colorHighlight} stroke={colorHighlight} strokeWidth= {0.5}d="M20.8,21.3h-1.5v-5.5c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v5.5h-1.5v-5.5c0-1.2,1-2.2,2.2-2.2s2.2,1,2.2,2.2
V21.3z"/>
      <path fill={color} stroke={color} strokeWidth= {0.5} d="M22,22H2.5V2.5C2.5,2.2,2.3,2,2,2S1.5,2.2,1.5,2.5v20C1.5,22.8,1.7,23,2,23h20c0.3,0,0.5-0.2,0.5-0.5S22.3,22,22,22z"/>
      <path  fill={colorHighlight} stroke={colorHighlight} strokeWidth= {0.5}d="M11.2,7.3c0-1.3,1.1-2.4,2.4-2.4h0.1c-0.4-0.2-0.8-0.4-1.2-0.4c-1.2,0-2.2,1-2.2,2.2v14.5h1.5V8.7
C11.4,8.3,11.2,7.8,11.2,7.3z"/>
      <path  fill={colorHighlight} stroke={colorHighlight} strokeWidth= {0.5}d="M13.6,9.6c-0.1,0-0.2,0-0.4,0v11.7h1.5V9.6H13.6z"/>
      <path fill={color} d="M21,5.7h-2V3.5C19,2.7,18.3,2,17.5,2C16.6,2,16,2.7,16,3.5v2.2h-2.5c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5H16V11
c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5V8.8h2c0.8,0,1.5-0.7,1.5-1.5C22.5,6.4,21.8,5.7,21,5.7z"/>
    </svg>
  )
}

export default NewGraphIcon;