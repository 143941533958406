import React, { useState } from 'react';
import styled from 'styled-components';

import UniverseListItem from './UniverseListItem';
import AlertDeleteConfirm from '@as_core/controls/alerts/AlertDeleteConfirm';
import { FlexItem } from "@components/layout/FlexStyles";
import { TextRegularBold } from "@components/elements/TextStyles";
import DataLoading from "@as_core/elements/DataLoading";
import InputSearchText from "@as_core/controls/inputs/InputSearchText";
import useUniverses from "@utils/api/useUniverses";
import useCognito from "@as_core/account/useCognito";

const debug = false;
export const UniverseList = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState<boolean>(false);
  const [universes, setUniverses] = useState<any[]>([]);
  const [universeToDelete, setUniverseToDelete] = useState<string>('');
  const { getAll, deleteUniverse } = useUniverses();
  const { getToken } = useCognito();

  // HANDLERS

  const updateSearch = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setSearchText('');
    }
  };

  const handleDeleteClick = (uuid) => {
    debug && console.log('handleDeleteClick ', uuid);
    setUniverseToDelete(uuid);
    setIsDeleteAlertOpen(true);
  };

  const _handleCancel = () => {
    setUniverseToDelete('');
    setIsDeleteAlertOpen(false);
  };

  const _handleDeleteConfirm = () => {
    debug && console.log('deleteConfirmed for ', universeToDelete);
    deleteUniverse(getToken(), universeToDelete).then();
    setUniverses((prev) => prev.filter((u) => u.uuid !== universeToDelete));
  };

  if (!loaded) {
    getAll(getToken()).then((response) => {
      setLoaded(true);
      setUniverses(response?.data);
    });
  }

  if (!loaded) {
    return(
        <DocumentListContainer>
          <DataLoading />
        </DocumentListContainer>
    )
  }
  let filteredList = universes;
  if (searchText.length > 2) {
    filteredList = universes.filter((d) =>
        d.name.toLowerCase().includes(searchText.toLowerCase()) ||
        d.type.toLowerCase().includes(searchText.toLowerCase()))
  }

  debug && console.log('UniverseList | universes:', universes);
  return (
    <DocumentListContainer>
      <FlexItem><TextRegularBold color={'accent'}>Your Available Universes</TextRegularBold></FlexItem>
      <FlexItem width={"50%"}>
        <InputSearchText
          value={searchText}
          setValue={updateSearch}
          onKeyDown={handleKeyDown}
          showLabel={false}
        />
      </FlexItem>
      <ScrollContainer>
        <DocumentListItemContainer>
          {filteredList.map((data, index) => (
            <UniverseListItem
              universe={data}
              handleDeleteClick={handleDeleteClick}
              key={`document-list-item-${index}`}
            />
          ))}
        </DocumentListItemContainer>
      </ScrollContainer>
      <AlertDeleteConfirm
        title={'Delete Universe'}
        message={'Confirm delete by typing DELETE below to Enable Delete'}
        alertOpen={isDeleteAlertOpen}
        onConfirm={_handleDeleteConfirm}
        closeAlert={_handleCancel}
      />
    </DocumentListContainer>
  );
};

const DocumentListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  height: 84%;
  width: calc(100% - 20px);
`;

const ScrollContainer = styled.div`
  display: flex;
  width: calc(100% - 10px);
  height: 100%;
  padding: 5px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  & ::-webkit-scrollbar {
    display: none;
  }
`;

const DocumentListItemContainer = styled.div`
  display: flex;
  flex-grow: initial;
  flex-direction: column;
  width: 100%;
`;
