import styled from 'styled-components/macro';
import {AppInfoT} from "@utils/useUserAppInfo";
import UserMessages, {MessageItem} from "@as_core/elements/UserMessages";
import MenuButton from "@components/elements/MenuButton";
import BackArrowIcon from "@components/icons/backArrow.icon";
import ObjectDetails, {FieldsTypeT} from "@subApps/admin/components/ObjectDetails";
import LabeledContainer from "@as_core/elements/LabeledContainer";

export const UserFieldsBase: FieldsTypeT[] = [
  { label: 'Email', value: 'authEmail'},
  { label: 'User Roles', value: 'roles', type: 'arrayJoin', editType: 'multiSelect' },
  { label: 'Last Login', value: 'lastLogin', type: 'datetime' },
  { label: 'Auth ID (cognito)', value: 'authId', allowCopy: true }
];

export const UserFieldsSubscription: FieldsTypeT[] = [
  { label: 'Type', value: 'subscription.type' },
  { label: 'Created On', value: 'subscription.createdOn', type: 'datetime'},
  { label: 'Expires On', value: 'subscription.expiresOn', type: 'datetime'},
  { label: 'Created By', value: 'subscription.source'},
  { label: 'Created Details', value: 'subscription.sourceId'},
  { label: 'Allowed Invitations', value: 'subscription.allowedInvites'},
  { label: 'Allowed Repositories', value: 'subscription.allowedRepositories'},
  { label: 'Created Repositories', value: 'repositories.owned', type: 'arrayCount'},
  { label: 'Repos via Sharing', value: 'repositories.shared', type: 'arrayCount'},
];

export const UserFieldsRepository: FieldsTypeT[] = [
  { label: 'Repository Name', value: 'repositories.current.repo.name' },
  { label: 'Owner Email', value: 'repositories.current.repo.ownerAuthEmail' },
  { label: 'Created On', value: 'repositories.current.repo.createdOn' },
  { label: 'Access Type', value: 'repositories.current.accessType' },
  { label: 'Repository ID', value: 'repositories.current.uuid', allowCopy: true },
];

interface UserDetailI {
  user: AppInfoT;
  backToList: ()=> void;
}

const debug = false;
const UserDetail = (props: UserDetailI) => {

  debug && console.log('RepoDetail | user', props.user);

  return(
    <UserContainer>
      <Header>
        <MenuButton
          text={'Back to user list'}
          onClick={()=>props.backToList()}
          icon={<BackArrowIcon size={32} />}
          width={40}
          height={40}
        />
      </Header>

      { props.user !== null ?
        <Column>
          <LabeledContainer label='Base User Details' width={'500px'}>
            <ObjectDetails fields={UserFieldsBase} object={props.user}/>
          </LabeledContainer>
          <LabeledContainer label='User Subscription' width={'500px'}>
            <ObjectDetails fields={UserFieldsSubscription} object={props.user}/>
          </LabeledContainer>
          <LabeledContainer label='Current Repository' width={'500px'}>
            <ObjectDetails fields={UserFieldsRepository} object={props.user}/>
          </LabeledContainer>
        </Column>
      :
      <UserMessages height='max-content' messages={[
        <MessageItem>user is undefined -- possible network error -- or bad user format</MessageItem>
      ]}/>
    }
    </UserContainer>
  );
};
export default UserDetail;

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  min-width: 500px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 500px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

