import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components/macro';
import { UserContext } from '@stores/UserContext';
import ProductCarousel from './components/ProductCarousel';
import OrdersCart from '../orders/components/OrdersCart';
import TextButton from '@components/controls/TextButton/TextButton';
import { FlexRow } from '@components/layout/FlexStyles';
import { ListLoader } from '@as_core/elements/listStyles';
import {FlexColumn} from "@as_core/elements/flexStyles";
import {ProductT} from "@business/products/products.types";
import useShoppingCart from "@as_core/hooks/useShoppingCart";

export const SubscriptionProductsList: Array<ProductT> = [
  {
    productCategoryCode: 'AS-3RND',
    productName: 'Basic - Free',
    productDescription: 'Basic -- no cost subscription with limited functionality.',
    productCatalogNumber: 'AS-3RND-FREE',
    subscriptionLevel: 'basic',
    unitPrice: 0.00,
    currencyType: '$',
    unitSize: 'no expiration',
    unitOrderMinSize: 1,
    allowedInvitees: 0,
    allowedRepositories: 0,
  },
  {
    productCategoryCode: 'AS-3RND',
    productName: 'Pilot - Invitation Only',
    productDescription: 'Pilot -- no cost subscription with full functionality for a limited duration.',
    productCatalogNumber: 'AS-3RND-PILOT',
    subscriptionLevel: 'pilot',
    unitPrice: 0.00,
    currencyType: '$',
    unitSize: 'variable length',
    unitOrderMinSize: 1,
    allowedInvitees: 0,
    allowedRepositories: 2,
  },
  {
    productCategoryCode: 'AS-3RND',
    productName: 'Starter - month',
    productDescription: 'Single month subscription to a limited set of 3RnD core capabilities. (Single user license)',
    productCatalogNumber: 'AS-3RND-1M-STR',
    subscriptionLevel: 'starter',
    unitPrice: 79.00,
    currencyType: '$',
    unitSize: 'month',
    pricingType: ['academic', 'commercial'],
    unitOrderMinSize: 1,
    allowedInvitees: 0,
    allowedRepositories: 5,
  },
  {
    productCategoryCode: 'AS-3RND',
    productName: 'Starter - annual',
    productDescription: 'Full year subscription to a limited set of 3RnD core capabilities. (Single user license)',
    productCatalogNumber: 'AS-3RND-12M-STR',
    subscriptionLevel: 'starter',
    unitPrice: 899.00,
    currencyType: '$',
    unitSize: 'month',
    unitOrderMinSize: 1
  },
  {
    productCategoryCode: 'AS-3RND',
    productName: 'Academic+ - month',
    productDescription: 'Single month subscription for academic users to all 3RnD functionality. (5 user seats)',
    productCatalogNumber: 'AS-3RND-1M-ACD',
    subscriptionLevel: 'academic',
    unitPrice: 229.00,
    currencyType: '$',
    unitSize: 'month',
    pricingType: ['academic'],
    unitOrderMinSize: 1
  },
  {
    productCategoryCode: 'AS-3RND',
    productName: 'Academic+ - annual',
    productDescription: 'Full year subscription for academic users to all 3RnD functionality. (5 user seats)',
    productCatalogNumber: 'AS-3RND-12M-ACD',
    subscriptionLevel: 'academic',
    unitPrice: 2400.00,
    currencyType: '$',
    unitSize: 'month',
    pricingType: ['academic'],
    unitOrderMinSize: 1
  },
  {
    productCategoryCode: 'AS-3RND',
    productName: 'Commercial+ - month',
    productDescription: 'Single month subscription for commercial users to all 3RnD functionality. (Single user license)',
    productCatalogNumber: 'AS-3RND-1M',
    subscriptionLevel: 'commercial',
    unitPrice: 339.00,
    currencyType: '$',
    unitSize: 'month',
    pricingType: ['commercial'],
    unitOrderMinSize: 1
  },
  {
    productCategoryCode: 'AS-3RND',
    productName: 'Commercial+ - annual',
    productDescription: 'Full month subscription for commercial users to all 3RnD functionality. (Single user license)',
    productCatalogNumber: 'AS-3RND-12M-ACD',
    subscriptionLevel: 'commercial',
    unitPrice: 3600.00,
    currencyType: '$',
    unitSize: 'month',
    pricingType: ['commercial'],
    unitOrderMinSize: 1
  }
]

const debug = false;
const SubscriptionOrderCreate = () => {
  const [products] = useState<Array<ProductT>>(SubscriptionProductsList);
  const ordersCart = useShoppingCart({});
  const { user } = useContext(UserContext);
  let orgType = user?.regInfo?.organization?.type ? user.regInfo.organization.type : 'commercial' ;
  debug && console.log('OrderCreate | user:', user, 'pricingType:', orgType);

  const [isDataLoading, setIsDataLoading] = useState(false);

  debug && console.log('OrderCreate {products}', products);
  const getAvailableProducts = () => {
    return products.filter((p) => p?.pricingType && p.pricingType.includes(orgType))
  }

  // TODO -- Add to database and update to call on first load
  const loadData = React.useCallback(async () => {
    {/*
    setIsDataLoading(true);
    const params: { [propName: string]: any } = {
      type: user.regInfo.organization.type,
    };
    const res: ResponseT<Array<ProductL>> = await getProducts(getToken(), params);
    setProducts((prev) => prev);
    */}
    setIsDataLoading(false);
  }, []);

  useEffect(() => {
    if (products && isDataLoading === false) {
      loadData().then();
    }
  }, [products, loadData]);

  const handleAddToCart = (product: ProductT) => {
    debug && console.log('OrderCreate | handleAddToCart', ordersCart.data, product);
    // only one subscription allowed
    if (ordersCart.length()) ordersCart.reset();
    // ordersCart.add(product, 1);
    // TODO -- Need to add subscriptions to Stripe and Search
  };
  debug && console.log('products', products, 'orders', ordersCart);

  return (
    <OrdersCreateContainer>
      {isDataLoading ? (
        <FlexRow width={'100%'} height={'200px'} v_centered h_centered>
          <ListLoader />
        </FlexRow>
      ) : (
        <FlexColumn width={'100%'} h_centered>
          <ProductCarousel
            products={getAvailableProducts()}
            onAddToCart={handleAddToCart}
          />
          <OrdersCart
            cart={ordersCart}
            noQuantityChanges={true}
            debug={debug}
          />
          {ordersCart.length() ? (
            <OrdersButton>
              <TextButton
                width={300}
                label={'Contact AsedaSciences to be in a 3RnD Pilot'}
                onClick={() => window.location.href = 'mailto:info@asedasciences.com?subject=Please send my 3RnD Pilot Information'}
              />
            </OrdersButton>
          ) : null}
        </FlexColumn>
      )}
    </OrdersCreateContainer>
  );
};

export default SubscriptionOrderCreate;

const OrdersCreateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
`;

const OrdersButton = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
`;
