import React from 'react';
import html2canvas from 'html2canvas';

export interface IDimension {
  height: number;
  width: number;
}

export interface IFormat {
  id: string;
  name: string;
  type: string;
  extension: string;
}

export interface IExportRatio {
  id: string;
  label: string;
  base: number;
  ratio: number;
}

export const EXPORT_RATIOS: Array<IExportRatio> = [
  { id: 'sixteen_nine', label: '16:9', base: 1600, ratio: 16 / 9 }, // 1.77
  { id: 'four_three', label: '4:3', base: 1600, ratio: 4 / 3 }, // 1.33
  { id: 'letter', label: '11 x 8.5', base: 1600, ratio: 11 / 8.5 },// 1.29
  { id: 'square', label: '1:1', base: 1600, ratio: 1.0 },
];

export const FORMATS: Array<IFormat> = [
  {
    id: 'png',
    name: 'Portable Network Graphic (.png)',
    type: 'image/png',
    extension: 'png',
  },
  {
    id: 'jpeg',
    name: 'Joint Photographic Experts Group (.jpg)',
    type: 'image/jpeg',
    extension: 'jpg',
  },
  //{id: 'svg', name: "Scalable Vector Graphic (.svg)", extension: "svg"}
];

export const capture = async (
  element: HTMLDivElement,
  dimensions: IDimension,
  format: IFormat,
  background: string
) => {
  try {
    const rect = element.getBoundingClientRect();
    const scale = rect.width / dimensions.width;
    const canvas = await html2canvas(element, {
      scale: 1 / scale,
      width: dimensions.width * scale,
      height: dimensions.height * scale,
      backgroundColor: background
    });

    document.body.appendChild(canvas);
    const data = canvas.toDataURL(format.type);
    document.body.removeChild(canvas);
    return data;
  } catch (ex) {
    console.error(ex);
  }
};

export const download = async (
  element: HTMLDivElement,
  dimensions: IDimension,
  format: IFormat
) => {
  try {
    const date = new Date();
    const isoDay = date.toISOString().slice(0, -14);
    const link = document.createElement('a');
    link.href = await capture(element,dimensions,format, '#FFFFFF');
    link.download = `image-${isoDay}-${date.getTime()}.${format.extension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return true;
  } catch (ex) {
    console.error(ex);
  }
};