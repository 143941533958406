import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { StyleContext } from '@theme/AppStyles';

import {
  setMagnifiedSvgImage,
  hideMagnifiedSvgImage,
} from '@stores/magnifiedSvgImage';

// TODO  Refactor to prevent state update on an unmounted component
// Consider using ref or portal

interface MolSvgProps {
  name?: string;
  mol_svg?: object,
  width?: number,
  hover?: boolean,
  className?: string
}

const debug = false;

const NameMolSvgTile = (props: MolSvgProps) => {
  const { name, mol_svg, hover, width=300 } = props;
  const dispatch = useDispatch();
  const [style] = useContext(StyleContext);

  const theme = style.name;
  debug && console.log('MolSvgTile {theme}', theme);
  debug && console.log('MolSvgTile {mol_svg}', mol_svg);

  // if not object return
  if (mol_svg === undefined || mol_svg === null || !Object.hasOwn(mol_svg, theme)) {
    return (
      <Container width={width}>
        {name}
      </Container>
    )
  }
  // issue that svg near neighbors
  let svg_url = "/img/icons/MissingStructure.svg";
  debug && console.log("mol_svg[style.name]", mol_svg[style.name]);
  try {
      const svg_img = JSON.parse(mol_svg[style.name]);
      svg_url = 'data:image/svg+xml;base64,' + btoa(svg_img);
  } catch(e) {
      console.log("MolSvgTile: JSON error ", e.message)
  }

  const handleMouseOver = (e) => {
    debug && console.log('handleMouseOver {e}', e);
    const rect = e.target.getBoundingClientRect();
    const payload = {
      x: rect.left + 0.5*width+50,
      y: rect.top,
      visible: true,
      svg_url: svg_url
    };
    debug && console.log('handleMouseOver {x,y}', payload.x, payload.y);
    dispatch(setMagnifiedSvgImage(payload));
  };

  const handleMouseLeave = () => {
    dispatch(hideMagnifiedSvgImage());
  };

  if (hover) {
    return (
      <Container
        width={width}
        className={props?.className}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        {name}
      </Container>
    );
  } else {
    return (
      <Container width={width} className={props?.className} >
        <img src={svg_url} alt=""/>
      </Container>
    );
  }

};

export default NameMolSvgTile;

const Container = styled.div<{width:number}>`
  display: flex;
  justify-content: flex-end;
  width: ${(p) => p.width}px;
  :hover {
    cursor: pointer;
  }
`;
