import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';
import SingleSelect from "@components/controls/SingleSelect";
import {FlexRow, FlexColumn, FlexItem} from "@components/layout/FlexStyles";
import {getSelectOptions} from "@as_core/controls/inputs/SingleSelect";

import Dropdown, {Label} from '../Elements/Dropdown';

let debug = false;
const myComponentName = "SelectTargetPanelV2";
const SelectRingColorsPanel = ({settings, updateSettings}) => {
  const [selectOptions, setSelectOptions] =
      useState(getSelectOptions(settings.data_fields, 'field'));

  function handlePrimarySelect(e) {
    debug && console.log("handlePrimarySelect", e.target.value);
    updateSettings('primary', e.target.value);
  }

  function handleSecondarySelect(id) {
    debug && console.log("handleSecondarySelect", id);
    updateSettings('secondary', [...settings.secondary, id]);
  }

  function handleSecondaryReset() {
    debug && console.log("handleSecondaryReset");
    updateSettings('secondary', []);
  }

  function handleSecondaryUnselect(id) {
    debug && console.log("handleSecondaryUnselect", id, settings.secondary.length);
    const newValues = settings.secondary.filter((v) => (v !== id));
    debug && console.log("handleSecondaryUnselect", id, settings.secondary.length);
    updateSettings('secondary', newValues);
  }
  debug && console.log(myComponentName + " | settings", settings);

  useEffect(() => {
    setSelectOptions(getSelectOptions(settings.data_fields, 'field'));
  }, [settings]);

  return (
    <Container>
      <FlexRow v_centered>
        <FlexItem>
          <Pictogram
            src='/img/universe/node_primary_highlight.svg'
            alt='Secondary target'
          />
        </FlexItem>
        <FlexItem>
          <FlexColumn width={'100%'}>
            <FlexItem><Label>Primary Ring Color</Label></FlexItem>
            <FlexItem width={'100%'}>
              <SingleSelect
                id='primary'
                label='Primary Ring Color'
                value={settings.primary}
                options={selectOptions}
                onChange={handlePrimarySelect}
                width={250}
              />
            </FlexItem>
          </FlexColumn>
        </FlexItem>
      </FlexRow>
      <FlexRow>
        <FlexItem>
          <Pictogram
            src='/img/universe/node_secondary_highlight.svg'
            alt='Secondary target'
          />
        </FlexItem>
        <FlexItem>
          <Dropdown
            id='secondary'
            label='Secondary Ring Color(s)'
            values={selectOptions}
            selectedIds={settings.secondary}
            maxAllowed={8}
            onSelect={handleSecondarySelect}
            onUnselect={handleSecondaryUnselect}
            onReset={handleSecondaryReset}
          />
        </FlexItem>
      </FlexRow>
    </Container>
  );
};

export default SelectRingColorsPanel;

const Container: any = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
  font-size: 18px;
`;

const Pictogram = styled.img`
  height: 50px;
  width: 50px;
  margin-right: ${(props) => props.theme.sizes.xsmall};
`;
