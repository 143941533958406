import React, { useContext } from 'react';
import { BarBase, PlotConfig } from '../../plot.types';
import { PlotContext } from '../plot.context';
import BarChart from 'src/dataviz/barchart/BarChart';
import {
  getBarSeriesTwoGroupingLevels,
  getBarSeriesOneGroupingLevel,
} from './data-wrangling-utils/get-bar-series';
import { Margin, Theme } from '@nivo/core';
import { DEFAULT_SMALL_GRAPH_MARGIN } from './constants';

type BarWrapperProps = {
  datavizTheme?: Theme;
  providedPlot?: PlotConfig<BarBase>;
  isSmallChart?: boolean;
};

export const BarWrapper = ({
  datavizTheme,
  providedPlot,
  isSmallChart,
}: BarWrapperProps) => {
  const plot =
    providedPlot ||
    (useContext(PlotContext) as { plot: PlotConfig<BarBase> }).plot;

  const { groupByColumns } = plot.config;

  if (!groupByColumns) {
    return null;
  }

  // A barplot can have 1 level of grouping -> it displays 1 bar per group
  // It can also have 2 levels of grouping -> groups AND subgroups -> it results in a stacked or grouped barplot
  const hasSubgroups = groupByColumns.length === 2;

  const data = hasSubgroups
    ? getBarSeriesTwoGroupingLevels(
        plot.data,
        plot.config.valueColumn,
        groupByColumns,
        plot.config.isSorted
      )
    : getBarSeriesOneGroupingLevel(
        plot.data,
        plot.config.valueColumn,
        groupByColumns[0],
        plot.config.isSorted
      );

  let keys = [plot.config.valueColumn];
  if (hasSubgroups) {
    keys = [...new Set(plot.data.map((d) => d[groupByColumns[1]]))];
  }

  // if graph is small and horizontal, put a big margin on the left to make labels fit
  const smallGraphMargin =
    plot.config.orientation === 'horizontal'
      ? { ...DEFAULT_SMALL_GRAPH_MARGIN, left: 110 }
      : DEFAULT_SMALL_GRAPH_MARGIN;

  return (
    <BarChart
      data={data}
      keys={keys}
      indexBy={groupByColumns[0]}
      margin={isSmallChart ? smallGraphMargin : undefined}
      orientation={plot.config.orientation}
      groupingMode={plot.config.groupingMode}
      title={isSmallChart ? undefined : plot.name}
      titleSize={plot.config?.titleFontSize}
      datavizTheme={datavizTheme}
      valueAxis={{
        isLogScale: plot.config.isLogScale,
        name: plot.config.valueColumn,
      }}
      isLegendEnabled={!isSmallChart}
    />
  );
};
