import _ from 'lodash';
import React from 'react';
import { ValueRendererT } from '@stores/fields';
import GenericRenderer from '@components/valueRenderers/GenericRenderer';
import MultiSelectRenderer from '@components/valueRenderers/MultiSelectRenderer';
import ObjectlistRenderer from '@components/valueRenderers/ObjectlistRenderer';
import SingleSelectRenderer from '@components/valueRenderers/SingleSelectRenderer';

const DisplayValue = (props) => {
  const { compound, fields, fieldId, propId, handleChange } = props;

  const getRenderer = React.useCallback((): ValueRendererT => {
    return _.get(fields, `${fieldId}.value_renderer`, ValueRendererT.string);
  }, [fields, fieldId]);

  const _handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    console.log("DisplayValue {propId, value}", propId, event.target.value);
    handleChange({
      action: 'UPDATECOMPOUND',
      payload: { propId, newValue: event.target.value as string },
    });
  };

  const getValue = React.useCallback((): {
    fieldSrc: string;
    valueSrc: string;
    name: string;
  }[] => {
    return _.get(compound, propId, '');
  }, [compound, propId]);

  const getOptions = React.useCallback((): string[] => {
    return _.get(fields, `${fieldId}.field_options`, '').split(';');
  }, [fields, fieldId]);

  return (
    <>
      {getRenderer() === ValueRendererT.objectlist ? (
        <ObjectlistRenderer
          compound={compound}
          fields={fields}
          fieldId={fieldId}
          propId={propId}
          handleChange={handleChange}
        />
      ) : getRenderer() === ValueRendererT.singleselect ? (
        <SingleSelectRenderer
          getValue={getValue}
          getOptions={getOptions}
          onChange={_handleChange}
        />
      ) : getRenderer() === ValueRendererT.multiselect ? (
        <MultiSelectRenderer
          compound={compound}
          fields={fields}
          fieldId={fieldId}
          propId={propId}
          handleChange={handleChange}
        />
      ) : (
        <GenericRenderer
          compound={compound}
          fields={fields}
          fieldId={fieldId}
          propId={propId}
          handleChange={handleChange}
        />
      )}
    </>
  );
};

export default DisplayValue;
