import { ResponsiveLine, Serie, SliceTooltipProps } from '@nivo/line';
import { Margin, Theme } from '@nivo/core';
import { getDatavizTheme } from '@plotting/single-plot-view/plot-panel/plot.themes';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { ScaleSpec } from '@nivo/scales';
import {
  DEFAULT_LEGEND_CONFIG,
  DEFAULT_TITLE_SIZE,
  DEFAULT_X_AXIS_STYLE,
  DEFAULT_Y_AXIS_STYLE,
} from '@dataviz/constants';
import { TooltipContainer } from '@dataviz/TooltipContainer';

const DEFAULT_MARGIN = {
  top: 60,
  right: 180,
  bottom: 80,
  left: 80,
};

interface LineChartProps {
  data: Serie[];
  margin?: Margin;
  title?: string;
  titleSize?: number;
  datavizTheme?: Theme;
  yAxis?: {
    name?: string;
    isLogScale?: boolean;
  };
  xAxis?: {
    name?: string;
  };
  isLegendEnabled?: boolean;
}

const LineChart = ({
  data,
  margin,
  title,
  datavizTheme,
  titleSize,
  yAxis,
  xAxis,
  isLegendEnabled,
}: LineChartProps) => {
  const { palette } = useContext(ThemeContext);
  const finalDatavizTheme = datavizTheme ?? getDatavizTheme({}, palette);

  const plotMargin = margin || DEFAULT_MARGIN;

  const plotTitleStyle = {
    fontFamily: finalDatavizTheme.fontFamily,
    fontSize: titleSize || DEFAULT_TITLE_SIZE,
    fill: finalDatavizTheme.textColor,
    textAnchor: 'middle',
  } as const;

  const PlotTitle = ({ innerWidth }) => {
    return (
      <text x={innerWidth / 2} y={-plotMargin.top / 2} style={plotTitleStyle}>
        {title}
      </text>
    );
  };

  const yScale: ScaleSpec = yAxis.isLogScale
    ? { type: 'symlog', max: 'auto' }
    : { type: 'linear' };

  const hasMultipleSeries = data.length > 1;

  const getLineChartTooltip = ({ axis, slice }: SliceTooltipProps) => {
    return (
      <TooltipContainer>
        <div>
          {slice.points.map((p, i) => {
            return (
              <div key={i}>
                <span style={{ color: p.serieColor }}>
                  <b>{p.serieId}</b>
                </span>
                <span>: </span>
                <span>{p.data.yFormatted}</span>
              </div>
            );
          })}
        </div>
      </TooltipContainer>
    );
  };

  return (
    <ResponsiveLine
      data={data}
      margin={plotMargin}
      sliceTooltip={getLineChartTooltip}
      enableSlices={'x'}
      enableCrosshair={true}
      animate={true}
      yScale={yScale}
      xScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
      }}
      axisBottom={{ ...DEFAULT_X_AXIS_STYLE, legend: xAxis.name }}
      axisLeft={{ ...DEFAULT_Y_AXIS_STYLE, legend: yAxis.name }}
      colors={data.map((d) => d.color)}
      layers={[
        PlotTitle,
        'grid',
        'markers',
        'axes',
        'areas',
        'crosshair',
        'lines',
        'points',
        'slices',
        'mesh',
        isLegendEnabled ? 'legends' : undefined,
      ]}
      // theme
      theme={finalDatavizTheme}
      legends={hasMultipleSeries ? [DEFAULT_LEGEND_CONFIG] : undefined}
    />
  );
};

export default LineChart;
