import React from 'react';
import styled from 'styled-components/macro';
import {FlexItem} from "@components/layout/FlexStyles";
import {TextSmall} from "@components/elements/TextStyles";

export type ColorMapT = {
  lowValue: number;
  lowColor: string;
  highValue: number;
  highColor: string;
  gradient: string;
}

const debug = false;
interface ColorLegendProps {
  colorMap: ColorMapT,
  showLabels?: boolean,
  title?: string,
  width?: number,
  fixedDecimal?: number,
}

const ColorLegend = (props:ColorLegendProps) => {
  const { colorMap, showLabels=true, title='', width=300, fixedDecimal= 1} = props
  debug && console.log("ColorLegend | {props}:", props);
  const lowText = colorMap.lowValue.toFixed(fixedDecimal).toString();
  const highText = colorMap.highValue.toFixed(fixedDecimal).toString();

  return(
    <LegendContainer width={width}>
      { title && (<FlexItem><TextSmall>{title}:</TextSmall></FlexItem>)}
      { showLabels && (<FlexItem><TextSmall>{lowText}</TextSmall></FlexItem>)}
      <FlexItem width={'100px'}>
         <ColorScale startColor={colorMap.lowColor} endColor={colorMap.highColor} />
      </FlexItem>
      { showLabels && (<FlexItem><TextSmall>{highText}</TextSmall></FlexItem>)}
    </LegendContainer>
  )
};

export default ColorLegend;

const LegendContainer = styled.div<{width: number}>`
  display: flex;
  flex-direction: row;
  width: ${(p) => p.width }px;
  justify-content: flex-end;
  align-items: center;
  margin-left: 5px;
  padding: 0;
  height: 12px;
`;

const ColorScale: any = styled.div`
  width: 100%;
  background: ${({ startColor, endColor }: any) =>
    'linear-gradient(to right, ' + startColor + ', ' + endColor + ')'};
  height: 12px;
  border: none;
  border-radius: 2px;
`;
