import React, { useRef, useEffect, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components/macro';

import { zoomIdentity } from 'd3';

import { createSimulation, updateSimulation } from './utils/force';
import { createDelaunay } from './utils/delaunay';
import { deepCopy } from './utils/tools';

import VectorPane from './Vector/VectorPane';
import EdgeWebGLPane from './WebGL/EdgeWebGLPane';
import Canvas2DPane from './Canvas2D/Canvas2DPane';
import DetailHovered from './Controls/DetailHovered';
import DetailSelectedPinned from './Controls/DetailSelectedPinned';

import {GraphProps, XYCoordinate} from "@components/universe/types";
import ContextMenu from "@components/universe/ContextMenu/ContextMenu";

const SHOW_NODE_DETAIL_ZOOM_LEVEL = 4;
let renderCount = 1;

const Graph = ({
     input,
     settings,
     updateSettings,
     aesSettings,
     continueSimulation,
     setContinueSimulation,
     selectedCompounds,
     pinnedCompounds
  }:GraphProps) => {
  const simulation = useRef<any>();
  const [transform, setTransform] = useState(zoomIdentity);
  const [showVectorVertices, setShowVectorVertices] = useState<boolean>(false);
  const [contextMenuOpen, setContextMenuOpen] = useState<boolean>(false);
  const [contextMenuPosition, setContextMenuPosition] = useState<XYCoordinate>({x: null, y: null});
  const [hoveredVertexLabel, setHoveredVertexLabel] = useState<string>('');
  const [iteration, setIteration] = useState(0);
  const data = useRef<any>({ vertices: [], edges: [] });
  const { width, height } = settings.dimensions;

  const edgeType = settings.edgeType;
  const edgesAes = _.get(aesSettings.edges, edgeType, []);

  function handleZoom(transform: any) {
    // console.log('handleZoom', transform);
    setTransform(transform);
  }

  // context menu moving off menu
  const handleMouseMove = (event: any) => {
    // Access mouse coordinates
    if (contextMenuOpen) {
      const mouseX = event.clientX;
      const mouseY = event.clientY;
      // console.log("mouseX:", mouseX, "mouseY:", mouseY, "contextMenuPosition:", contextMenuPosition);
      if (mouseX - contextMenuPosition.x < -10 || mouseX - contextMenuPosition.x > 75 ||
          mouseY - contextMenuPosition.y < -10 || mouseY - contextMenuPosition.y > 100) {
        setContextMenuOpen(false);
        window.removeEventListener('mousemove', handleMouseMove);
      }
    }
  };

  const resetSelected = () => {
    updateSettings('selectedVertex', undefined);
    updateSettings('selectedNeighborVertices', []);
    updateSettings('selectedEdges', []);
    // pinnedCompounds.reset();
  }

  // reset and set selection and zoom when edge type changes
  useEffect(() => {
    resetSelected();
  }, [edgeType]);

  // set the data depending on edge type
  useEffect(() => {
    data.current = {
      vertices: deepCopy(input.vertices),
      edges: deepCopy(input.edges[edgeType]),
    };
  }, [input, edgeType]);

  // initialise the force simulation
  useEffect(() => {
    // console.log('setup simulation');
    simulation.current = createSimulation();
    simulation.current.stop().on('tick', () => {
      setIteration((i) => (i > 5000 ? 0 : ++i));
    });

    const currentSimulation = simulation.current;
    return () => currentSimulation.stop();
  }, []);

  useEffect(() => {
    //console.log('update simulation');
    setContinueSimulation(false);
    simulation.current.stop();
    simulation.current = updateSimulation(
      simulation.current,
      settings,
      data.current.vertices,
      data.current.edges,
      edgesAes
    );
    simulation.current.alpha(1).restart();
  }, [width, height, data.current.edges, edgesAes, continueSimulation]);

  // change from the circles (distant) to the corona (close)
  useEffect(() => {
    // console.log('setShowVectorVertices', transform.k, SHOW_NODE_DETAIL_ZOOM_LEVEL);
    setShowVectorVertices(transform.k > SHOW_NODE_DETAIL_ZOOM_LEVEL);
  }, [transform]);

  useEffect(() => {
    updateSettings('delaunay', createDelaunay(data.current.vertices));
  }, [iteration, data]);

  useEffect(() => {
    if (contextMenuOpen) {
      window.addEventListener('mousemove', handleMouseMove);
    }
  }, [contextMenuOpen])
  renderCount = renderCount + 1;

  // console.log('Graph | render', renderCount, 'iteration', iteration, ' transform', transform);
  return (
    <UniverseContainer key={'UniverseContainer'}
      onContextMenu={(e) => {
        e.preventDefault(); // prevent the default behavior
        // console.log("Right Click", e.pageX, e.pageY);
        setContextMenuOpen(prev => !prev);
        setContextMenuPosition({x: e.pageX, y: e.pageY});
      }}
    >
      <DetailSelectedPinned
        key={'detailProperties'}
        data={data.current.vertices}
        pinnedCompounds={pinnedCompounds}
        settings={settings}
        resetSelected={resetSelected}
        aesSettings={aesSettings.vertex}
      />
      <WebGLWrapper>
        <EdgeWebGLPane
          iteration={iteration}
          data={data.current}
          settings={settings}
          aesSettings={aesSettings.edges}
          transform={transform}
        />
      </WebGLWrapper>
      <CanvasWrapper> {/* colored nodes at low zoom */}
        <Canvas2DPane
          iteration={iteration}
          data={data.current}
          settings={settings}
          pinnedCompounds={pinnedCompounds}
          aesSettings={aesSettings.vertex}
          transform={transform}
          showVertices={!showVectorVertices}
          selectedCompounds={selectedCompounds}
        />
      </CanvasWrapper>
      <VectorWrapper>
        <VectorPane
          data={data.current}
          pinnedCompounds={pinnedCompounds}
          settings={settings}
          updateSettings={updateSettings}
          aesSettings={aesSettings.vertex}
          transform={transform}
          zoomed={handleZoom}
          showVertices={showVectorVertices}
          setHoveredVertexLabel = {setHoveredVertexLabel}
        />
      </VectorWrapper>
      <ContextMenu
        open={contextMenuOpen}
        setOpen={setContextMenuOpen}
        position={contextMenuPosition}
        label={hoveredVertexLabel}
        settings={settings}
        selectedCompounds={selectedCompounds}
        pinnedCompounds={pinnedCompounds}
      />
      <DetailHovered
        transform={transform}
        settings={settings}
        pinnedCompounds={pinnedCompounds}
        aesSettings={aesSettings.vertex}
        contextMenuOpen={contextMenuOpen}
        hoveredVertexLabel = {hoveredVertexLabel}
      />
    </UniverseContainer>
  );
};

export default Graph;

const UniverseContainer = styled.div``;

const WebGLWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
  pointer-events: none;
`;

const CanvasWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 2;
`;

const VectorWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 5;
`;
