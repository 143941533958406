import React from 'react';
import _ from 'lodash';

import Gauge from './Gauge';
import ColorBarTable from './ColorBarTable';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { FieldSliceT } from '@stores/fields';
import { FlexColumn, FlexItem} from "@components/layout/FlexStyles";
import {CompoundT} from "../../../../../type";

interface propsT {
  compound: CompoundT;
  width?: number;
}

const Scorecard = (props: propsT) => {
  const { compound, width=290 } = props;
  const { fields: allFields } = useSelector((state: RootState) => state.fields as FieldSliceT);
  const data_key = _.get(allFields, 'cell_health_index.data_key', null);
  const value = _.get(compound, data_key, 0 );
  return (
    <FlexColumn width={width + 'px'} h_centered>
      <FlexItem>
        <Gauge value={value} min={0} max={1} />
      </FlexItem>
      <FlexItem>
        <ColorBarTable compound={compound} assay={'chi'} />
      </FlexItem>
    </FlexColumn>
  );
};

export default Scorecard;