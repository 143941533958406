import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import MatchCircle from '@pages/shared/matchPercent';
import Modal from '@pages/shared/Modal';

interface ItemProps {
  selected?: boolean;
}

interface MatchedColumnsInt {
  asedaColumns?: string;
  asedaValues?: string;
  id?: string;
  inputColumns?: string;
  percentMatch?: number;
}
const RowBuilderRequiredV2 = (props) => {
  const { matchedFields, inputData, onData } = props;
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [firstInitialisation, setFirstInitialisation] = useState(true);
  const [modalData, setModalData] = useState(null);
  const [hoveredItemIndex, setHoveredItemIndex] = useState(null);
  const [matchedObjects, setMatchedObjects] = useState([]);
  const [alternativeObject, setAlternativeObject] = useState([]);
  // Function to open the modal with specific data
  const openModal = (data) => {
    setModalData(data);
    setModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalData(null);
    setModalOpen(false);
  };

  useEffect(() => {
    if (matchedFields.data && matchedObjects && firstInitialisation) {
      setSelectedItemIndex(new Array(matchedObjects.length).fill(null));
      setMatchedObjects(matchedFields.data.matched_objects)
      setAlternativeObject(matchedFields.data.alternative_match)
      matchedFields.data.matched_objects && matchedFields.data.matched_objects.length ? setFirstInitialisation(false) : setFirstInitialisation(true)
    }
    let column_id = matchedObjects.filter((obj) => obj.id === "Yes")[0]
    column_id ? onData(column_id["asedaColumns"]) : "";
  }, [matchedFields.data, alternativeObject]);

  const handleItemClick = (index: number) => {
    if (selectedItemIndex === index) {
      setSelectedItemIndex(null);
    } else {
      setSelectedItemIndex(index);
      setHoveredItemIndex(null);
    }
  };

  const updateIdAtIndex = (index) => {
    const updatedMatchedObjects = matchedObjects.map((obj, i) => {
      if (i === index) {
        onData(obj["inputColumns"])
        return {
          ...obj,
          "id": "Yes",
        };
      } else if (obj.id === "Yes") {
        return {
          ...obj,
          "id": "No",
        };
      }
      return obj;
    });
    setMatchedObjects(updatedMatchedObjects);
  };

  const handleAlternative = (index: number, asedaCol: string) => {
    const alternateIndex = alternativeObject.findIndex((filtObj) => filtObj.asedaColumns === asedaCol);
    const oldChoice = matchedObjects[index]
    const newChoice = alternativeObject[alternateIndex]
    const newMatchedObjects = [...matchedObjects];
    newMatchedObjects[index] = newChoice;
    const newAlternativeObject = [...alternativeObject];
    newAlternativeObject[alternateIndex] = oldChoice;

    setMatchedObjects(newMatchedObjects);
    setAlternativeObject(newAlternativeObject);
  }


  return (
    <Container>
      <Header>
        <Column>Id</Column>
        <Column>Input Columns</Column>
        <Column>Aseda Columns</Column>
        <Column>Aseda values</Column>
        <Column>Input values</Column>
        <Column>Percent match</Column>
      </Header>
      <Content>
        {matchedFields.data &&
          matchedObjects &&
          matchedObjects.map((obj: MatchedColumnsInt, index: number) => {
            const key = obj.inputColumns
            const exampleData = matchedFields.data.columns_with_examples[key];
            const hasExampleData = obj.asedaValues !== "Not Found";
            const filtered_alternative_object = alternativeObject.filter((filtObj) => filtObj.inputColumns === key);
            return (
              <div key={index}>
                <ItemWrapper>
                  <Item
                    onClick={() => handleItemClick(index)}
                    selected={selectedItemIndex === index}
                    onMouseEnter={() => setHoveredItemIndex(index)}
                    onMouseLeave={() => setHoveredItemIndex(null)}
                  >
                    <ItemContent>
                      {obj.id === "No" ? (
                        <ButtonMoreData onClick={() => updateIdAtIndex(index)}>{'Define as ID'}</ButtonMoreData>
                      ) : (
                        obj.id
                      )
                      }
                    </ItemContent>
                    <ItemContent>{obj.inputColumns}</ItemContent>
                    <ItemContent>{obj.asedaColumns}</ItemContent>
                    <ItemContentExample>
                      {obj.asedaValues}
                      {hasExampleData && (
                        <ButtonMoreData onClick={() => openModal(exampleData)}>
                          View More Data
                        </ButtonMoreData>
                      )}
                    </ItemContentExample>
                    <ItemContent>{inputData[key]}</ItemContent>
                    <ItemContent>
                      <MatchCircle percent={obj.percentMatch} circleColor="#fff" progressColor="rgb(214, 11, 81)" />
                    </ItemContent>
                  </Item>
                </ItemWrapper>
                {selectedItemIndex === index && (
                  filtered_alternative_object.length ? (
                    filtered_alternative_object.map((altObj: MatchedColumnsInt, altIndex: number) => {
                      const altKey = altObj.asedaColumns;
                      const altExampleData = matchedFields.data.columns_with_examples[altKey];
                      const hasAltExampleData = altObj.asedaValues !== "Not Found";

                      return (
                        <ItemAlternative key={altIndex}>
                          <ItemContent>

                            {altObj.id}

                          </ItemContent>
                          <ItemAlternativeLeft>{key}</ItemAlternativeLeft>
                          <ItemAlternativeRight>
                            <ContentItemAlternativeRight>
                              <ItemContentExample>
                                {altKey}
                                <ButtonMoreData onClick={() => handleAlternative(index, altKey)}>Select as match</ButtonMoreData>
                              </ItemContentExample>
                              <ItemContentExample>
                                {altObj.asedaValues}
                                {hasAltExampleData && (
                                  <ButtonMoreData onClick={() => openModal(matchedFields.data.columns_with_examples[altKey])}>
                                    View More Data
                                  </ButtonMoreData>
                                )}
                              </ItemContentExample>
                              <ItemContent>{inputData[key]}</ItemContent>
                              <ItemContent>
                                <MatchCircle percent={altObj.percentMatch} circleColor="#fff" progressColor="rgb(214, 11, 81)" />
                              </ItemContent>
                            </ContentItemAlternativeRight>
                          </ItemAlternativeRight>
                        </ItemAlternative>
                      );
                    })
                  ) : (
                    <ItemAlternative>
                      <ItemContentId>
                        No
                      </ItemContentId>
                      <EmptyAlternativeText>
                        Sorry, we didn't find alternative columns.
                      </EmptyAlternativeText>
                    </ItemAlternative>
                  )
                )}
              </div>
            );
          })}
        <Modal isOpen={isModalOpen} onClose={closeModal} data={modalData} title="More Examples" />
      </Content>
    </Container>
  );
};



export default React.memo(RowBuilderRequiredV2);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const ButtonMoreData = styled.button`
  width: fit-content;
  border-radius: 10px;
  border-color: pink;
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  width: 97%;
  height: 50px;
  background-color: rgb(34 38 65);
  justify-content: space-around;
  position: absolute;
  z-index: 10;
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 17%;

`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 2%;
`;

const ItemWrapper = styled.div`
  position: relative;
`;

const Item = styled.button<ItemProps>`
  width: 100%;
  box-sizing: border-box;
  border-bottom: solid #F4B860;
  border-top: solid #F4B860;
  min-height: 14%;
  background-color: ${(props) => (props.selected ? 'rgb(70 75 96)' : '#101945')};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2%;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s;

  &:hover {
    background-color: ${(props) => (!props.selected && 'rgb(19 29 80)')};
    overflow-y: hidden;
  }
`;

const ItemContent = styled.div`
  align-self: center;
  text-align: center;
  width: 19%;
  overflow-wrap: break-word;
  font-size: 20px;
  color: ${(props) => props.theme.palette.textPrimary};
  font-family: Helvetica;
`;

const ItemContentTextId = styled.div`
  align-self: center;
  text-align: center;
  max-width: fit-content;
  margin-left: 4.5%;
  overflow-wrap: break-word;
  font-size: 20px;
  color: rgb(214, 11, 81);
  font-family: Helvetica;
  & button {
    margin-left: -35px;
  }
`;

const ItemContentId = styled.div`
  align-self: center;
  text-align: right;
  width: 7%;
  overflow-wrap: break-word;
  font-size: 20px;
  color: ${(props) => props.theme.palette.textPrimary};
  font-family: Helvetica;
  margin-right: 1.7%;
  height: fit-content;
  z-index: 15;

  & button {
    margin-left: 30px;
    margin-right: -50px;
    z-index: 20;
  }
`;

const ItemContentExample = styled.div`
  align-self: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 19%;
  overflow-wrap: anywhere;
  -webkit-box-align: center;
  align-items: center;
  height: 60px;
  justify-content: space-around;
  font-size: 20px;
  color: ${(props) => props.theme.palette.textPrimary};
  font-family: Helvetica;
`;

const ItemAlternative = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-bottom: solid #F4B860;
  border-top: solid #F4B860;
  min-height: 14%;
  background-color: rgb(70 75 96);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const ItemAlternativeLeft = styled.div`
  width: 19%;
  box-sizing: border-box;
  align-self: center;
  min-height: 14%;
  background-color: rgb(70 75 96);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const ItemAlternativeRight = styled.button`
  width: 77%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
    background-color: rgb(70 75 96);
    border: none;
    z-index: 2;
`;

const ContentItemAlternativeRight = styled.div`
  width: 99.5%;
  min-height: 14%;
  background-color: rgb(70 75 96);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const EmptyAlternativeText = styled.div`
  width: 99.5%;
  height: 40px;
  background-color: rgb(70 75 96);
  text-align: center;
  margin-top: 0.5%;
`;
