import React, { memo } from 'react';

import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import DraggableModal from '../../elements/DraggableModal';
import TextButton from '@components/controls/TextButton';
import FilterSearch from './filter.search';
import FilterDisplay from './filter.display';
import { FilterModalI } from './filter.types';
import { v4 as uuidv4 } from 'uuid';
import { DraggablePanelBody } from '@components/modals/common';
import { RootState } from '../../../store';
import { FieldSliceT } from '@stores/fields';
import {
  addFilter,
  updateFilter,
  updateLogicalFilter,
  removeFilter,
  clearAllFilters,
} from '@stores/filters';

const debug = false;
export const FilterModal = memo(
  ({
    modalOpen,
    setModalOpen,
    onSave,
    onClose,
    isFilterActive,
    setIsFilterActive,
  }: FilterModalI) => {
    const dispatch = useDispatch();

    // handle the dataFields
    const { fields: allFields } = useSelector(
      (state: RootState) => state.fields as FieldSliceT
    );
    const all_fields = Object.keys(allFields);
    // debug && console.log('filter {all_fields} ', all_fields);
    const invalid_fields = ['molSVGUUID', 'formula', 'InChI', 'InChIKey'];
    const filter_fields = all_fields.filter((f) => !invalid_fields.includes(f));
    // debug && console.log('filter {filter_fields} ', filter_fields);

    // handle the filters
    const { filters } = useSelector((state: RootState) => state.filters);
    const { filterExtents } = useSelector(
      (state: RootState) => state.filterExtents
    );
    debug && console.log('filter | filters', filters);

    const handleClose = () => {
      setModalOpen(false);
      onSave();
    };

    const handleClear = () => {
      dispatch(clearAllFilters());
      setIsFilterActive(false);
    };

    const handleSave = () => {
      setModalOpen(false);
      onClose && onClose();
    };

    // add the new filter using appropriate initial values
    function handleFilterSelect(id) {
      const slider_fields = ['float', 'integer', 'circle'];
      if (slider_fields.includes(allFields[id].value_renderer)) {
        // default values -- handles cell health
        let min = 0.0;
        let max = 1.0;
        let step = 0.02;
        // set to defined values
        // TODO: call the get range functionality of the API
        if (Object.keys(filterExtents).includes(id)) {
          min = filterExtents[id].min;
          max = filterExtents[id].max;
          step = filterExtents[id].step;
        }
        //console.log("id min max step", id, min, max, step)
        const newFilter = {
          type: 'slider',
          uuid: uuidv4(),
          field: id,
          title: allFields[id].long_name,
          value: [min, max],
          min: min,
          max: max,
          step: step,
          marks: [
            { value: min, label: min.toString() },
            { value: max, label: max.toString() },
          ],
        };
        dispatch(addFilter(newFilter));
        isFilterActive || setIsFilterActive(true);
      } else if (allFields[id].value_renderer === 'string') {
        const newFilter = {
          type: 'string',
          uuid: uuidv4().toString(),
          field: id,
          comparator: 'contains',
          value: '',
        };
        dispatch(addFilter(newFilter));
        isFilterActive || setIsFilterActive(true);
      } else if (allFields[id].value_renderer === 'zebrafish') {
        const newFilter = {
          type: 'logical',
          uuid: uuidv4().toString(),
          field: id,
          comparator: 'exists',
        };
        dispatch(addFilter(newFilter));
        isFilterActive || setIsFilterActive(true);
      } else if (
        ['singleselect', 'multiselect'].includes(allFields[id].value_renderer)
      ) {
        const newFilter = {
          type: 'select',
          uuid: uuidv4().toString(),
          field: id,
          comparator: 'includes',
          value: [],
        };
        dispatch(addFilter(newFilter));
        isFilterActive || setIsFilterActive(true);
      }
    }

    // handle the delete by sending reducer
    const handleFilterDelete = (uuid) => {
      debug && console.log('FilterModal | handleFilterDelete', uuid);
      dispatch(removeFilter({ uuid: uuid }));
      filters.length > 1 || setIsFilterActive(false);
    };

    // handle the update by sending to reducer
    function handleFilterUpdate(type, update) {
      debug && console.log('FilterModal | handleFilterUpdate', type, update);
      if (type === 'logical') {
        dispatch(updateLogicalFilter(update));
      } else {
        dispatch(updateFilter(update));
      }
    }

    return (
      <DraggableModal
        modalOpen={modalOpen}
        onCloseClick={handleClose}
        title='Filter Compound Set'
        opacity={1.0}
      >
        <DraggablePanelBody>
          <FilterSearch
            id='add_filter'
            label='Add Filter'
            values={filter_fields}
            idInfo={allFields}
            onSelect={handleFilterSelect}
          />
          <FilterDisplay
            filters={filters}
            fieldInfo={allFields}
            handleFilterUpdate={handleFilterUpdate}
            filterDelete={handleFilterDelete}
          />
          <ActionBar>
            <TextButton
              text='Clear All Filters'
              height={30}
              width={120}
              onClick={handleClear}
            />
            <TextButton
              text='Apply'
              height={30}
              width={90}
              onClick={handleSave}
            />
          </ActionBar>
        </DraggablePanelBody>
      </DraggableModal>
    );
  }
);

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  bottom: 8px;
  right: 16px;
`;
