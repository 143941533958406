import { useState } from 'react';
import StationaryModal from '@components/elements/StationaryModal';
import styled from 'styled-components/macro';
import { Close } from '@mui/icons-material';
import HeaderMenu from '@as_core/controls/HeaderMenu';
import { FlexBanner } from '@as_core/elements/flexStyles';
import DatasetList from './DatasetList';

type actionT = {
  key: string;
  label: string;
};

const menuActions: Array<actionT> = [
  { key: 'data', label: 'My Data Sets' },
  { key: 'favorites', label: 'My Favorite Compound Sets' },
];

const SelectDataModal = (props) => {
  const { isModalOpen, setIsModalOpen } = props;
  const [selectedMenu, setSelectedMenu] = useState(menuActions[0]);

  return (
    <StationaryModal
      title={'Create Plot'}
      opacity={0.95}
      modalOpen={isModalOpen}
      onCloseClick={() => setIsModalOpen(false)}
      width={700}
    >
      <InnerPanel>
        <ModalTitle>
          <ModalLabel>
            <Title>Create Plot</Title>
            <SecondTitle>: Select Data</SecondTitle>
          </ModalLabel>
          <TitleIcon>
            <Close onClick={() => setIsModalOpen(false)} />
          </TitleIcon>
        </ModalTitle>
        <ModalBody>
          <Subtitle>
            Select either from your uploaded datasets or saved favorite compound
            sets to generate a plot
          </Subtitle>
          <FlexBanner>
            {menuActions.map((action) => (
              <HeaderMenu
                key={`create__plat__${action.key}`}
                label={action.label}
                isActive={action.key === selectedMenu.key}
                onClick={() => setSelectedMenu(action)}
              />
            ))}
          </FlexBanner>
          <DatasetList type={selectedMenu.key} width={680} height={260} />
        </ModalBody>
      </InnerPanel>
    </StationaryModal>
  );
};

export default SelectDataModal;

const InnerPanel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: ${(p) => p.theme.palette.backgroundTertiary};
  border-radius: 15px;
  border: 2px solid #636363;
  margin: 0;
  height: 100%;
`;

const ModalTitle = styled.div`
  align-items: center;
  position: relative;
  padding: 5px;
  border-bottom: 1px solid #636363;
  padding: 8px;
`;

const ModalLabel = styled.div`
  display: flex;
  flex-direction: row;
  width: 400px;
  align-items: center;
  position: relative;
  padding: 5px;
`;

const Title = styled.div`
  display: flex;
  position: relative;
  font-size: 20px;
  max-width: 300px;
`;

const SecondTitle = styled.div`
  display: flex;
  position: relative;
  font-size: 18px;
  max-width: 300px;
  margin-left: 10px;
  color: ${(p) => p.theme.palette.accentSecondary};
`;

const TitleIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  filter: contrast(1) brightness(0.7);
  :hover {
    color: ${(props) => props.theme.palette.accentPrimary};
    filter: contrast(1) brightness(1);
  }
`;

const ModalBody = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: column;
  min-height: 300px;
  padding: 8px;
`;

const Subtitle = styled.div`
  font-style: italic;
  color: ${(p) => p.theme.palette.accentSecondary};
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 16px;
`;
