import spinner_img from './TransparentSpinner.gif';

const InProcessSpinner = (props) => {
  const { width=50 } = props;
  return (
    <>
      <img
        src={spinner_img}
        style={{ width: `${width}px`, margin: 'auto', display: 'block' }}
        alt='In Process ...'
      />
    </>
  );
};

export default InProcessSpinner;
