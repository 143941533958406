import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import './SingleInputDateRangePicker.css';
export default function SingleInputDateRangePicker ({ onDateSelect, selectedDateRange }: { onDateSelect: (date: any) => void, selectedDateRange: any }) { 
   const handleDateRangeChange = (dateRange :any) => {
    onDateSelect(dateRange);
  };

  return (
    <div className="containerDaterangePicker">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          value={selectedDateRange}
          onChange={handleDateRangeChange}
          slots={{ field: SingleInputDateRangeField }}
          className="containerDateRange"
        />
      </LocalizationProvider>
    </div>
  );
}
