import _ from "lodash";
import {VERTEX_OUTER_RING_SIZE, SELECTION_MAX_DISTANCE, nanColor,
  selectedColor, selectedVertexColor, hoveredVertexColor}
  from "@components/universe/config";
import {getDistance} from "@components/universe/utils/topo";
import {getNeighborVertexIds} from "@components/universe/utils/tools";
import {convertArrayToObject} from "@components/universe/Vector/VectorPane";
import {labelColors} from "@components/universe/universe.config";

/**
 *
 * @param delaunay
 * @param x
 * @param y
 * @param k
 * @param vertices
 */
export function getClosestVertexByPosition(delaunay, x, y, k, vertices) {
  if (isNaN(x) || isNaN(y) ) return;
  if (delaunay && vertices && vertices.length) {
    const index = delaunay.find(x, y);
    if (isNaN(index)) return;
    const { id, x: targetX, y: targetY } = vertices.find((v) => v.index === index);
    const distance = getDistance(x, y, targetX, targetY);
    // console.log("getClosestVertexByPosition", distance, SELECTION_MAX_DISTANCE, k)
    if (distance < SELECTION_MAX_DISTANCE) {
      return vertices.find((v) => v.id === id);
    }
  }
}

/**
 *
 * @param id
 * @param vertices
 * @param edges
 */
export function getNeighborVertices(id, vertices, edges) {
  const newNeighborIds = getNeighborVertexIds(id, edges);
  return vertices.filter((v) => newNeighborIds.includes(v.id));
}

export function getVerticesByIds(ids:string[], vertices) {
  if (vertices === null || vertices === undefined) return [];
  return vertices.filter((v) => ids.includes(v.id));
}

let debug = false;

function getAlpha(id, highlightedVertices, dimNonSelected) {
  if (highlightedVertices.includes(id)) return 1.0;
  if (dimNonSelected) return 0.50;
  return 0.75
}
/**
 *
 * @param ctx
 * @param vertices
 * @param hoveredVertex
 * @param selectedVertex
 * @param hoveredNeighborVertices
 * @param selectedNeighborVertices
 * @param nodeColorBy
 * @param radiusFactor
 * @param aesSettings
 */
export const drawVertices = (
    ctx,
    vertices,
    hoveredVertex,
    selectedVertex,
    hoveredNeighborVertices,
    selectedNeighborVertices,
    nodeColorBy,
    radiusFactor,
    aesSettings) => {
  debug &&  console.log('canvas2d | aesSettings:', aesSettings, 'drawVertices:', vertices);
  const highlightVertices = []
  const dimNonSelected = !!selectedVertex;
  if (hoveredVertex) highlightVertices.push(hoveredVertex.id);
  if (selectedVertex) highlightVertices.push(selectedVertex.id);
  if (hoveredNeighborVertices) {
    hoveredNeighborVertices.map((v) => { highlightVertices.push(v.id)});
  }
  if (selectedNeighborVertices) {
    selectedNeighborVertices.map((v) => { highlightVertices.push(v.id)});
  }
  vertices.forEach((v) => {
    ctx.globalAlpha = getAlpha(v.id, highlightVertices, dimNonSelected);
    ctx.beginPath();
    ctx.fillStyle = _.get(aesSettings[v.id].fill, nodeColorBy, nanColor);
    ctx.arc(v.x, v.y, aesSettings[v.id].radius * radiusFactor, 0, 2 * Math.PI, false);
    ctx.fill();
  });
};

/**
 * Draw the highlights that are behind the vectors
 * @param ctx
 * @param vertices
 * @param selectedVertex
 * @param hoveredVertex
 * @param hoveredNeighborVertices
 * @param selectedNeighborVertices
 * @param radiusFactor
 * @param selectedDatasetId
 * @param aesSettings
 * @param palette
 * @param selectedCompounds
 */
export const drawShadowVertices = (
    ctx,
    vertices,
    pinnedVertices,
    hoveredVertex,
    selectedVertex,
    hoveredNeighborVertices,
    selectedNeighborVertices,
    radiusFactor,
    selectedDatasetId,
    aesSettings,
    palette,
    selectedCompounds
) => {
  debug && console.log("canvas2d | drawShadowVertices | vertices:", vertices);

  ctx.fillStyle = palette.accentPrimary;

  ctx.globalAlpha = 0.4;
  ctx.beginPath();

  // selected compounds highlight
  if (selectedCompounds.data.length) {
    const selectedVertices = getVerticesByIds(selectedCompounds.data, vertices);
    ctx.globalAlpha = 0.6;
    ctx.fillStyle = selectedColor;
    ctx.beginPath();
    selectedVertices.forEach((v) => {
      ctx.moveTo(v.x, v.y);
      ctx.arc(v.x, v.y,
        aesSettings[v.id].radius * radiusFactor * VERTEX_OUTER_RING_SIZE,
        0, 2 * Math.PI, false);
    });
    ctx.fill();
  }

  if (pinnedVertices.length) {
    const vertexObject = convertArrayToObject(getVerticesByIds(pinnedVertices, vertices), 'id');
    const pinShadows = pinnedVertices.map((id, i)=>(
      {v: vertexObject[id], color: labelColors['pinned'][i]}
    ));
    ctx.globalAlpha = 0.8;
    pinShadows.forEach((p) => {
      ctx.fillStyle = p.color;
      ctx.beginPath();
      ctx.moveTo(p.v.x, p.v.y);
      ctx.arc(p.v.x, p.v.y,
          aesSettings[p.v.id].radius * radiusFactor * VERTEX_OUTER_RING_SIZE,
          0, 2 * Math.PI, false);
      ctx.fill();
    });
  }



  if (selectedDatasetId !== '') {
    ctx.globalAlpha = 0.6;
    ctx.fillStyle = palette.standout;
    ctx.beginPath();
    vertices
        .filter((f) => f.datasetId === selectedDatasetId)
        .forEach((v) => {
          ctx.moveTo(v.x, v.y);
          ctx.arc(v.x, v.y, aesSettings[v.id].radius * radiusFactor * VERTEX_OUTER_RING_SIZE, 0, 2 * Math.PI, false);
        });
    ctx.fill();
    ctx.fillStyle = palette.backgroundQuaternary;
  }

  // draw hovered vertex
  if (hoveredVertex) {
    const { x, y, id } = hoveredVertex;
    ctx.globalAlpha = 1.0;
    ctx.fillStyle = hoveredVertexColor;
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.arc(x, y, aesSettings[id].radius * radiusFactor * VERTEX_OUTER_RING_SIZE, 0, 2 * Math.PI, false);
    ctx.fill();
  }

  // draw neighbors of the hovered vertex
  if (hoveredNeighborVertices) {
    ctx.globalAlpha = 0.5;
    ctx.fillStyle = hoveredVertexColor;
    ctx.beginPath();
    hoveredNeighborVertices.forEach((v) => {
      ctx.moveTo(v.x, v.y);
      ctx.arc(v.x, v.y, aesSettings[v.id].radius * radiusFactor * VERTEX_OUTER_RING_SIZE, 0, 2 * Math.PI, false);
    });
    ctx.fill();
  }

  // draw selected vertex
  if (selectedVertex) {
    const { x, y, id, libraryId } = selectedVertex;
    if (libraryId === selectedDatasetId) {
      // ctx.fillStyle = palette.accentPrimary;
      ctx.fillStyle = selectedVertexColor;
      ctx.globalAlpha = 1.0;
    } else {
      ctx.fillStyle = palette.accentPrimary;
      ctx.globalAlpha = 1.0;
    }
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.arc(x, y, aesSettings[id].radius * radiusFactor * VERTEX_OUTER_RING_SIZE, 0, 2 * Math.PI, false);
    ctx.fill();
    ctx.fillStyle = palette.backgroundQuaternary;
  }

  // draw neighbors of the selected vertex
  if (selectedNeighborVertices) {
    ctx.globalAlpha = 0.5;
    ctx.fillStyle = palette.accentPrimary;
    ctx.beginPath();
    selectedNeighborVertices.forEach((v) => {
      ctx.moveTo(v.x, v.y);
      ctx.arc(v.x, v.y, aesSettings[v.id].radius * radiusFactor * VERTEX_OUTER_RING_SIZE, 0, 2 * Math.PI, false);
    });
    ctx.fill();
  }

  ctx.globalAlpha = 1.0;
};

