import React from 'react';
import { arc as d3arc } from 'd3';
import { nanColor } from '../config';

const arc = d3arc().cornerRadius(0.2);

const CoronaSegment = ({ color, segment }) => {
  // console.log("Corona Segment", segment, color);
  return (
    <path
      className={`vertex-corona-ring-${segment.fieldType}`}
      d={arc(segment)}
      fill={color || nanColor}
    />
  );
};

export default CoronaSegment;
