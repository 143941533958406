export const deepCopy = (arr) => arr.map((item) => Object.assign({}, item));

export const getNeighborVertexIds = (id, edges) => {
  const allConnectedVertexIds = edges
    .filter((e) => e.source.id === id || e.target.id === id)
    .map((e) => [e.target.id, e.source.id])
    .flat()
    .filter((v) => v !== id);

  const uniqueVertexIds = [...new Set(allConnectedVertexIds)];

  return uniqueVertexIds.filter((v) => v.id !== id);
};

export const getVertexEdges = (id, edges) => {
  return edges
    .filter((e) => e.source.id === id || e.target.id === id)
    .map((e) => [e.id])
    .flat()
};

export const normalize = (arr, id) => {
  let tmp = {};
  arr.forEach((d) => {
    tmp[d[id]] = d;
  });
  return tmp;
};
