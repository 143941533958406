import styled from 'styled-components/macro';
import TextField from '@mui/material/TextField';

interface IInputField {
  value: string | number;
  onBlur?: any;
  onFocus?: any;
  onChange: any;
  label?: string;
  placeholder?: string;
  marginTop?: number;
  type?: any;
  error?: boolean;
  autoComplete?: string;
  name?: string;
  onKeyDown?: any;
  id?: string;
  size?: 'medium' | 'small';
  className?: string;
  variant?: any;
  multiline?: boolean;
  rows?: number;
  InputProps?: any;
  select?: boolean;
  children?: any;
  margin?: any;
  fullWidth?: any;
  disabled?: boolean;
}

const InputField = (props: IInputField) => {
  const { placeholder, autoComplete, className } = props;

  return (
    <TextFieldContainer className={className}>
      <StyledTextField
        {...props}
        placeholder={placeholder || 'Enter value ...'}
        autoComplete={autoComplete || 'chrome-off'}
      >
        {props.children}
      </StyledTextField>
    </TextFieldContainer>
  );
};

export default InputField;

const TextFieldContainer = styled.div<{ marginTop?: number }>`
  width: 100%;
  display: flex;
`;

const StyledTextField = styled(TextField)`
  width: 100%;

  & .MuiInputBase-root {
    // match the border radius around the input field
    border-radius: 10px;
    font-size: 14px;
  }

  & .MuiOutlinedInput-input {
    background: transparent;
  }

  & .MuiFormLabel-root {
    // set a smaller label (no input) to adjust to reduced padding
    font-size: 14px;
  }

  & .MuiOutlinedInput-input::placeholder {
    font-size: 12px;
    font-style: italic;
  }
  // Handle the autofill color
  & :-webkit-autofill,
  & :-webkit-autofill:hover,
  & :-webkit-autofill:focus,
  & :-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
  }
`;
