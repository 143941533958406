import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components/macro";
import useInvitations, {InvitationT} from "@utils/api/useInvitations";
import ShowInvitations from "@subApps/account/userLoginAlerts/components/ShowInvitations";
import DataLoading from "@as_core/elements/DataLoading";
import { UserContext } from "@stores/UserContext";

function isOpen(i:any) {
  const actions = i.actions;
  if (!i?.actions || actions.length === 0) return true;
  const lastAction = i.actions[i.actions.length -1].action;
  // console.log('lastAction', lastAction);
  return !['decline', 'accept', 'cancel'].includes(lastAction);
}

const debug = false;
const UserInvitations = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invitations, setInvitations] = useState<any[]>([]);
  const [invitationsUpdated, setInvitationsUpdated] = useState<boolean>(false);
  debug && console.log('InvitationCurrent');
  const { user} = useContext(UserContext);
  const { getByInvitee } = useInvitations();

  useEffect(() => {
    setIsLoading(true);
    getByInvitee(user.authEmail).then((res) => {
      if (Object.hasOwn(res, 'invitee') && res['invitee'].length) {
        debug && console.log('UserInvitations | invitations', res['invitee']);
        const activeInvitations = res['invitee'].filter((i:InvitationT) => isOpen(i));
        debug && console.log('UserInvitations | activeInvitations', activeInvitations);
        setInvitations(activeInvitations);
      }
      setInvitationsUpdated(false);
      setIsLoading(false);
    });
  }, [invitationsUpdated]);

  debug && console.log('UserInvitations | invitations', invitations);
  return(
    <UserInvitationUpdatesContents key={'user-invitations'}>
      { isLoading ?
        <DataLoading />
      : invitations.length ?
          <ShowInvitations
            invitations={invitations}
            setInvitationsUpdated={setInvitationsUpdated}
          />
      : null
      }
    </UserInvitationUpdatesContents>
  )
}

export default UserInvitations;

const UserInvitationUpdatesContents = styled.div`
  display: flex;
  width: calc(100% - 20px);
  margin-left: 10px;
  padding: 0;
  flex-direction: column;
`;