import React from 'react';
import _ from 'lodash';
import styled from 'styled-components/macro';
import {FlexItem} from "@components/layout/FlexStyles";

import { properties } from '../../../inputs/properties';

import PropertyDensity from './PropertyDensity';
import {labelColors} from "@components/universe/universe.config";

/**
 * Function to show the property panels as well as the neighbors panels
 * @param vertex
 * @param hoveredVertex
 * @param pinnedVertices
 * @param distributions
 * @param height
 * @constructor
 */
const PropertiesPanel = ({ vertex, hoveredVertex, pinnedVertices, distributions, height=300 }) => {
  const propertiesHeight = Object.keys(distributions).length * 85;
  // console.log('PropertiesPanel | vertex', vertex, 'hoveredVertex', hoveredVertex, 'pinnedVertices', pinnedVertices);

  const getSecondaryValues = (property: string) => {
    const secondaryValues = [];
    if (hoveredVertex
      && hoveredVertex.id !== vertex.id
      && !pinnedVertices.find((p) => p.v.id === hoveredVertex.id)) {
      // console.log('showing hoveredVertexLine');
      const value = _.get(hoveredVertex?.chem_data, property, null)
      if (value !== null) {
        secondaryValues.push({value: value, color: labelColors['hovered']})
      }
    }
    if (pinnedVertices.length) {
      pinnedVertices.forEach((p, i) => {
        // console.log(p);
        const value = _.get(p.v?.chem_data, property, null)
        if (value !== null) {
          secondaryValues.push({value: value, color: p.color})
        }
      })
    }
    // console.log(secondaryValues);
    return secondaryValues;
  }


  return (
    <Container height={height}>
      <PropertiesContainer height={propertiesHeight}>
      {Object.keys(distributions).map((property) => {
        if (_.get(vertex?.chem_data, property, null) === null) {
          return null;
        }
        return (
          <FlexItem>
            <PropertyDensity
              key={property}
              value={_.get(vertex?.chem_data, property, 0)}
              secondaryValues={getSecondaryValues(property)}
              valueFormat={properties[property].format}
              density={distributions[property]}
              label={properties[property].name}
              width={230}
            />
          </FlexItem>
        );
      })}
      </PropertiesContainer>
    </Container>
  );
};

export default PropertiesPanel;

const Container: any = styled.div<{height: number}>`
  display: flex;
  width: 242px;
  height: ${(p) => p.height}px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const PropertiesContainer: any = styled.div<{height: number}>`
  display: flex;
  flex-direction: column;
  height: ${(p) => p.height}px;
`;