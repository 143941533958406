import { ResponsiveBar } from '@nivo/bar';
import { Margin, Theme } from '@nivo/core';
import { getDatavizTheme } from '@plotting/single-plot-view/plot-panel/plot.themes';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
  DEFAULT_TITLE_SIZE,
  DEFAULT_X_AXIS_STYLE,
  DEFAULT_Y_AXIS_STYLE,
} from '@dataviz/constants';
import { binDataForHistogram } from './binDataForHistogram';

const DEFAULT_MARGIN = {
  top: 60,
  right: 60,
  bottom: 90,
  left: 80,
};

type Axis = {
  name?: string;
};

interface HistogramProps {
  data: number[];
  xAxis?: Axis;
  binNumber: number;
  margin?: Margin;
  title?: string;
  titleSize?: number;
  datavizTheme?: Theme;
}

const Histogram = ({
  data,
  xAxis,
  binNumber,
  margin,
  title,
  titleSize,
  datavizTheme,
}: HistogramProps) => {
  const { palette } = useContext(ThemeContext);
  const finalDatavizTheme = datavizTheme ?? getDatavizTheme({}, palette);

  const plotMargin = margin || DEFAULT_MARGIN;

  const plotTitleStyle = {
    fontFamily: finalDatavizTheme.fontFamily,
    fontSize: titleSize || DEFAULT_TITLE_SIZE,
    fill: finalDatavizTheme.textColor,
    textAnchor: 'middle',
  } as const;

  const PlotTitle = ({ innerWidth }) => {
    return (
      <text x={innerWidth / 2} y={-plotMargin.top / 2} style={plotTitleStyle}>
        {title}
      </text>
    );
  };

  // Bin the data
  const binnedData = binDataForHistogram(data, binNumber);

  return (
    <ResponsiveBar
      data={binnedData}
      keys={['count']}
      indexBy='bin'
      margin={plotMargin}
      isInteractive={false}
      axisBottom={{
        ...DEFAULT_X_AXIS_STYLE,
        legend: xAxis.name,
        format: undefined,
      }}
      axisLeft={{ ...DEFAULT_Y_AXIS_STYLE, legend: 'Count' }}
      theme={finalDatavizTheme}
      layers={[PlotTitle, 'grid', 'axes', 'bars']}
      indexScale={{ type: 'band', round: true }}
      tooltip={undefined}
      enableGridX={true}
      enableGridY={true}
      animate={true}
      colors={['#DB0B5B']}
    />
  );
};

export default Histogram;
