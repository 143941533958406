import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { StarBorder, Star } from '@mui/icons-material';
import { HomeTileCardT } from '@as_core/tiles/HomeTileCard.type';
import {Tooltip} from "@mui/material";

interface propsT {
  subscriptionLevel: string;
  card: HomeTileCardT;
  onClickPinned: (e: any, id: string) => void;
}

const debug = false;

const HomeTileCard = (props: propsT) => {
  const { subscriptionLevel, card, onClickPinned } = props;
  debug && console.log('HomeTileCard', props);

  const getCardContent = (active, card) => {
    const adaptiveWidth = 190 - (64 - card.description.length)
    return (
      <CardContent active={active}>
        <PinnedContainer>
          {subscriptionLevel === 'basic' ? null :
            card.isPinned ? (
            <Star onClick={(e) => onClickPinned(e, card.id)} />
          ) : (
            <StarBorder onClick={(e) => onClickPinned(e, card.id)} />
          )}
        </PinnedContainer>
        <Header>{card.title}</Header>
        <Icon>{card.icon}</Icon>
        <Description width={adaptiveWidth}>{card.description}</Description>
        {card.newTab ? <Warning active={active}>External Link</Warning> : null}
      </CardContent>
    );
  };

  const active = !card?.enable ? true : card.enable.includes(subscriptionLevel);
  // console.log('card', card.title, subscriptionLevel, active);
  // return an inactive button
  if (!active) {
    return (
      <StyledCard active={active}>
        <Tooltip
          title={'Requires a higher level subscription'}
          arrow
          placement={'top-start'}
        >
          {getCardContent(active, card)}
        </Tooltip>
      </StyledCard>
    );
  }
  // have as coming soon
  if (card.link === '') {
    return (
      <StyledCard active={false}>
        <Tooltip
          title={'Inactive - Coming Soon'}
          arrow
          placement={'top-start'}
        >
          {getCardContent(false, card)}
        </Tooltip>
      </StyledCard>
    );
  }

  // handle whether internal link or external
  if (card.newTab) {
    return (
      <StyledCard active={active}>
        <a href={card.link} target='_blank' rel='noopener noreferrer'>
          {getCardContent(active, card)}
        </a>
      </StyledCard>
    );
  } else {
    return (
      <StyledCard active={active}>
        <Link to={card.link}>{getCardContent(active, card)}</Link>
      </StyledCard>
    );
  }
};

export default HomeTileCard;

const PinnedContainer = styled.div`
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 999;
`;

export const StyledCard = styled.div<{ active: boolean }>`
  display: flex;
  height: 150px;
  width: 210px;
  margin: 5px;
  padding: 3px;
  border-radius: 20px;
  opacity: ${(p) => (p.active ? 1.0 : 0.5)};
  background-color: ${(p) =>
    p.active
      ? p.theme.palette.backgroundTertiary
      : p.theme.palette.backgroundQuaternary};
  :hover {
    background-color: ${(p) => p.theme.palette.backgroundQuaternary};
  }
`;

export const CardContent = styled.div<{ active: boolean }>`
  position: relative;
  display: flex;
  height: 140px;
  width: 200px;
  padding: 5px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  border-radius: 15px;
  :hover {
    border: 1px solid
      ${(p) =>
        p.active
          ? p.theme.palette.accentPrimary
          : p.theme.palette.accentSecondary};
  }
`;

const Header = styled.div`
  display: flex;
  font-size: 14px;
  min-height: 32px;
  justify-content: center;
  text-align: center;
`;

const Icon = styled.div`
  display: flex;
  width: 100%;
  min-height: 50px;
  justify-content: center;
  align-items: center;
`;

const Description = styled.div<{width: number}>`
  padding-top: 5px;
  text-align: center;
  width: ${(p) => p.width}px;
  font-size: ${(p) => p.theme.sizes.xsmall};
  line-height: ${(p) => p.theme.sizes.small};
  color: ${(p) => p.theme.palette.textSecondary};
`;

const Warning = styled.div<{active: boolean}>`
  position: absolute;
  bottom: -7.5px;
  padding: 2px 5px;
  line-height: 12px;
  text-align: center;
  font-style: italic;
  font-size: ${(p) => p.theme.sizes.xsmall};
  color: ${(p) => p.theme.palette.accentSecondary};
  background-color: ${(p) =>
          p.active
                  ? p.theme.palette.backgroundTertiary
                  : p.theme.palette.backgroundQuaternary};
`;
