import { authClient, authStaticClient, authCoreClient } from './base';

export const Compounds = {
  all: (token, config) => authClient(token).get('/cmp/compounds/', config),
  filtered: (token, data, config) =>
    authClient(token).post('/cmp/compounds/', data, config),
  get: (token, compoundId, config) =>
    authClient(token).get(`/cmp/compounds/${compoundId}`, config),
  neighbors: (token, compoundId, config) =>
    authClient(token).get(`/cmp/compounds/${compoundId}/neighbors`, config),
  list: (token, data, config) =>
    authClient(token).post('/cmp/compounds/list', data, config),
  update: (token, data, config = null) =>
    authClient(token).put('/cmp/compounds/save/compound', data, config),
  upload: (token, data, config = null) =>
    authClient(token).put('/cmp/compounds/upload/compound', data, config),
  delete: (token, compoundId, config = null) =>
    authClient(token).get(`/cmp/compounds/delete/${compoundId}`, config),
  calc: (token, config) =>
    authClient(token).post('/cmp/compounds/calc/physchem', config),
  saveFile: (token, data) =>
    authClient(token).post('/cmp/compounds/save/file', data),
  updateSVG: (token, data, config) =>
    authClient(token).put('/cmp/compounds/save/svg', data, config),
  getCompoundCategory: (token, config) =>
    authClient(token).get(`/cmp/compounds/aggregate/compoundcategory`, config),
  getCompoundFiltered: (token, data) =>
    authClient(token).post(`/cmp/compounds/filter-data`, data),
  getCompoundFilteredById: (token, tableId) =>
    authClient(token).post(`/cmp/compounds/filter-data?table_id=${tableId}`),
  getCompoundsAsync: (token, data) =>
    authClient(token).post('/cmp/compounds/get_all_compounds_async', data),
  searchLibraries: (token, config) =>
      authClient(token).post(`/cmp/compounds/search`, config),
};

export const Assays = {
  get: (token, config) => authClient(token).get('/cmp/assays/', config),
  getFields: (token, config) => authClient(token).get('/cmp/assays/fields', config),
};

export const Data = {
  all: (token, config) => authClient(token).get('/data/', config),
  get: (token, dataId) => authClient(token).get(`/data/id/${dataId}`),
  create: (token, data) => authClient(token).post('/data/create', data),
  delete: (token, uuid) => authClient(token).get(`/data/delete/${uuid}`),
};

export const DoseResponse = {
  compute: (token, config) => authClient(token).post('/cmp/upload/dose_response', config)
};

export const Universe = {
  all: (token, config) => authClient(token).get('/cmp/universe/', config),
  get: (token, uuid) => authClient(token).get(`/cmp/universe/${uuid}`),
  create: (token, type, data) => authClient(token).post(`/cmp/universe/create/${type}`, data),
  delete: (token, uuid) => authClient(token).delete(`/cmp/universe/${uuid}`),
};

 export const PlateformAnalysis = {
  createFilters: (token, data) => authClient(token).post('/data-analytics/filters', data),
  getAllFilters: (token, config) => authClient(token).get('/data-analytics/filters', config),
  updateFilters: (token, filter_id, data) => authClient(token).put(`/data-analytics/filters/${filter_id}/`, data),
  deleteFilters: (token, filter_id) => authClient(token).delete(`/data-analytics/filters/${filter_id}`),
  createTable: (token, data) => authClient(token).post('/data-analytics/tables/', data),
  calculateAggregation: (token, data) => authClient(token).post('/data-analytics/aggregate', data),
  deleteSavedWork: (token, filterId: string) => authClient(token).delete(`/saved-work/saved-work/${filterId}`),
  saveComponents: (token, data) => authClient(token).post(`/data-analytics/save-components`, data),
  fetchSavedWork: (token) => authClient(token).get(`/saved-work/saved-work`),
  applySavedWork: (token, savedWorkId) => authClient(token).post(`/saved-work/apply-saved-work?saved_work_id=${savedWorkId}`),
  updateSavedWork: (token, savedWorkId, data) => authClient(token).put(`/saved-work/saved-work/${savedWorkId}`, data),
  removeFilterFromTable: (token, tableUUID: string, filterId: string) =>
  authClient(token).post(`/data-analytics/table-association/${tableUUID}/filters/${filterId}`),
  deleteAggregate: (token, aggregateId: string) => authClient(token).delete(`data-analytics/aggregate/${aggregateId}`),
  deleteTable: (token, tableId: string) => authClient(token).delete(`data-analytics/tables/${tableId}`),
  uploadFile: (token, data) => authClient(token).post(`/cmp/compounds/uploadAbbvie`, data),
  finilizeUploadFile: (token, data) => authClient(token).post(`/cmp/compounds/finalize`, data),
  
 }

export const UserRegistration = {
  get: (token, config) => authCoreClient(token).get('/user/', config),
  create: (token, data) => authCoreClient(token).post('/user/create', data),
  update: (token, data) => authCoreClient(token).post('/user/update', data),
  delete: (token, data) => authCoreClient(token).post('/user/delete', data),
};

export const BugReports = {
  get: (token, config) => authCoreClient(token).get('/bugs/', config),
  submit: (token, data) => authCoreClient(token).post('/bugs/submit', data),
  update: (token, data) => authCoreClient(token).put('/bugs/update', data),
  delete: (token, uuid) => authCoreClient(token).post('/bugs/delete', uuid),
};

export const ApiInfo = {
  get: (token) => authClient(token).get('/info/'),
};

export const Plots = {
  all: (token, config) => authClient(token).get('/cmp/plots/', config),
  get: (token, plotId) => authClient(token).get(`/cmp/plots/id/${plotId}`),
  create: (token, plot) => authClient(token).post('/cmp/plots/create', plot),
  update: (token, plot) => authClient(token).put('/cmp/plots/create', plot),
  delete: (token, plotId) => authClient(token).get(`/cmp/plots/delete/${plotId}`),
};

export const Notes = {
  get: (noteId, token) => authClient(token).get(`/compounds/${noteId}/note`),
  update: (data, token) => authClient(token).put(`/compounds/save/note`, data),
};

export const Static = {
  getImage: (url, token, source) =>
    authStaticClient(token).get(url, {
      cancelToken: source.token,
      responseType: 'arraybuffer',
    }),
};

export const CompoundSearch = {
  getAll: (token, config) =>
    authClient(token).get('/cmp/search', config),
};

export const KinomeUniverse = {
  edges: (token, data, config) =>
    authClient(token).post('/kinomeuniverse/edges', data, config),
  vertices: (token, data, config) =>
    authClient(token).post(`/kinomeuniverse/vertices`, data, config),
};

export const Aggregation = {
  bucketAutoCfieldsNumeric: (token, cfieldId, config) =>
    authClient(token).get(
      `/cmp/aggregates/bucketauto/cfields/numeric/${cfieldId}`,
      config
    ),
  bucketCfieldsNumeric: (token, field_id, config) =>
    authClient(token).get(
      `/cmp/aggregates/bucket/cfields/numeric/${field_id}`,
      config
    ),
  bucketCfieldsCategory: (token, cfieldId, config) =>
    authClient(token).get(
      `/cmp/aggregates/bucket/cfields/category/${cfieldId}`,
      config
    ),
  bucketCfieldsFilterCategory: (
    token,
    cfieldId,
    filterId,
    filterValue,
    config
  ) =>
    authClient(token).get(
      `/cmp/aggregates/bucket/cfields/category/${cfieldId}/filter/${filterId}/${filterValue}`,
      config
    ),
  bucketCfieldsExistsCategory: (token, cfieldId, existsId, config) =>
    authClient(token).get(
      `/cmp/aggregates/bucket/cfields/category/${cfieldId}/exists/${existsId}`,
      config
    ),
  bucketCfieldsCategoryDistribution: (token, config) =>
    authClient(token).get(
      `/cmp/aggregates/bucket/cfields/category/distribution`,
      config
    ),
  bucketCfieldsCategoryDistributionExists: (token, config) =>
    authClient(token).get(
      `/cmp/aggregates/bucket/cfields/category/distribution/exists`,
      config
    ),
  bucketCfieldsCategoryDistributionFiltered: (token, config) =>
    authClient(token).get(
      `/cmp/aggregates/bucket/cfields/category/distribution`,
      config
    ),
  bucketAnnotationsCategory: (token, aFieldId, config) =>
    authClient(token).get(
      `/cmp/aggregates/bucket/annotations/category?ann_field_id=${aFieldId}`,
      config
    ),
  bucketAnnotationsClassCategory: (
    token,
    ann_field_id,
    ann_name,
    class_field_id,
    config
  ) =>
    authClient(token).get(
      `/cmp/aggregates/bucket/annotations/category/class?ann_field_id=${ann_field_id}&ann_name=${ann_name}&class_field_id=${class_field_id}`,
      config
    ),
  bucketAnnotationsDataCategory: (
    token,
    ann_field_id,
    data_exists_field_id,
    config
  ) =>
    authClient(token).get(
      `/cmp/aggregates/bucket/annotations/category/data?ann_field_id=${ann_field_id}&data_exists_field_id=${data_exists_field_id}`,
      config
    ),
  bucketAnnotationsCategoryDistribution: (token, config) =>
    authClient(token).get(
      `/cmp/aggregates/bucket/annotations/category/distribution`,
      config
    ),
  bucketAnnotationsClassCategoryDistribution: (token, config) =>
    authClient(token).get(
      `/cmp/aggregates/bucket/annotations/category/class/distribution`,
      config
    ),
  bucketAnnotationsDataCategoryDistribution: (token, config) =>
    authClient(token).get(
      `/cmp/aggregates/bucket/annotations/category/data/distribution`,
      config
    ),
};
