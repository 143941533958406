import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { FormControl, MenuItem } from '@mui/material';
import { BugsSubmitI } from './bugs.types';
import { BugReports } from '@utils/api';
import ButtonText from './components/ButtonText';
import AlertDialog from './components/AlertDialog';
import { menuItems } from '@app_config/sideMenu';
import { APP_CONFIG } from '@app_config/app';
import {
  FlexRow,
  FlexRowSpaced,
  FlexItem,
  FlexSpacer,
} from '@components/layout/FlexStyles';
import { TextTitle, TextSmall } from '@components/elements/TextStyles';
import useCognito from '@as_core/account/useCognito';
import InputField from '@as_core/controls/inputs/InputField';
import SelectField from '@as_core/controls/inputs/SelectField';

const getCategories = () => {
  return [...menuItems.map((m) => m?.name), 'Other', 'Suggestion'];
};

const BugsSubmit = ({
  setNewBugReported,
  onSubmit,
  inEditBug,
  setInEditBug,
}: BugsSubmitI) => {
  const debug = false;
  const { getToken } = useCognito();
  const [bugCategories] = useState<string[]>(getCategories());
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [values, setValues] = useState({
    title: '',
    description: '',
    category: '',
  });
  const [errors, setErrors] = useState({
    title: null,
    description: null,
    category: null,
  });

  const handleAlert = () => {
    setNewBugReported(true);
    setAlertOpen(false);
    onSubmit();
  };

  const submitBugReport = async () => {
    debug && console.log('SUBMIT: title, description', values);

    const localErrors = {
      title: null,
      description: null,
      category: null,
    };

    if (values.title === '') {
      localErrors.title = 'Title required*';
    }

    if (values.description === '') {
      localErrors.description = 'Description required*';
    }

    if (values.category === '') {
      localErrors.category = 'Category required*';
    }

    setErrors(localErrors);

    if (!Object.values(localErrors).find((error) => error !== null)) {
      const token = getToken();
      const payload = {
        system: APP_CONFIG.name,
        title: values.title,
        description: values.description,
        category: values.category,
      };

      if (inEditBug) {
        await BugReports.update(token, { ...payload, uuid: inEditBug.uuid });
        setInEditBug(null);
      } else {
        await BugReports.submit(token, payload);
        setNewBugReported(true);
      }
      setAlertMessage(
        `Bug Report Successfully ${
          inEditBug ? 'Updated' : 'Submitted'
        }: ${new Date().toLocaleString()}`
      );
      setAlertOpen(true);
      setValues({
        title: '',
        description: '',
        category: '',
      });
    }
  };
  debug && console.log('title, description', values);

  useEffect(() => {
    if (inEditBug) {
      setValues({
        title: inEditBug?.title,
        description: inEditBug?.description,
        category: inEditBug?.category,
      });
      setErrors({
        title: null,
        description: null,
        category: null,
      });
    }
  }, [inEditBug]);

  const onCancelEdit = () => {
    setValues({
      title: '',
      description: '',
      category: '',
    });
    setInEditBug(null);
  };

  const onChange = (value: any, key: string) => {
    if (errors[key]) {
      setErrors((previous) => ({ ...previous, [key]: null }));
    }
    setValues((previous) => ({ ...previous, [key]: value }));
  };

  return (
    <>
      <Content>
        <FlexRowSpaced width={'100%'}>
          <FlexItem>
            <Heading color={'accent'}>
              {inEditBug
                ? 'Update a previously submitted bug'
                : 'Submit a newly discovered bug or suggestion'}
            </Heading>
          </FlexItem>
          <FlexItem>
            {inEditBug && (
              <>
                <ButtonText
                  width={100}
                  label='Cancel'
                  onClick={onCancelEdit}
                  outlined
                />
                <FlexSpacer width={10} />
              </>
            )}
            <ButtonText
              width={100}
              label={inEditBug ? 'Update' : 'Submit'}
              onClick={submitBugReport}
            />
          </FlexItem>
        </FlexRowSpaced>
        <FlexRow>
          <FlexItem>
            <TextSmall>
              {inEditBug
                ? 'Edit either description, category or title'
                : 'Enter a short name and detailed description below.'}
            </TextSmall>
          </FlexItem>
        </FlexRow>
        <FlexRow width={'100%'}>
          <FlexItem width={'50%'}>
            <InputField
              id={'title'}
              type={'text'}
              variant={'outlined'}
              placeholder={'Enter short title'}
              label={errors.title || 'Title'}
              value={values.title}
              onChange={(e) => onChange(e.target.value, 'title')}
              error={Boolean(errors.title)}
            />
          </FlexItem>
          <FlexItem width={'50%'}>
            <FormControl fullWidth>
              <SelectField
                labelId='category'
                id='category'
                value={values.category}
                label={errors.category || 'Category'}
                variant='outlined'
                onChange={(e) => onChange(e.target.value, 'category')}
                error={Boolean(errors.category)}
              >
                {bugCategories.map((category, i) => (
                  <MenuItem key={`category_${i}`} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </SelectField>
            </FormControl>
          </FlexItem>
        </FlexRow>
        <FlexRow width={'100%'}>
          <FlexItem width={'100%'}>
            <InputField
              id='description'
              variant='outlined'
              label={errors.description || 'Description'}
              value={values.description}
              multiline
              rows={3}
              onChange={(e) => onChange(e.target.value, 'description')}
              error={Boolean(errors.description)}
            />
          </FlexItem>
        </FlexRow>
      </Content>
      <AlertDialog
        title={'Bug Report Submitted'}
        message={alertMessage}
        alertOpen={alertOpen}
        closeAlert={handleAlert}
      />
    </>
  );
};

export default BugsSubmit;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 680px;
`;

const Heading = styled(TextTitle)`
  font-size: 18px;
`;
