import React from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';

import ColorBar from '@components/elements/ColorBar';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { FieldSliceT } from '@stores/fields';

const ColorBarTable = ({ compound }) => {
  const { fields: allFields } = useSelector((state: RootState) => state.fields as FieldSliceT);
  const fields = ['cell_morphology', 'calcein', 'mitosox', 'mbbr', 'sytox', 'violet', 'pi', 'jc9'];
  return (
    <Container>
      <Table>
        <tbody>
        { fields.map((f) => (
          <Row key={'coltab_row_'+ f}>
            <LabelCell key={'lab_cell_' + f}>{allFields[f].short_name}</LabelCell>
            <Cell key={'col_cell_' + f}>
              <ColorBar key={f} value={_.get(compound, allFields[f].data_key, 0)} />
            </Cell>
          </Row>
        ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default ColorBarTable;

const Container = styled.div`
  display: flex;
  justify-items: center;
`;

const Row = styled.tr``;

const Table = styled.table`
  width: 100%;
`;

const Cell = styled.td`
  vertical-align: middle;
  font-size: ${(p) => p.theme.sizes.xsmall};
  font-weight: ${(p) => p.theme.weights.light};
`;

const LabelCell = styled(Cell)`
  width: 100px;
  text-align: right;
  padding-right: 5px;
`;
