import React from 'react';
import styled from 'styled-components/macro';
import ImportGenericTable from './ImportGenericTable';
import UploadDataStepper from '../shared/UploadDataStepper';
import UploadGeneric from './UploadGeneric';
import useFileImport from '../useFileImport';

function getSteps() {
  return ['Upload Spreadsheet', 'Review Data', 'Save'];
}

const debug = false;
const ImportGeneric = (props) => {
  const { height, width, handleReset } = props;
  const { context } = useFileImport();
  debug && console.log('ImportGeneric: {step}', context.activeStep);

  // ###########################################################################
  // STEPPER
  // ###########################################################################
  const steps = getSteps();

  if (context.activeStep < 1) {  // handling reset issue
    context.setActiveStep(1);
  }

  const handleNext = () => {
    debug && console.log('UploadData | handleNext');
    context.setActiveStep((currStep) => currStep + 1);
  };

  const handleBack = () => {
    debug && console.log('UploadData | handleBack');
    context.setActiveStep((currStep) => {
      const nextStep = currStep - 1;
      if (nextStep === 1) {
        // placeholder
      }
      if (nextStep < 1) {
        handleReset();
      }
      return nextStep;
    });
  };

  debug && console.log('ImportGeneric {activeStep}', context.activeStep);
  return (
    <Container height={height} width={width}>
      <UploadDataStepper
        handleBack={handleBack}
        handleReset={handleReset}
        handleNext={handleNext}
        uploadStep={1}
        steps={steps}
      />
      <StepContainer>
        {(() => {
          switch (context.activeStep) {
            case 1:
              return <ImportGenericTable width={width} />;
            case 2:
              return <UploadGeneric height={height} width={width} handleNext={handleNext}/>;
            case 3:
              return <UploadGeneric height={height} width={width} handleNext={handleNext}/>;
          }
        })()}
      </StepContainer>
    </Container>
  );
};

export default ImportGeneric;

const Container = styled.div<{ height?: number; width?: number }>`
  display: flex;
  flex-direction: column;
  height: ${(p) => p.height}px;
  width: ${(p) => p.width}px;
  padding: 0.5rem;
`;

const StepContainer = styled.div`
  display: flex;
  border: ${(p) => p.theme.borders.thin};
  border-radius: ${(p) => p.theme.borderRadius};
  padding: ${(p) => p.theme.sizes.small};
`;
