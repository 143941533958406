import styled from 'styled-components/macro';
import React, { useContext } from 'react';
import { StyleContext, THEME } from '@theme/AppStyles';

import { BiologyContent } from './OurBiologyContent';
import ParameterImageViewer from './ParameterImageViewer';

const debug = false;
const OurBiologyDisplay = (props) => {
  const { section, subsection } = props;
  const [style] = useContext(StyleContext);

  debug &&
    console.log(
      'OurBiologyDisplay | section: ',
      section,
      ' subsection: ',
      subsection
    );

  // handle null or undefined cases
  if (!BiologyContent.hasOwnProperty(section)) {
    return (
      <Container className={props?.className} style={{ overflow: 'auto' }}>
        <Section>
          Cannot locate content in "BiologyContent" for "{section}"
        </Section>
      </Container>
    );
  }
  const subSectionContent = BiologyContent[section];

  if (!subSectionContent.hasOwnProperty(subsection)) {
    return (
      <Container className={props?.className} style={{ overflow: 'auto' }}>
        <Section>
          Cannot locate content in "BiologyContent[{section}]" for "{subsection}
          "
        </Section>
      </Container>
    );
  }

  const displayImage = (element) => {
    debug && console.log('displayImage: ', element);

    if (!element.hasOwnProperty('src')) {
      return <>Image - missing src</>;
    }
    if (element?.width) {
      return (
        <Image
          src={element?.[`src_${style.name}`] || element.src}
          width={element.width}
        />
      );
    } else {
      return <Image src={element?.[`src_${style.name}`]} />;
    }
  };

  const displayImageNoWrap = (element) => {
    debug && console.log('displayImageNoWrap: ', element);

    if (!element.hasOwnProperty('src')) {
      return <>Image - missing src</>;
    }
    if (element?.width) {
      return (
        <ImageNoWrap
          src={element?.[`src_${style.name}`] || element.src}
          width={element.width}
        />
      );
    } else {
      return <ImageNoWrap src={element?.[`src_${style.name}`]} />;
    }
  };

  const displayMeasures = (element) => {
    console.log('displayMeasures: ', element);
    if (element?.height) {
      return (
        <ParameterImageViewer
          config={element?.config}
          height={element.height}
        />
      );
    } else {
      return <ParameterImageViewer config={element?.config} />;
    }
  };

  const displayLink = (element) => {
    debug && console.log('displayLink: ', element);
    if (!element.hasOwnProperty('href')) {
      return <>Link - missing href</>;
    }
    return (
      <Link href={element?.href} target='_blank'>
        {element?.href}
      </Link>
    );
  };

  const displayContent = (element, index) => {
    debug && console.log('displayContent | element: ', element);
    if (!element.hasOwnProperty('content_type')) {
      console.log(
        'OurBiologyTab: error -- element does not have content_type defined'
      );
      return null;
    }
    switch (element['content_type']) {
      case 'title':
        return <Title key={index}>{element['text']}</Title>;
      case 'subtitle':
        return <SubTitle key={index}>{element['text']}</SubTitle>;
      case 'paragraph':
        return <Paragraph key={index}>{element['text']}</Paragraph>;
      case 'image':
        return (
          <React.Fragment key={index}>{displayImage(element)}</React.Fragment>
        );
      case 'image-nowrap':
        return (
          <React.Fragment key={index}>
            {displayImageNoWrap(element)}
          </React.Fragment>
        );
      case 'measurements':
        return (
          <React.Fragment key={index}>
            {displayMeasures(element)}
          </React.Fragment>
        );
      case 'link':
        return (
          <React.Fragment key={index}>{displayLink(element)}</React.Fragment>
        );
      case 'line':
        return (
          <Paragraph key={index}>
            <Line />
          </Paragraph>
        );
      case 'break':
        return <Break key={index} />;
      default:
        console.log('Do not know type ', element['content_type']);
    }
  };

  return (
    <Container className={props?.className} style={{ overflow: 'auto' }}>
      <Section key={`section_${props.className}`}>
        {BiologyContent[section][subsection].map((element, index) => {
          return displayContent(element, index);
        })}
      </Section>
    </Container>
  );
};

export default OurBiologyDisplay;

const Container = styled.div``;

const Section = styled.div`
  font-size: 12pt;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
  color: ${(p) => p.theme.palette.titlePrimary};
`;

//Need to update with the proper color;

export const Title = styled.div`
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 10px;
  color: ${(p) => p.theme.palette.accentPrimary};
`;

const SubTitle = styled.div`
  font-weight: bold;
  font-size: 12pt;
  color: ${(p) => p.theme.palette.accentPrimary};
`;

const Paragraph = styled.div`
  margin-bottom: 10px;
  margin-right: 5px;
`;

const Link = styled.a`
  color: ${(p) => p.theme.palette.accentPrimary};
  padding-bottom: 10px;
`;

const Image = styled.img`
  float: left;
  margin-right: 10px;
`;

const ImageNoWrap = styled.img`
  margin-right: 10px;
`;

const Break = styled.div`
  clear: both;
`;

const Line = styled.div`
  clear: both;
  padding: 5px;
  border-bottom: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
`;
