import React, { useState } from 'react';
import styled from 'styled-components/macro';
import SimpleTable from '../../SimpleTable';
import { FlexColumn, FlexRow, FlexItem } from "@components/layout/FlexStyles";
import { TextSmall, TextSmallBold, TextSpace } from "@components/elements/TextStyles";
import ColumnFieldPickerTable from './ColumnFieldPickerTable';
import useFileImport from '../../../useFileImport';

const debug = false;
const ColumnFieldPicker = (props) => {
  const {
    activeColumn,
    columnFields,
    onColumnPicked,
    height,
    width,
    selectedColumns = [],
  } = props;
  const [previewColumnIndex, setPreviewColumnIndex] = useState<number>(-1);
  const { context } = useFileImport();
  const { getPreview } = useFileImport();
  debug && console.log("context", context);
  const availableColumns = context.columns.map((c) => c.colName)
    .filter((c) => !selectedColumns.includes(c));
  debug && console.log("availableColumns:", availableColumns, 'previewColumnIndex:', previewColumnIndex);
  // set the column name for preview
  function handleSetPreview(v) {
    if (v === '') {
      setPreviewColumnIndex(-1);
    } else {
      setPreviewColumnIndex(context.columns.findIndex((c) => c.colName === v))
    }
  }

  const getColumnPreview = () => {
    if (previewColumnIndex === -1) {
      return [];
    } else {
      return getPreview(
        context.workbook.workbook,
        context.columns[previewColumnIndex].sheetName,
        previewColumnIndex
      )
    }
  }

  return (
    <Container height={height} width={width}>
      <FlexRow width={'100%'} h_centered>
        <FlexItem>
          <TextSmall color={'primary'}>File:</TextSmall>
          <TextSpace />
          <TextSmallBold color={'accent'}>{context.workbook.filename}</TextSmallBold>
        </FlexItem>
      </FlexRow>
      <FlexRow width={'100%'} h_centered>
        <FlexItem>
          <TextSmall color={'primary'}>Select Column to Map to </TextSmall>
          <TextSpace />
          <TextSmallBold color={'accentSecondary'}>
            {columnFields[activeColumn].label}
          </TextSmallBold>
        </FlexItem>
      </FlexRow>
      <FlexRow width={'100%'} h_centered>
        <FlexItem width={'50%'}>
          <FlexColumn>
            <FlexItem>
              <TextSpace width={15} />
              <TextSmall color={'accentSecondary'}>File Column Name</TextSmall>
            </FlexItem>
            <FlexItem width={'50%'}>
              <MyColumnFieldPickerTable
                setPreviewColumn={handleSetPreview}
                availableFields={availableColumns}
                onColumnPicked={onColumnPicked}
                selectedColumns={selectedColumns}
                height={height}
                width={width}
              />
            </FlexItem>
          </FlexColumn>
        </FlexItem>
        <FlexItem width={'50%'}>
          <FlexColumn>
            <FlexItem>
              <TextSpace width={15} />
              <TextSmall color={'accentSecondary'}>Column Sample Data</TextSmall>
            </FlexItem>
            <SampleContainer height={height}>
              {previewColumnIndex > -1 ? (
                <>
                  <ScrollContainer height={height}>
                    <MySimpleTable
                      title={context.columns[previewColumnIndex].colName}
                      rows={getColumnPreview()}
                    />
                  </ScrollContainer>
                </>) : <></>
              }
            </SampleContainer>
          </FlexColumn>
        </FlexItem>
      </FlexRow>
    </Container>
  );
};

export default ColumnFieldPicker;

const Container = styled.div<{ height?: number; width?: number }>`
  display: flex;
  flex-direction: column;
  height: calc(${(p) => p.height} - 220px);
`;

const SampleContainer = styled.div<{ height: number }>`
  display: flex;
  margin: 5px;
  flex-direction: column;
  width: 250px;
  height: calc(${(p) => p.height}px);
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  border-radius: 15px;
`;

const MyColumnFieldPickerTable = styled(ColumnFieldPickerTable) <{
  height: number;
  width: number;
}>`
  display: flex;
  width: calc(${(p) => p.width}px - 300px);
  height: calc(${(p) => p.height}px - 300px);
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  border-radius: 15px;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  & ::-webkit-scrollbar {
    display: none;
  }
`;

const MySimpleTable = styled(SimpleTable)`
  display: flex;
  width: 100%;
  border-radius: 15px;
`;

const ScrollContainer = styled.div<{ height: number }>`
  display: flex;
  height: calc(${(p) => p.height}px);
  overflow-y: scroll;
  border-radius: 15px;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-track {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
  ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
  ::-webkit-scrollbar-corner {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
`;
