import React from 'react';
import styled from 'styled-components/macro';
import { Slider } from '@mui/material';

function valuetext(value: number) {
  return `${value}`;
}

export default function RangeSlider({ data, handleUpdate }) {
  const { value, min, max, title, step, marks } = data;
  const [valueLocal, setValueLocal] = React.useState([...value]);

  const handleChange = (event: any, newValue: number | number[]) => {
    setValueLocal(newValue as number[]);
  };

  return (
    <Container>
      <Title>
        {title}
      </Title>
      <StyledSlider
        value={valueLocal}
        onChange={handleChange}
        onChangeCommitted={handleUpdate}
        valueLabelDisplay='auto'
        aria-labelledby='range-slider'
        min={min}
        max={max}
        step={step}
        marks={marks}
        getAriaValueText={valuetext}
      />
    </Container>
  );
}

const Container = styled.div`
  border-radius: ${(p) => p.theme.borderRadius};
  min-width: 300px;
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
`;

const Title = styled.div`
  font-size: calc(0.8 * ${(props) => props.theme.sizes.small});
`;

const StyledSlider = styled(Slider)`
  max-width: 450px;
  margin-left: 1.0rem;
  font-size: ${(props) => props.theme.sizes.small};
`;
