import React from 'react';
import styled from 'styled-components';

// import "ketcher-react/dist/index.css"; // imported as a static asset in index.ts

import { StandaloneStructServiceProvider } from "ketcher-standalone";
import { Editor } from "ketcher-react";

import Miew from "miew";
import {Ketcher} from "ketcher-core";
(window as any).Miew = Miew;

interface IEditorProps {
  mol?: string;
  onChange?: (mol: string, smiles: string) => void;
}

const debug = false;
export const CompoundEditor = React.memo(({ mol, onChange }: IEditorProps) => {
  const structServiceProvider = new StandaloneStructServiceProvider();

  const handleOnInit = async (ketcher : Ketcher) => {
    (window as any).ketcher = ketcher;

    ketcher.setMolecule(mol);

    ketcher.editor.subscribe('change', () => {
      Promise.all([
        ketcher.getMolfile('v3000'),
        ketcher.getSmiles(false)
      ]).then(([molfile,smiles]) => {
        debug && console.log("CompoundEditor | handleChange {smiles}", smiles);
        onChange && onChange(molfile,smiles);
      });
    });
  };

  return (
    <Panel>
      <Editor
        staticResourcesUrl={""}
        structServiceProvider={structServiceProvider}
        // @ts-ignore: ketcher type def is incorrect
        onInit={handleOnInit}
        errorHandler={console.error}
      />
    </Panel>
  );
});

const Panel = styled.div`
  background-color: #B2B3D2;
  position: absolute;
  height: 87%;
  width: 100%;
  overflow: hidden;
`;
