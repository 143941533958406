import { Universes } from './universes.api';
import { handleError } from '@utils/misc';
import { ResponseT } from '@utils/api/base';
import {AxiosRequestConfig} from "axios";

export interface Dictionary {
  [key: string]: any;
}

const universeDataPath = "/data/universe/"

const useUniverses = () => {

  const getAll = async (
    token: string
  ): Promise<ResponseT<any>> => {
    let resp;

    try {
      resp = await Universes.all(token, null);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('Universes.getAll ERRORS', resp.data.errors);
    }

    return resp.data;
  };

  const getInfo = async (
      token: string,
      uuid: string,
      params: object
  ): Promise<ResponseT<any>> => {
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await Universes.get(token, uuid, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('Universes.get ERRORS', resp.data.errors);
    }

    return resp.data;
  };

  const getNetwork = async ( token: string, uuid: string, params: object ): Promise<ResponseT<any>> => {
    const config: AxiosRequestConfig = { params };
    let resp;
    // console.log(uuid, config);

    try {
      resp = await Universes.getNetwork(token, uuid, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('Universes.getNetwork ERRORS', resp.data.errors);
    }

    return resp.data;
  };

  const getNetworkLocal = async ( universeType: string ) => {
    const jsonPath = universeDataPath + universeType + '/network.json';
    let network = {};
    try {
      const response = await fetch(jsonPath);
      network = await response.json();
    } catch (err) {
      handleError(err);
      return { data: network, errors: ['error fetching file' + err]};
    }

    return { data: network };
  };

  const getImages = async ( token: string, uuid: string, params: object ): Promise<ResponseT<any>> => {
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await Universes.getImages(token, uuid, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('Universes.getImages ERRORS', resp.data.errors);
    }

    return resp.data;
  };

  const getImagesLocal = async ( universeType: string ) => {
    const jsonPath = universeDataPath + universeType + '/images.json';
    let images = {};
    try {
      const response = await fetch(jsonPath);
      images = await response.json();
    } catch (err) {
      handleError(err);
      return { data: images, errors: ['error fetching file' + err]};
    }

    return { data: images };
  };

  const deleteUniverse = async (token: string, uuid:string): Promise<ResponseT<null>> => {
    let resp;

    try {
      resp = await Universes.delete(token, uuid);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('deleteUniverse ERRORS', resp.data.errors);
    }
  };

  return {
    getAll,
    getInfo,
    getNetwork,
    getNetworkLocal,
    getImages,
    getImagesLocal,
    deleteUniverse
  };
};

export default useUniverses;
