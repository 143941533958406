import React from 'react';
import { LayerCard } from '../LayerCard';
import { LineBase, PlotConfig } from '@plotting/single-plot-view/plot.types';
import { VariableSelect } from '@plotting/controls/VariableSelect';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { COLORS } from '@utils/scales/color/ColorSchemes';
import { scaleOrdinal } from 'd3-scale';
import { DiscreteColorPaletteSelect } from '@plotting/controls/ColorPaletteSelect';

type LineCardProps = {
  plot: PlotConfig<LineBase>;
  onChange: (newPlotConfig: PlotConfig<LineBase>) => void;
};

export const LineCard = ({ plot, onChange }: LineCardProps) => {

  const updateTimeColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<LineBase> = {
      ...plot,
      config: { ...plot.config, timeColumn: columnName },
    };
    onChange(newPlotConfig);
  };

  const updateValueColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<LineBase> = {
      ...plot,
      config: { ...plot.config, valueColumn: columnName },
    };
    onChange(newPlotConfig);
  };

  const updateGroupColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<LineBase> = {
      ...plot,
      config: { ...plot.config, groupByColumn: columnName },
    };
    onChange(newPlotConfig);
  };

  const updateIsLogScale = (event: any, checked: boolean) => {
    const newPlotConfig: PlotConfig<LineBase> = {
      ...plot,
      config: { ...plot.config, isLogScale: checked },
    };
    onChange(newPlotConfig);
  };

  const updateStyle = (style) => {
    const newPlotConfig: PlotConfig<LineBase> = {
      ...plot,
      config: { ...plot.config, style },
    };
    onChange(newPlotConfig);
  };

  const groups: string[] = [
    ...new Set(plot.data.map((d) => d[plot.config.groupByColumn])),
  ];
  const defaultColors = COLORS.find((col) => col.id === 'aseda');
  const defaultColorScale = scaleOrdinal<string>()
    .domain(groups.map((g) => String(g)))
    .range(defaultColors.scheme);

  const defaultKeyColor = groups.map((group) => {
    return {
      id: group,
      color: defaultColorScale(group),
    };
  });

  return (
    <>
      <LayerCard title={'Variable Selection'} disableExpand>
        <VariableSelect
          id='variable-value'
          label={'Numeric Variable'}
          value={plot.config.valueColumn}
          options={plot.columns}
          onChange={updateValueColumn}
        />
        <VariableSelect
          id='variable-time'
          label={'Time Variable'}
          value={plot.config.timeColumn}
          options={plot.columns}
          onChange={updateTimeColumn}
        />
        <VariableSelect
          id='variable-group'
          label={'Group By'}
          value={plot.config.groupByColumn}
          options={plot.columns}
          onChange={updateGroupColumn}
        />
      </LayerCard>
      <LayerCard title={'Appearance'} disableExpand>
        <FormControlLabel
          control={
            <Checkbox
              checked={plot.config.isLogScale}
              onChange={updateIsLogScale}
            />
          }
          label={<Typography fontSize={12}>Use Log Scale</Typography>}
        />
        <DiscreteColorPaletteSelect
          values={plot.config.style || defaultKeyColor}
          onChange={updateStyle}
        />
      </LayerCard>
    </>
  );
};
