import React from 'react';
import UploadComponent from '@components/UploadComponent';
import AbbvieUpload from '@components/abbvieUpload';
import useFileImport from './useFileImport';
import styles from '../../../components-style/UploadComponents.module.css';
import styled from 'styled-components/macro';
import { FlexRow, FlexItem, FlexSpacer } from '@components/layout/FlexStyles';
import { TextSmall, TextSmallBold } from '@components/elements/TextStyles';
import RadioButtonSelector from '@pages/shared/RadioButtonSelector';
interface UploadFileOptionsT {
  choices: any;
  onChange: (e) => void;
}
const UploadFile = ({ choices, onChange }: UploadFileOptionsT) => {
  const { getWorkbook, context, loadWorkbookAndColumns } = useFileImport();
  const handleOnDrop = async (acceptedFiles) => {
    const workbook = await getWorkbook(acceptedFiles);
    loadWorkbookAndColumns(workbook, 0, 0, context.importer !== 'compound_set');
  };
  return (
    <UploadFileContainer>
      <FlexRow h_centered v_centered width={'100%'} height={'30px'}>
        <TextSmall color={'accentSecondary'}>Select file type and select/drop files</TextSmall>
      </FlexRow>
      <FlexRow>
        <FlexItem>
          <UploadSelector>
            <Item><TextSmallBold color={'primary'}> File Type:</TextSmallBold></Item>
            <RadioButtonSelector
              key='FileUploadButtonSelector'
              radioKey='FileUploadButtonSelector'
              options={choices}
              selected={context.importer}
              onChange={onChange}
              vertical
            />
          </UploadSelector>
        </FlexItem>
        <FlexSpacer />
        <FlexItem>
          <div className={styles.componentUploadFile}>
            {context.importer === 'abbvie' ? (
              <AbbvieUpload  />
            ) : (
              <UploadComponent onDrop={handleOnDrop} />
            )}
          </div>
        </FlexItem>
      </FlexRow>
    </UploadFileContainer>
  );
};
export default UploadFile;
const UploadFileContainer = styled.div`
  height: 100%;
  margin-top: 50px;
  min-height: 275px;
  display: flex;
  flex-direction: column;
`;
const UploadSelector = styled.div`
  display: flex;
  margin-top: 10px;
  margin-right: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 30%;
`;
const Item = styled.div`
  display: flex;
  color: ${(p) => p.theme.palette.textPrimary};
  font-weight: bold;
`;