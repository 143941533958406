import styled from 'styled-components/macro';

const circleRenderer = ({ cellData }) => {
  //console.log("circleRenderer {cellData}", cellData);
  if (isNaN(cellData)) {
    return <></>;
  }

  const hew = 338;
  const saturation = 100 * cellData;
  const luminance = 100 - cellData * 100 + 40;
  const color = 'hsl(' + hew + ',' + saturation + '%,' + luminance + '%)';
  const radius = 85 * (cellData < 0.01 ? 0.01 : cellData) + '%';

  return (
    <CircleBackground>
      <CircleInner color={color} size={radius} />
    </CircleBackground>
  );
};

export default circleRenderer;

const CircleBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.renderers.circleBackground};
`;

const CircleInner = styled.div<{ color: string; size: string }>`
  background-color: ${(p) => p.color};
  width: ${(p) => p.size};
  height: ${(p) => p.size};
  border-radius: 50%;
`;
