import styled from 'styled-components/macro';

export const InnerPanel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  background-color: ${(p) => p.theme.palette.backgroundTertiary};
  border-radius: 2px;
  border: 2px solid #636363;
  padding: 0;
`;

export const ModalHeader = styled.div`
  align-items: center;
  position: relative;
  padding: 5px;
`;

export const ModalLabel = styled.div`
  display: flex;
  flex-direction: row;
  width: 400px;
  align-items: center;
  position: relative;
  padding: 5px;
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  filter: contrast(1) brightness(0.7);
  cursor: pointer;

  :hover {
    color: ${(props) => props.theme.palette.accentPrimary};
    filter: contrast(1) brightness(1);
  }
`;

export const Title = styled.div`
  display: flex;
  position: relative;
  max-width: 300px;
  margin-right: 10px;
  padding-left: 5px;
`;

export const LeftSection = styled.div`
  display: flex;
  align-items: baseline;
`;

export const DraggableContainer: any = styled.div`
  position: relative;
  user-select: none;

  & * {
    box-sizing: border-box;
  }
`;

export const DraggableModalPanel: any = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(p) => p.theme.palette.titlePrimary};
  font-size: calc(0.8 * ${(props) => props.theme.sizes.small});
  font-weight: ${(props) => props.theme.weights.default};
`;

export const DraggableInputLabel: any = styled.label`
  display: flex;
  min-width: 70px;
  align-items: baseline;
  padding-right: 4px;
  color: ${(p) => p.theme.palette.titlePrimary};
  font-size: calc(0.8 * ${(props) => props.theme.sizes.small});
`;

export const DraggableInput: any = styled.input`
  display: flex;
  width: 120px;
  padding: 2px ${(props) => props.theme.sizes.xsmall};
  color: ${(p) => p.theme.palette.titlePrimary};
  font-size: calc(0.8 * ${(props) => props.theme.sizes.small});
  font-weight: ${(props) => props.theme.weights.default};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.palette.titleSecondary};
  outline: none;
  background: none;
`;

export const RightSection: any = styled.div`
  margin-left: 16px;
  max-height: 300px;
  z-index: 10000;
  font-size: calc(0.8 * ${(props) => props.theme.sizes.small});
  border: 1px solid ${(props) => props.theme.palette.titlePrimary};
  border-radius: 4px;
  background-color: ${(p) => p.theme.palette.backgroundSecondary};

  max-width: 60%;
`;

export const RightSectionList: any = styled.ul`
  width: 100%;
  max-height: 298px;
  list-style-type: none;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */

  & ::-webkit-scrollbar {
    display: none;
  }
`;

export const RightSectionListItem: any = styled.li`
  width: 100%;
  padding: calc(0.5 * ${(props) => props.theme.sizes.xxsmall})
    ${(props) => props.theme.sizes.xsmall};
  color: ${(props) => props.theme.palette.titlePrimary};
  font-size: calc(0.8 * ${(props) => props.theme.sizes.small});
  cursor: pointer;

  //   This can't be written only like this?
  //   For removing the logic with active and selected, because I couldn't see when it's selected
  //   &:hover {
  //     background-color: ${(props) => props.theme.palette.accentSecondary};
  //   }

  ${({ active, theme }: any) =>
    active &&
    `
    background-color: ${theme.palette.accentSecondary};
  `}

  ${({ selected, theme }: any) =>
    selected &&
    `
    color: ${theme.palette.backgroundSecondary};
    background-color: ${theme.palette.titlePrimary};
  `}
`;

export const DraggablePanelBody = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
  padding: 4px 8px;
  height: 90%;
`;
