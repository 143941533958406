import React from 'react';
import { rgb, hsl } from 'd3-color';
import { Theme } from '@nivo/core';
import { TooltipContainer } from '@dataviz/TooltipContainer';
import { ScatterPlotDatum, ScatterPlotTooltipProps } from '@nivo/scatterplot';

export const getVolcanoTooltip = (theme: Theme) => {
  return ({
    node,
  }: ScatterPlotTooltipProps<ScatterPlotDatum & { geneName: string }>) => {
    console.log('node', node);
    const { data, color, formattedX, formattedY } = node;

    const baseColor = hsl(rgb(color).formatHsl());
    baseColor.l = Math.max(0.7, baseColor.l + 0.2);

    return (
      <TooltipContainer color={baseColor.toString()}>
        <p style={{ color: baseColor.toString(), fontSize: 14 }}>
          <b> {'Gene: ' + data.geneName}</b>
        </p>
        <p>{'x: ' + parseFloat(Number(formattedX).toFixed(3))}</p>
        <p>{'y: ' + parseFloat(Number(formattedY).toFixed(3))}</p>
      </TooltipContainer>
    );
  };
};
