import { useState, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useNavigate, useParams } from 'react-router-dom';
import SignIn from '@as_core/account/forms/SignIn';
import SignUp from '@as_core/account/forms/SignUp';
import Register from '@as_core/account/forms/Register';
import useUserRegistrationInfo from '@utils/useUserRegistrationInfo';
import LoadingMessage from '@as_core/account/LoadingMessage';

import { UserContext } from '@stores/UserContext';
import ForgotPassword from '@as_core/account/forms/ForgotPassword';

const debug: boolean = false;
const PgAuthentication = () => {
  const { action } = useParams();
  const { user, setUser } = useContext(UserContext);
  const [redirect, setRedirect] = useState<string | null>(null);
  const [isRegDataLoading, setIsRegDataLoading] = useState(action === 'new');
  const [statusMessage, setStatusMessage] = useState('');
  const { getUserRegistrationInfo } = useUserRegistrationInfo();
  const navigate = useNavigate();
  const {isAuthenticated, isRegistered} = user;
  debug && console.log(`PgAuthentication | action: "${action}" user:`, user);

  // check for url to redirect as parameter - on mount
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const url = queryParams.get('url');
    debug && console.log('query URL ', url);
    if (url !== undefined) setRedirect(url);
  }, []);

  // handle the user registration data -- only called for sign-in as sign-up will do this
  useEffect(() => {
    debug && console.log('PgAuthentication: User auth/reg/loading state changed', isAuthenticated, isRegistered, isRegDataLoading);
    if (action!=='register' && isAuthenticated && !isRegistered && !isRegDataLoading) {
      setStatusMessage('Retrieving User Registration Data');
      setIsRegDataLoading(true);
      debug && console.log('Loading User Registration Data');
      let token = user.authSession.getAccessToken().getJwtToken();
      getUserRegistrationInfo(token, {}).then((response) => {
        // @ts-ignore
        setIsRegDataLoading(false);
        debug && console.log('PgAuthentication | getUserRegistrationInfo {response}:', response);
        if (response?.data.length) {
          const data = response?.data[0];
          setUser((prev) => ({
            ...prev, isRegistered: true,
            regInfo: data
          }));
        } else {
          alert('App | response data missing | error' + response?.errors);
          debug &&
            console.error(
              'App | response data missing | error',
              response?.errors
            );
        }
        if (redirect) {
          debug && console.log('Redirecting', redirect);
          navigate(redirect);
        }
      }).catch(()=>{
        alert('Error loading user registration -- empty or issues with API?');
        setIsRegDataLoading(false);
        setStatusMessage('');
        navigate('/user/register');
      });
    }
  }, [isAuthenticated, isRegistered, isRegDataLoading]);

  // handle the user registration data -- only called for sign-in as sign-up will do this
  useEffect(() => {
    debug && console.log('PgAuthentication: User reg/loading state changed', isRegistered, isRegDataLoading);
  }, [isRegistered, isRegDataLoading]);

  debug && console.log('PgAuthentication: User auth/reg/loading states', isAuthenticated, isRegistered, isRegDataLoading);
  return (
    <Center>
      {statusMessage ? (
        <LoadingMessage message={statusMessage}/>
      ) : action === 'new' ? (
        <Scroll>
          <SignUp key='sign-up-form' setMessage={setStatusMessage} />
        </Scroll>
      ) : action === 'register' ? (
        <Scroll>
          <Register key='register-form' />
        </Scroll>
      ) : action === 'forgot' ? (
        <ForgotPassword />
      ) : (
        <SignIn key='signin-in-component' setMessage={setStatusMessage}/>
      )}
    </Center>
  );
};

export default PgAuthentication;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const Scroll = styled.div`
  display: flex;
  width: max-content;
  height: max-content;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
  ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 7px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
  ::-webkit-scrollbar-corner {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
`;

