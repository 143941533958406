import React, { createContext } from 'react';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import {AppInfoT} from "@utils/useUserAppInfo";

export interface UserT {
  isLoading: boolean;              // make sure that AuthWrapper has time to check for user authentication session and data
  isAuthenticated: boolean;        // first level registration (Cognito)
  isRegistered: boolean;           // user Information -- done at create account and stored in AsedaSciences API (global)
  isAppAuthorized: boolean;        // anything related to the particular app
  authId: string;                  // holds the Cognito ID
  authEmail: string;               // holds the email used with the cognito ID
  authSession: CognitoUserSession, // when authenticated holds all the cognito authentication information
  authRoles: Array<string>;        // holds the user roles as assigned through Cognito Groups
  regInfo: {[key: string]: any};   // holds the user registration retrieved after authentication
  appInfo: AppInfoT;   // holds the user-specific application information retrieved after authentication
  subApp: string;                  // hold the subApp context for the left side menus
  [key: string]: any;              // Additional user fields if necessary
}

interface UserContextProps {
  user: UserT | null;
  setUser: React.Dispatch<React.SetStateAction<UserT | null>>;
}

export const UserEmpty:UserT = {
  isLoading: true,
  isAuthenticated: false,
  isRegistered: false,
  isAppAuthorized: false,
  authId: null,
  authEmail: null,
  authSession: null,
  authRoles: [],
  regInfo: {},
  appInfo: {
    subscription: {
      uuid: '',
      type: 'basic',
      ownerAuthId: '',
      ownerAuthEmail: '',
      allowedInvites: 0,
      allowedRepositories: 0,
    },
    repositories: {
      current: {uuid: '', repo: null, accessType: 'readOnly'},
      owned: [],
      shared: []
    }
  },
  subApp: '3RnD',
  creditsInfo: { creditsUsed: 0, creditsAvailable: 0 }
}

export const UserContext = createContext<UserContextProps>({user: UserEmpty, setUser: null});
