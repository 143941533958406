import React from 'react';
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { VariableSelect } from '../../controls/VariableSelect';
import { PieBase, PlotConfig } from '@plotting/single-plot-view/plot.types';

interface PieModalEditorProps {
  plot?: PlotConfig<PieBase>;
  onSave?: (config: PlotConfig<PieBase>) => void;
}

export const PieModalEditor = ({ plot, onSave }: PieModalEditorProps) => {
  const updateGroupByColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<PieBase> = {
      ...plot,
      config: { ...plot.config, groupByColumn: columnName },
    };
    onSave(newPlotConfig);
  };

  const updateValueColumn = (columnName: string) => {
    const newPlotConfig: PlotConfig<PieBase> = {
      ...plot,
      config: { ...plot.config, valueColumn: columnName },
    };
    onSave(newPlotConfig);
  };

  const updateIsSorted = (e: any, isSorted: boolean) => {
    const newPlotConfig: PlotConfig<PieBase> = {
      ...plot,
      config: { ...plot.config, isSorted },
    };
    onSave(newPlotConfig);
  };

  const updateIsDonut = (e: any, isDonut: boolean) => {
    const newPlotConfig: PlotConfig<PieBase> = {
      ...plot,
      config: { ...plot.config, isDonut },
    };
    onSave(newPlotConfig);
  };

  return (
    <Stack spacing={2} marginTop={4} maxWidth={400}>
      <VariableSelect
        id='variable-key'
        label={'Grouping Variable'}
        value={plot.config.groupByColumn}
        options={plot.columns}
        onChange={updateGroupByColumn}
      />
      <VariableSelect
        id='variable-value'
        label={'Numeric Variable'}
        value={plot.config.valueColumn}
        options={plot.columns}
        onChange={updateValueColumn}
      />
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={plot.config.isSorted}
              onChange={updateIsSorted}
            />
          }
          label={<Typography fontSize={12}>Sorted</Typography>}
        />
        <FormControlLabel
          control={
            <Checkbox checked={plot.config.isDonut} onChange={updateIsDonut} />
          }
          label={<Typography fontSize={12}>Donut</Typography>}
        />
      </div>
    </Stack>
  );
};
