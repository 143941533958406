import {OrderT} from "@business/types";
import _ from "lodash";
import BasicTable from '@as_core/tables/BasicTable';

export type ShowFieldsT = {
  label: string;
  value: string;
  render?: string;
  width?: number;
}

export function renderOrderCost(order: OrderT) {
  let total_cost = Number(_.get(order, 'payment.amount', 0)/100.0).toLocaleString();
  const currency = _.get(order, 'payment.currency', '');
  if (currency === 'usd') {
    total_cost = '$ ' + total_cost;
  }
  return total_cost;
}

export function renderOrderDetails(order: OrderT) {
  const orderFields: ShowFieldsT[] = [
    { value: 'product.catalogNumber', label: 'Catalog #', width: 150 },
    { value: 'product.productName', label: 'Product', width: 220 },
    { value: 'quantity', label: 'Quantity', width: 100 },
    { value: 'product.unitSize', label: 'Unit Size', width: 100 },
  ];
  const rows = [];
  Object.values(order.order).forEach((order) => {
    const row = {};
    orderFields.forEach((f) => {
      row[f.value] = _.get(order, f.value, '');
    })
    rows.push(row);
  })
  return <BasicTable fields={orderFields} rows={rows}/>;
}
