import { useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, Slider } from '@mui/material';
import { Clear, Image } from '@mui/icons-material';
import styled from 'styled-components/macro';
import { EXPORT_RATIOS, IDimension, IExportRatio } from '../screenshot.service';
import { SelectRatio } from './screenshot.select.ratio';
import InputField from '@as_core/controls/inputs/InputField';
interface Props {
  value: IDimension;
  ratio?: IExportRatio;
  onChange?: (type: IDimension) => void;
}

const DEFAULT_RATIO = EXPORT_RATIOS.find((s) => s.id === 'letter');

const getRatio = (dimension: IDimension) => {
  const ratio = (dimension.width / dimension.height).toFixed(2);
  return EXPORT_RATIOS.find((aspect) => aspect.ratio.toFixed(2) === ratio);
};

export const Dimensions = ({ value, onChange }: Props) => {
  const [ratio, setRatio] = useState(getRatio(value) || DEFAULT_RATIO);
  const [scale, setScale] = useState(value.height / ratio.base);
  const [height, setHeight] = useState(value.height);
  const [width, setWidth] = useState(value.width);
  const [ratioLock, setRatioLock] = useState(true);

  useEffect(() => {
    const ratio = getRatio(value) || DEFAULT_RATIO;
    setRatio(ratio);
    setScale(value.height / ratio.base);
  }, [value]);

  const toggleRatioLock = () => {
    setRatioLock(!ratioLock);
  };

  const updateRatio = (selected) => {
    if (!ratioLock) return;
    const size = {
      width: Math.round(selected.base * selected.ratio * scale),
      height: Math.round(selected.base * scale),
    };
    setRatio(selected);
    setHeight(size.height);
    setWidth(size.width);
    onChange && onChange(size);
  };

  const updateScale = (event, value) => {
    if (!ratioLock) return;
    const size = {
      width: Math.round(ratio.base * ratio.ratio * value),
      height: Math.round(ratio.base * value),
    };

    setScale(value);
    setHeight(size.height);
    setWidth(size.width);
    onChange && onChange(size);
  };

  const updateHeight = (event) => {
    const update = event.target.value;
    setHeight(update);
    onChange && onChange({ height, width });
  };

  const updateWidth = (event) => {
    const update = event.target.value;
    setWidth(update);
    onChange && onChange({ height, width });
  };

  const scaleSlider = (
    <Grid container spacing={1}>
      <Grid item xs={1}>
        <Image fontSize='small' />
      </Grid>
      <Grid item xs={10}>
        <Slider
          value={scale}
          min={0.1}
          max={4.0}
          step={0.1}
          onChange={updateScale}
          disabled={!ratioLock}
        />
      </Grid>
      <Grid item xs={1}>
        <Image />
      </Grid>
    </Grid>
  );

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox defaultChecked={ratioLock} onChange={toggleRatioLock} />
        }
        label='lock ratio'
      />
      <SelectRatio
        value={ratio}
        sizes={EXPORT_RATIOS}
        onChange={updateRatio}
        disabled={!ratioLock}
      />
      <Grid item xs={8}>
        {scaleSlider}
      </Grid>
      <Separator />
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <InputField
            id='export-width'
            label='width (px)'
            type='number'
            variant='filled'
            value={width}
            disabled={ratioLock}
            onChange={updateWidth}
          />
        </Grid>
        <Grid item xs={2}>
          <TimesIcon />
        </Grid>
        <Grid item xs={5}>
          <InputField
            id='export-height'
            label='height (px)'
            type='number'
            variant='filled'
            value={height}
            disabled={ratioLock}
            onChange={updateHeight}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const TimesIcon = styled(Clear)`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
`;

const Separator = styled.div`
  background-color: ${(p) => p.theme.palette.textSecondary};
  height: 1px;
  width: 100%;
  margin: 16px 0;
`;
