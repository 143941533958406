import React from 'react';
import { animated, to } from '@react-spring/web';
import { std, sqrt, median } from 'mathjs';
import { ScatterPlotDatum, ScatterPlotNodeProps } from '@nivo/scatterplot';
import { getSVGShape } from '@components/shapes/Shape';

const strokeWidth = 2;

export const getScatterplotNode = ({
  node,
  style,
  blendMode,
  onMouseEnter,
  onMouseMove,
  onMouseLeave,
  onClick,
}: ScatterPlotNodeProps<ScatterPlotDatum>) => {
  return (
    <g transform={`translate(${node.x},${node.y})`}>
      <circle
        r={node.size / 2}
        fill={node.color}
        style={{ mixBlendMode: blendMode }}
        onMouseEnter={(event) => onMouseEnter?.(node, event)}
        onMouseMove={(event) => onMouseMove?.(node, event)}
        onMouseLeave={(event) => onMouseLeave?.(node, event)}
        onClick={(event) => onClick?.(node, event)}
      />
    </g>
  );
};
