import React from 'react';
import styled from 'styled-components/macro';
import CurveFitResults from "../../importDoseResponse/curveFit/CurveFitResults";
import { FlexRow, FlexItem, FlexColumn } from "@components/layout/FlexStyles";
import { TextSmallBold } from "@components/elements/TextStyles";
import { DataSetT } from "@components/dataSets/dataSet.types";
import PreviewDataTable from "./PreviewDataTable";

interface propsT {
  dataSet: DataSetT;
  height?: number;
  width?: number;
}

const debug = false;
const PreviewDataDoseResponse = (props: propsT) => {
  debug && console.log('PreviewDataDoseResponse | props', props);
  const {dataSet, height, width} = props;

  return(
      <PreviewDataContainer height={height} width={width}>
        <FlexRow>
          <FlexColumn width={'max-content'}>
            <FlexItem>
              <TextSmallBold color={'accentSecondary'}>Data</TextSmallBold>
            </FlexItem>
            <ScrollBox height={height-40} width={width-380}>
              <PreviewDataTable data={dataSet.data}/>
            </ScrollBox>
          </FlexColumn>
          <FlexColumn width={"380px"}>
            <FlexItem>
              <TextSmallBold color={'accentSecondary'}>Dose Response Curve Fit</TextSmallBold>
            </FlexItem>
              <CurveFitResults height={height-40}
                               dataSet={dataSet.dataFormatted} fitType={'LL.4'}
              />
          </FlexColumn>
        </FlexRow>

      </PreviewDataContainer>
  )
}

export default PreviewDataDoseResponse;

const PreviewDataContainer = styled.div<{height: number, width: number}>`
  width: calc(${(p) => p.width} - 20)px;
  height: ${(p) => p.height}px;
  padding-left: 5px;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const ScrollBox = styled.div<{height: number, width: number}>`
  display: flex;
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  overflow-x: hidden;
`;