import styled from "styled-components/macro";
import InvitationCards from "@subApps/account/userLoginAlerts/components/InvitationCards";
import Title from "@ui-components/elements/Title";

const debug = false;
const ShowInvitations = ({invitations, setInvitationsUpdated}) => {

  debug && console.log('ShowInvitations | invitations: ', invitations);
  if (!invitations.length) {
    return null;
  }
  return(
    <InvitationsContainer>
      <Title variant={'normal'} text={'My Pending Invitations'}/>
      <Cards>
        { invitations.length ?
          <InvitationCards
            invitations={invitations}
            setInvitationsUpdated={setInvitationsUpdated}
          />
          : null}
      </Cards>
    </InvitationsContainer>
  )
}
export default ShowInvitations;

const InvitationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  margin: 5px 5px;
  padding: 5px;
  border-radius: 15px;
`;

const Cards = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
`;