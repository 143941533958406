import React, {useContext, useEffect} from 'react';
import styled from 'styled-components/macro';

import useFileImport from '../useFileImport';
import { createDoseResponseDataSetFromExcel } from '@components/dataSets/dataSet.service';
import {Header, Row} from '../shared/RowElements';
import {FlexRow, FlexItem} from '@components/layout/FlexStyles';
import {TextSmall, TextSmallBold} from '@components/elements/TextStyles';
import ImportDoseResponseReviewRow from './ImportDoseResponseReviewRow';
import {UserContext} from "@stores/UserContext";

const debug = false;
const ImportDoseResponseReview = (props) => {
  const { columnFields, dataSet, setDataSet, width } = props;
  const { context: { workbook, columns } } = useFileImport();
  const { user } = useContext(UserContext);
  debug && console.log('ImportDoseResponseReview | workbook', workbook);
  debug && console.log('ImportDoseResponseReview | columns', columns);

  useEffect(()=>{
    debug && console.log("ImportDoseResponseReview | useEffect | columns", columns,
        'columnFields', columnFields);
    // process the file and put it in the correct format
    let myColumns = columns;
    for (const col of columnFields) {
      const index = myColumns.findIndex((c) => c.colName === col.matched_column);
      if (index !== -1) myColumns[index].field_id = col.value;
    }
    const dataSet = createDoseResponseDataSetFromExcel(user.authId, workbook, myColumns);
    debug && console.log("doseResponseDataSet:", dataSet);
    setDataSet(dataSet);
  }, [workbook, columns]);

  debug && console.log('ImportDoseResponseReview | dataSet ', dataSet.dataFormatted);

  return (
    <Container>
      { !dataSet?.dataFormatted ? <></> :
        <>
          <FlexRow width={'100%'} h_centered>
            <FlexItem><TextSmallBold color={'accentSecondary'}>Number of Dose Response Experiments(s) in File:</TextSmallBold></FlexItem>
            <FlexItem><TextSmall color={'primary'}>{dataSet?.dataFormatted?.length}</TextSmall></FlexItem>
          </FlexRow>
          <ScrollContainer>
            <Table width={width}>
              <tbody>
              <Row key={'doseresponseheader_0'}>
                <Header>Compound Ids</Header>
                <Header>Sample Ids</Header>
                <Header>Dose-Response Pairs</Header>
                <Header>Data Preview</Header>
              </Row>
              {dataSet.dataFormatted.map((data, dataIdx) => {
                debug && console.log('ImportDoseResponseReview | row data:', data);
                return(
                    <ImportDoseResponseReviewRow
                        key={'row_' + dataIdx}
                        data={data}
                        dataIdx={dataIdx}
                    />
                )
              })}
              </tbody>
            </Table>
          </ScrollContainer>
        </> }
    </Container>
  );
};

export default ImportDoseResponseReview;

const Container = styled.div<{ height?: number; width?: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ScrollContainer = styled.div`
  display: flex;
  width: 100%;
  overflow-y: scroll;
  max-height: calc(100vh - 330px);
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  border-radius: 15px;
  -ms-overflow-style: none; /* IE and Edge */
  & ::-webkit-scrollbar {
    display: none;
  }
`;

const Table = styled.table<{ height?: number; width?: number }>`
  display: flex;
  justify-content: space-between;
  table-layout: auto;
  width: 100%;
`;
