import axios from 'axios';

console.log("process.env",process.env);

// interfaces
export interface ResponseT<T> {
  errors: string[];
  limit?: number;
  total?: number;
  size?: number;
  start_after?: number;
  continues?: boolean;
  data?: T;
}

export interface BasicResponseT<T> {
  errors: string[];
  data?: T;
}

// 3RnD compound service api
export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_CPDSRV,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const authClient = (token) => {
  apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return apiClient;
};

// AsedaSciences core service api
export const apiCoreClient =  axios.create({
  baseURL: process.env.REACT_APP_API_CORE,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const authCoreClient = (token) => {
  apiCoreClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return apiCoreClient;
};

// AsedaSciences requests service api
export const apiServicesClient =  axios.create({
  baseURL: process.env.REACT_APP_API_SERVICES,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const authServicesClient = (token) => {
  apiServicesClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return apiServicesClient;
};


// Static Content
export const staticClient = axios.create({
  baseURL: 'https://thewarriors3.com', // <- ENV variable
  headers: {
    'Content-Type': 'application/json',
  },
});

export const authStaticClient = (token) => {
  staticClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return staticClient;
};

export const getCancelToken = () => {
  return axios.CancelToken.source();
};
