import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const CompoundsIcon = ({
    size = 24,
  }) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 17.5L12 23L21.5 17.5V6.5L12 1L2.5 6.5V17.5Z" stroke={color} strokeWidth="2" strokeMiterlimit="10"
              strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.0001 7.69997L8.70011 9.69997C8.20011 9.99997 8.60011 10.8 9.20011 10.5L12.5001 8.49997C13.1001
          8.19997 12.6001 7.39997 12.0001 7.69997Z" fill={colorHighlight}/>
        <path d="M12.5003 15.7L9.20027 13.7C8.70027 13.4 8.20027
          14.2 8.70027 14.5L12.0003 16.5C12.6003 16.8 13.1003 16 12.5003 15.7Z" fill={colorHighlight}/>
        <path d="M16.1006 14.0999V10.0999C16.1006 9.4999 15.1006 9.4999 15.1006 10.0999V14.0999C15.1006 14.7999
          16.1006 14.7999 16.1006 14.0999Z" fill={colorHighlight}/>
        <path d="M18.0002 8.9001L12.7002 5.6001V4.3001C12.7002 3.7001 11.7002 3.7001 11.7002 4.3001V5.7001L6.7002
          8.7001C6.6002 8.7001 6.5002 8.8001 6.5002 9.0001V15.0001L5.1002 15.9001C4.6002 16.3001 5.2002 17.0001
          5.7002 16.7001L7.0002 15.9001L12.0002 18.8001C12.1002 18.9001 12.3002 18.9001 12.5002 18.8001L18.0002
          15.5001V8.9001ZM17.1002 14.9001L12.3002 17.7001L7.5002 14.9001V9.3001L12.3002 6.5001L17.1002
          9.3001V14.9001Z" fill={colorHighlight}/>
      </svg>
    </>
  )
}

export default CompoundsIcon;