import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import RowBuilderRequiredV2 from './required/RowBuilderRequiredV2';
import { MatchFieldT } from '@stores/fields';
import useFields from '@utils/useFields';
import useCompounds from '@components/compounds/useCompounds';
import { useNavigate } from "react-router-dom";
import FileUploader from "../FileUploader"

const debug = false;
const initialData = {
    columns_with_examples: [],
    matched_columns: {},
};

const AssignColumnsTableRequiredV2 = (props) => {
    const {
        columnFields,
        nextAvailable,
        setNextAvailable,
        columnData,
        fileData,
        workbook

    } = props;
    const { saveCompounds } = useCompounds();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [matchedFields, setMatchedFields] = useState<MatchFieldT>(initialData);
    const countRequired = columnFields.filter((c) => c.required).length;
    const countSet = columnFields.filter((c) => (c.required && c.matched_column !== '')).length;
    const [columnId, setColumnId] = useState(null);
    const navigate = useNavigate();
    const { getSpecificsFields } = useFields();


    let columns = Object.keys(columnData)
    if (columnFields.length === 0) {
        return <Error>The Uploaded file is empty.</Error>;
    }

    useEffect(() => {
        if (nextAvailable && countSet !== countRequired) {
            setNextAvailable(false);
        }
        if (!nextAvailable && countSet === countRequired) {
            setNextAvailable(true);
        }
    }, [nextAvailable, countRequired, countSet])

    useEffect(() => {
        debug && console.log("PgFieldEditor | useEffect | loaded:", loaded);
        if (!loaded) {
            getSpecificsFields(columns).then((response) => {
                setMatchedFields(response);
                setLoaded(true);
            })
        }
    }, [columns, loaded]);

    const handleDataFromChild = async () => {
        const formData = new FormData();
        formData.append('uploadedFile', fileData[0]);
        formData.append('columnId', columnId);
        if (columnId === null) {
            window.alert("Please select first an ID")
        }
        const res = await saveCompounds(formData)
        if (res && res.status === 201) {
           navigate('/data-analysis'); 
        }
    };


    const updateMessage = countSet + ' of ' + countRequired
        + ' required columns assigned [All must be matched to continue]';


    return (
        <Container>
            <UpdateContainer>{updateMessage}</UpdateContainer>
            <ScrollContainer>
                <RowBuilderRequiredV2
                    inputData={columnData}
                    matchedFields={matchedFields}
                    onData={setColumnId}
                />
            </ScrollContainer>
            <ButtonValid onClick={handleDataFromChild}>Validate</ButtonValid>
            <FileUploader />
        </Container>
    );
};

export default AssignColumnsTableRequiredV2;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
const Error = styled.div`
    color: red;
    font-weight: bold;

`;
const UpdateContainer = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  font-size: 12px;
  font-style: italic;
  color: ${(p) => p.theme.palette.accentSecondary}
`;

const ScrollContainer = styled.div`
  width: 100%;
  height: 77%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const ButtonValid = styled.button`
    width: 15%;
    height: 8%;
    margin-top: 2%;
    margin-right: 0px;
    margin-left: 84.8%;
    background-color: rgb(214, 11, 81);
    border: solid 2px #F4B860;
    border-radius: 5px;
    cursor: pointer;
    font-size: 30px;
    font-weight: 500;
    color: white;
`;