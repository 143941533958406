import React from 'react';
import styled from 'styled-components/macro';
import CancelIcon from '@mui/icons-material/Cancel';

const HoverOverlay = (props) => {
  const { onClick } = props;
  const [hovered, setHovered] = React.useState(false);

  const handleClick = () => {
    if (hovered) {
      onClick();
    }
  };

  return (
    <Container
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleClick}
    >
      {hovered ? (
        <>
          <Cancel /> Clear Selection
        </>
      ) : (
        props.children
      )}
    </Container>
  );
};

export default HoverOverlay;

const Container = styled.div`
  display: block;
  cursor: pointer;
`;

const Cancel = styled(CancelIcon)`
  font-size: ${(p) => p.theme.sizes.xxsmall};
  vertical-align: middle;
  color: grey;
`;
