import React from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';

import Gauge from './Gauge';
import ColorBarTable from './ColorBarTable';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { FieldSliceT } from '@stores/fields';

interface propsT {
  compound: object;
  width?: number;
}

const Scorecard = (props: propsT) => {
  const { compound, width=300 } = props;
  const { fields: allFields } = useSelector((state: RootState) => state.fields as FieldSliceT);
  const data_key = _.get(allFields, 'cell_health_index.data_key', null);
  const value = _.get(compound, data_key, 0 );
  return (
    <Column width={width}>
      <Item>
        <Gauge value={value} min={0} max={1} />
      </Item>
      <Item>
        <ColorBarTable compound={compound} />
      </Item>
    </Column>
  );
};

export default Scorecard;

const Column = styled.div<{width: number}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(p)=>p.width}px;
`;

const Item = styled.div`
  display: flex;
`;
