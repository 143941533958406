import React, { useContext } from 'react';
import { PlotContext } from '../plot.context';
import PieChart from 'src/dataviz/piechart/PieChart';
import _ from 'lodash';
import { Margin, Theme } from '@nivo/core';
import { getPieChartData } from './data-wrangling-utils/get-pie-chart-data';
import { PieBase, PlotConfig } from '@plotting/single-plot-view/plot.types';

const SMALL_GRAPH_MARGIN = {
  top: 50,
  right: 50,
  bottom: 50,
  left: 50,
};

type PieWrapperProps = {
  datavizTheme?: Theme;
  providedPlot?: PlotConfig<PieBase>;
  isSmallChart?: boolean;
};

// This component renders the Pie Chart of the MyPlots tab
// It basically recovers the information for the graph editor
// And pass them to the src/dataviz/piechart component
export const PieWrapper = ({
  datavizTheme,
  providedPlot,
  isSmallChart,
}: PieWrapperProps) => {
  const plot =
    providedPlot ||
    (useContext(PlotContext) as { plot: PlotConfig<PieBase> }).plot;

  const data = getPieChartData(
    plot.data,
    plot.config.groupByColumn,
    plot.config.valueColumn,
    plot.config.style
  );

  return (
    <PieChart
      data={data}
      datavizTheme={datavizTheme}
      isSorted={plot.config.isSorted}
      margin={isSmallChart ? SMALL_GRAPH_MARGIN : undefined}
      title={isSmallChart ? undefined : plot.name}
      titleSize={plot.config?.titleFontSize}
      isDonut={plot.config.isDonut}
    />
  );
};
