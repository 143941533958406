import { AxiosRequestConfig } from "axios";
import {authCoreClient, ResponseT} from "@utils/api/base";
import { handleError } from "@utils/misc";
import useCognito from "@as_core/account/useCognito";
import {OrderT} from "@business/types";

export const Orders = {
  all: (token, config) => authCoreClient(token).get('/orders', config),
  get: (token, orderId) => authCoreClient(token).get(`/orders/id/${orderId}`),
  list: (token, config) => authCoreClient(token).get('/orders/list', config),
  create: (token, config) => authCoreClient(token).post('/orders/create', config),
  createSession: (token, config) => authCoreClient(token).post('/orders/create-checkout-session', config),
  createSessionEmbedded: (token, config) => authCoreClient(token).post('/orders/create-checkout-session-embedded', config),
  update: (token, config) => authCoreClient(token).patch('/orders/update', config),
};

const useOrders = () => {
  const { getToken } = useCognito();

  const getOrders = async (params): Promise<ResponseT<any>> => {
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await Orders.all(getToken(), config);
    } catch (err) {
      handleError(err);
      return null;
    }

    return resp.data;
  };

  const getOrder = async (
    orderId,
  ): Promise<ResponseT<OrderT>> => {
    let resp;

    try {
      resp = await Orders.get(getToken(), orderId);
    } catch (err) {
      handleError(err);
      return null;
    }

    return resp.data;
  };

  const createOrder = async (token, config) => {
    let resp;

    try {
      resp = await Orders.create(token, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    return resp.data;
  };



  const updateOrder = async (updates: {[key: string]: any}) => {
    const config: AxiosRequestConfig = updates;
    let resp;

    try {
      resp = await Orders.update(getToken(), config);
    } catch (err) {
      handleError(err);
      return null;
    }

    return resp.data;
  };


  const createOrderSessionEmbedded = async (config): Promise<{clientSecret: string, orderId: string}> => {
    let resp;

    try {
      resp = await Orders.createSessionEmbedded(getToken(), config);
    } catch (err) {
      handleError(err);
      return null;
    }

    return resp.data;
  };

  const createOrderSession = async (config): Promise<{status: string, url: string, code: string, orderId: string}> => {
    let resp;

    try {
      const response = await Orders.createSession(getToken(), config);
      resp = response.data;
      resp.status = 'OK'
    } catch (err) {
      handleError(err);
      resp.status = 'ERROR'
    }

    return resp;
  };

  return {
    getOrders,
    // getSubmissionsFinite,
    getOrder,
    createOrder,
    createOrderSession,
    createOrderSessionEmbedded,
    updateOrder
  };
};

export default useOrders;
