import React from 'react';
import styled from 'styled-components/macro';
import { CompoundFieldT } from '@stores/fields';

// type ItemDetailProps = { field: CompoundFieldT; isDragDisabled: boolean };
const ItemDetail = (props) => {
  // const { field, isDragDisabled }: ItemDetailProps = props;
  const { state, fieldId } = props;

  const field = state.fields[fieldId];
  //console.log("ItemDetail {field}", field);

  if (field) {
    return (
      <Container>
        <TopRow>
          <Title {...props}>{field?.long_name}</Title>
          <Symbol {...props}>({field?.symbol})</Symbol>
        </TopRow>
      </Container>
    );
  } else {
    return (<></>);
  }
};

export default ItemDetail;

interface ExtraProps {
  isDragging?: boolean;
  isDraggingOver?: boolean;
  isDragDisabled?: boolean;
  field_type?: string;
  field?: CompoundFieldT;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 5px;
`;

const Title = styled.span<ExtraProps>`
  color: ${(p) =>
    p.isDragDisabled
      ? p.theme.palette.textSecondary
      : p.theme.palette.titlePrimary};
  font-size: ${(p) => p.theme.sizes.small};
`;

const Symbol = styled.span<ExtraProps>`
  font-size: 14px;
  display: flex;
  color: ${(p) => p.theme.palette.textSecondary };
  justify-items: center;
  align-items: center;
  margin-left: 5px;
`;



