import React, { ReactNode } from 'react';
import _ from "lodash";
import {UseCartObjectReturnType} from "@as_core/hooks/useShoppingCart";
import OrderCreate from "@business/orders/OrderCreate";
import OrderConfirm from "@business/orders/components/OrderConfirm";
import OrdersStripe from "@business/orders/OrdersStripe";
import OrdersList from "@subApps/orders/components/Orders/OrdersList";
import RequestsList from "@subApps/orders/components/Common/RequestsList";
import ConsultingList from "@subApps/orders/components/Consulting/ConsultingList";
import CellHealthIcon from "@components/icons/cellhealth.icon";
import ZebrafishIcon from "@components/icons/zebrafish.icon";
import BiomimeticsIcon from "@components/icons/biomimetics.icon";
import {RequestT} from "@subApps/orders/hooks/useRequests";
import MenuButton from "@components/elements/MenuButton";
import ViewIcon from "@as_core/icons/view.icon";
import HoverTextTruncate from "@as_core/elements/HoverTextTruncate";
import TextButton from '@as_core/controls/buttons/TextButton/TextButton';
import {VENDOR_MASK} from "@subApps/orders/hooks/useVendors";

const consultingCodes: string[] = ['AS-BMVK-KVC', 'AS-ZBE-DTX16-CTG'];
const subscriptionCodes: string[] = ['AS-SCREEN-3RND'];

export const showCodes = ['AS-SYS', 'AS-ZBE', 'AS-BMVK', 'AS-CQC-SST'];
const categoryIcons = {
  'AS-SYS': <CellHealthIcon size={48}/>,
  'AS-ZBE': <ZebrafishIcon size={48}/>,
  'AS-BMVK': <BiomimeticsIcon size={48}/>
}

export function getIcon(code: string) {
  return Object.hasOwn(categoryIcons, code) ? categoryIcons[code] : null;
}

export function isRequestConsulting(request: RequestT) {
  return consultingCodes.includes(request.cat_code);
}

export function filterByConsulting(requests:RequestT[], isConsulting: boolean = true): RequestT[] {
  const nonSubscription = requests.filter((r) => !subscriptionCodes.includes(r.cat_code));
  if (isConsulting) {
    return nonSubscription.filter((r) => isRequestConsulting(r));
  }
  return nonSubscription.filter((r) => !isRequestConsulting(r));
}

export function getStatusValue(request:RequestT, handleClick: (id:string, action:string)=>void): any {
  const value: string = _.get(request, 'status', '');
  if (value === 'Open') {
    const assigned_to = _.get(request,'assigned_to', '');
    // console.log('status: ', value, 'assigned_to', assigned_to);
    if (assigned_to === null || assigned_to === '') {
      return('Processing Request');
    } else {
      const cat_no = _.get(request, 'cat_code', '');
      if (consultingCodes.includes(cat_no)) {
        return(
          <TextButton
            width={135}
            height={30}
            fontSize={12}
            margin={'2px'}
            label={'Scheduling Required'}
            onClick={() => window.location.href = `mailto:info@asedasciences.com?subject=Need to Schedule ${request?.prod_name}`}
          />);
      }
      return(
        <TextButton
          width={140}
          height={30}
          fontSize={12}
          margin={'2px'}
          label={'User Action Required'}
          onClick={() => handleClick(request.id, 'update')}
        />);
    }
  } else if (value === 'Delivered') {
    const cat_no = _.get(request, 'cat_code', '');
    if (subscriptionCodes.includes(cat_no)) {
      return 'Activated';
    }
  }
  return value;
}

export function getStatusCounts(requests:RequestT[]): {[key:string]: number} {
  const counts = {};
  requests.forEach((r) => {
    if (Object.hasOwn(counts, r.status)) {
      counts[r.status] += 1;
    } else {
      counts[r.status] = 1;
    }
  })
  return counts;
}

export type positionT = {
  x: number;
  y: number;
}

export type positionCountT = {
  x: number;
  y: number;
  count: number;
}

export function mapCountsToPositions(
  counts: {[key:string]: number},
  mapPositions: {[key:string]: positionT}
) : positionCountT[] {
  const positions = [];
  Object.keys(counts).forEach((status) => {
    // console.log('status', status, counts[status]);
    if (Object.hasOwn(mapPositions, status)) {
      positions.push({
        x: mapPositions[status].x,
        y: mapPositions[status].y,
        count: counts[status]
      })
    }
  });
  return positions;
}

export type TableFieldsT = {
  value: string;
  label: string;
  type?: string;
  width?: number;
}

export const getFieldValue = (request: RequestT, field: TableFieldsT): any => {
  const value: string = _.get(request, field.value, '');
  if (value === null) return '';
  if (field?.type) {
    if (['date', 'datetime'].includes(field.type)) {
      if (value !== '') {
        const dateObj = new Date(value)
        if (field.type === 'date') return dateObj.toLocaleDateString()
        return dateObj.toLocaleDateString() + ' ' + dateObj.toLocaleTimeString();
      } else {
        return value;
      }
    } else if (field?.type && field.type === 'quantity') {
      let quantity = _.get(request, 'quantity', 0);
      let unit_size = _.get(request, 'unit_value', '');
      const parts = unit_size.split(' ');
      if (parts.length > 1) {
        quantity = quantity * Number(parts[0]);
        unit_size = parts[1];
        if (quantity > 1) unit_size += 's';
      }
      return quantity.toString().concat(' ', unit_size);
    } else if (field.type === 'vendor') {
      const value = _.get(request, 'assigned_to', '');
      if (Object.hasOwn(VENDOR_MASK, value)) {
        return VENDOR_MASK[value];
      }
      return value;
    } else if (field.type === 'shortString') {
      return <HoverTextTruncate text={value} characters={15}/>;
    }
  }
  return value;
}

export const getTableRows = (
  requests: RequestT[],
  tableFields: TableFieldsT[],
  handleViewClick: (id:string, action:string)=>void
) => {
  let rows = [];
  requests.forEach((request) => {
    let row = {};
    for (const field of tableFields) {
      if (field?.type && field.type === 'action') {
        row[field.value] =
          <MenuButton
            onClick={() => handleViewClick(request?.id, 'view')}
            icon={<ViewIcon/>}
            text={'View Request'}
            tooltipPlacement={'bottom'}
          />;
      } else if (field?.type && field.type === 'status') {
        row[field.value] = getStatusValue(request, handleViewClick);
      } else {
        row[field.value] = getFieldValue(request, field);
      }
    }
    rows.push(row);
  });
  return rows;
};


export const getTitle = (action: string): string => {
  switch(action) {
    case 'create':
      return 'Create New Order';
    case 'confirm':
      return 'Confirm Order';
    case 'active':
      return 'In Process Orders';
    case 'requests':
      return 'Open Requests';
    case 'consulting':
      return 'Open Consulting Requests';
    case 'stripe':
      return 'Stripe Processing';
    case 'complete':
      return 'Completed Orders';
  }
  return 'Title';
}

export const getComponent = (action: string, ordersCart:UseCartObjectReturnType): ReactNode => {
  switch(action) {
    case 'create':
      return <OrderCreate ordersCart={ordersCart}/>
    case 'confirm':
      return <OrderConfirm ordersCart={ordersCart}/>
    case 'stripe':
      return <OrdersStripe />
    case 'requests':
      return <RequestsList />
    case 'consulting':
      return <ConsultingList />
    case 'active':
      return <OrdersList orderType={'active'}/>
    case 'complete':
      return <OrdersList orderType={'complete'}/>
  }
  return <div>Unknown Action {action}</div>
}