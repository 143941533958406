import { useEffect, useState, useRef, useMemo } from 'react';
import BaseTable, { Column } from 'react-base-table';
import { Checkbox } from '@mui/material';
import 'react-base-table/styles.css';
import styled, { css } from 'styled-components/macro';
import { callOrReturn, normalizeColumns, noop } from '../../utils';
import { Tooltip } from '@mui/material';
import styles from '../../../components-style/SelectableTable.module.css';
import '../../../components-style/SelectableTable.scss';
import { CompoundT } from '../../../type';
import PushPinIcon from '@components/icons/pushPin.icon';

const debug = false;
const headerRenderer = ({
  cells,
  columns,
  selectedRowKeys,
  onSelectedRowsChange,
  searchKeys,
  hasSelectAll,
}) => {
  if (columns.every((x) => x?.frozen)) return cells;
  return columns.map((_column, index) => {
    if (index === 0 && hasSelectAll) {
      const [isChecked, setIsChecked] = useState<boolean>(false);

      const handleOnClick = () => {
        if (selectedRowKeys?.length < searchKeys?.length) {
          onSelectedRowsChange(searchKeys);
          setIsChecked(true);
        } else {
          onSelectedRowsChange([]);
          setIsChecked(false);
        }
      };

      return (
        <CheckboxContainer>
          <Checkbox
            indeterminate={
              selectedRowKeys?.length > 0 &&
              selectedRowKeys?.length < searchKeys?.length
            }
            checked={isChecked}
            onClick={handleOnClick}
            key={index}
          />
        </CheckboxContainer>
      );
    }

    return (
      <Tooltip
        key={index}
        title={
          cells[index]?.props?.children?.[1]?.props?.column?.description
            ? cells[index].props.children?.[1].props.column.description
            : ''
        }
        arrow
        placement='bottom'
      >
        {cells[index]}
      </Tooltip>
    );
  });
};

const SelectionCell = (props) => {
  const { rowData, column } = props;
  const { selectedRowKeys, rowKey, handleUnpin } = column;

  const _handleChange = (e) => {
    const { rowData, rowIndex, column } = props;
    const { onChange } = column;

    onChange({ selected: e.target.checked, rowData, rowIndex });
  };

  const checked = selectedRowKeys?.includes(rowData[rowKey]);

  const onUnpin = () => {
    handleUnpin(rowData);
  };

  return (
    <div className={styles.select}>
      <Checkbox checked={checked} onChange={_handleChange} />
      <PushPinIcon id='pin-icon' className={styles.pin} onClick={onUnpin} />
    </div>
  );
};

const SelectableTable = (props: any) => {
  const {
    columns,
    children,
    selectable,
    selectionColumnProps,
    selectedRowKeys,
    isSearchable,
    setPinnedData,
    onSelectedRowsChange,
    hasSelectAll,
    ...rest
  } = props;

  const [localData, setLocalData] = useState(props.data);
  const tableRef = useRef<any>();
  const [_columns, set_Columns] = useState(
    columns || normalizeColumns(children)
  );

  useEffect(() => {
    setLocalData(props.data);
  }, [props.data]);

  const _handleSelectChange = ({ selected, rowData, rowIndex }) => {
    const selectedRowKeys = [...props.selectedRowKeys];
    const key = rowData[props.rowKey];
    debug &&
      console.log(
        '_handleSelectChange | rowData:',
        rowData,
        'rowIndex:',
        rowIndex,
        'selectedRowKeys',
        selected
      );

    if (selected) {
      if (!selectedRowKeys.includes(key)) selectedRowKeys.push(key);
    } else {
      const index = selectedRowKeys.indexOf(key);
      if (index > -1) {
        selectedRowKeys.splice(index, 1);
      }
    }

    props.onRowSelect({ selected, rowData, rowIndex });
    props.onSelectedRowsChange(selectedRowKeys);
  };

  const handleUnpin = (rowData) => {
    setPinnedData((previous) =>
      previous.filter((pinned) => pinned?._id !== rowData?._id)
    );
  };

  const _rowClassName = ({ rowData, rowIndex }) => {
    const { rowClassName, rowKey } = props;
    const { selectedRowKeys } = props;
    debug &&
      console.log(
        '_rowClassName | rowData:',
        rowData,
        'rowIndex:',
        rowIndex,
        'selectedRowKeys',
        selectedRowKeys
      );

    const rowClass = rowClassName
      ? callOrReturn(rowClassName, { rowData, rowIndex })
      : '';
    const key = rowData[rowKey];

    return [rowClass, selectedRowKeys.includes(key) && 'row-selected']
      .filter(Boolean)
      .concat(' ');
  };

  useEffect(() => {
    if (selectable) {
      const selectionColumn = {
        width: 80,
        flexShrink: 0,
        resizable: false,
        frozen: Column.FrozenDirection.LEFT,
        cellRenderer: SelectionCell,
        ...selectionColumnProps,
        key: '__selection__',
        rowKey: props.rowKey,
        selectedRowKeys: selectedRowKeys,
        frozenData: props.frozenData,
        onChange: _handleSelectChange,
        handleUnpin: handleUnpin,
      };
      set_Columns([
        selectionColumn,
        ...(columns || normalizeColumns(children)),
      ]);
    }
  }, [selectable, props.rowKey, selectedRowKeys, columns]);

  const headerFrozenHeight = useMemo(() => {
    return tableRef?.current?.table?.headerRef.props.height;
  }, [tableRef?.current?.table?.headerRef.props.height]);

  const searchKeys = localData.map(
    (compound: CompoundT) => compound?.searchKey
  );

  return (
    <StyledTable
      className='StyledTable'
      {...rest}
      data={localData}
      columns={_columns}
      rowClassName={_rowClassName}
      ref={tableRef}
      headerFrozenHeight={headerFrozenHeight}
      headerRenderer={({ cells, columns }) =>
        headerRenderer({
          cells,
          columns,
          selectedRowKeys,
          onSelectedRowsChange,
          searchKeys,
          hasSelectAll,
        })
      }
    />
  );
};

export default SelectableTable;

SelectableTable.defaultProps = {
  ...BaseTable.defaultProps,
  onRowSelect: noop,
  onSelectedRowsChange: noop,
};

const CheckboxContainer = styled.div`
  width: 65px;
  min-width: 65px;
  padding-left: 10px;
`;

// Define a CSS variable with the theme value
const backgroundPrimary = css`
  --background-primary: ${(p) => p.theme.palette.backgroundPrimary};
  --background-secondary: ${(p) => p.theme.palette.backgroundSecondary};
  --background-tertiary: ${(p) => p.theme.palette.backgroundTertiary};
  --accent-primary: ${(p) => p.theme.palette.accentPrimary};
  --accent-secondary: ${(p) => p.theme.palette.accentPrimary};
  --text-primary: ${(p) => p.theme.palette.textPrimary};
`;

// Apply the CSS variable to the styled component
const StyledTable = styled(BaseTable)<{ headerFrozenHeight?: number }>`
  // Rest of your styles...
  ${backgroundPrimary};
  --min-header-height: ${(p) => p.headerFrozenHeight}px;
`;
