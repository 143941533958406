import {authClient} from "@utils/api/base";
import useCognito from "@as_core/account/useCognito";
import {UserT} from "@stores/UserContext";

export type InvitedUserT = {
  authEmail: string;
  authId: string;
  userAdded: string;
}

export type AccessRepositoryT = 'readOnly' | 'readEdit' | 'fullAccess';
export const AccessLabels: {[key: string]: string} = {
  'readOnly': 'Read-only Access',
  'readEdit': 'Read & Edit Access',
  'fullAccess': 'Full Access',
  'removed': 'Access Removed'
}

export const RepositoryAccessSelectOptions: {value: AccessRepositoryT, label: string}[] = [
  {value: 'readOnly', label: AccessLabels['readOnly']},
  {value: 'readEdit', label: AccessLabels['readEdit']},
  {value: 'fullAccess', label: AccessLabels['fullAccess']},
]

export type RepositoryDetailsT = {
  _id?: string;
  name: string;
  uuid: string;
  ownerAuthEmail: string;
  ownerAuthId: string;
  active?: boolean;
  createdOn: string;
  invitedUsers?: any[];
  numberInvitedUsers?: number;
}


export type SharedRepositoryDetailsT = {
  repositoryId: string;
  accessType: AccessRepositoryT;
  sharedOn: string;
}


export type RepositoriesT = {
  current: {uuid: string, accessType: AccessRepositoryT, repo: RepositoryDetailsT};
  owned: string[];
  shared: string[];
}


// generalized ApiResponse for Invitations API Calls
type ApiResponseT<T> = {
  data: {
    errors: string[],
    data: T
  }
  status: number,
  statusText: string
}

export type UserRepositoriesT = {
  owned: RepositoryDetailsT[];
  shared: RepositoryDetailsT[];
}

export function getRepositoryAccessLabel(user:UserT, repo: RepositoryDetailsT) {
  if (user.authId === repo.ownerAuthId) return AccessLabels['fullAccess'];
  const invitedUser = repo.invitedUsers.find((u) => u.authId === user.authId);
  let accessType = 'removed';
  if (invitedUser !== undefined) {
    accessType = invitedUser.accessType;
    if (user.appInfo.subscription.type === 'basic') accessType = 'readOnly';
  }
  return AccessLabels[accessType];
}

export const Organizations = {
  get: (config: {} | {[key:string]:string}, token:string) => authClient(token).get(`/organizations`, config),
  getAll: (token:string) => authClient(token).get(`/organizations/all`),
  getUser: (token:string) => authClient(token).get(`/organizations/user`),
  getById: (token:string, repoId: string) => authClient(token).get(`/organizations/details/${repoId}`),
  activate: (orgId:string, token:string) =>
    authClient(token).post(`/organizations/${orgId}/activate`),
  create: (data: {[key:string]:any}, token:string) => authClient(token).post(`/organizations/create_by_user`, data),
  update: (token:string, orgId: string, data: {[key:string]:any}) => authClient(token).patch(`/organizations/update/${orgId}`, data),
  delete: (orgId:string, token:string) => authClient(token).delete(`/organizations/${orgId}`),
  deleteUser: (token: string, repoId: string, userId: string) =>
    authClient(token).delete(`/organizations/${repoId}/user/${userId}`),
};

const useRepos = () => {
  const { getToken } = useCognito();

  const activateRepository = async (orgId: string): Promise<RepositoriesT> => {
    let resp: ApiResponseT<RepositoriesT>;

    try {
      resp = await Organizations.activate(orgId, getToken());
    } catch (err) {
      alert(err);
      console.error('activateRepositories ERRORS(err)', err);
      return null;
    }
    if (resp.data.errors.length > 0) {
      alert(resp.data.errors);
      console.error('activateRepositories ERRORS', resp.data.errors);
    }
    return resp.data.data;
  };

  const createRepository = async (name:string):Promise<RepositoryDetailsT> => {
    let resp: any;

    try {
      resp = await Organizations.create({name: name}, getToken());
    } catch (err) {
      alert(err);
      console.error('createRepositories ERRORS(err)', resp.data.errors);
      return null;
    }
    if (resp.data.errors.length > 0) {
      alert(resp.data.errors);
      console.error('createRepositories ERRORS', resp.data.errors);
    }
    console.log('createRepositories', { ...resp.data.data });
    return resp.data.data;
  };

  const getUserRepositories = async (): Promise<UserRepositoriesT> => {
    let resp: any;
    try {
      resp = await Organizations.getUser(getToken());
    } catch (err) {
      alert(err);
      console.error('getUserRepositories ERRORS(err)', err);
      return null;
    }
    if (resp.data.errors.length > 0) {
      alert(resp.data.errors);
      console.error('getUserRepositories ERRORS', resp.data.errors);
    }
    return resp.data.data;
  }

  const getRepository = async (repoId: string): Promise<RepositoryDetailsT> => {
    let resp: any;
    try {
      resp = await Organizations.getById(getToken(), repoId);
    } catch (err) {
      alert(err);
      console.error('getRepoByAdmin ERRORS(err)', err);
      return null;
    }
    if (resp.data.errors.length > 0) {
      alert(resp.data.errors);
      console.error('getRepoByAdmin ERRORS', resp.data.errors);
    }
    return resp.data.data;
  }

  const getAllRepositories = async (): Promise<RepositoryDetailsT[]> => {
    let resp: any;
    try {
      resp = await Organizations.getAll(getToken());
    } catch (err) {
      alert(err);
      console.error('getAllRepositories ERRORS(err)', err);
      return null;
    }
    if (resp.data.errors.length > 0) {
      alert(resp.data.errors);
      console.error('getAllRepositories ERRORS', resp.data.errors);
    }
    return resp.data.data;
  }

  const getRepositories = async (
      token: string,
      params = {}
  ):Promise< {orgs: {[key:string]: RepositoryDetailsT | SharedRepositoryDetailsT }, orgIds: string[] }> => {
    //console.log('getRepositories TOKEN', token);
    let resp: any;
    const config = { params };

    try {
      resp = await Organizations.get(config, token);
    } catch (err) {
      alert(err);
      console.error('getRepositories ERRORS', err);
      return null;
    }
    if (resp.data.errors.length > 0) {
      alert(resp.data.errors);
      console.error('getRepositories ERRORS', resp.data.errors);
    }
    const newOrgs = { orgs: {}, orgIds: [] };
    resp.data.data.length &&
      resp.data.data.forEach((item:any) => {
        newOrgs.orgs[item.uuid] = item;
        newOrgs.orgIds.push(item.uuid);
      });
    return newOrgs;
  };

  const deleteRepositoryUser = async (repoId:string, user_id:string) => {
    let resp: any;
    try {
      resp = await Organizations.deleteUser(getToken(), repoId, user_id);
    } catch (err) {
      alert(err)
      console.error('deleteRepositoryUser Errors(err)', err);
      return null;
    }
    if (resp.data.errors.length > 0) {
      alert(resp.data.errors)
      console.error('deleteRepositoryUser ERRORS', resp.data.errors);
    }
    return resp.data.data;
  };

  const updateRepository = async (orgId:string, params: {[key: string]: any}) => {
    let resp: any;
    try {
      resp = await Organizations.update(getToken(), orgId, params);
    } catch (err) {
      alert(err)
      console.error('updateRepository Errors(err)', err);
      return null;
    }
    if (resp.data.errors.length > 0) {
      alert(resp.data.errors)
      console.error('updateRepository ERRORS', resp.data.errors);
    }
    return resp.data.data;
  };

  const deleteRepository = async (orgId:string) => {
    let resp: any;
    try {
      resp = await Organizations.delete(orgId, getToken());
    } catch (err) {
      alert(err);
      console.error('deleteRepository ERRORS(err)', resp.data.errors);
      return null;
    }
    if (resp.data.errors.length > 0) {
      alert(resp.data.errors);
      console.error('deleteRepository ERRORS', resp.data.errors);
    }
    return resp.data.data;
  };

  return {
    getRepositories,
    getAllRepositories,
    getUserRepositories,
    getRepository,
    createRepository,
    activateRepository,
    deleteRepositoryUser,
    updateRepository,
    deleteRepository
  };
};

export default useRepos;
