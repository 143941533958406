import styled from 'styled-components/macro';
import Tile from './Tile';

const TileView = (props) => {
  return (
    <TileViewContainer>
      {props.tiles.map((t, index) => (
        <Tile key={index} info={t} />
      ))}
    </TileViewContainer>
  );
};

export default TileView;

const TileViewContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
`;
