import React from 'react';
import styled from 'styled-components/macro';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ArrowForwardIosSharp } from '@mui/icons-material';

type Props = {
  children: React.ReactNode;
  title: string;
  icon?: any;
  fontSize?: number;
  defaultExpanded?: boolean;
  className?: any;
};

const Accordion = (props:Props) => {
  const fontSize = (props?.fontSize) ? props.fontSize : 16;

  return (
    <StyledAccordion
      defaultExpanded={props?.defaultExpanded ? props.defaultExpanded : false}
    >
      <StyledAccordionSummary
        expandIcon={<StyledArrow size={fontSize}/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <StyledSummary>
          <SummaryIcon>
            { props?.icon }
          </SummaryIcon>
          <SummaryTitle fontSize={fontSize}>
            { props?.title }
          </SummaryTitle>
        </StyledSummary>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        { props.children }
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default Accordion;


const StyledAccordion = styled(MuiAccordion)`
  width: 100%;
  &.MuiAccordion-root {
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
  }
  &.MuiAccordion-root.Mui-expanded {
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
  }
`;

const StyledArrow = styled(ArrowForwardIosSharp)<{size:number}>`
  color: ${(p) => p.theme.palette.accentSecondary };
  &.MuiSvgIcon-root {
    font-size: ${(p)=>p.size}px;
    margin-right: 5px;
    font-weight: bold;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  width: 100%;
  & .MuiAccordionSummary-content {
    margin: 3px;
    align-content: center;
  }
  & .MuiAccordionSummary-content.Mui-expanded {
    margin: 3px;
    align-content: center;
    border-bottom: 1px solid ${(p) => p.theme.palette.accentSecondary };
  }
  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  width: 95%;
  &.MuiAccordionDetails-root {
    padding: 5px;
    margin-left: 50px;
  }
`;

const StyledSummary = styled.div`
  display: flex;
  flex-direction: row;
`;

const SummaryIcon = styled.div`
  display: flex;
  margin-right: 10px;
  height: 32px;
`;

const SummaryTitle = styled.div<{fontSize: number}>`
  display: flex;
  font-size: ${(p)=>p.fontSize}px;
`;