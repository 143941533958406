import _ from 'lodash';
import styled from 'styled-components/macro';
import DisplayValue from './DisplayValue';

const SectionRow = (props) => {
  const { field_id, fields, data_key, compound, handleChange } = props;

  //console.log("SectionRow {field_id, fieldSrc}",field_id, fieldSrc);

  return (
    <Row className={props?.className} {...props}>
      <Prop>
        {/* {_.get(compound, `${fieldSrc}.${field_id}.source_id`) === userId ? (
          <User>u</User>
        ) : (
          <React.Fragment />
        )} */}
        {fields[field_id].long_name}
      </Prop>
      <Value>
        <DisplayValue
          compound={compound}
          fields={fields}
          fieldId={field_id}
          propId={data_key}
          handleChange={handleChange}
        />
      </Value>
    </Row>
  );
};

export default SectionRow;

interface ExtraProps {
  compound?: any;
  field_id?: string;
  fieldSrc?: string;
}

const Row = styled.tr<ExtraProps>`
  margin-bottom: 3px;
  border-bottom: ${(p) => p.theme.borders.thin};
  /* &:nth-child(even) {
    background-color: ${({ compound, field_id, theme, fieldSrc }) => {
    return _.get(compound, `${fieldSrc}.${field_id}.source_id`, '-') === 'new'
      ? theme.palette.accentSecondary
      : 'inherits';
  }};
  } */
  background-color: ${({ compound, field_id, theme, fieldSrc }) => {
    return _.get(compound, `${fieldSrc}.${field_id}.source_id`, '-') === 'new'
      ? theme.palette.backgroundQuaternary
      : 'inherits';
  }};
  color: ${({ compound, field_id, theme, fieldSrc }) => {
    return _.get(compound, `${fieldSrc}.${field_id}.source_id`, '-') === 'new'
      ? theme.palette.textQuaternary
      : 'inherits';
  }};
`;

const Cell = styled.td<ExtraProps>`
  padding-left: ${(p) => p.theme.sizes.xxsmall};
  vertical-align: text-top;
  font-size: ${(p) => p.theme.sizes.small};
  font-weight: ${(p) => p.theme.weights.light};
`;

const Prop = styled(Cell)`
  padding-left: ${(p) => p.theme.sizes.xxsmall};
`;

const Value = styled(Cell)`
  padding-left: ${(p) => p.theme.sizes.large};
`;
