import React, { useContext } from 'react';
import styled from "styled-components/macro";
import { StyleContext } from '@theme/AppStyles';
import {mapCountsToPositions, positionT} from "@subApps/orders/utils";

const notificationPositions: {[key: string]: positionT} = {
  'Open' : { x: -15, y: 73},
  'Scheduled': { x: 210, y: 73},
  'Completed': { x: 600, y: 73}
}

type PropsT = {
  counts: {[key:string]: number}
}

const debug = false;
const ConsultingProcessMap = (props:PropsT) => {
  const [style] = useContext(StyleContext);
  const width:number = 467.3;
  const height:number = 113.4;
  const scale = 1.25;
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentSecondary;

  // map the counts to the notification positions on the image
  const positions = mapCountsToPositions(props.counts, notificationPositions);
  debug && console.log('counts', props.counts, 'positions', positions);

  return (
    <MapContainer width={scale*width} height={scale*height}>
      { positions.map((p) => (
        <NotificationCountBubble x={p.x} y={p.y}>{p.count}</NotificationCountBubble>
      ))}
      <svg width={scale*width} height={scale*height} viewBox="0 0 467.3 113.4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={colorHighlight} d="M168.6,88.4l-16.5-9.5c-1.3-.7-2.9.2-2.9,1.6v4.1h-35c-3,0-5.4,2.4-5.4,5.4s2.4,5.4,5.4,5.4h35v4.1c0,1.5,1.6,2.4,2.9,1.6l16.5-9.5c1.3-.7,1.3-2.6,0-3.3Z"/>
        <path fill={colorHighlight} d="M347.9,88.4l-16.5-9.5c-1.3-.7-2.9.2-2.9,1.6v4.1h-35c-3,0-5.4,2.4-5.4,5.4s2.4,5.4,5.4,5.4h35v4.1c0,1.5,1.6,2.4,2.9,1.6l16.5-9.5c1.3-.7,1.3-2.6,0-3.3Z"/>
        <g>
          <path fill={colorHighlight} d="M423,28.2h1.1c.4,1.7,2,3,3.9,3s4-1.8,4-4-1.8-4-4-4-3.4,1.3-3.9,3h-1.1c-.4-1.7-2-3-3.9-3s-4,1.8-4,4,1.8,4,4,4,3.4-1.3,3.9-3ZM428,25.2c1.1,0,2,.9,2,2s-.9,2-2,2-2-.9-2-2c0-1.1.9-2,2-2ZM417.1,27.2c0-1.1.9-2,2-2s2,.9,2,2-.9,2-2,2-2-.9-2-2Z"/>
          <path fill={color} d="M442.6,51.1c-.9-1.5-2.4-2.6-4-3.1l-5.4-1.9c-1-.4-2-1-2.6-1.9-.3-.4-1-.5-1.4-.2s-.5,1-.2,1.4c.2.2.4.4.5.6l-1.2,3.5c-1.6,1.2-3.6,1.7-5.6,1.5-.6,0-1,.3-1.1.9s.3,1,.9,1.1c1.7.2,3.3,0,4.9-.6l-3.3,9.2c-.2.5,0,1.1.6,1.3.5.2,1.1,0,1.3-.6l5.3-14.9c1.2.7,1.8.7,6.7,2.5,1.2.4,2.3,1.2,3,2.3.3.5.9.6,1.4.3.5-.3.6-.9.3-1.4h0Z"/>
          <path fill={color} d="M417.6,46.1c1.1-1.1,1.8-2.5,2.1-4.1,8.4,2.6,17.2-3.7,17.2-12.7v-8.2c0-3.9-3.1-7-7-7h-12.7c-3.9,0-7,3.1-7,7v8c-1.4-.4-2.5-1.4-2.9-2.8-.4-1.5-.6-3-.6-4.6.2-22.3,33.5-22.2,33.7,0,0,1.6-.2,3.1-.6,4.6-.1.5.2,1.1.7,1.2.5.1,1.1-.2,1.2-.7.4-1.7.7-3.4.6-5.1-.3-24.8-37.5-24.9-37.7,0,0,1.7.2,3.5.6,5.1.6,2.3,2.6,4.1,5,4.4.4,2.5,1.4,4.9,3.1,6.8.4.4,1,.5,1.4.1.4-.4.5-1,.1-1.4-1.8-2.1-2.8-4.7-2.8-7.4v-8.1c0-2.8,2.2-5,5-5h12.7c2.8,0,5,2.2,5,5v8.1c0,8.3-8.5,13.6-15.8,10.5-.8-.3-1.6.4-1.3,1.3-.1,2.3-1.7,4.4-3.9,5.1l-5.4,1.9c-3.2,1.1-5.2,4-5.2,7.3v6.7c0,.6.4,1,1,1s1-.4,1-1v-6.7c0-2.5,1.6-4.7,3.9-5.4h0s-.1,0,2.5-.9l-.3,9.2c0,.3,0,.5.3.7l3.5,3.7c.4.4,1,.4,1.4,0,.4-.4.4-1,0-1.4l-3.2-3.4.3-9.6c.9-.3,1.4-.5,2.2-.9l5.3,14.9c.2.5.8.8,1.3.6.5-.2.8-.8.6-1.3l-5.5-15.6Z"/>
          <path fill={color} d="M434.5,51.6c-.6,0-1,.5-1,1l.2,5.2-3.2,3.4c-.4.4-.4,1,0,1.4.4.4,1,.4,1.4,0l3.5-3.7c.2-.2.3-.4.3-.7l-.2-5.6c0-.6-.5-1-1-1h0Z"/>
          <path fill={colorHighlight} d="M462.2,54.2l-5.7-7.5v-8.7h.4c.6,0,1-.4,1-1s-.4-1-1-1h-8c-.6,0-1,.4-1,1s.4,1,1,1h5.6v9c0,.2,0,.4.2.6l5.9,7.8c1.8,2.3.1,5.6-2.8,5.6h-10c-2.9,0-4.6-3.3-2.8-5.6l2.5-3.3c2,.8,3.5.7,5.1-.1.5-.3.7-.9.4-1.3-.3-.5-.9-.7-1.3-.4-.7.4-1.5.7-2.9.2l2.2-2.8c.1-.2.2-.4.2-.6v-4.7c0-.6-.4-1-1-1s-1,.4-1,1v4.4l-5.7,7.5c-2.8,3.6-.2,8.8,4.4,8.8h10c4.5,0,7.1-5.2,4.3-8.8h0Z"/>
        </g>
        <g>
          <rect stroke={colorHighlight} strokeWidth={2} x="4.5" y="71.1" width="90" height="37.8" rx="11.4" ry="11.4"/>
          <g>
            <path fill={color} d="M24.8,83.7s.9.9,2.1.9,1.2-.3,1.2-1c0-1.5-4-1.3-4-3.9s1.2-2.5,2.9-2.5,2.5.9,2.5.9l-.7,1.3s-.8-.7-1.9-.7-1.3.4-1.3,1c0,1.5,4,1.1,4,3.9s-1,2.5-2.8,2.5-2.9-1.2-2.9-1.2l.8-1.2Z"/>
            <path fill={color} d="M33.9,79.6c1.6,0,2.4.9,2.4.9l-.7,1s-.6-.7-1.6-.7-1.9.9-1.9,1.9.7,1.9,1.9,1.9,1.8-.8,1.8-.8l.6,1.1s-.9,1-2.5,1-3.3-1.4-3.3-3.2,1.3-3.2,3.3-3.2Z"/>
            <path fill={color} d="M37.6,77.4h1.5v3c0,.3,0,.5,0,.5h0c.3-.6,1-1.2,2.1-1.2s2.1.7,2.1,2.3v3.9h-1.5v-3.6c0-.7-.2-1.2-1-1.2s-1.4.5-1.7,1.3c0,.2,0,.5,0,.8v2.8h-1.5v-8.5Z"/>
            <path fill={color} d="M47.6,79.6c1.8,0,2.7,1.3,2.7,2.9s0,.6,0,.6h-4.2c0,1.1.9,1.6,1.8,1.6s1.8-.7,1.8-.7l.6,1.1s-.9.9-2.5.9-3.3-1.5-3.3-3.2,1.3-3.2,3.1-3.2ZM48.8,82.1c0-.8-.5-1.3-1.2-1.3s-1.3.5-1.5,1.3h2.7Z"/>
            <path fill={color} d="M53.9,79.6c1.3,0,1.8.8,1.8.8h0s0-.2,0-.3v-2.7h1.5v8.5h-1.4v-.5c0-.2,0-.4,0-.4h0s-.5,1-1.9,1-2.7-1.3-2.7-3.2,1.2-3.2,2.8-3.2ZM54.2,84.7c.8,0,1.5-.6,1.5-1.9s-.5-1.9-1.5-1.9-1.5.7-1.5,1.9.6,1.9,1.5,1.9Z"/>
            <path fill={color} d="M58.8,79.8h1.5v3.6c0,.7.2,1.2.9,1.2,1.1,0,1.7-1,1.7-2.1v-2.8h1.5v6.1h-1.5v-.7c0-.2,0-.4,0-.4h0c-.3.6-1.1,1.3-2.1,1.3s-2.1-.7-2.1-2.3v-3.9Z"/>
            <path fill={color} d="M66.1,77.4h1.5v6.4c0,.7.2.8.6.8s.2,0,.2,0v1.3s-.2,0-.5,0c-.8,0-1.9-.2-1.9-2v-6.6Z"/>
            <path fill={color} d="M72.3,79.6c1.8,0,2.7,1.3,2.7,2.9s0,.6,0,.6h-4.2c0,1.1.9,1.6,1.8,1.6s1.8-.7,1.8-.7l.6,1.1s-.9.9-2.5.9-3.3-1.5-3.3-3.2,1.3-3.2,3.1-3.2ZM73.5,82.1c0-.8-.5-1.3-1.2-1.3s-1.3.5-1.5,1.3h2.7Z"/>
            <path fill={color} d="M29.4,91.8h1.7l1.7,4.2c.2.5.4,1.2.4,1.2h0s.2-.7.4-1.2l1.7-4.2h1.7l.7,8.5h-1.5l-.4-4.8c0-.6,0-1.3,0-1.3h0s-.3.8-.5,1.3l-1.4,3.3h-1.4l-1.4-3.3c-.2-.5-.5-1.3-.5-1.3h0s0,.8,0,1.3l-.4,4.8h-1.5l.7-8.5Z"/>
            <path fill={color} d="M41.9,94c1.8,0,2.7,1.3,2.7,2.9s0,.6,0,.6h-4.2c0,1.1.9,1.6,1.8,1.6s1.8-.7,1.8-.7l.6,1.1s-.9.9-2.5.9-3.3-1.5-3.3-3.2,1.3-3.2,3.1-3.2ZM43.1,96.5c0-.8-.5-1.3-1.2-1.3s-1.3.5-1.5,1.3h2.7Z"/>
            <path fill={color} d="M48.6,94c1.8,0,2.7,1.3,2.7,2.9s0,.6,0,.6h-4.2c0,1.1.9,1.6,1.8,1.6s1.8-.7,1.8-.7l.6,1.1s-.9.9-2.5.9-3.3-1.5-3.3-3.2,1.3-3.2,3.1-3.2ZM49.7,96.5c0-.8-.5-1.3-1.2-1.3s-1.3.5-1.5,1.3h2.7Z"/>
            <path fill={color} d="M52.8,95.5h-.8v-1.2h.8v-1.8h1.5v1.8h1.4v1.2h-1.4v2.4c0,1,.8,1.2,1.2,1.2s.3,0,.3,0v1.3s-.2,0-.5,0c-.9,0-2.5-.3-2.5-2.3v-2.6Z"/>
            <path fill={color} d="M57.1,91.8h1.5v1.4h-1.5v-1.4ZM57.1,94.2h1.5v6.1h-1.5v-6.1Z"/>
            <path fill={color} d="M60.3,94.2h1.5v.7c0,.2,0,.4,0,.4h0c.3-.5.9-1.3,2.2-1.3s2.1.7,2.1,2.3v3.9h-1.5v-3.6c0-.7-.2-1.2-1-1.2s-1.4.5-1.6,1.2c0,.3-.1.5-.1.8v2.8h-1.5v-6.1Z"/>
            <path fill={color} d="M69.9,101.5c.9,0,1.7-.4,1.7-1.5v-.4c0-.1,0-.3,0-.3h0c-.4.5-.9.8-1.7.8-1.8,0-2.7-1.4-2.7-3.1s.9-3,2.7-3,1.8.8,1.8.8h0s0,0,0-.2v-.5h1.4v5.7c0,2.1-1.6,2.9-3.2,2.9s-1.6-.2-2.2-.5l.5-1.2s.8.4,1.7.4ZM71.7,97.1c0-1.4-.7-1.8-1.5-1.8s-1.4.7-1.4,1.7.6,1.9,1.5,1.9,1.4-.4,1.4-1.8Z"/>
          </g>
        </g>
        <g>
          <path fill={color} d="M79.9,6.2h-2.2v-1.2c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5,1.5v1.2h-7.2v-1.2c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5,1.5v1.2h-7.2v-1.2c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5,1.5v1.2h-7.2v-1.2c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5,1.5v1.2h-7.2v-1.2c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5,1.5v1.2h-7.2v-1.2c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5,1.5v1.2h-2.2c-6.5,0-11.8,5.3-11.8,11.8v34.4c0,6.5,5.3,11.8,11.8,11.8h58.4c6.5,0,11.8-5.3,11.8-11.8V18c0-6.5-5.3-11.8-11.8-11.8ZM88.7,52.4c0,4.8-3.9,8.8-8.8,8.8H21.5c-4.8,0-8.8-3.9-8.8-8.8v-30.7h76v30.7ZM88.7,18.7H12.7v-.7c0-4.8,3.9-8.8,8.8-8.8h2.2v1.8c0,.8.7,1.5,1.5,1.5s1.5-.7,1.5-1.5v-1.8h7.2v1.8c0,.8.7,1.5,1.5,1.5s1.5-.7,1.5-1.5v-1.8h7.2v1.8c0,.8.7,1.5,1.5,1.5s1.5-.7,1.5-1.5v-1.8h7.2v1.8c0,.8.7,1.5,1.5,1.5s1.5-.7,1.5-1.5v-1.8h7.2v1.8c0,.8.7,1.5,1.5,1.5s1.5-.7,1.5-1.5v-1.8h7.2v1.8c0,.8.7,1.5,1.5,1.5s1.5-.7,1.5-1.5v-1.8h2.2c4.8,0,8.8,3.9,8.8,8.8v.7Z"/>
          <g>
            <rect fill={colorHighlight} x="17.5" y="33.3" width="5" height="5"/>
            <rect fill={colorHighlight} x="27.8" y="33.3" width="5" height="5"/>
            <rect fill={colorHighlight} x="38.1" y="33.3" width="5" height="5"/>
            <rect fill={colorHighlight} x="48.5" y="33.3" width="5" height="5"/>
            <rect fill={colorHighlight} x="58.8" y="33.3" width="5" height="5"/>
            <rect fill={colorHighlight} x="69.1" y="33.3" width="5" height="5"/>
            <rect fill={colorHighlight} x="79.5" y="33.3" width="5" height="5"/>
          </g>
          <g>
            <rect fill={colorHighlight} x="17.6" y="25" width="5" height="5"/>
            <rect fill={colorHighlight} x="27.9" y="25" width="5" height="5"/>
            <rect fill={colorHighlight} x="38.2" y="25" width="5" height="5"/>
            <rect fill={colorHighlight} x="48.6" y="25" width="5" height="5"/>
            <rect fill={colorHighlight} x="58.9" y="25" width="5" height="5"/>
            <rect fill={colorHighlight} x="69.2" y="25" width="5" height="5"/>
            <rect fill={colorHighlight} x="79.6" y="25" width="5" height="5"/>
          </g>
          <g>
            <rect fill={colorHighlight} x="17.6" y="41.7" width="5" height="5"/>
            <rect fill={colorHighlight} x="27.9" y="41.7" width="5" height="5"/>
            <rect fill={colorHighlight} x="38.2" y="41.7" width="5" height="5"/>
            <rect fill={colorHighlight} x="48.6" y="41.7" width="5" height="5"/>
            <rect fill={colorHighlight} x="58.9" y="41.7" width="5" height="5"/>
            <rect fill={colorHighlight} x="69.2" y="41.7" width="5" height="5"/>
            <rect fill={colorHighlight} x="79.6" y="41.7" width="5" height="5"/>
          </g>
          <g>
            <rect fill={colorHighlight} x="17.6" y="50" width="5" height="5"/>
            <rect fill={colorHighlight} x="27.9" y="50" width="5" height="5"/>
            <rect fill={colorHighlight} x="38.2" y="50" width="5" height="5"/>
            <rect fill={colorHighlight} x="48.6" y="50" width="5" height="5"/>
            <rect fill={colorHighlight} x="58.9" y="50" width="5" height="5"/>
            <rect fill={colorHighlight} x="69.2" y="50" width="5" height="5"/>
            <rect fill={colorHighlight} x="79.6" y="50" width="5" height="5"/>
          </g>
        </g>
        <g>
          <rect stroke={colorHighlight} strokeWidth={2} x="183.8" y="71.1" width="90" height="37.8" rx="11.4" ry="11.4"/>
          <g>
            <path fill={color} d="M200.7,92.1s.9.9,2.1.9,1.2-.3,1.2-1c0-1.5-4-1.3-4-3.9s1.2-2.5,2.9-2.5,2.5.9,2.5.9l-.7,1.3s-.8-.7-1.9-.7-1.3.4-1.3,1c0,1.5,4,1.1,4,3.9s-1,2.5-2.8,2.5-2.9-1.2-2.9-1.2l.8-1.2Z"/>
            <path fill={color} d="M209.8,88c1.6,0,2.4.9,2.4.9l-.7,1s-.6-.7-1.6-.7-1.9.9-1.9,1.9.7,1.9,1.9,1.9,1.8-.8,1.8-.8l.6,1.1s-.9,1-2.5,1-3.3-1.4-3.3-3.2,1.3-3.2,3.3-3.2Z"/>
            <path fill={color} d="M213.5,85.8h1.5v3c0,.3,0,.5,0,.5h0c.3-.6,1-1.2,2.1-1.2s2.1.7,2.1,2.3v3.9h-1.5v-3.6c0-.7-.2-1.2-1-1.2s-1.4.5-1.7,1.3c0,.2,0,.5,0,.8v2.8h-1.5v-8.5Z"/>
            <path fill={color} d="M223.5,88c1.8,0,2.7,1.3,2.7,2.9s0,.6,0,.6h-4.2c0,1.1.9,1.6,1.8,1.6s1.8-.7,1.8-.7l.6,1.1s-.9.9-2.5.9-3.3-1.5-3.3-3.2,1.3-3.2,3.1-3.2ZM224.7,90.5c0-.8-.5-1.3-1.2-1.3s-1.3.5-1.5,1.3h2.7Z"/>
            <path fill={color} d="M229.8,88c1.3,0,1.8.8,1.8.8h0s0-.2,0-.3v-2.7h1.5v8.5h-1.4v-.5c0-.2,0-.4,0-.4h0s-.5,1-1.9,1-2.7-1.3-2.7-3.2,1.2-3.2,2.8-3.2ZM230.1,93.1c.8,0,1.5-.6,1.5-1.9s-.5-1.9-1.5-1.9-1.5.7-1.5,1.9.6,1.9,1.5,1.9Z"/>
            <path fill={color} d="M234.7,88.2h1.5v3.6c0,.7.2,1.2.9,1.2,1.1,0,1.7-1,1.7-2.1v-2.8h1.5v6.1h-1.5v-.7c0-.2,0-.4,0-.4h0c-.3.6-1.1,1.3-2.1,1.3s-2.1-.7-2.1-2.3v-3.9Z"/>
            <path fill={color} d="M242,85.8h1.5v6.4c0,.7.2.8.6.8s.2,0,.2,0v1.3s-.2,0-.5,0c-.8,0-1.9-.2-1.9-2v-6.6Z"/>
            <path fill={color} d="M248.2,88c1.8,0,2.7,1.3,2.7,2.9s0,.6,0,.6h-4.2c0,1.1.9,1.6,1.8,1.6s1.8-.7,1.8-.7l.6,1.1s-.9.9-2.5.9-3.3-1.5-3.3-3.2,1.3-3.2,3.1-3.2ZM249.4,90.5c0-.8-.5-1.3-1.2-1.3s-1.3.5-1.5,1.3h2.7Z"/>
            <path fill={color} d="M254.5,88c1.3,0,1.8.8,1.8.8h0s0-.2,0-.3v-2.7h1.5v8.5h-1.4v-.5c0-.2,0-.4,0-.4h0s-.5,1-1.9,1-2.7-1.3-2.7-3.2,1.2-3.2,2.8-3.2ZM254.8,93.1c.8,0,1.5-.6,1.5-1.9s-.5-1.9-1.5-1.9-1.5.7-1.5,1.9.6,1.9,1.5,1.9Z"/>
          </g>
        </g>
        <g>
          <rect stroke={colorHighlight} strokeWidth={2} x="363.2" y="71.1" width="90" height="37.8" rx="11.4" ry="11.4"/>
          <g>
            <path fill={color} d="M382.1,77.7c2.1,0,3.1,1.2,3.1,1.2l-.7,1.2s-1-.9-2.3-.9-2.9,1.3-2.9,2.9,1.1,3,2.9,3,2.5-1.1,2.5-1.1l.8,1.1s-1.2,1.4-3.3,1.4-4.4-1.9-4.4-4.4,1.9-4.4,4.4-4.4Z"/>
            <path fill={color} d="M389.6,80.1c1.9,0,3.4,1.3,3.4,3.2s-1.5,3.2-3.4,3.2-3.4-1.3-3.4-3.2,1.5-3.2,3.4-3.2ZM389.6,85.2c1,0,1.8-.8,1.8-1.9s-.8-1.9-1.8-1.9-1.8.8-1.8,1.9.8,1.9,1.8,1.9Z"/>
            <path fill={color} d="M394.2,80.2h1.5v.7c0,.2,0,.4,0,.4h0c.3-.5.9-1.3,2.2-1.3s2.1.7,2.1,2.3v3.9h-1.5v-3.6c0-.7-.2-1.2-1-1.2s-1.4.5-1.6,1.2c0,.3-.1.5-.1.8v2.8h-1.5v-6.1Z"/>
            <path fill={color} d="M401.7,84.5s.7.7,1.7.7.8-.2.8-.6c0-.9-3-.8-3-2.8s1-1.8,2.3-1.8,2.1.7,2.1.7l-.6,1.1s-.6-.5-1.5-.5-.8.2-.8.6c0,.9,3,.8,3,2.7s-.9,1.8-2.3,1.8-2.4-.9-2.4-.9l.7-1.1Z"/>
            <path fill={color} d="M406.9,80.2h1.5v3.6c0,.7.2,1.2.9,1.2,1.1,0,1.7-1,1.7-2.1v-2.8h1.5v6.1h-1.5v-.7c0-.2,0-.4,0-.4h0c-.3.6-1.1,1.3-2.1,1.3s-2.1-.7-2.1-2.3v-3.9Z"/>
            <path fill={color} d="M414.2,77.8h1.5v6.4c0,.7.2.8.6.8s.2,0,.2,0v1.3s-.2,0-.5,0c-.8,0-1.9-.2-1.9-2v-6.6Z"/>
            <path fill={color} d="M417.9,81.5h-.8v-1.2h.8v-1.8h1.5v1.8h1.4v1.2h-1.4v2.4c0,1,.8,1.2,1.2,1.2s.3,0,.3,0v1.3s-.2,0-.5,0c-.9,0-2.5-.3-2.5-2.3v-2.6Z"/>
            <path fill={color} d="M422.2,77.8h1.5v1.4h-1.5v-1.4ZM422.2,80.2h1.5v6.1h-1.5v-6.1Z"/>
            <path fill={color} d="M425.4,80.2h1.5v.7c0,.2,0,.4,0,.4h0c.3-.5.9-1.3,2.2-1.3s2.1.7,2.1,2.3v3.9h-1.5v-3.6c0-.7-.2-1.2-1-1.2s-1.4.5-1.6,1.2c0,.3-.1.5-.1.8v2.8h-1.5v-6.1Z"/>
            <path fill={color} d="M435,87.6c.9,0,1.7-.4,1.7-1.5v-.4c0-.1,0-.3,0-.3h0c-.4.5-.9.8-1.7.8-1.8,0-2.7-1.4-2.7-3.1s.9-3,2.7-3,1.8.8,1.8.8h0s0,0,0-.2v-.5h1.4v5.7c0,2.1-1.6,2.9-3.2,2.9s-1.6-.2-2.2-.5l.5-1.2s.8.4,1.7.4ZM436.8,83.1c0-1.4-.7-1.8-1.5-1.8s-1.4.7-1.4,1.7.6,1.9,1.5,1.9,1.4-.4,1.4-1.8Z"/>
            <path fill={color} d="M388.4,98.6s.9.9,2.1.9,1.2-.3,1.2-1c0-1.5-4-1.3-4-3.9s1.2-2.5,2.9-2.5,2.5.9,2.5.9l-.7,1.3s-.8-.7-1.9-.7-1.3.4-1.3,1c0,1.5,4,1.1,4,3.9s-1,2.5-2.8,2.5-2.9-1.2-2.9-1.2l.8-1.2Z"/>
            <path fill={color} d="M397.2,94.5c1.8,0,2.7,1.3,2.7,2.9s0,.6,0,.6h-4.2c0,1.1.9,1.6,1.8,1.6s1.8-.7,1.8-.7l.6,1.1s-.9.9-2.5.9-3.3-1.5-3.3-3.2,1.3-3.2,3.1-3.2ZM398.4,96.9c0-.8-.5-1.3-1.2-1.3s-1.3.5-1.5,1.3h2.7Z"/>
            <path fill={color} d="M401.4,98.9s.7.7,1.7.7.8-.2.8-.6c0-.9-3-.8-3-2.8s1-1.8,2.3-1.8,2.1.7,2.1.7l-.6,1.1s-.6-.5-1.5-.5-.8.2-.8.6c0,.9,3,.8,3,2.7s-.9,1.8-2.3,1.8-2.4-.9-2.4-.9l.7-1.1Z"/>
            <path fill={color} d="M406.8,98.9s.7.7,1.7.7.8-.2.8-.6c0-.9-3-.8-3-2.8s1-1.8,2.3-1.8,2.1.7,2.1.7l-.6,1.1s-.6-.5-1.5-.5-.8.2-.8.6c0,.9,3,.8,3,2.7s-.9,1.8-2.3,1.8-2.4-.9-2.4-.9l.7-1.1Z"/>
            <path fill={color} d="M412.1,92.2h1.5v1.4h-1.5v-1.4ZM412.1,94.6h1.5v6.1h-1.5v-6.1Z"/>
            <path fill={color} d="M418.2,94.5c1.9,0,3.4,1.3,3.4,3.2s-1.5,3.2-3.4,3.2-3.4-1.3-3.4-3.2,1.5-3.2,3.4-3.2ZM418.2,99.6c1,0,1.8-.8,1.8-1.9s-.8-1.9-1.8-1.9-1.8.8-1.8,1.9.8,1.9,1.8,1.9Z"/>
            <path fill={color} d="M422.8,94.6h1.5v.7c0,.2,0,.4,0,.4h0c.3-.5.9-1.3,2.2-1.3s2.1.7,2.1,2.3v3.9h-1.5v-3.6c0-.7-.2-1.2-1-1.2s-1.4.5-1.6,1.2c0,.3-.1.5-.1.8v2.8h-1.5v-6.1Z"/>
          </g>
        </g>
        <path fill={colorHighlight} d="M371.5,44.1c-.6,0-1.1-.2-1.6-.6-.8-.7-1.1-1.8-.7-2.8l1.3-3.1c-1.7-.8-2.9-2.6-2.9-4.7v-11.6c0-2.9,2.3-5.2,5.2-5.2h20.5c2.9,0,5.2,2.3,5.2,5.2v11.6c0,2.9-2.3,5.2-5.2,5.2h-11l-9.5,5.6c-.4.2-.8.3-1.3.3ZM372.8,19.5c-1,0-1.8.8-1.8,1.8v11.6c0,1,.8,1.8,1.8,1.8h2.5l-1.9,4.7,8-4.7h11.9c1,0,1.8-.8,1.8-1.8v-11.6c0-1-.8-1.8-1.8-1.8h-20.5Z"/>
        <path fill={color} d="M378.2,44.9v-3.1l-3.4,2v1.1c0,1-.8,1.8-1.8,1.8h-2.5l1.9,4.7-8-4.7h-11.9c-1,0-1.8-.8-1.8-1.8v-11.6c0-1,.8-1.8,1.8-1.8h13.9v-3.4h-13.9c-2.9,0-5.2,2.3-5.2,5.2v11.6c0,2.9,2.3,5.2,5.2,5.2h11l9.5,5.6c.4.2.8.3,1.3.3s1.1-.2,1.6-.6c.8-.7,1.1-1.8.7-2.8l-1.3-3.1c1.7-.8,2.9-2.6,2.9-4.6Z"/>
        <g>
          <circle fill={color} cx="354.6" cy="39.1" r="1.7"/>
          <circle fill={color} cx="360.4" cy="39.1" r="1.7"/>
          <circle fill={color} cx="366.3" cy="39.1" r="1.7"/>
        </g>
        <g>
          <circle fill={colorHighlight} cx="377.8" cy="27.1" r="1.7"/>
          <circle fill={colorHighlight} cx="383.6" cy="27.1" r="1.7"/>
          <circle fill={colorHighlight} cx="389.4" cy="27.1" r="1.7"/>
        </g>
        <g>
          <path fill={color} d="M260.7,6.3h-2.2v-1.2c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5,1.5v1.2h-7.2v-1.2c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5,1.5v1.2h-7.2v-1.2c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5,1.5v1.2h-7.2v-1.2c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5,1.5v1.2h-7.2v-1.2c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5,1.5v1.2h-7.2v-1.2c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5,1.5v1.2h-2.2c-6.5,0-11.8,5.3-11.8,11.8v34.4c0,6.5,5.3,11.8,11.8,11.8h58.4c6.5,0,11.8-5.3,11.8-11.8V18.1c0-6.5-5.3-11.8-11.8-11.8ZM269.5,52.5c0,4.8-3.9,8.8-8.8,8.8h-58.4c-4.8,0-8.8-3.9-8.8-8.8v-30.7h76v30.7ZM269.5,18.8h-76v-.7c0-4.8,3.9-8.8,8.8-8.8h2.2v1.8c0,.8.7,1.5,1.5,1.5s1.5-.7,1.5-1.5v-1.8h7.2v1.8c0,.8.7,1.5,1.5,1.5s1.5-.7,1.5-1.5v-1.8h7.2v1.8c0,.8.7,1.5,1.5,1.5s1.5-.7,1.5-1.5v-1.8h7.2v1.8c0,.8.7,1.5,1.5,1.5s1.5-.7,1.5-1.5v-1.8h7.2v1.8c0,.8.7,1.5,1.5,1.5s1.5-.7,1.5-1.5v-1.8h7.2v1.8c0,.8.7,1.5,1.5,1.5s1.5-.7,1.5-1.5v-1.8h2.2c4.8,0,8.8,3.9,8.8,8.8v.7Z"/>
          <g>
            <rect stroke={color} strokeWidth={1} x="198.2" y="33.5" width="5" height="5"/>
            <rect stroke={color} strokeWidth={1} x="208.5" y="33.5" width="5" height="5"/>
            <rect stroke={color} strokeWidth={1} x="218.9" y="33.5" width="5" height="5"/>
            <rect stroke={color} strokeWidth={1} x="229.2" y="33.5" width="5" height="5"/>
            <rect stroke={color} strokeWidth={1} x="239.5" y="33.5" width="5" height="5"/>
            <rect stroke={color} strokeWidth={1} x="249.9" y="33.5" width="5" height="5"/>
            <rect stroke={color} strokeWidth={1} x="260.2" y="33.5" width="5" height="5"/>
          </g>
          <g>
            <rect stroke={color} strokeWidth={1} x="198.3" y="25.1" width="5" height="5"/>
            <rect stroke={color} strokeWidth={1} x="208.7" y="25.1" width="5" height="5"/>
            <rect stroke={color} strokeWidth={1} x="219" y="25.1" width="5" height="5"/>
            <rect stroke={color} strokeWidth={1} x="229.3" y="25.1" width="5" height="5"/>
            <rect stroke={color} strokeWidth={1} x="239.7" y="25.1" width="5" height="5"/>
            <rect stroke={color} strokeWidth={1} x="250" y="25.1" width="5" height="5"/>
            <rect stroke={color} strokeWidth={1} x="260.3" y="25.1" width="5" height="5"/>
          </g>
          <rect stroke={color} strokeWidth={1} x="198.3" y="41.8" width="5" height="5"/>
          <rect stroke={color} strokeWidth={1} x="208.7" y="41.8" width="5" height="5"/>
          <rect stroke={color} strokeWidth={1} x="219" y="41.8" width="5" height="5"/>
          <rect stroke={color} strokeWidth={1} x="229.3" y="41.8" width="5" height="5"/>
          <rect fill={colorHighlight} x="237.2" y="39.3" width="10" height="10"/>
          <rect stroke={color} strokeWidth={1} x="250" y="41.8" width="5" height="5"/>
          <rect stroke={color} strokeWidth={1} x="260.3" y="41.8" width="5" height="5"/>
          <g>
            <rect stroke={color} strokeWidth={1} x="198.3" y="50.1" width="5" height="5"/>
            <rect stroke={color} strokeWidth={1} x="208.7" y="50.1" width="5" height="5"/>
            <rect stroke={color} strokeWidth={1} x="219" y="50.1" width="5" height="5"/>
            <rect stroke={color} strokeWidth={1} x="229.3" y="50.1" width="5" height="5"/>
            <rect stroke={color} strokeWidth={1} x="239.7" y="50.1" width="5" height="5"/>
            <rect stroke={color} strokeWidth={1} x="250" y="50.1" width="5" height="5"/>
            <rect stroke={color} strokeWidth={1} x="260.3" y="50.1" width="5" height="5"/>
          </g>
        </g>
      </svg>
    </MapContainer>
  )
}

export default ConsultingProcessMap;

const MapContainer = styled.div<{width: number, height: number}>`
  display: flex;
  position: relative;
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  margin: 10px 0;
`;

const NotificationCountBubble = styled.div<{x:number, y:number}>`
  position: absolute;
  font-size: 16px;
  z-index: 1;
  top: ${(p)=>p.y}px;
  left: ${(p)=>p.x}px;
  border-radius: 50%;
  color: ${(props) => props.theme.palette.textPrimary };
  background-color:  ${(props) => props.theme.palette.accentPrimary };
  border: 2px solid ${(p)=>p.theme.palette.backgroundSecondary};
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;