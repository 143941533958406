import React, { useEffect, useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import useCompounds from '../useCompounds';
import SimilarityCard from './SimilarityCard';
import MagnifiedSvgImage from '../images/MagnifiedSvgImage';

const NeighborsLoader = ({ height, neighbors }) => {
  return (
    <React.Fragment>
      <MagnifiedSvgImage imageOffset={100} offsetRight={false} />
      <Container height={height}>
        {neighbors.map((neighbor, idx) => (
          <SimilarityCard key={idx} neighbor={neighbor} />
        ))}
      </Container>
    </React.Fragment>
  );
};

export default NeighborsLoader;

const Container = styled.div<{ height: number }>`
  display: flex;
  flex-direction: column;
  height: ${(p) => p.height - 60}px;
  overflow-y: scroll;
  & ::-webkit-scrollbar {
    display: none;
  }
`;
