import React, {useState, useEffect, useContext} from 'react';
import {useNavigate} from "react-router-dom";
import styled from 'styled-components';
import {FlexItem} from "@components/layout/FlexStyles";
import {TextRegularBold} from "@components/elements/TextStyles";
import {CreateOptionsT} from "@components/universe/types";
import UploadIcon from "@components/icons/upload.icon";
import FavoritesIcon from "@components/icons/favorites.icon";
import MyCompoundsIcon from "@components/icons/myCompounds.icon";
import Card from "./Card";
import {UserContext} from "@stores/UserContext";
import {getUserSubscriptionType} from "@utils/useUserAppInfo";


const createOptions:CreateOptionsT[] = [
  { value: 'upload', label: 'Upload a Data File',
    icon: <UploadIcon size={36} />,
    description: 'Upload a data file containing all the available biological'
     + ' biological data to be able to create a single unique Compound Universe'
      + ' Select Type: "Target Panel (kinome)"',
    subscriptions: ['pilot']
  },
  { value: 'favorites', label: 'Select Favorites List',
    icon: <FavoritesIcon size={36} />,
    description: 'Select a saved favorites set to create'
        + ' either an independent Universe or use a AsedaSciences '
        + ' reference universe if AsedaSciences biological data is available'
        + ' or just using compound structures',
    subscriptions: ['pilot']
  },
  { value: 'user', label: 'Select Individual Compounds',
    icon: <MyCompoundsIcon size={36} />,
    description: 'Manually select compounds from your uploaded compounds ' +
        + ' to create either an independent Universe or against an AsedaSciences '
        + ' reference universe if AsedaSciences biological data is available'
        + ' or just using compound structures',
    subscriptions: ['pilot2']
  },
]

const debug = false;
export const UniverseCreate = () => {
  const navigate = useNavigate();
  const [userCreateOptions, setUserCreateOptions] = useState<Array<CreateOptionsT>>([]);
  const { user } = useContext(UserContext);
  const userSubscriptionType = getUserSubscriptionType(user.appInfo);

  useEffect(()=> {
    const options = createOptions.filter((i) =>
      !i?.subscriptions || i?.subscriptions.includes(userSubscriptionType));
    setUserCreateOptions(options);
  }, [])

  // HANDLERS
  function handleClick(value:string) {
    debug && console.log('handleClick | value', value);
    if (value === 'upload') {
      navigate('/import/upload');
    } else {
      alert('Create of type ' + value + ' not implemented yet.');
    }
  }

  return (
    <UniverseCreateContainer>
      <FlexItem><TextRegularBold color={'accentSecondary'}>Create a New Universe</TextRegularBold></FlexItem>
      <Cards>{
        userCreateOptions.map((c) => (
          <Card card={c} onClick={handleClick} />
        ))
      }
      </Cards>
    </UniverseCreateContainer>
  );
};

const UniverseCreateContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  height: max-content;
  width: calc(100vw - 50px);
`;

const Cards = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`;
