import { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const PushPinIcon = ({
  size = 24,
  color = 'default',
  active = false,
  onClick = () => {},
  ...props
}) => {
  const [style] = useContext(StyleContext);
  const fillColor = color !== 'default' ? color : style.palette.accentPrimary;
  if (active) {
    color = style.palette.accentPrimary;
  }

  return (
    <svg
      {...props}
      onClick={onClick}
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path
          fill={fillColor}
          d='M19.4,6L14,0.6c-0.9-0.9-2.3-0.8-3.1,0s-0.9,2.2,0,3.1l0.4,0.4L8.1,7.3C5.3,6.3,2.2,7,0.2,9.1
	c-0.2,0.2-0.2,0.6,0,0.8l4.5,4.5L0.9,18l0,0L0,20l1.9-0.9l0,0l3.7-3.7l4.5,4.5c0.2,0.2,0.5,0.2,0.8,0c2.1-2.1,2.8-5.3,1.8-7.9
	l3.2-3.2l0.4,0.4c0.9,0.9,2.3,0.8,3.1,0C20.2,8.2,20.2,6.9,19.4,6z'
        />
      </g>
    </svg>
  );
};

export default PushPinIcon;
