import styled from 'styled-components/macro';
import RepoMessages, {MessageItem} from "@as_core/elements/UserMessages";
import MenuButton from "@components/elements/MenuButton";
import BackArrowIcon from "@components/icons/backArrow.icon";
import {RepositoryDetailsT} from "@subApps/account/repositoryManagement/useRepos";
import LabeledContainer from "@as_core/elements/LabeledContainer";
import ObjectDetails, {FieldsTypeT} from "@subApps/admin/components/ObjectDetails";

export const RepoFields: FieldsTypeT[] = [
  { label: 'Repository ID', value: 'uuid' },
  { label: 'Repository Name', value: 'name' },
  { label: 'Owner Email', value: 'ownerAuthEmail' },
  { label: 'Created On', value: 'createdOn', type: 'datetime' },
  { label: 'Invited Users', value: 'invitedUsers', type: 'invitedUsers' },
];

interface RepoDetailI {
  repo: RepositoryDetailsT;
  backToList: ()=> void;
}

const debug = false;
const RepoDetail = (props: RepoDetailI) => {

  debug && console.log('RepoDetail | repo', props.repo);

  return(
    <RepoContainer>
      <Header>
        <MenuButton
          text={'Back to repository list'}
          onClick={()=>props.backToList()}
          icon={<BackArrowIcon size={32} />}
          width={40}
          height={40}
        />
      </Header>
      { props.repo !== null ?
        <Column>
          <LabeledContainer label='Current Repository' width={'500px'}>
            <ObjectDetails fields={RepoFields} object={props.repo}/>
          </LabeledContainer>
        </Column>
      :
        <RepoMessages height='max-content' messages={[
          <MessageItem>repo is undefined -- possible network error -- or bad repo format</MessageItem>
        ]}/>
      }
    </RepoContainer>
  );
};
export default RepoDetail;

const RepoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  padding: 10px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 500px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
