import React, { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import {
  ColumnsInit,
  ColumnT,
  LibrariesInit,
  LibraryT,
  UseFileImportContextT,
  workbookT,
} from './useFileImport';
import { LibrarySearchRequestT } from '@utils/useLibrarySearch';
import UploadFile from './UploadFile';
import ImportCompoundSet from './importCompoundSet/ImportCompoundSet';
import ImportTargetPanelNarrow from './importTargetPanel/ImportTargetPanelNarrow';
import ImportTargetPanel from './importTargetPanel/ImportTargetPanel';
import ImportDoseResponse from './importDoseResponse/ImportDoseResponse';
import ImportGeneric from './importGeneric/ImportGeneric';
import { FileImportContext } from '@stores/FileImportContext';
import { useNavigate } from "react-router-dom";
import { UserContext } from "@stores/UserContext";
import AbbvieUpload from '@components/abbvieUpload';
import {getUserSubscriptionType} from "@utils/useUserAppInfo";
export const FileImportOptions = [
  {
    label: 'Compound Set w/ Data',
    value: 'compound_set',
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+'],
  },
  {
    label: 'Target Panel (Universe) - Wide ',
    info: 'This wide data format is compound id, smiles and then the targets along the columns. '
      + 'One row per compound with as many columns as targets assayed. See example in Info->Data Upload Examples',
    value: 'target_panel',
    subscriptions: ['pilot'],
  },
  {
    label: 'Target Panel (Universe) - Narrow ',
    info: 'This data format is narrow where each row specifies a different target assay value. '
      + 'This will only have a few columns -- but many rows. See example in Info->Data Upload Examples',
    value: 'target_panel_narrow',
    subscriptions: ['pilot'],
  },
  {
    label: 'Dose Response Curve',
    value: 'dose_response',
    info: 'This data format is narrow where each row specifies a different compound, sample dose response. '
      + 'There are four required columns [compound ID, sample ID, dose, response] and any number of dose-responses.'
      + ' See example in Info->Data Upload Examples',
    subscriptions: ['pilot'],
  },
  {
    label: 'Generic Plotting Data',
    value: 'generic',
    info: 'This is the most generic type of tabular data. All rows/columns are imported and the column types are '
      + ' set as the XLSX column type. Data will be saved in "My Data Sets"',
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+'],
  }
];
/*
    label: 'Abbvie Data',
    value: 'abbvie',
    info: 'This is a custom data format for Abbvie data. See example in Info->Data Upload Examples',
    subscriptions: ['pilot', 'starter', 'academic+', 'commercial+'],
  */
const INITIAL_STEP = 1;
const debug = false;
const UploadData = () => {
  // STATES
  const [workbook, setWorkbook] = useState<workbookT>();
  const [activeStep, setActiveStep] = useState(INITIAL_STEP);
  const [importer, setImporter] = useState('compound_set');
  const [fileLoaded, setFileLoaded] = useState(false);
  const [activeColumn, setActiveColumn] = useState<number>(null);
  const [columns, setColumns] = useState<ColumnT[]>(ColumnsInit);
  const [libraries, setLibraries] = useState<LibraryT[]>(LibrariesInit);
  const [requests, setRequests] = useState<LibrarySearchRequestT[]>([]);
  const { user } = useContext(UserContext);
  const userSubscriptionType = getUserSubscriptionType(user.appInfo);
  const uploadOptions = FileImportOptions.filter((i) =>
    !i?.subscriptions || i?.subscriptions.includes(userSubscriptionType));
  const navigate = useNavigate();
  const context: UseFileImportContextT = {
    workbook, setWorkbook,
    activeStep, setActiveStep,
    columns, setColumns,
    activeColumn, setActiveColumn,
    importer, setImporter,
    fileLoaded, setFileLoaded,
    libraries, setLibraries,
    requests, setRequests,
  };
  const handleReset = () => {
    // RESET STEPPER
    debug && console.log('UploadData | Reset()');
    context.setFileLoaded(false);
    context.setWorkbook(null);
    context.setActiveColumn(null);
    context.setColumns(ColumnsInit);
    context.setLibraries(LibrariesInit);
    context.setActiveStep(INITIAL_STEP);
    navigate('/import/uploadData');
  };
  function handleChange(e:any) {
    debug && console.log('handleChange {v}', e.target.value);
    setImporter(e.target.value);
  }
  debug && console.log('UploadData | context: ', context);
  debug && console.log('importer', importer, 'activeStep', activeStep);
  const validImporters = FileImportOptions.map((o) => { return o.value });
  debug && console.log("validImporters", validImporters);
  if (context.importer) {
    const selectedOption = FileImportOptions.filter((item) => item.value === context.importer);
    debug && console.log('UploadData | selectedOption:', selectedOption);
  }
  return (
    <FileImportContext.Provider value={context}>
      <Container>
        {!context.workbook || !Object.values(validImporters).includes(context?.importer) ? (
          <UploadFile choices={uploadOptions} onChange={handleChange} />
        ) : (
          <UploadDataSelect>
            {(() => {
              switch (context.importer) {
                case 'compound_set':
                  return <ImportCompoundSet handleReset={handleReset} />;
                case 'target_panel':
                  return <ImportTargetPanel handleReset={handleReset} />;
                case 'target_panel_narrow':
                  return <ImportTargetPanelNarrow
                    formatType={'narrow'}
                    handleReset={handleReset} />;
                case 'dose_response':
                  return <ImportDoseResponse handleReset={handleReset} />;
                case 'generic':
                  return <ImportGeneric handleReset={handleReset} />;
                case 'abbvie':
                  return <AbbvieUpload />;
              }
            })()}
          </UploadDataSelect>
        )}
      </Container>
    </FileImportContext.Provider>
  );
};
export default UploadData;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 110px);
  align-items: center;
`;
const UploadDataSelect = styled.div`
  display: flex;
`;
