import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import { debounce } from '@utils/debounce';
import PageContainer from '@as_core/pages/PageContainer';
import usePlots from '../usePlots';
import { GraphTile } from './GraphTile';
import InputSearchText from '@as_core/controls/inputs/InputSearchText';
import AlertDeleteConfirm from '@as_core/controls/alerts/AlertDeleteConfirm';
import useCognito from '@as_core/account/useCognito';
import MenuButton from '@components/controls/MenuButton';
import NewGraphIcon from '@components/icons/newGraph.icon';
import { FlexItem, FlexSpacer } from '@as_core/elements/flexStyles';

import SelectDataModal from '../select-data-modal/SelectDataModal';
import ErrorMessages from '@as_core/elements/UserMessages';
import { MessageItem } from '@as_core/elements/UserMessages';
import UploadIcon from '@components/icons/upload.icon';
import { Grid, Paper } from '@mui/material';

const debug = false;
export const PlotList = () => {
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [plotToDelete, setPlotToDelete] = useState('');
  const [selected, setSelected] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [plots, setPlots] = useState([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(search);
  const { getPlots, deletePlot } = usePlots();
  const { getToken } = useCognito();

  // LOAD DATA
  useEffect(() => {
    if (!loaded) {
      const params = {};
      getPlots(getToken(), params).then((plotData) => {
        setLoaded(true);
        setPlots(plotData?.data || []);
      });
    }
  }, [loaded]);

  const updateSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
    debounce(
      'PlotListFilter',
      () => {
        setFilter(value);
      },
      200
    );
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Escape') {
      setSearch('');
      debounce(
        'PlotListFilter',
        () => {
          setFilter('');
        },
        200
      );
    }
  };

  const plotFilter = (plot: any) => {
    if (!filter || filter.trim().length === 0) return true;

    const SEARCH_FIELDS = [
      'name',
      'userId',
      'compoundIds',
      'createdOn',
      'modifiedOn',
    ];

    const tokens = filter.match(/\S+/g);
    let matchText = SEARCH_FIELDS.map((key) => plot[key].toString()).join();
    matchText += new Date(plot.createdOn).toLocaleDateString();
    matchText += new Date(plot.createdOn).toUTCString();
    matchText += new Date(plot.modifiedOn).toLocaleDateString();
    matchText += new Date(plot.modifiedOn).toUTCString();

    return tokens.find((token) =>
      matchText.toLowerCase().includes(token.toLowerCase())
    );
  };

  // HANDLERS
  const handleDeleteClick = (uuid: string) => {
    debug && console.log('handleDeleteClick ', uuid);
    setPlotToDelete(uuid);
    setIsDeleteAlertOpen(true);
  };

  const _handleCancel = () => {
    setPlotToDelete('');
    setIsDeleteAlertOpen(false);
  };

  const _handleDeleteConfirm = () => {
    debug && console.log('deleteConfirmed for ', plotToDelete);
    deletePlot(getToken(), plotToDelete).then(() => {
      setPlots((previous) =>
        previous.filter((plot) => plot.uuid !== plotToDelete)
      );
      setPlotToDelete('');
    });
  };

  const selectConfig = (config: any) => {
    setSelected(config);
  };

  return (
    <PageContainer title='My Plots'>
      <PlotBanner>
        <LeftColumn>
          <ActionPanel>
            <FlexItem>
              <MenuButton
                text={'Create New Plot'}
                icon={<NewGraphIcon />}
                margin={'1px'}
                tooltipPlacement='bottom-end'
                onClick={() => setIsModalOpen(true)}
              />
            </FlexItem>
            <FlexItem>
              <SearchPane>
                <InputSearchText
                  value={search}
                  setValue={updateSearch}
                  onKeyDown={handleKeyDown}
                  showLabel={false}
                />
              </SearchPane>
            </FlexItem>
          </ActionPanel>
        </LeftColumn>
      </PlotBanner>

      {plots.length ? (
        <Grid container spacing={3} padding={2}>
          {plots.filter(plotFilter).map((plot, i) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
              <Paper elevation={1}>
                <GraphTile plot={plot} handleDeleteClick={handleDeleteClick} />
              </Paper>
            </Grid>
          ))}
          <AlertDeleteConfirm
            title={'Delete Plot'}
            message={'Confirm delete by typing DELETE below to Enable Delete'}
            alertOpen={isDeleteAlertOpen}
            onConfirm={_handleDeleteConfirm}
            closeAlert={_handleCancel}
          />
        </Grid>
      ) : (
        <PlotContainer>
          <ErrorMessages
            messages={[
              <MessageItem color='accentSecondary'>
                You do not have any saved plots.
              </MessageItem>,
              <MessageItem>
                <NewGraphIcon />
                <FlexSpacer width={5} />
                If you have uploaded data and/or created a compound favorites
                list, click on the "Create New Plot" above to create a plot.
              </MessageItem>,
              <MessageItem>
                <UploadIcon />
                You can also create a plot after uploading new data.
              </MessageItem>,
            ]}
          />
        </PlotContainer>
      )}
      <SelectDataModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </PageContainer>
  );
};

const PlotBanner = styled.div`
  display: flex;
  flex-direction: column;
  height: 50px;
  width: calc(100vw - 50px);
  padding: 5px;
`;

const PlotContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 100px);
  width: calc(100vw - 50px);
  padding: 5px;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
`;

const ActionPanel = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const SearchPane = styled.div`
  display: flex;
  padding: 5px;
  height: 30px;
  width: 400px;
`;
