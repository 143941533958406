import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import { scaleLinear, extent, max, line, curveBasis, format } from 'd3';

const margin = {
  top: 15,
  right: 20,
  bottom: 25,
  left: 20,
};

const PropertyDensity = ({
  value,
  secondaryValues = [],
  valueFormat = '.2f',
  density,
  label,
  height = 100,
  width = 250
}) => {
  const labelRef = useRef<any>();
  const containerRef = useRef<any>();
  const [path, setPath] = useState<string>();
  const [valueMark, setValueMark] = useState<any>();
  const [secondaryValueMarks, setSecondaryValueMarks] = useState<any[]>([]);
  const [ticks, setTicks] = useState<any>();
  const f = format(valueFormat);

  useEffect(() => {
    const xScale = scaleLinear()
      .domain(
          extent([...density, [value]], (d) => d[0])
      )
      .range([margin.left, width - margin.right]);

    const yScale = scaleLinear()
      .domain([0, max(density, (d): number => d[1])])
      .range([height - margin.bottom, margin.top]);

    const modDensity = [
      [density[0][0], 0],
      ...density,
      [density.slice(-1)[0][0], 0],
    ];

    setTicks(
      xScale.ticks(2).map((d, i) => ({
        id: i,
        value: d,
        x: xScale(d),
        y: yScale.range()[0],
      }))
    );

    setPath(
      line()
        .curve(curveBasis)
        .x((d) => xScale(d[0]))
        .y((d) => yScale(0.8*d[1]))(modDensity)
    );

    setValueMark({
      x: xScale(value),
      y1: yScale.range()[0],
      y2: yScale.range()[1],
    });

    if (secondaryValues.length) {
      const updatedSecondaryValueMarks = [];
      secondaryValues.map((v) => {
        updatedSecondaryValueMarks.push(
          {
            x: xScale(v.value),
            y1: yScale.range()[0],
            y2: yScale.range()[1],
            color: v.color
          }
        )
      })
      setSecondaryValueMarks(updatedSecondaryValueMarks);
    } else {
      setSecondaryValueMarks([]);
    }
  }, [width, height, density, value, secondaryValues]);

  return (
    <Container ref={containerRef} width={width} height={height}>
      <Label ref={labelRef}>{label}</Label>
      <Graph>
        <svg width={width} height={height}>
          <g className='density-plot'>
            {ticks && (
                <g className='ticks'>
                  {ticks.map((tick) => {
                    return (
                        <g key={tick.id}>
                          <TickLine x1={tick.x} y1={tick.y} x2={tick.x} y2={tick.y + 5} />
                          <TickText x={tick.x} y={tick.y + 13}>{tick.value}</TickText>
                        </g>
                    );
                  })}
                </g>
            )}
            <Path d={path} />
            {valueMark && (
                <g className='value-mark' transform={`translate(${valueMark.x} 0)`}>
                  <ValueMarkLine x1='0' y1={valueMark.y1} x2='0' y2={valueMark.y2 + 10} />
                  <ValueMarkText x='0' y={valueMark.y2 + 5}> {f(value)} </ValueMarkText>
                </g>
            )}
            {secondaryValueMarks.map((v) =>
                <g className='secondary-value-mark' transform={`translate(${v.x} 0)`}>
                  <SecondaryValueMarkLine x1='0' y1={v.y1} x2='0' y2={v.y2 + 10} color={v.color}/>
                </g>
            )}
          </g>
        </svg>
      </Graph>
    </Container>
  );
};

export default PropertyDensity;

const Container: any = styled.div<{width: number, height: number}>`
  display: flex;
  position: relative;
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
`;

const Graph: any = styled.div`
  position: absolute;
  top: 10px;
  left: 0;
`;

const Label: any = styled.div`
  position: absolute;
  padding-right: 5px;
  top: 0;
  right: 0;
  width: 100%;
  text-align: right;
  font-size: 0.7rem;
`;

const Path: any = styled.path`
  stroke: ${(p) => p.theme.palette.titlePrimary};
  stroke-width: 0.1rem;
  stroke-opacity: 0.8;
  fill: ${(p) => p.theme.palette.titlePrimary};
  fill-opacity: 0.2;
`;

const TickLine: any = styled.line`
  stroke: ${(p) => p.theme.palette.titlePrimary};
  stroke-width: 0.1rem;
  stroke-opacity: 0.6;
`;

const TickText: any = styled.text`
  fill: ${(p) => p.theme.palette.titlePrimary};
  font-size: 0.5rem;
  text-anchor: middle;
`;

const ValueMarkLine: any = styled.line`
  stroke: ${(p) => p.theme.palette.accentPrimary};
  stroke-width: 0.15rem;
  stroke-opacity: 0.8;
`;

const ValueMarkText: any = styled.text`
  fill: ${(p) => p.theme.palette.titlePrimary};
  font-size: ${(p) => p.theme.sizes.xsmall};
  text-anchor: middle;
`;

const SecondaryValueMarkLine: any = styled.line<{color?: string}>`
  stroke: ${(p) => p.color? p.color : '#39A9CB'};
  stroke-width: 0.15rem;
  stroke-opacity: 0.8;
`;


