import {authCoreClient, ResponseT} from '@utils/api/base';
import { CouponT } from '../types';
import { handleError } from '@utils/misc';
import {AxiosRequestConfig} from "axios";
import useCognito from "@as_core/account/useCognito";

export const Coupons = {
  get: (token: string, config: AxiosRequestConfig) => authCoreClient(token).get('/coupons', config),
};

const useCoupons = () => {
  const { getToken } = useCognito()

  const getCoupon = async (params: {[key:string]: any}): Promise<ResponseT<Array<CouponT>>> => {
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await Coupons.get(getToken(), config);
    } catch (err) {
      handleError(err);
      return null;
    }

    return resp.data;
  };

  return {
    getCoupon,
  };
};

export default useCoupons;
