import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import _ from 'lodash';

import PagePanel from '@components/pages/PagePanel';
import DetailBanner from './detail/DetailBanner';
import CHIScorecardTile from './detail/CHIScorecardTile';
import ZFScorecardTile from '@components/compounds/view/detail/ZFScorecardTile';

import { FlexRow, FlexColumn } from '@components/layout/FlexStyles';

import DraggableModal from '@components/elements/DraggableModal';
import useWindowDimensions from '@as_core/hooks/useWindowDimensions';

// Components
import { v4 as uuidv4 } from 'uuid';
import PropertyTable from './detail/propertyTable/PropertyTable';
import { assayAvailable } from './detail/assays.service';

import NeighborsLoader from '@components/compounds/neighbors/NeighborsLoader';
import { CompoundT } from '../../../type';
import MolImgTile from '../images/MolImgTile';

import LabNotes from '@components/labNotes/LabNotes';
import qs from 'qs';
import { compoundObjectNameField, fieldIdToObjectId, ltrim } from '@utils/misc';
import useCompounds from '../useCompounds';

import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { FieldSliceT } from '@stores/fields';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const sectionsCfields = ['Reference', 'Classification', 'Chemical', 'Toxicity'];

interface CompoundDetailT {
  compound: CompoundT;
  neighbors: any;
}

const debug = false;

const CompoundDetail = (props: CompoundDetailT) => {
  const { compound } = props;
  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const { updateCompound, getNeighbors } = useCompounds();
  const { height: windowHeight } = useWindowDimensions();
  const [neighbors, setNeighbors] = useState([]);
  const [searchText, setSearchText] = useState<string>('');

  debug &&
    console.log(
      'CompoundDetail | compound: ',
      compound,
      ' neighbors: ',
      neighbors
    );

  const { fields: allFields } = useSelector(
    (state: RootState) => state.fields as FieldSliceT
  );

  // STATES
  const [notesModalOpen, setNotesModalOpen] = useState<boolean>(false);

  let panel_height = windowHeight - 120;

  const handleNotesClose = () => {
    setNotesModalOpen(false);
  };
  const handleNotesChange = () => {
    notesModalOpen ? setNotesModalOpen(false) : setNotesModalOpen(true);
  };

  const handleSetView = (viewName) => {
    debug && console.log('DetailBanner handleSetView:', viewName);
    if (viewName === view) {
      viewName = '';
    }
    handleChange({ action: 'SETVIEW', payload: { view: viewName } });
  };

  const handleUpdateCompound = async (props) => {
    const { propId, newValue } = props;
    if (_.get(compound, propId) === newValue) {
      return;
    }
    const compoundUpdate = {
      uuid: compound.uuid,
    };
    _.set(compoundUpdate, propId, newValue);

    // Upload Changes
    const newData: CompoundT = await updateCompound(compoundUpdate);
    console.log('newDATA', newData);
    // setCompound(newData);
  };

  const addObject = async (props) => {
    const { fieldId, propId } = props;

    const newId = uuidv4();
    const compoundUpdate = {
      uuid: compound.uuid,
    };

    // Add reference in cfields to new object
    const value = _.get(compound, propId, '');
    const newValue = ltrim(
      `${value},${fieldIdToObjectId[fieldId]}.${newId}`,
      ','
    );
    _.set(compoundUpdate, propId, newValue);

    // Add new object to the compound
    _.set(compoundUpdate, `${fieldIdToObjectId[fieldId]}.${newId}`, {});

    // Upload Changes
    const newData = await updateCompound(compoundUpdate);
    // setCompound(newData);
    setView({
      view: 'object',
      fieldSrc: `${fieldIdToObjectId[fieldId]}`,
      valueSrc: `${fieldIdToObjectId[fieldId]}.${newId}`,
      title: 'Unknown',
    });
  };

  // ###########################################################################
  // SEARCH CONTROL
  // ###########################################################################
  const updateSearch = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setSearchText('');
    }
  };

  // ###########################################################################
  // DETAIL BANNER
  // ###########################################################################
  const view = query.get('view') || '';
  const setView = React.useCallback(
    (props) => {
      const search = {
        ...qs.parse(location.search, { ignoreQueryPrefix: true }),
        ...props,
      };
      navigate({ ...location, search: qs.stringify(search) });
    },
    [navigate, location]
  );

  // ###########################################################################
  // Object View
  // ###########################################################################
  const fieldSrc = query.get('fieldSrc') || '';
  const valueSrc = query.get('valueSrc') || '';

  const handleChange = (props) => {
    const { action, payload } = props;
    switch (action) {
      case 'UPDATECOMPOUND':
        handleUpdateCompound(payload).then();
        break;
      case 'SETVIEW':
        setView(payload);
        break;
      case 'ADDOBJECT':
        addObject(payload).then();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (view === 'object') {
      setView({
        view: 'object',
        fieldSrc,
        valueSrc,
        title: _.get(
          compound,
          `${valueSrc}.${compoundObjectNameField[fieldSrc]}.value`,
          'Unknown'
        ),
      });
    }
  }, [compound]);

  const fetchNeighbors = useCallback(async (compoundId) => {
    const params: { [propName: string]: any } = {
      library: 'aseda',
    };
    const res = await getNeighbors(compoundId, params);
    setNeighbors(res.value);
  }, []);

  useEffect(() => {
    fetchNeighbors(compound?.uuid).then();
  }, [compound?.uuid]);

  const getBanner = () => {
    return (
      <DetailBanner
        view={view}
        searchText={searchText}
        updateSearch={updateSearch}
        handleKeyDown={handleKeyDown}
        handleSetView={handleSetView}
        handleNotesChange={handleNotesChange}
        compound={compound}
        neighbors={neighbors}
      />
    );
  };

  return (
    <DetailsContainer>
      {getBanner()}
      <FlexRow>
        <FlexColumn width={'330px'}>
          <ScrollBox>
            <PagePanel width={'300px'} height={'300px'}>
              <StyledMolImgTile mol_svg={compound?.mol_svg} hover={false} />
            </PagePanel>
            {assayAvailable(compound, allFields, 'chi') && (
              <PagePanel title='Cell Health Metrics' width={'300px'}>
                <CHIScorecardTile compound={compound} />
              </PagePanel>
            )}
            {assayAvailable(compound, allFields, 'zf') && (
              <PagePanel title='Zebrafish Results' width={'300px'}>
                <ZFScorecardTile compound={compound} />
              </PagePanel>
            )}
          </ScrollBox>
        </FlexColumn>
        <FlexColumn>
          <FlexRow>
            <PropertyTable
              key='properties'
              compound={compound}
              searchText={searchText}
              height={panel_height}
              fieldSrc='cfields'
              sections={sectionsCfields}
              handleChange={handleChange}
            />
            {view === 'neighbors' ? (
              <PagePanel
                title='Tanimoto Neighbors'
                enableClose={true}
                onClose={() => handleSetView('')}
              >
                <NeighborsLoader height={panel_height} neighbors={neighbors} />
              </PagePanel>
            ) : null}
          </FlexRow>
        </FlexColumn>
      </FlexRow>
      <DraggableModal
        title={'Compound Notes'}
        modalOpen={notesModalOpen}
        height={400}
        width={650}
        onCloseClick={handleNotesClose}
        opacity={1.0}
      >
        <LabNotes height={400} width={650} compoundId={compound?.uuid} />
      </DraggableModal>
    </DetailsContainer>
  );
};

export default CompoundDetail;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ScrollBox = styled.div`
  height: calc(100vh - 120px);
  overflow-y: auto;
  overflow-x: hidden;
`;
const StyledMolImgTile = styled(MolImgTile)`
  display: flex;
  width: 290px;
`;
