import { AxiosRequestConfig } from "axios";
import {authServicesClient, ResponseT} from "@utils/api/base";
import { handleError } from "@utils/misc";
import useCognito from "@as_core/account/useCognito";

// the api settings
export const Requests = {
  all: (token: string, config: AxiosRequestConfig) => authServicesClient(token).get('/requests', config),
  byId: (token: string, query: string) => authServicesClient(token).get(`/requests/list${query}`),
  get: (token: string, orderId: string) => authServicesClient(token).get(`/requests/id/${orderId}`),
  forUser: (token:string, userId: string) => authServicesClient(token).get(`/requests/user/${userId}`),
  create: (token: string, config: AxiosRequestConfig) => authServicesClient(token).post('/requests', config),
  update: (token: string, requestId: string, config: AxiosRequestConfig) => authServicesClient(token).patch(`/requests/${requestId}`, config),
};

// the Request Object Type
export type RequestT = {
  id: string;
  name: string;
  user_id: string;
  org_id: string;
  created_at: string;
  updated_at: string;
  request_type: string;
  cat_code: string;
  prod_name: string;
  quantity: number;
  unit_value: string;
  assigned_to: string;
  status: string;
  compounds: number;
}

const useRequests = () => {
  const { getToken } = useCognito();

  const getRequests = async (params: any): Promise<ResponseT<any>> => {
    const config: AxiosRequestConfig = { params };
    let resp: any;

    try {
      resp = await Requests.all(getToken(), config);
    } catch (err) {
      handleError(err);
      return null;
    }
    return resp.data;
  };

  const getRequestsByIds = async (ids: number[]): Promise<RequestT[]> => {
    if (ids.length === 0) return [];
    const id_query = ids.map((id, index) => ((index === 0? '': '&') + `ids=${id}`));
    let query = '?'.concat(...id_query);
    let resp: any;

    try {
      resp = await Requests.byId(getToken(), query);
    } catch (err) {
      handleError(err);
      return null;
    }
    return resp.data;
  };

  const getUserRequests = async (userId: string): Promise<Array<RequestT>> => {
    let resp: any;

    try {
      resp = await Requests.forUser(getToken(), userId);
    } catch (err) {
      handleError(err);
      return null;
    }
    return resp.data;
  };

  const getRequest = async (
    orderId: string,
  ): Promise<ResponseT<RequestT>> => {
    let resp: any;

    try {
      resp = await Requests.get(getToken(), orderId);
    } catch (err) {
      handleError(err);
      return null;
    }

    return resp.data;
  };

  const createRequest = async (params: any) => {
    const config: AxiosRequestConfig = params;
    let resp: any;

    try {
      resp = await Requests.create(getToken(), config);
    } catch (err) {
      handleError(err);
      return null;
    }

    return resp.data;
  };

  const updateRequest = async (requestId: string, updates: {[key: string]: any}) => {
    const config: AxiosRequestConfig = updates;
    let resp: any;

    try {
      resp = await Requests.update(getToken(), requestId, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    return resp.data;
  };

  return {
    getRequests,
    getRequestsByIds,
    getUserRequests,
    getRequest,
    createRequest,
    updateRequest
  };
};

export default useRequests;
