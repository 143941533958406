import styled from "styled-components/macro";
import {CopyAll} from "@mui/icons-material";
import {Tooltip} from "@mui/material";

interface CopyToClipboardI {
  text: string;
  label?: string;
}
const CopyToClipboard = (props:CopyToClipboardI) => {
  const handleClick = (text: string) => {
    console.log('filePath', text);
    navigator.clipboard.writeText(text).then();
  }

  return(
    <Tooltip
      title={props?.label ? props.label : 'Copy DOI to clipboard'}
      arrow
      placement={'bottom-end'}
      >
     <ClipboardLink onClick={()=>handleClick(props.text)}>
       <CopyAll />
     </ClipboardLink>
    </Tooltip>
  )
}

export default CopyToClipboard;

const ClipboardLink = styled.span`
  color: ${(p) => p.theme.palette.accentPrimary};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 5px;
  filter: grayscale(0.75);
  :hover {
    filter: grayscale(0.0);
  }
`;