import React, { useEffect } from 'react';
import styled from 'styled-components';

const Modal = ({ isOpen, onClose, data, title }) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isOpen, onClose]);

  const handleClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    isOpen && (
      <ModalOverlay onClick={handleClickOutside}>
        <ModalContainer>
          <CloseButton onClick={onClose}>
            <CloseIcon>&times;</CloseIcon>
          </CloseButton>
          <ModalTitle>Example of Values</ModalTitle>
          <ModalContent>
            {Array.isArray(data) ? (
              data.map((item, index) => (
                <HoverableValue key={index}>{item}</HoverableValue>
              ))
            ) : (
              <HoverableValue>{data}</HoverableValue>
            )}
          </ModalContent>
        </ModalContainer>
      </ModalOverlay>
    )
  );
};

export default Modal;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ModalContainer = styled.div`
  background: rgb(197 123 153);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
  padding: 20px;
  max-width: 80%;
  opacity: 0.8;
  position: relative;
  text-align: center;
`;

const ModalTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 10px;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  color: #000;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0px;
  right: 5px;
  cursor: pointer;
  background: none;
  border: none;
  height: 15px;
  width: 15px;
`;

const CloseIcon = styled.span`
  cursor: pointer;
  font-size: 24px;
  line-height: 1;
  color: #000;
`;

const ModalContent = styled.div`
  padding: 10px;
  color: inherit; /* Inherit the text color from the parent container */
`;

const HoverableValue = styled.div`
  &:hover {
    background-color: rgb(0, 1, 41);;
    color: white;
    cursor: pointer;
    opacity: 0.5;
  }
`;
