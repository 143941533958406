import React, { useContext, useMemo, useEffect, useCallback } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import ProgressBar from '@components/ProgressBar';
import TextButton from '@components/controls/TextButton';
import MyCompoundsIcon from '@components/icons/myCompounds.icon';
import { TextSmall } from '@components/elements/TextStyles';
import { Row, Header, Cell } from '../shared/RowElements';
import useFileImport from '../useFileImport';
import useCompoundUpload from '@utils/useCompoundUpload';
import {
  addCompoundView,
  updateCompoundView,
  CompoundViewItemT,
  CompoundViewTypeT,
} from '@stores/compoundViews';
import { RootState } from '../../../../store';
import useCognito from '@as_core/account/useCognito';
import { UserContext } from '@stores/UserContext';

interface UploadCompoundsProps {
  handleNext: () => void;
  height: number;
  width: number;
  compoundSet: CompoundSetItem[];
}

interface CompoundSetItem {
  uuid: string;
  [key: string]: any;
}

const UploadCompounds: React.FC<UploadCompoundsProps> = ({
  handleNext,
  height,
  width,
  compoundSet,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { getToken } = useCognito();
  const compoundViews = useSelector((state: RootState) => state.compoundViews);
  const {
    context: { workbook, columns },
  } = useFileImport();
  const identity = useMemo(
    () => columns.find((c) => c.fieldFilter === 'Identity'),
    [columns]
  );
  const idField = useMemo(
    () => `cfields.${identity?.field_id}.value`,
    [identity]
  );

  const uploadRequests = useMemo(
    () => compoundSet.map((data, index) => ({ rowIndex: index, data })),
    [compoundSet]
  );

  const { progressMessage, throttle } = useCompoundUpload(
    getToken(),
    uploadRequests
  );
  const results = useMemo(() => throttle.values, [throttle]);

  const getValue = useCallback(
    (index: number) => {
      const result = results.find((p) => p.index === index);
      if (result?.error) {
        return `Error: ${result.error.message || 'Error'}`;
      } else if (result) {
        const statusCode = _.get(result, 'response.status', 0);
        return statusCode >= 200 && statusCode < 300
          ? 'Success'
          : `ERROR (${statusCode})`;
      }
      return 'Request Pending';
    },
    [results]
  );

  const goToMyCompounds = useCallback(() => {
    navigate('/compounds/user/table');
  }, [navigate]);

  const createFavorite = useCallback(async () => {
    // Création des nouveaux IDs de champ
    const newFieldIds = columns
      .filter((e) => e.field_id)
      .map((item) => item.field_id);
    if (newFieldIds.includes('smiles') && !newFieldIds.includes('molSVGUUID')) {
      newFieldIds.push('molSVGUUID');
    }
    const newIdList = compoundSet.map((cpd) => cpd.uuid);
    const token = getToken();
    const previousViewId = compoundViews.viewIds.find(
      (vId) => compoundViews.views[vId].title === workbook.filename
    );

    const favorite: CompoundViewItemT = {
      uuid: previousViewId
        ? compoundViews.views[previousViewId].uuid
        : uuidv4(),
      title: workbook.filename,
      compoundIds: [{ libraryId: 'user', compoundIds: newIdList }],
      fieldIds: newFieldIds,
      viewType: CompoundViewTypeT.Finite,
      userId: user.authId,
    };

    if (previousViewId) {
      const priorView = compoundViews.views[previousViewId];
      const priorFieldIds = priorView.fieldIds || [];
      const priorCompoundIds =
        priorView.compoundIds.find((c) => c.libraryId === 'user')
          ?.compoundIds || [];

      favorite.fieldIds = Array.from(
        new Set([...priorFieldIds, ...newFieldIds])
      );
      favorite.compoundIds[0].compoundIds = Array.from(
        new Set([...priorCompoundIds, ...newIdList])
      );

      dispatch(updateCompoundView(token, favorite));
    } else {
      dispatch(addCompoundView(token, favorite));
    }
  }, [
    columns,
    compoundSet,
    compoundViews,
    dispatch,
    getToken,
    user.authId,
    workbook.filename,
  ]);

  useEffect(() => {
    if (throttle.loading === false) {
      createFavorite().then();
      handleNext();
    }
  }, [results]);

  return (
    <Container height={height} width={width}>
      <ControlContainer>
        {throttle.loading ? (
          <LoadingView
            progressMessage={progressMessage}
            percentage={throttle.percentageLoaded}
          />
        ) : (
          <UploadedView
            totalRequests={throttle.totalRequests}
            goToMyCompounds={goToMyCompounds}
          />
        )}
      </ControlContainer>
      <ScrollContainer height={height}>
        <Table>
          <thead>
            <Row>
              <Header>Row</Header>
              <Header>{identity?.colName}</Header>
              <Header>Upload Result</Header>
            </Row>
          </thead>
          <tbody>
            {compoundSet.map((cpd, index) => (
              <Row key={`rows-${index}`}>
                <Cell>{index + 1}</Cell>
                <Cell>{_.get(cpd, idField, '')}</Cell>
                <Cell>{getValue(index)}</Cell>
              </Row>
            ))}
          </tbody>
        </Table>
      </ScrollContainer>
    </Container>
  );
};

const LoadingView: React.FC<{
  progressMessage: string;
  percentage: number;
}> = ({ progressMessage, percentage }) => (
  <>
    <ProgressBar percentage={percentage} height='10px' />
    <Table>
      <tbody>
        <Row>
          <Header>{progressMessage}</Header>
        </Row>
      </tbody>
    </Table>
  </>
);

const UploadedView: React.FC<{
  totalRequests: number;
  goToMyCompounds: () => void;
}> = ({ totalRequests, goToMyCompounds }) => (
  <UploadMessage>
    <Item>
      <TextSmall>{totalRequests} compounds uploaded.</TextSmall>
    </Item>
    <Item>
      <TextButton
        text='Go to MyCompounds'
        onClick={goToMyCompounds}
        icon={<MyCompoundsIcon size={32} />}
        tooltipPlacement='bottom'
        width={250}
        height={30}
        margin='5px'
      />
    </Item>
  </UploadMessage>
);

export default React.memo(UploadCompounds);

// Styled components remain the same

const Container = styled.div<{ height?: number; width?: number }>`
  display: block;
  position: relative;
`;

const ControlContainer = styled.div`
  width: 100%;
`;

const ScrollContainer = styled.div<{ height?: number; width?: number }>`
  max-height: calc(100vh - 390px);
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  & ::-webkit-scrollbar {
    display: none;
  }
`;

const Table = styled.table`
  table-layout: auto;
  width: 100%;
`;

const UploadMessage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`;

const Item = styled.div`
  display: flex;
  justify-content: center;
`;
