import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import {
  getAesValues,
  getGraphSettings,
  getRingFieldsFromSettings,
} from '@components/universe/utils/compoundUniverse';

import Graph from './Graph';
import UniverseBanner from '@components/universe/UniverseBanner';

import {
  CompoundUniverseProps,
  GraphSettingsT,
  VertexT,
} from '@components/universe/types';
import { StyleContext } from '@theme/AppStyles';
import { getDensities } from '@components/universe/utils/computation';
import useWindowDimensions from '@as_core/hooks/useWindowDimensions';
import useObjectArray from '@as_core/hooks/useObjectArray';
import {addToPinned} from "@components/universe/ContextMenu/ContextMenu";

let debug = false;
/**
 * Main Component for the Compound Universe that is fed the data from the various
 * page controls
 * @param universeSelector
 * @param data
 * @param images
 * @param settings
 * @constructor
 */
const CompoundUniverse = ({
  universeSelector,
  data,
  images = {},
  settings = {},
}: CompoundUniverseProps) => {
  debug &&
    console.log('CompoundUniverse | data: ', data, ' settings: ', settings);

  const [style] = useContext(StyleContext);
  const { height, width } = useWindowDimensions();
  const [ready, setReady] = useState(false);
  const [aesSettings, setAesSettings] = useState(
    getAesValues(data.vertices, data.edges, images, settings, style)
  );
  const selectedCompounds = useObjectArray([]);
  const pinnedCompounds = useObjectArray([]);
  const [graphSettings, setGraphSettings] = useState<GraphSettingsT>(
    getGraphSettings(settings, height, width)
  );
  const [continueSimulation, setContinueSimulation] = useState<boolean>(false);
  // destructure for useEffect dependencies
  const { vertices: dataVertices, edges: dataEdges } = data;

  const handleContinueSimulation = (value) => {
    setContinueSimulation(value);
  };
  // console.log('selectedCompounds', selectedCompounds.data);

  // console.log('CompoundUniverse: data.current', dataCurrent);

  // when the search string is updated -- update the matching nodes
  const handleSearchResponse = (search: string) => {
    // console.log('CompoundUniverse | handleSearchResponse:', search)
    if (search.length > 2) {
      return data.vertices
        .filter((v) => v?.name.toLowerCase().includes(search.toLowerCase()))
        .map((v) => ({ id: v?.id, label: v?.name }));
    }
    return [];
  };

  // handle setting the selectedVertex from a search for names
  const handleSearchItemSelect = (selected: VertexT) => {
    addToPinned(pinnedCompounds, selected.id, 'front');
  };

  // general function to update the various graphSettings
  const handleUpdateSettings = (field: string, value: any) => {
    // console.log('handleUpdateSettings | field, value', field, value);
    setGraphSettings((prev) => ({ ...prev, [field]: value }));
  };

  // handle any changes that may affect the graph rendering
  useEffect(() => {
    // console.log('dataVertices dataEdges - useEffect triggered');
    pinnedCompounds.reset();
    selectedCompounds.reset();
    setGraphSettings(getGraphSettings(settings, height, width));
    setAesSettings(
      getAesValues(dataVertices, dataEdges, images, settings, style)
    );
    setReady(true);
  }, [dataVertices, dataEdges]);

  // handle any changes that may affect the graph rendering
  useEffect(() => {
    // console.log('dataVertices dataEdges images - useEffect triggered');
    setAesSettings(
      getAesValues(dataVertices, dataEdges, images, settings, style)
    );
  }, [dataVertices, dataEdges, images, settings, style]);

  useEffect(() => {
    handleUpdateSettings('dimensions', { height: height, width: width });
  }, [height, width]);

  useEffect(() => {
    handleUpdateSettings(
      'coronaFields',
      getRingFieldsFromSettings(graphSettings)
    );
  }, [graphSettings.primary, graphSettings.secondary]);

  useEffect(() => {
    if (data?.vertices) {
      handleUpdateSettings('densities', getDensities(data.vertices));
    }
  }, [data.vertices]);
  debug &&
    console.log(
      'CompoundUniverse | settings:',
      settings,
      'aesSettings:',
      aesSettings,
      'data:',
      data
    );

  return (
    <Container>
      <UniverseBanner
        universeSelector={universeSelector}
        datasets={data?.datasets}
        settings={graphSettings}
        updateSettings={handleUpdateSettings}
        setContinueSim={setContinueSimulation}
        getSearchResponse={handleSearchResponse}
        onSearchItemSelect={handleSearchItemSelect}
        selectedCompounds={selectedCompounds}
      />
      <GraphWrapper className='graph-wrapper'>
        {ready && data && data.vertices.length && (
          <Graph
            input={data}
            settings={graphSettings}
            updateSettings={handleUpdateSettings}
            aesSettings={aesSettings}
            continueSimulation={continueSimulation}
            setContinueSimulation={handleContinueSimulation}
            selectedCompounds={selectedCompounds}
            pinnedCompounds={pinnedCompounds}
          />
        )}
      </GraphWrapper>
    </Container>
  );
};

export default CompoundUniverse;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: ${(p) => p.theme.fonts.main};
`;

const GraphWrapper = styled.div`
  position: relative;
  width: calc(100vw - 50px);
  height: 100%;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
  overflow: hidden;
`;
