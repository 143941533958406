import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const TileIcon = ({
    size = 24,
  }) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M9.21534 3H5.08153C3.93379 3 3 3.93379 3 5.08153V9.21534C3 10.3631 3.93379 11.2969
          5.08153 11.2969H9.21534C10.3631 11.2969 11.2969 10.3631 11.2969 9.21534V5.08153C11.2969
          3.93379 10.3631 3 9.21534 3ZM9.89062 9.21534C9.89062 9.58768 9.58768 9.89062 9.21534
          9.89062H5.08153C4.70919 9.89062 4.40625 9.58768 4.40625 9.21534V5.08153C4.40625 4.70919
          4.70919 4.40625 5.08153 4.40625H9.21534C9.58768 4.40625 9.89062 4.70919 9.89062
          5.08153V9.21534Z" fill={color}/>
          <path d="M18.8906 3H14.8125C13.6494 3 12.7031 3.94627 12.7031 5.10938V9.1875C12.7031
          10.3506 13.6494 11.2969 14.8125 11.2969H18.8906C20.0537 11.2969 21 10.3506 21
          9.1875V5.10938C21 3.94627 20.0537 3 18.8906 3ZM19.5938 9.1875C19.5938 9.5752 19.2783
          9.89062 18.8906 9.89062H14.8125C14.4248 9.89062 14.1094 9.5752 14.1094 9.1875V5.10938C14.1094
          4.72167 14.4248 4.40625 14.8125 4.40625H18.8906C19.2783 4.40625 19.5938 4.72167 19.5938
           5.10938V9.1875Z" fill={color}/>
          <path d="M9.21534 12.7031H5.08153C3.93379 12.7031 3 13.6369 3 14.7847V18.9185C3 20.0662
          3.93379 21 5.08153 21H9.21534C10.3631 21 11.2969 20.0662 11.2969 18.9185V14.7847C11.2969
          13.6369 10.3631 12.7031 9.21534 12.7031ZM9.89062 18.9185C9.89062 19.2908 9.58768 19.5938
          9.21534 19.5938H5.08153C4.70919 19.5938 4.40625 19.2908 4.40625 18.9185V14.7847C4.40625
          14.4123 4.70919 14.1094 5.08153 14.1094H9.21534C9.58768 14.1094 9.89062 14.4123 9.89062
          14.7847V18.9185Z" fill={color}/>
          <path d="M18.8906 12.7031H14.8125C13.6494 12.7031 12.7031 13.6494 12.7031
          14.8125V18.8906C12.7031 20.0537 13.6494 21 14.8125 21H18.8906C20.0537 21 21 20.0537
          21 18.8906V14.8125C21 13.6494 20.0537 12.7031 18.8906 12.7031ZM19.5938 18.8906C19.5938
          19.2783 19.2783 19.5938 18.8906 19.5938H14.8125C14.4248 19.5938 14.1094 19.2783 14.1094
          18.8906V14.8125C14.1094 14.4248 14.4248 14.1094 14.8125 14.1094H18.8906C19.2783 14.1094
          19.5938 14.4248 19.5938 14.8125V18.8906Z" fill={color}/>
        </g>
      </svg>
    </>
  )
}

export default TileIcon;