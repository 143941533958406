// Editable.js
import React from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';

// Component accept text, placeholder values and also pass what type of Input - input, textarea so that we can use it for styling accordingly
const Editable = ({
  childRef,
  text,
  type,
  placeholder,
  children,
  handleSubmit,
  handleReset,
  ...props
}) => {
  // Manage the state whether to show the label or the input box. By default,
  //   label will be shown.
  // Exercise: It can be made dynamic by accepting initial state as props
  //   outside the component
  const [isEditing, setEditing] = React.useState(false);

  // Event handler while pressing any key while editing
  const handleKeyDown = (event, type) => {
    const { key } = event;
    const isShift = event.getModifierState('Shift');
    /* 
    - For textarea, check only Escape and Tab key and set the state to false
    - For everything else, all three keys will set the state to false
    */
    switch (key) {
      case 'Escape':
        setEditing(false);
        handleReset();
        break;
      case 'Enter':
        if (type !== 'textarea' || isShift) {
          setEditing(false);
          handleSubmit();
        }
        break;
      case 'Tab':
        if (type !== 'textarea') {
          setEditing(false);
          handleSubmit();
        } else {
          event.preventDefault();
        }
        break;
      default:
        break;
    }
  };

  const onBlur = () => {
    setEditing(false);
    handleSubmit();
  };

  React.useEffect(() => {
    if (childRef && childRef.current && isEditing === true) {
      childRef.current.focus();
    }
  }, [isEditing, childRef]);
  /*
- It will display a label is `isEditing` is false
- It will display the children (input or textarea) if `isEditing` is true
- when input `onBlur`, we will set the default non edit mode
Note: For simplicity purpose, I removed all the classnames, you can check the repo for CSS styles
*/
  return (
    <Container {...props}>
      {isEditing ? (
        <EditContent onBlur={onBlur} onKeyDown={(e) => handleKeyDown(e, type)}>
          {children}
        </EditContent>
      ) : (
        <Content onClick={() => setEditing(true)}>
          {_.isNumber(text) ? text : text || placeholder || '-'}
        </Content>
      )}
    </Container>
  );
};

export default Editable;

const Container = styled.div`
  font-size: 12px;
`;

const Content = styled.div`
  padding: 0.2rem;
  white-space: pre-line;
  cursor: text;
  word-break: break-word;
`;

const EditContent = styled(Content)`
  background-color: ${(p) => p.theme.palette.backgroundTertiary};
`;
