import { AxiosRequestConfig } from 'axios';
import {getObjectValueByKey, handleError} from '@utils/misc';
import useCognito from "@as_core/account/useCognito";
import {RepositoriesT} from "@subApps/account/repositoryManagement/useRepos";
import {authClient} from "@utils/api/base";
import {UserT} from "@stores/UserContext";

const User = {
  get: (token:string, config:{[key:string]:any}) => authClient(token).get('/user', config),
  getRepositories: (token:string) => authClient(token).get('/user/repositories'),
  getAll: (token:string) => authClient(token).get('/user/all'),
  getUserAdmin: (token:string, userId: string) => authClient(token).get(`/user/details/${userId}`),
  create: (token:string, data:{[key:string]:any}) => authClient(token).post('/user/create', data),
  update: (token:string, data:{[key:string]:any}) => authClient(token).post('/user/update', data),
  delete: (token:string, data:{[key:string]:any}) => authClient(token).post('/user/delete', data),
};

export type SubscriptionT = {
  uuid: string;
  type: string;
  ownerAuthId: string;
  ownerAuthEmail: string;
  invitedUsers?: SubscriptionSharesT[];
  allowedInvites: number;
  allowedRepositories: number;
  created?: string;
  expires?: string;
  source?: string;
  sourceId?: string;
}

export type SubscriptionSharesT = {
  authId: string;
  authEmail: string;
  sharedOn: string;
}

export type AppInfoT = {
  _id?: string;
  authId?: string;
  roles?: string[];
  subscription: SubscriptionT;
  repositories: RepositoriesT;
  lastLogin?: string;
}

export interface ResponseI<T> {
  errors: Array<string>;
  data: T;
}

export function getUserSubscriptionType(appInfo:AppInfoT) {
  return getObjectValueByKey(appInfo.subscription, 'type', 'basic');
}

// in case of a shared subscription -- we allow the user a single repository
export function getMaxRepositories(user: UserT) {
  if (user.appInfo.subscription.ownerAuthId === user.authId) return user.appInfo.subscription.allowedRepositories;
  if (getUserSubscriptionType(user.appInfo) === 'basic') return 0;
  return 1;
}

export function getMaxInvites(user: UserT) {
  if (user.appInfo.subscription.ownerAuthId === user.authId) return user.appInfo.subscription.allowedRepositories;
  return 0;
}


const debug = false;
const useUserAppInfo = () => {
  const { getToken } = useCognito();

  const getUserInfo = async (token: string, params: {[key: string]: any}): Promise<ResponseI<AppInfoT>> => {
    const config: AxiosRequestConfig = {params};
    let resp;

    try {
      resp = await User.get(token, config);
      debug && console.log('getUserInfo | resp', resp);
    } catch (err) {
      alert(err);
      console.error('useUserAppInfo | getUserInfo ERRORS (err)', err)
      return null;
    }

    if (resp.data.errors.length > 0) {
      alert(resp.data.errors)
      console.error('useUserAppInfo | getUserInfo ERRORS', resp.data.errors);
    }
    return resp.data.data;
  };

  const getUserRepositories = async (): Promise<RepositoriesT> => {
    let resp;

    try {
      resp = await User.getRepositories(getToken());
    } catch (err) {
      alert(err);
      console.error('useUserAppInfo | getUserRepositories ERRORS (err)', err)
      return null;
    }

    if (resp.data.errors.length > 0) {
      alert(resp.data.errors)
      console.error('useUserAppInfo | getUserRepositories ERRORS', resp.data.errors);
    }
    return resp.data.data;
  };

  const getAllUsers = async (): Promise<any[]> => {
    let resp;

    try {
      resp = await User.getAll(getToken());
    } catch (err) {
      alert(err)
      console.error('useUserAppInfo | getUser ERRORS(err)', err);
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      alert(resp.data.errors);
      console.error('useUserAppInfo | getUser ERRORS', resp.data.errors);
    }
    if (resp?.data.data.length) return resp.data.data;
    return null;
  };

  const getUserByAdmin = async (userId:string): Promise<AppInfoT> => {
    let resp: any;

    try {
      resp = await User.getUserAdmin(getToken(), userId);
    } catch (err) {
      alert(err)
      console.error('useUserAppInfo | getUserByAdmin ERRORS(err)', err);
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      alert(resp.data.errors);
      console.error('useUserAppInfo | getUserByAdmin ERRORS', resp.data.errors);
    }
    return resp.data.data;
  };

  const deleteUserInfo = async (params:{[key:string]:any}): Promise<ResponseI<string>> => {
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await User.delete(getToken(), config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      alert(resp.data.errors);
      console.error('deleteUser ERRORS', resp.data.errors);
    }
    return resp.data;
  };

  const updateUserInfo = async (params:{[key:string]:any}): Promise<ResponseI<AppInfoT>> => {
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await User.update(getToken(), config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getUser ERRORS', resp.data.errors);
    }
    return resp.data[0];
  };

  return {
    getUserInfo,
    getUserRepositories,
    getAllUsers,
    getUserByAdmin,
    updateUserInfo,
    deleteUserInfo,
  };
};
export default useUserAppInfo;
