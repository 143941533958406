import React from 'react';

// in case need console log messages
const debug = false;

// utility function to take username and convert to initials
export function stringAvatar(user) {
  debug && console.log('stringAvatar | user.regInfo.name', user.regInfo?.name);
  let initials = '?';
  if (user.regInfo?.name) {
    if (typeof user.regInfo.name === 'string') {
      // Assuming name is a string and we want the first two characters
      initials = user.regInfo.name
    } else if (typeof user.regInfo.name === 'object' && user.regInfo.name.first && user.regInfo.name.last) {
      // Assuming name is an object with first and last properties
      initials = user.regInfo.name.first[0] + user.regInfo.name.last[0];
    }
  }
  return <>{initials}</>;
}
