import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {ProductCategoryT} from "@business/products/products.types";
import CardCarousel from "@as_core/elements/CardCarousel";

type PropsT = {
  productCategories: ProductCategoryT[];
  active?: string;
  onSelect?: (v: string) => void;
  debug?: boolean;
}

const ProductCategorySelector = (props:PropsT) => {
  const { productCategories, active } = props;
  const [cards, setCards] = useState<any[]>([]);
  props?.debug && console.log(props);

  useEffect(()=>{
    const productCards = productCategories.map((c) => (
      <CategorySelector
        active = {props?.active === c.categoryCode}
        onClick = {() => props.onSelect(c.categoryCode)}
      >
        <Icon active={props?.active === c.categoryCode}>
          {c?.icon ? c.icon : null}
        </Icon>
        <ProductTitle>{c.categoryName}</ProductTitle>
      </CategorySelector>
    ));
    setCards(productCards);
  },[productCategories, active])

  props?.debug && console.log('cards', cards);

  return(
    <CategorySelectorContainer>
      <CardCarousel
        cards={cards}
      />
    </CategorySelectorContainer>
  );
}

export default ProductCategorySelector;

const CategorySelectorContainer = styled.span`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;


const CategorySelector = styled.span<{active:boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 200px;
  color: ${(p) => p.active ?  
          p.theme.palette.textPrimary : p.theme.palette.textSecondary};
  border-radius: 15px;
  padding: 5px;
  background-color: ${(p) => p.theme.palette.backgroundTertiary};
  border: 1px solid ${(p) => p.active? 
          p.theme.palette.accentPrimary : p.theme.palette.accentSecondary};

  :hover {
    background-color: ${(p) => p.theme.palette.backgroundQuaternary };
  }
`;

const Icon = styled.div<{active:boolean}>`
  display: flex;
  padding-right: 5px;
  ${(p) => p.active ? `` : `filter: grayscale(1.0)`};
`

const ProductTitle = styled.div`
  display: flex;
  font-size: 14px;
  padding-left: 5px;

`;
