import React, {
  createContext,
  RefObject,
  useState,
  createRef,
} from 'react';
import {EXPORT_RATIOS, capture, download, IDimension, IFormat} from './screenshot.service';
import { ScreenshotModal } from './screenshot.modal';

export type IAction = (dimensions: IDimension) => any;
export type IActionCallback = (dimensions: IDimension) => Promise<() => void>;

export interface IActionSet {
  [key: string]: IAction;
}

export interface IScreenshotContext {
  reference: RefObject<HTMLDivElement>
  actions: IActionSet;
  registerAction: (key: string, action: IAction | IActionCallback) => void;
  print: () => Promise<void>;
  capture: (dimensions: IDimension, format: IFormat) => Promise<string>;
  download: (dimensions: IDimension, format: IFormat) => Promise<void>;
  openModal: () => void;
}

const ScreenshotContext = createContext({} as IScreenshotContext);

const ScreenshotProvider = ({children}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const reference = createRef<HTMLDivElement>();

  const context: IScreenshotContext = {
    reference: reference,
    actions: {},
    registerAction: (key, callback) => {
      context.actions[key] = callback;
    },
    print: async () => {
      const element = reference.current;
      const frame = element?.ownerDocument?.defaultView;
      const original = { height: element.style.height, width: element.style.width };
      const ratio = EXPORT_RATIOS.find(r => r.id === 'letter');
      const dimensions = {
        height: 800,
        width: Math.round((800 * ratio.ratio)),
      };
      element.style.width = `${dimensions.width}px`;
      element.style.height = `${dimensions.height}px`;

      const callbacks = await processActions(dimensions);
      frame.focus()
      frame.print();
      await processCallbacks(callbacks);

      element.style.width = original.width;
      element.style.height = original.height;
    },
    capture: async (dimensions: IDimension, format: IFormat) => {
      const element = reference.current;
      const original = { height: element.style.height, width: element.style.width };
      element.style.width = `${dimensions.width}px`;
      element.style.height = `${dimensions.height}px`;

      const callbacks = await processActions(dimensions);
      const image = await capture(element, dimensions, format, null);
      await processCallbacks(callbacks);

      element.style.width = original.width;
      element.style.height = original.height;
      return image;
    },
    download: async (dimensions: IDimension, format: IFormat) => {
      const element = reference.current;
      const original = { height: element.style.height, width: element.style.width };
      element.style.width = `${dimensions.width}px`;
      element.style.height = `${dimensions.height}px`;

      const callbacks = await processActions(dimensions);
      await download(element, dimensions, format);
      await processCallbacks(callbacks);

      element.style.width = original.width;
      element.style.height = original.height;
    },
    openModal: () => {
      setModalOpen(true);
    },
  };

  const processActions = async (dimensions: IDimension) => {
    const callbacks = {};
    for (const key in context.actions) {
      try {
        const action = context.actions[key];
        const callback = await action(dimensions);
        if (callback instanceof Promise) callbacks[key] = callback;
      } catch (ex) {
        console.error(`Screenshot context action failed to complete action=${key}`,ex);
      }
    }
    return callbacks;
  };

  const processCallbacks = async (callbacks) => {
    for (const key in callbacks) {
      try {
        await callbacks[key]();
      } catch (ex) {
        console.error(`Screenshot context action callback failed to complete. action=${key}`,ex);
      }
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <ScreenshotContext.Provider value={context}>
      {children}
      <ScreenshotModal
        open={modalOpen}
        onClose={handleClose}
      />
    </ScreenshotContext.Provider>
  );
};

export { ScreenshotProvider, ScreenshotContext };
