import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import './SelectSmall.css';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

interface SelectSmallProps {
  menuItems: { value: string | number; label: string; icon?: any }[];
  value: string | undefined;
  onChange: (event: SelectChangeEvent) => void;
  size: 'small' | 'medium' | 'large' | undefined;
  style?: React.CSSProperties; // Added style prop
  minwidth?: string; // Add the width prop
  maxwidth?: string; // Add the width prop
}

export default function SelectSmall({
  menuItems,
  value,
  onChange,
  size,
  style, // Receive and apply the style prop
  minwidth,
  maxwidth
}: SelectSmallProps) {
  const theme = useTheme();
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const isMobile = windowWidth <= 768;

  React.useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={style}>
      <FormControl sx={{ m: 1, mt: 3, "& .css-1k8ks8h-MuiPaper-root-MuiPopover-paper-MuiMenu-paper": {
            backgroundColor: "white"
          } }}>
        <Select
          displayEmpty
          value={value}
          onChange={onChange}
          input={<OutlinedInput />}
          style={{
            backgroundColor: '#F2F3F4',
            borderRadius: '5px',
            height: '40px',
            minWidth: isMobile ? minwidth : maxwidth
          }}
       
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
          size={size as any}
        >
          {menuItems.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <div style={{ display: 'flex', alignItems: 'center', float: "left", fontFamily: '"Roboto", "Helvetica","Arial",sans-serif',
                            fontWeight: 400, fontSize: '1rem', lineHeight: '1.4375em', letterSpacing: '0.00938em', color: 'black' }}>
                <span className='icon-selectsmall'>{item.icon}</span> {item.label}
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
