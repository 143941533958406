import React, { useState, useEffect, ReactNode, useContext } from 'react';
import styled, { ThemeContext, DefaultTheme } from 'styled-components/macro';
import { Card, Tooltip, IconButton, Typography } from '@mui/material';
import { NavigateNext, NavigateBefore } from '@mui/icons-material';
import { CardProps } from '@mui/material/Card/Card';
import { Stack } from '@mui/system';

export interface LayerCardProps {
  className?: string;
  children?: ReactNode;
  title?: string;
  expandable?: boolean;
  onChange?: (expanded: boolean) => void;
  disableExpand?: boolean;
}

export const LayerCard = ({
  title,
  children,
  className,
  onChange,
  disableExpand,
}: LayerCardProps) => {
  const [expanded, setExpanded] = useState(false);
  const themeContext = useContext(ThemeContext) as DefaultTheme;
  const iconStyles = {
    color: themeContext.palette.textSecondary,
  };

  useEffect(() => {
    onChange && onChange(expanded);
  }, [expanded]);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Panel className={className} elevation={4} $expanded={expanded}>
      {title && (
        <Stack
          direction={'row'}
          justifyContent={'center'}
          marginBottom={2}
          sx={{ textTransform: 'uppercase', width: '100%' }}
        >
          <Typography sx={{ fontSize: 13 }}>{title}</Typography>
        </Stack>
      )}
      <Stack alignItems='flex-start' spacing={2} marginTop={4}>
        {children}
      </Stack>
      <BottomRight>
        {!disableExpand && (
          <Tooltip
            placement={'left'}
            title={expanded ? '' : 'expand to see all parameters'}
          >
            <HoverButton size='small' onClick={handleExpand}>
              {expanded ? (
                <NavigateBefore sx={iconStyles} />
              ) : (
                <NavigateNext sx={iconStyles} />
              )}
            </HoverButton>
          </Tooltip>
        )}
      </BottomRight>
    </Panel>
  );
};

interface ExpandableCardProps extends CardProps {
  $expanded: boolean;
}
type ExpandableCard = (props: ExpandableCardProps) => JSX.Element;

const Panel = styled(Card as ExpandableCard)`
  position: relative;
  width: 100%;
  margin: 1px;
  padding: 10px 10px 40px 10px;
  border: 1px solid rgba(0, 0, 0, 0);
`;

const BottomRight = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 4px;
  cursor: pointer;

  & :hover {
    color: ${(p) => p.theme.palette.textPrimary};
  }
`;

const HoverButton = styled(IconButton)`
  opacity: 0.8;

  & :hover {
    opacity: 1;
  }
`;
