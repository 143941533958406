import React from 'react';
import styled from 'styled-components/macro';
import { Select, MenuItem, Input } from '@mui/material';

import { FilterGenericI } from '../filter.types';

const debug = false;
const FilterString = ({ filterName, filter, handleUpdate }: FilterGenericI) => {
  debug && console.log("FilterString ", filterName, filter);
  const [newValue, setNewValue] = React.useState(filter.value);
  const [newComparator, setNewComparator] = React.useState(filter.comparator);
  const inputRef = React.useRef();

  // just update locally while typing
  const handleLocalUpdateValue = (v) => {
    debug && console.log("handleLocalUpdateValue", v)
    setNewValue(v);
  }
  const handleLocalUpdateComparator = (v) => {
    debug && console.log("handleLocalUpdateComparator", v)
    setNewComparator(v);
  }

  // check to update when lose focus by mouse leave
  const checkToUpdate = () => {
    debug && console.log("check to update")
    if (newValue !== filter.value) {
      debug && console.log("  pushing new value");
      handleUpdate('string', {uuid: filter.uuid, value: newValue});
    }
    if (newComparator !== filter.comparator) {
      debug && console.log("  pushing new comparator");
      handleUpdate('string',{uuid: filter.uuid, comparator: newComparator});
    }
  }

  return (
    <FilterStringItem>
      <Row>
        <Item>
          {filterName}
        </Item>
        <Item>
          <StyledSelect
            id="comparator"
            variant={"standard"}
            value = {newComparator}
            onMouseLeave = {checkToUpdate}
            onChange = {(e) => {handleLocalUpdateComparator(e.target.value)}}
          >
            <MenuItem value="contains">contains</MenuItem>
            <MenuItem value="does not contain">does not contain</MenuItem>
          </StyledSelect>
        </Item>
        <Item>
          <StyledInput
            ref={inputRef}
            type='text'
            name={filter.key}
            value={newValue}
            placeholder={'placeholder'}
            onMouseLeave={checkToUpdate}
            onChange={(e) => { handleLocalUpdateValue(e.target.value)}}
          />
        </Item>
      </Row>
    </FilterStringItem>
  );
}

export default FilterString;

const FilterStringItem: any = styled.label`
  width: 500px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Item = styled.div`
  padding: 3px;
  display: flex;
`;

const StyledSelect = styled(Select)`
  width: 150px;
  height: 30px;
  padding-left: 5px;
  border: ${(p) => p.theme.borders.thinAccentPrimary};
`;

const StyledInput = styled(Input)`
  width: 150px;
  height: 30px;
  padding-left: 5px;
  border: ${(p) => p.theme.borders.thinAccentPrimary};
`;
