import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const DataFolderIcon = ({
    size = 24,
  }) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  const colorHighlight = style.palette.accentPrimary;
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={color} d="M21.8,17H2.2c-.6,0-1.2-.6-1.2-1.3V2.3c0-.7.5-1.3,1.2-1.3h6.7c.4,0,.8.2,1.1.4l2.2,2c.3.3.7.4,1.1.4h8.6c.6,0,1.2.6,1.2,1.3v10.5c0,.7-.5,1.3-1.2,1.3Z"/>
      <path fill={color} d="M20.8,2h-9l.9.9c.2.2.3.2.5.2h8.6c0-.6-.5-1.1-1-1.1Z"/>
      <path fill={colorHighlight}  d="M23.7,20.2h-9.4c-.2-.6-.7-1-1.3-1h-1.5c-.6,0-1.1.4-1.3,1H.7v.8h9.5c.2.5.7.9,1.3.9h1.5c.6,0,1.1-.4,1.3-.9h9.4v-.8Z"/>
      <rect fill={color}  x="11.8" y="16.3" width=".9" height="2.1"/>
    </svg>
  )
}

export default DataFolderIcon;