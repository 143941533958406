import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import HomeTileCard from '@as_core/tiles/HomeTileCard';
import useUserSettings from "@utils/api/useUserSettings";

import { HomeTileCardT } from '@as_core/tiles/HomeTileCard.type';
import useCognito from "@as_core/account/useCognito";

interface propsT {
  subscriptionLevel: string;
  tiles: Array<HomeTileCardT>;
  setTiles: any;
}

const debug = false;

// display a set of active/inactive tiles
const HomePageTiles = (props: propsT) => {
  const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false);
  debug && console.log('HomePageTiles | props: ', props);
  const { subscriptionLevel, tiles, setTiles } = props;
  const { getUserSettings, updateUserSettings } = useUserSettings();
  const { getToken } = useCognito();

  if (!settingsLoaded && subscriptionLevel !== 'basic') {
    getUserSettings(getToken(), 'home').then((resp)=>{
        debug && console.log("response", resp.data);
        if (resp?.data && Object.hasOwn(resp.data, 'pinnedTiles')) {
          let pinned = resp.data['pinnedTiles'];
          tiles.forEach((tile)=> {
            if (pinned.includes(tile.id)) {
              tile.isPinned = true
            }
          })
        }
        setSettingsLoaded(true);
    });
  }

  useEffect(()=>{
    debug && console.log("tiles updated");
    if (settingsLoaded && subscriptionLevel !== 'basic') { // need to make sure done override before first loaded
      const pinnedTiles = tiles
          .filter((obj) => obj.isPinned === true)
          .map((obj) => obj.id);
      updateUserSettings(getToken(),'home', {"pinnedTiles": pinnedTiles}).then();
      debug && console.log("updateUserSettings | pinnedTiles", pinnedTiles);
    }
  },[tiles]);

  const onClickPinned = (e, id) => {
    e.preventDefault();
    const localTiles = [...tiles];
    const usedTile = localTiles.filter((tile) => tile.id === id)[0];
    usedTile.isPinned = !usedTile.isPinned;
    setTiles([...localTiles]);
  };

  return (
    <TileContainer>
      <Cards>
        {tiles
          .sort((a, b) => (a.isPinned && !b.isPinned ? -1 : 1))
          .map((card) => {
            if (card?.restrict && card?.restrict.includes(subscriptionLevel)) return null
            return (
              <HomeTileCard
                onClickPinned={onClickPinned}
                key={card.id}
                subscriptionLevel={subscriptionLevel}
                card={card}
              />
            );
          })}
      </Cards>
    </TileContainer>
  );
};

const TileContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const Cards = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export default HomePageTiles;
