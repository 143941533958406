import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { select, zoom as d3zoom, zoomIdentity } from 'd3';
import { getScreenCoords } from '../utils/topo';
import Vertex from './Vertex';
import VertexPin from './VertexPin';

export function isVisible(transform: any, dimensions: {width: number, height: number}, d: {x: number, y: number}) {
  const offset = 100;
  const pos = getScreenCoords([d.x, d.y], transform);
  if (pos[0] + offset < 0 || pos[0] - offset > dimensions.width ||
    pos[1] + offset < 0 || pos[1] - offset > dimensions.height) return false;
  return true;
}

export const convertArrayToObject = (array: any, key: string) => {
  const initialValue = {};
  return array.reduce((obj:any, item:any) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

import {ZOOM_DURATION, MIN_ZOOM, MAX_ZOOM } from "@components/universe/utils/scale.config";
import {VectorPaneProps} from "@components/universe/types";
import {getClosestVertexByPosition, getNeighborVertices, getVerticesByIds} from "@components/universe/utils/vertex";
import {getVertexEdges} from "@components/universe/utils/tools";
import {labelColors} from "@components/universe/universe.config";

let debug = false;
/**
 * Draw the vertices when zoomed in with the corona ring and central image
 * @param data
 * @param pinnedCompounds
 * @param settings
 * @param updateSettings
 * @param aesSettings
 * @param transform
 * @param zoomed
 * @param showVertices
 * @param setHoveredVertexLabel
 * @constructor
 */
const VectorPane = ({
    data,
    pinnedCompounds,
    settings,
    updateSettings,
    aesSettings,
    transform,
    zoomed,
    showVertices,
    setHoveredVertexLabel
  }:VectorPaneProps) => {
  const { selectedVertex, dimensions } = settings;
  const { width, height } = dimensions;

  const zoomCatcherRef = useRef<SVGSVGElement>();
  const [ pinnedVertices, setPinnedVertices ] = useState<any[]>([]);
  const [svgPos, setSvgPos] = useState({ left: 0, top: 0 });
  const zoom = useRef<any>(
    d3zoom()
      .scaleExtent([MIN_ZOOM, MAX_ZOOM])
      .on('zoom', ({ transform }) => zoomed(transform))
  );

  function getPosition(e) {
    const scaledPos = [e.clientX - svgPos.left, e.clientY - svgPos.top];
    return transform.invert(scaledPos);
  }

  /**
   * handle the mouse movement that will select/set the hovered vertex and neighbors/edges that are drawn
   * in the Canvas2DPane and stored in the graphSettings (or settings)
   * @param e
   */
  function handleMouseMove(e) {
    const [x, y] = getPosition(e);
    const newHoveredVertex = getClosestVertexByPosition(settings.delaunay, x, y, transform.k, data.vertices);
    if (newHoveredVertex) {
      if (!settings.hoveredVertex ||
          (settings.hoveredVertex && newHoveredVertex.id !== settings.hoveredVertex?.id)) {
        // console.log('handleMouseMove | set HoveredVertex');
        setHoveredVertexLabel(newHoveredVertex?.name || newHoveredVertex?.id);
        updateSettings('hoveredVertex', newHoveredVertex);
        updateSettings('hoveredNeighborVertices', getNeighborVertices(newHoveredVertex.id, data.vertices, data.edges));
        updateSettings('hoveredEdges', getVertexEdges(newHoveredVertex.id, data.edges));
      }
    } else {
      if (settings.hoveredVertex) {
        // console.log('handleMouseMove | unset HoveredVertex');
        setHoveredVertexLabel('');
        updateSettings('hoveredVertex', undefined);
        updateSettings('hoveredNeighborVertices', []);
        updateSettings('hoveredEdges', []);
      }
    }
  }

  /**
   * handle the select/deselect of a node -- this also triggers the zoom
   * @param e
   */
  function handleClick(e) {
    const [x, y] = getPosition(e);
    const newSelectedVertex = getClosestVertexByPosition(settings.delaunay, x, y, transform.k, data.vertices);
    if (newSelectedVertex) {
      if (pinnedCompounds.data.includes(newSelectedVertex.id)) {
        pinnedCompounds.delete(newSelectedVertex.id);
      }
      updateSettings('selectedVertex', newSelectedVertex);
      updateSettings('selectedNeighborVertices', getNeighborVertices(newSelectedVertex.id, data.vertices, data.edges));
      updateSettings('selectedEdges', getVertexEdges(newSelectedVertex.id, data.edges));
    } else if (settings.selectedVertex) {
      updateSettings('selectedVertex', undefined);
      updateSettings('selectedNeighborVertices', []);
      updateSettings('selectedEdges', []);
    }
  }

  useEffect(() => {
    // need to make sure that we get colors on right pins -- getVerticesByIds does not maintain id order
    const vertices = convertArrayToObject(getVerticesByIds(pinnedCompounds.data, data.vertices), 'id');
    const pins = pinnedCompounds.data.map((id, i)=>({v: vertices[id], color: labelColors['pinned'][i]}));
    if (settings?.selectedVertex) {
      pins.push({v: settings.selectedVertex, color: labelColors['selected']});
    }
    setPinnedVertices(pins)
  }, [pinnedCompounds]);

  useEffect(() => {
    if (zoom.current) {
      select(zoomCatcherRef.current).call(zoom.current);
    }
  }, []);

  useEffect(() => {
    if (zoom.current) {
      const zoomCatcher = select(zoomCatcherRef.current);
      let updatedTransformation = zoomIdentity;
      if (selectedVertex) {
        const { x, y } = selectedVertex;
        updatedTransformation = updatedTransformation
          .translate(width / 2 + 125, height / 2)
          .scale(transform.k)  // DHR was ZOOM_DETAIL_SCALE
          .translate(-x - width / 100, -y);
      }
      zoomCatcher
        .transition()
        .duration(ZOOM_DURATION)
        .call(zoom.current.transform, updatedTransformation);
    }
  }, [selectedVertex]);

  useEffect(() => {
    if (zoomCatcherRef && zoomCatcherRef.current) {
      const { left, top } = zoomCatcherRef.current.getBoundingClientRect();
      setSvgPos({ left, top });
    }
  }, [dimensions]);

  const inViewVertices = data.vertices.filter((v) => isVisible(transform, settings.dimensions, {x: v.x, y: v.y}))
  debug && console.log("VectorPane | inVewVertices", inViewVertices.length);

  return (
    <Svg
      width={width}
      height={height}
      ref={zoomCatcherRef}
      onMouseMove={(e) => handleMouseMove(e)}
      onClick={(e) => handleClick(e)}
    >
      <Rect className='dummy-rect' x='0' y='0' width={width} height={height} />
      <g
        className='vertices'
        transform={'translate(' + transform.x + ' ' + transform.y + ') scale(' + transform.k + ')'}
      >
        {showVertices && inViewVertices
          .map((v) => {
            return (
              <Vertex
                key={v.id}
                vertex={v}
                radius={aesSettings[v.id]?.radius}
                vertexAesSettings={aesSettings[v.id]}
                settings={settings}
                hideValues={true}
              />
            );
        })}
      </g>
      <g className={'vertexPins'}
         transform={'translate(' + transform.x + ' ' + transform.y + ') scale(' + transform.k + ')'}
      >
        {pinnedVertices.map((pin) => {
          return(
            <VertexPin
              vertex={pin.v}
              color={pin.color}
              smallPin={showVertices}
            />
          )
        })}
      </g>
    </Svg>
  );
};

export default VectorPane;

const Svg = styled.svg`
  cursor: pointer;
  pointer-events: all;
`;

const Rect = styled.rect`
  fill: none;
  stroke: none;
`;
