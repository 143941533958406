import React, { Fragment } from 'react';
import styled from 'styled-components/macro';
import CompoundTile from '@components/compounds/view/CompoundTile';
import Scorecard from './platesCompare/scorecard/Scorecard';
import CompareTile from '../components/compareTile/CompareTile';

const PgPlatesCompare = () => {
  const compound = {};
  return (
    <Fragment>
      <PlatesCompare>
        <Row>
          <CompoundTile />
          <CompoundTile />
        </Row>
        <Row>
          <Scorecard compound={compound} />
          <CompareTile />
          <Scorecard compound={compound} />
        </Row>
      </PlatesCompare>
    </Fragment>
  );
};

export default PgPlatesCompare;

const PlatesCompare = styled.div`
  display: grid;
  row-gap: ${(p) => p.theme.sizes.small};
`;

const Row = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-around;
  column-gap: ${(p) => p.theme.sizes.small};
`;
