import styled, { DefaultTheme } from 'styled-components/macro';

interface OuterBarProps {
  isHorizontal?: boolean;
  theme: DefaultTheme;
}

interface InnerBarProps {
  width: string;
}

const debug = false;
const zebraRenderer = ({ className, cellData, column, width=30 }) => {
  debug && console.log("zebraRenderer: cellData", cellData)

  const getWidth = (v) => {
    let width = "0%"
    if (v === "NA") {
      width = "0%"
    } else if (v < 0.05) {
      return ("100%")
    } else {
      let log10_v = Math.log10(v)
      if (log10_v > 1.3) {
        width = "5%"
      } else {
        let scaled = 100.0*(1.3-log10_v)/2.6;
        scaled = Math.max(0, Math.min(100, scaled));
        width = scaled.toFixed(0).toString() + "%"
        debug && console.log('v:', v, 'log10_v', log10_v, 'scaled', scaled)
      }
    }
    debug && console.log('v:', v, 'width', width)
    return(width)
  }

  if (typeof(cellData) === "undefined" || cellData === "NT") {
    return (<></>);
  } else {
    return (
      <BarContainer>
        <OuterBar
          className={className}
          isHorizontal={column.barsHorizontal || false}
          width={width}
        >
          { cellData === "NA" ? <></> : <InnerBar width={getWidth(cellData)}></InnerBar> }
        </OuterBar>
      </BarContainer>
    );
  }
};

export default zebraRenderer;

const BarContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
`;

const OuterBar = styled.div<{width?: number; isHorizontal: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 10px;
  height: ${ (p) => p.width}px;
  margin: 1px;
  padding: 1px;
  border: 1px solid ${({ theme }) => theme.palette.textPrimary};
  border-radius: 2px;
  opacity: 0.7;
  transform: rotate(
    ${({ isHorizontal }: OuterBarProps) => (isHorizontal ? '90deg' : '0deg')}
  );
  transition: transform 0.4s ease-out;
`;
OuterBar.defaultProps = {
  width: 30,
}

const InnerBar = styled.div`
  width: 100%;
  height: ${({ width = '0%' }: InnerBarProps) => width};
  background-color: ${({ theme }) => theme.palette.titlePrimary};
  border: none;
  border-radius: 2px;
`;
