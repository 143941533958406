import {useState, useEffect, useContext} from 'react';
import styled from 'styled-components/macro';
import useFileImport from '../useFileImport';
import { Data } from '@utils/api';
import { useNavigate } from 'react-router-dom';
import TextButton from '@components/controls/TextButton';
import GraphIcon from '@components/icons/graph.icon';
import {createGenericDataSetFromExcel} from "@components/dataSets/dataSet.service";
import {UserContext} from "@stores/UserContext";
import useCognito from "@as_core/account/useCognito";

const debug = false;
const UploadGeneric = (props) => {
  const { height, width, handleNext } = props;
  const [dataUploaded, setDataUploaded] = useState<boolean>(false);
  const [uuid, setUuid] = useState('');
  const { user } = useContext(UserContext);
  const { getToken } = useCognito();
  const navigate = useNavigate();

  debug && console.log('UploadGeneric');
  const { context } = useFileImport();
  const { context: { workbook, columns }} = useFileImport();

  debug && console.log('context | workbook: ', workbook, 'columns: ', columns);

  const uploadGenericData = async () => {
    const dataSet = createGenericDataSetFromExcel(user.authId, context.workbook, context.columns);
    debug && console.log('UploadGeneric | dataSet:', dataSet);

    // encode the dataset
    let payload = dataSet;
    payload.data = encodeURIComponent(JSON.stringify(dataSet.data));

    await Data.create(getToken(), payload).then(() => {
      setUuid(payload.uuid);
    });
  };

  useEffect(() => {
    uploadGenericData()
      .then(() => {
        setDataUploaded(true);
      })
      .catch((error) => console.error(error));
    handleNext();
  }, []);

  return (
    <Container height={height} width={width}>
      <ControlContainer>
        {!dataUploaded ? (
          <>Data Being Saved</>
        ) : (
          <UploadedMessage>
            <Row>
              <Item>Successfully Uploaded Data</Item>
            </Row>
            <Row>
              <TextButton
                text='Create Plot'
                onClick={() =>
                  navigate('/plots/create', { state: { dataId: uuid } })
                }
                icon={<GraphIcon size={28} />}
                tooltipPlacement='bottom'
                width={200}
                height={32}
                margin='5px'
              />
              <TextButton
                text='Data List'
                onClick={() => navigate('/import/list')}
                icon={<GraphIcon size={28} />}
                tooltipPlacement='bottom'
                width={200}
                height={32}
                margin='5px'
              />
            </Row>
          </UploadedMessage>
        )}
      </ControlContainer>
    </Container>
  );
};

export default UploadGeneric;

const Container = styled.div<{ height?: number; width?: number }>`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const ControlContainer = styled.div`
  width: 100%;
`;

const UploadedMessage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
`;

const Item = styled.div`
  font-size: 14px;
  display: flex;
`;
