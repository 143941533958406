import styled from 'styled-components';
import {FlexRow, FlexItem} from "@as_core/elements/flexStyles";

import {CreateOptionsT} from "@components/universe/types";
interface CreateTileProps {
  card: CreateOptionsT,
  onClick: (v)=>void;
}

const Card = ({card, onClick}:CreateTileProps) => {
  return(
    <>
      <CardContent onClick={()=>onClick(card.value)}>
        <Header>
          <HeaderIcon>{card.icon}</HeaderIcon>
          <HeaderText>{card.label}</HeaderText>
        </Header>
        <Description>{card.description}</Description>
      </CardContent>
    </>
  )
}

export default Card;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 110px;
  width: 350px;
  margin: 10px;
  padding: 10px;
  align-content: center;
  background-color: ${(p) => p.theme.palette.backgroundSecondary };
  border: 1px solid ${(p) => p.theme.palette.accentSecondary };
  border-radius: 15px;
  :hover {
    border: 1px solid ${(p) => p.theme.palette.accentPrimary };
    background-color: ${(p) => p.theme.palette.backgroundQuaternary };
  }
`;

const Header = styled(FlexRow)`
  justify-content: center;
  align-items: center;
`;

const HeaderText = styled(FlexItem)`
  display: flex;
  font-size: ${(p) => p.theme.sizes.small};
  min-height: 36px;
  justify-content: center;
  text-align: center;
`;

const HeaderIcon = styled(FlexItem)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Description = styled.div`
  padding-top: 5px;
  text-align: center;
  font-size: ${(p) => p.theme.sizes.xsmall};
  line-height: ${(p) => p.theme.sizes.small};
  color: ${(p) => p.theme.palette.textSecondary};
`;

