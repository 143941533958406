import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import _ from 'lodash';
import { ListLoader } from '@as_core/elements/listStyles';
import useOrders from '@business/orders/useOrders';
import {OrderT} from '@business/types';
import { FlexRow } from '@as_core/elements/flexStyles';
import MenuButton from '@components/elements/MenuButton';
import BasicTable from '@as_core/tables/BasicTable';
import ViewIcon from '@as_core/icons/view.icon';
import ViewOrderDetails from "@subApps/orders/components/Orders/ViewOrderDetails";
import {renderOrderCost} from "@business/orders/components/utils";
import ErrorMessages, {MessageItem} from "@as_core/elements/UserMessages";

const tableFields = [
  { value: 'view', label: '', key: '', type: 'computed', width: 40 },
  { value: 'name', label: 'Order Name', width: 200},
  { value: 'created', label: 'Order Date', type: 'date', width: 150 },
  { value: 'type', label: 'Order Type', width: 100 },
  { value: 'line_items', label: '# Items', width: 80},
  { value: 'requests', label: '# Requests', width: 80},
  { value: 'status', label: 'Fulfilment Status', width: 150},
  { value: 'cost', label: 'Total Cost', width: 100},
];

const getTableRows = (orders: OrderT[], handleViewClick: (id:string)=>void) => {
  let rows = [];
  orders.forEach((order) => {
    let row = {};
    for (const field of tableFields) {
      if (field?.type && field.type === 'date') {
        const value = _.get(order, field.value, '');
        if (value !== '') {
          const dateObj = new Date(value)
          row[field.value] = dateObj.toLocaleDateString() + ' ' + dateObj.toLocaleTimeString();
        } else {
          row[field.value] = '';
        }
      } else if (field.value === 'view') {
        row[field.value] =
          <MenuButton
            onClick={() => handleViewClick(order?.orderId)}
            icon={<ViewIcon />}
            text={'View Order'}
            tooltipPlacement={'bottom'}
          />;
      } else if (field.value === 'line_items') {
        row[field.value] = Object.values(order.order).length;
      } else if (field.value === 'requests') {
        row[field.value] = order.requests.length;
      } else if (field.value === 'cost') {
        row[field.value] = renderOrderCost(order);
      } else if (field.value === 'payment_date') {
        row[field.value] = Number(_.get(order, 'payment.amount', 0)/100.0).toLocaleString();
      } else {
        row[field.value] = order[field.value];
      }
    }
    rows.push(row);
  });
  return rows;
};

interface PropsI {
  orderType: string;
}

const debug = false;
const OrdersList = (props:PropsI) => {
  const {orderType} = props;
  debug && console.log('ordersList {orderType}', orderType);
  const { getOrders } = useOrders();
  const [ orders, setOrders ] = useState<Array<OrderT>>([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedOrderView, setSelectedOrderView] = useState('success');
  const [loaded, setLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  debug && console.log('ordersList {orders}', orders);

  const filterOrders = (orderType: string) => {
    return orderType === 'complete' ? orders.filter((o) => o.open === false) : orders.filter((o) => o.open);
  }

  const handleViewClick = (orderId: string) => {
    debug && console.log('handleViewClick ', orderId);
    const matched:OrderT = orders.find((o) => o.orderId === orderId);
    if (matched) {
      setSelectedOrder(matched);
      if (matched.status === 'canceled') {
        setSelectedOrderView('canceled');
      } else {
        setSelectedOrderView('success');
      }
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      getOrders({in_process: orderType !== 'complete'}).then((res => {
        debug && console.log('useEffect | res: ', res);
        const orders = res.data[0].reverse();
        setOrders(orders); // TODO - Fix the return in the API
        setLoaded(true);
        setIsLoading(false);
      }));
    }
  }, [orderType]);

  debug && console.log('loaded', loaded, 'isLoading', isLoading, 'orders.length', orders.length);
  const filteredOrders = filterOrders(orderType);

  return (
    <>
      { loaded && !isLoading && filteredOrders.length ?
        <ListContainer>
          { selectedOrder !== null ?
            <ViewOrderDetails
              order={selectedOrder}
              viewMode={selectedOrderView}
              handleBack={()=>setSelectedOrder(null)}
              topBuffer={60}
            />
            :
            <BasicTable
              fields={tableFields}
              rows={getTableRows(filteredOrders, handleViewClick)}
              border={false}
            />
          }
        </ListContainer>
      : isLoading ?
          <FlexRow width={'100%'} height={'200px'} v_centered h_centered>
            <ListLoader />
          </FlexRow>
      :
          <ErrorMessages messages={[
            <MessageItem>You do not have any {orderType === 'complete' ? ' complete ' : ' open '} orders</MessageItem>
          ]}/>
      }
    </>
  );
};

export default OrdersList;

const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
