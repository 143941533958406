import React, { useContext } from 'react';
import { PieTooltipProps, ResponsivePie } from '@nivo/pie';
import { ThemeContext } from 'styled-components';
import { Margin, Theme } from '@nivo/core';
import { getDatavizTheme } from '@plotting/single-plot-view/plot-panel/plot.themes';
import { DEFAULT_TITLE_SIZE } from '@dataviz/constants';
import { TooltipContainer } from '@dataviz/TooltipContainer';

// Pie Chart Component
// A Wrapper around the NIVO's ResponsivePie component
// It adds a bit of styling to fit our application

const DEFAULT_MARGIN = {
  top: 60,
  right: 140,
  bottom: 90,
  left: 116,
};
const MIN_INNER_RADIUS = 0.015;
const DEFAULT_DONUT_RADIUS = 0.5;

export interface PieChartDatumType {
  id: string;
  value: number;
  label: string;
  color: string;
}

interface PieChartProps {
  data: PieChartDatumType[];
  datavizTheme?: Theme; // you can provide a dataviz theme OR the component will build it for you
  onClick?: (val) => void;
  isSorted?: boolean;
  margin?: Partial<Margin>;
  title?: string;
  titleSize?: number;
  isDonut?: boolean;
}

const PieChart = ({
  data,
  onClick,
  isSorted,
  margin,
  title,
  titleSize,
  isDonut,
  datavizTheme,
}: PieChartProps) => {
  // Dataviz theme can be passed as a prop (for the screenshotting feature for instance) OR built here
  const { palette } = useContext(ThemeContext);
  const finalDatavizTheme = datavizTheme ?? getDatavizTheme({}, palette);

  const getPieChartTooltip = ({
    datum,
  }: PieTooltipProps<PieChartDatumType>) => {
    return (
      <TooltipContainer>
        <p style={{ color: datum.color, fontSize: 14 }}>
          <b>{datum.label}</b>
        </p>
        <p>{'Value: ' + parseFloat(Number(datum.formattedValue).toFixed(3))}</p>
      </TooltipContainer>
    );
  };

  const plotTitleStyle = {
    fontFamily: finalDatavizTheme.fontFamily,
    fontSize: titleSize || DEFAULT_TITLE_SIZE,
    fill: finalDatavizTheme.textColor,
    textAnchor: 'middle',
  } as const;

  const PlotTitle = ({ centerX }) => {
    return (
      <text x={centerX} y={-20} style={plotTitleStyle}>
        {title}
      </text>
    );
  };

  return (
    <ResponsivePie
      // Below props can be customized through the app. Received as props.
      data={data}
      onClick={onClick}
      sortByValue={isSorted}
      margin={margin || DEFAULT_MARGIN}
      innerRadius={isDonut ? DEFAULT_DONUT_RADIUS : MIN_INNER_RADIUS}
      // Below props are NOT customizable. Consistent on the whole app.
      colors={{ datum: 'data.color' }}
      borderWidth={0}
      padAngle={1}
      cornerRadius={3}
      motionConfig='wobbly'
      activeOuterRadiusOffset={15}
      tooltip={getPieChartTooltip}
      theme={finalDatavizTheme}
      layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', PlotTitle]}
      valueFormat={'>-.3~d'}
      // Arc Link Label = what's outside the ring
      enableArcLinkLabels={true}
      arcLinkLabel={'data.label'}
      arcLinkLabelsTextOffset={2}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsThickness={1}
      arcLinkLabelsColor={finalDatavizTheme.textColor}
    />
  );
};

export default PieChart;
