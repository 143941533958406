import React from 'react';
import styled from 'styled-components/macro';

const SectionHeading = (props) => {
  return (
    <Row className={props?.className}>
      <SectionCell colSpan={2}>
        <SectionName>{props.children}</SectionName>
      </SectionCell>
    </Row>
  );
};

export default SectionHeading;

const Row = styled.tr``;

const SectionName = styled.div`
  text-transform: capitalize;
  padding-top: ${(p) => p.theme.sizes.small};
  padding-bottom: ${(p) => p.theme.sizes.small};
  outline: none;
  font-size: ${(p) => p.theme.sizes.default};
  font-weight: ${(p) => p.theme.weights.heavy};
  color: ${(p) => p.theme.palette.titleSecondary};
  box-sizing: border-box;
  border-bottom: ${(p) => p.theme.borders.thin};
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
`;

const SectionCell = styled.th`
  position: sticky;
  top: 0;
  z-index: 1;
`;
