import React, {useState} from 'react';
import styled from 'styled-components/macro';
import BasicTable from "@as_core/tables/BasicTable";
import {getObjectValueByKey} from "@utils/misc";
import InputSearchText from "@as_core/controls/inputs/InputSearchText";
import {FieldsTypeT} from "@subApps/admin/components/ObjectDetails";
import {getTableRows} from "@subApps/admin/components/shared";

const columns: FieldsTypeT[] = [
  { value: 'view', label: '', type: 'action', id: {key: 'authId', label: 'User'}, width: 40},
  { value: 'authId', label: 'authId', width: 260 },
  { value: 'authEmail', label: 'Email', width: 320 },
  { value: 'subscription', label: 'Subscription', width: 90},
  { value: 'lastLogin', label: 'Last Login', type:'date', width: 100 },
  { value: 'roles', label: 'Roles', type:'roles', width: 180 },
]

interface UserListI {
  users: any[];
  handleSelect: (v:string) => void;
}

const debug = false;
const UserList = (props:UserListI) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  debug && console.log('UserList | users: ', props.users);

  // HANDLERS
  const handleClick = (action: string, userId: string) => {
    debug && console.log('UserList | handleClick (action):', action, ' (userId):', userId);
    props.handleSelect(userId);
  };

  // search function
  function search(term: string) {
    debug && console.log('UserList | search term:', term);
    if (term.length > 0) {
      return props.users.filter((user) =>
        (
          getObjectValueByKey(user, 'authId').toLowerCase().includes(term.toLowerCase()) ||
          getObjectValueByKey(user, 'authEmail').toLowerCase().includes(term.toLowerCase()) ||
          getObjectValueByKey(user, 'subscription').toLowerCase().includes(term.toLowerCase())
        ));
    } else {
      return props.users;
    }
  }

  const filteredUsers = search(searchTerm);
  debug && console.log(searchTerm, filteredUsers);

  return (
    <UserListContainer>
      { props.users ?
        <>
          <InputSearchText
            value={searchTerm}
            width={'800px'}
            setValue={(e:any)=>setSearchTerm(e.target.value)}
            onKeyDown={search}
            showLabel={false}
          />
          <ScrollContainer >
            <BasicTable
              key={'user-table'}
              fields={columns}
              rows={getTableRows(filteredUsers, columns, handleClick)}
            />
          </ScrollContainer>
        </>
      :
      null }
    </UserListContainer>
  );
};
export default UserList;

const UserListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ScrollContainer = styled.div`
  display: flex;
  width: 1030px;
  max-height: calc(100vh - 140px);
  overflow-y: auto;
  overflow-x: hidden;
`;
