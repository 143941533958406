import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from '@mui/material';
import { PhotoCamera, SaveAlt } from '@mui/icons-material';
import { Dimensions } from './components/screenshot.dimensions';
import { ScreenshotContext } from './screenshot.context';
import { EXPORT_RATIOS, FORMATS } from './screenshot.service';

export interface ModalProps {
  open?: boolean;
  onExport?: () => void;
  onClose?: () => void;
}

const defaultRatio = EXPORT_RATIOS.find((s) => s.id === 'letter');
const defaultFormat = FORMATS[0];

export const ScreenshotModal = ({
  open: defaultOpen = false,
  onExport,
  onClose,
}: ModalProps) => {
  const [open, setOpen] = useState(defaultOpen);
  const [rendering, setRendering] = useState(false);
  const [dimensions, setDimensions] = useState({
    width: Math.round(defaultRatio.base * defaultRatio.ratio),
    height: defaultRatio.base
  });
  const [format, setFormat] = useState(defaultFormat);
  const context = useContext(ScreenshotContext);

  useEffect(() => {
    setDimensions({
      width: Math.round(defaultRatio.base * defaultRatio.ratio),
      height: defaultRatio.base
    });
  }, [defaultOpen]);

  const updateDimensions = (update) => {
    setDimensions(update);
  };

  const updateFormat = (event) => {
    const value = FORMATS.find((d) => d.id === event.target.value);
    setFormat(value);
  };

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  const handleExport = () => {
    setRendering(true);
    context.download(dimensions, format).then(() => {
      setRendering(false);
      onExport && onExport();
    });
  };

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  // @ts-ignore  Modal is a valid component despite the typescript warning
  const ModalTS = (props) => <Modal {...props} />;

  return (
    <ModalTS
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <InnerPanel>
        <ModalScroll>
          <ModalTitle>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Export Image
              <TitleIcon>
                <PhotoCamera />
              </TitleIcon>
            </Typography>
          </ModalTitle>
          <PanelBody>
            <Grid container spacing={2}>
              <Grid item xs={2} style={{ textAlign: 'right' }}>
                <Typography>Dimensions</Typography>
              </Grid>
              <Grid item xs={10}>
                <Dimensions value={dimensions} onChange={updateDimensions} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={2} style={{ textAlign: 'right' }}>
                <Typography>Format</Typography>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormControl variant='filled' fullWidth>
                      <InputLabel>format</InputLabel>
                      <Select
                        id='export-format'
                        value={format.id}
                        onChange={updateFormat}
                      >
                        {FORMATS.map((format, index) => (
                          <MenuItem key={index} value={format.id}>
                            {format.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <ModalFooter>
              <Button
                variant='contained'
                onClick={handleExport}
                disabled={rendering}
                endIcon={
                  rendering ? <CircularProgress size={'0.6rem'} /> : <SaveAlt />
                }
              >
                Export
              </Button>
            </ModalFooter>
          </PanelBody>
        </ModalScroll>
      </InnerPanel>
    </ModalTS>
  );
};

const InnerPanel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: ${(p) => p.theme.palette.backgroundTertiary};
  border: 2px solid #000;
  box-shadow: 24;
  padding: 0;
`;

const ModalScroll = styled.div`
  max-height: 100vh;
  overflow: auto;
`;

const ModalTitle = styled.div`
  position: relative;
  padding: 8px 16px;
`;

const TitleIcon = styled.div`
  position: relative;
  float: right;
`;

const PanelBody = styled.div`
  position: relative;
  padding: 8px 16px;
  min-height: 300px;
  border-top: 1px solid ${(p) => p.theme.palette.textPrimary};
`;

const ModalFooter = styled.div`
  position: relative;
  margin: 12px 0 8px 0;
  text-align: right;
`;
