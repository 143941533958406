import _ from 'lodash';
import React from 'react';
import styled from 'styled-components/macro';
import Scorecard from './scorecard/Scorecard';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { FieldSliceT } from '@stores/fields';

const CHIScorecardTile = (props) => {
  const { compound } = props;
  const { fields: allFields } = useSelector((state: RootState) => state.fields as FieldSliceT);
  const data_key = _.get(allFields, 'cell_health_index.data_key', null);
  const score = _.get(compound, data_key, null);
  return (
    <Container>
      { score===null ? (
        <></>
      ) : (
        <Scorecard compound={compound} />
      )}
    </Container>
  );
};

export default CHIScorecardTile;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
