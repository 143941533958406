import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import { useOnClickOutside } from '../../../hooks';

import Chip from './Chip';

const Dropdown = ({
  id = 'primary',
  label = 'Primary target',
  values = [],
  selectedIds = [],
  maxAllowed = 8,
  onSelect,
  onUnselect,
  onReset,
}) => {
  const containerRef = useRef();
  const [chips, setChips] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [hoveredSuggestion, setHoveredSuggestion] = useState(0);
  const [inputValue, setInputValue] = useState('');

  function handleSuggestionSelect(id) {
    if (selectedIds.includes(id)) {
      onUnselect(id);
    } else {
      if (selectedIds.length >= maxAllowed) {
        onUnselect(selectedIds[0]);
      }
      onSelect(id);
    }
    handleHideSuggestions();
  }

  function handleSuggestionUnselect(id) {
    onUnselect(id);
  }

  function handleAddClick() {
    setShowSuggestions(prev => !prev);
  }

  function handleShowSuggestions() {
    setHoveredSuggestion(0);
    setShowSuggestions(true);
  }

  function handleHideSuggestions() {
    setShowSuggestions(false);
  }

  function handleKeyDown(e) {
    if (['Esc', 'Escape'].includes(e.key)) {
      setShowSuggestions(false);
    }
    if (!showSuggestions) return;
    if (e.key === 'ArrowUp') {
      setHoveredSuggestion((state) => Math.max(state - 1, 0));
    } else if (e.key === 'ArrowDown') {
      setHoveredSuggestion((state) =>
        Math.min(state + 1, suggestions.length - 1)
      );
    }
  }

  useOnClickOutside(containerRef, handleHideSuggestions);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const regexp = new RegExp(inputValue, 'gi');
    setSuggestions(values.filter((d) => regexp.test(d.label)));
  }, [inputValue]);

  useEffect(() => {
    setInputValue('');
  }, [showSuggestions]);

  useEffect(() => {
    setChips(values.filter((d) => selectedIds.includes(d.value)));
  }, [values, selectedIds]);

  return (
    <Container ref={containerRef}>
      <Label>
        <span>{label}</span>
        {chips.length > 0 && <button onClick={()=>onReset()}>Reset</button>}
      </Label>
      <Field>
        <Chips>
          {chips.map((d) => (
            <Chip
              key={d.value}
              id={d.value}
              name={d.label}
              onClick={()=>handleSuggestionUnselect(d.value)}
            />
          ))}
          <Chip key={'add'} id={'+'} name={'+'} onClick={handleAddClick}/>
        </Chips>
      </Field>
      {showSuggestions && (
        <Suggestions>
          <Input
            id={id}
            type='text'
            placeholder='Search...'
            autocomplete='off'
            spellcheck='off'
            value={inputValue}
            onFocus={handleShowSuggestions}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <SuggestionList>
            {suggestions.map((d, i) => (
              <SuggestionListItem
                key={d.value}
                active={hoveredSuggestion !== null && hoveredSuggestion === i}
                selected={selectedIds.includes(d.value)}
                onMouseEnter={() => setHoveredSuggestion(i)}
                onMouseLeave={() => setHoveredSuggestion(null)}
                onClick={() => handleSuggestionSelect(d.value)}
              >
                {d.label}
              </SuggestionListItem>
            ))}
          </SuggestionList>
        </Suggestions>
      )}
    </Container>
  );
};

export default Dropdown;

const Container: any = styled.div`
  position: relative;
  width: 275px;
  user-select: none;
  & * {
    box-sizing: border-box;
  }
`;

export const Label: any = styled.label`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  color: ${(p) => p.theme.palette.titlePrimary};
  font-size: 14px;
`;

const Field: any = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  cursor: pointer;
  background-color: ${(p) => p.theme.palette.backgroundPrimary };
`;

const Chips: any = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding: 3px;
  border: 1px solid ${(props) => props.theme.palette.titlePrimary};
  border-radius: 5px;
  cursor: pointer;
  overflow-x: scroll;
`;

const Suggestions: any = styled.div`
  position: absolute;
  left: 0;
  z-index: 10000;
  width: 100%;
  border: 1px solid ${(props) => props.theme.palette.titleSecondary};
  border-bottom-left-radius: ${(props) => props.theme.borderRadius};
  border-bottom-right-radius: ${(props) => props.theme.borderRadius};
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
`;

const Input: any = styled.input`
  width: 100%;
  height: var(--chips-height);
  padding: 0 5px;
  color: ${(p) => p.theme.palette.titlePrimary};
  font-size: ${(props) => props.theme.sizes.small};
  font-weight: ${(props) => props.theme.weights.default};
  border: none;
  outline: none;
  background: none;
`;

const SuggestionList: any = styled.ul`
  width: 100%;
  max-height: 250px;
  list-style-type: none;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  & ::-webkit-scrollbar {
    display: none;
  }
`;

const SuggestionListItem: any = styled.li`
  width: 100%;
  font-size: 14px;
  padding: 0 5px;
  color: ${(props) => props.theme.palette.titlePrimary};
  cursor: pointer;

  ${({ active, theme }: any) =>
    active &&
    `
    background-color: ${theme.palette.accentSecondary};
  `}

  ${({ selected, theme }: any) =>
    selected &&
    `
    color: ${theme.palette.titlePrimary};
    background-color: ${theme.palette.accentPrimary};
  `}
`;
