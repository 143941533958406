import React from 'react';
import styled from 'styled-components/macro';
import { Slider } from '@mui/material';
import { FilterGenericI } from '../filter.types';

function valuetext(value: number) {
  return `${value}`;
}

const debug = false;
const FilterSlider = ({ filterName, filter, handleUpdate }: FilterGenericI) => {
  const [newValue, setNewValue] = React.useState(filter.value);

  const handleLocalUpdate = (v) => {
    debug && console.log("handleLocalUpdate: ", v);
    setNewValue(v);
  };
  // check to update when lose focus by mouse leave
  const checkToUpdate = () => {
    debug && console.log("checkToUpdate");
    if (newValue[0] !== filter.value[0] || newValue[1] !== filter.value[1]) {
      debug && console.log("  push update to refresh");
      handleUpdate('slider', { uuid: filter.uuid, value: newValue });
    }
  }

  return (
    <FilterSliderItem>
      <Title>
        {filterName}
      </Title>
      <StyledSlider
        value={newValue}
        onChange={(e, v) => {handleLocalUpdate(v)}}
        onChangeCommitted={checkToUpdate}
        valueLabelDisplay='auto'
        aria-labelledby='range-slider'
        min={filter.min}
        max={filter.max}
        step={filter.step}
        marks={filter.marks}
        getAriaValueText={valuetext}
      />
    </FilterSliderItem>
  );
}

export default FilterSlider;

const FilterSliderItem: any = styled.label`
  width: 500px;
`;

const Title = styled.div`
  font-size: calc(0.8 * ${(props) => props.theme.sizes.small});
`;

const StyledSlider = styled(Slider)`
  max-width: 450px;
  margin-left: 1.0rem;
  font-size: ${(props) => props.theme.sizes.small};
`;