import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const GraphIcon = ({
    size = 24,
  }) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path fillRule="evenodd" clipRule="evenodd" d="M6.5 11.25C7.32843 11.25 8 11.9216 8
          12.75V22.25H5V12.75C5 11.9216 5.67157 11.25 6.5 11.25Z" stroke={colorHighlight} strokeWidth="1.5"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M12.5 6.25C13.3284 6.25 14 6.92157 14
          7.75V22.25H11V7.75C11 6.92157 11.6716 6.25 12.5 6.25Z" stroke={colorHighlight} strokeWidth="1.5"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M18.5 15.25C19.3284 15.25 20 15.9216
          20 16.75V22.25H17V16.75C17 15.9216 17.6716 15.25 18.5 15.25Z" stroke={colorHighlight} strokeWidth="1.5"/>
          <path d="M22 22H2C1.72386 22 1.5 22.2239 1.5 22.5C1.5 22.7761 1.72386 23 2
          23H22C22.2761 23 22.5 22.7761 22.5 22.5C22.5 22.2239 22.2761 22 22 22Z" fill={color} stroke={color}/>
          <path d="M2.5 2C2.5 1.72386 2.27614 1.5 2 1.5C1.72386 1.5 1.5 1.72386 1.5
          2V22C1.5 22.2761 1.72386 22.5 2 22.5C2.27614 22.5 2.5 22.2761 2.5 22V2Z" fill={color} stroke={color}/>
        </g>
      </svg>
    </>

  )
}

export default GraphIcon;