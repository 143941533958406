import React, { useContext } from 'react';
import { DrcBase, PlotConfig } from '../../plot.types';
import { PlotContext } from '../plot.context';
import { Margin, Theme } from '@nivo/core';
import { DoseResponseCurve } from '@dataviz/dose-response-curve/DoseResponseCurve';
import {
  getDrcCurveData,
  getDrcScatterData,
} from './data-wrangling-utils/get-dose-response-curve-series';
import { DEFAULT_SMALL_GRAPH_MARGIN } from './constants';

type DoseResponseCurveWrapperProps = {
  datavizTheme?: Theme;
  providedPlot?: PlotConfig<DrcBase>;
  isSmallChart?: boolean;
};

export const DoseResponseCurveWrapper = ({
  datavizTheme,
  providedPlot,
  isSmallChart,
}: DoseResponseCurveWrapperProps) => {
  const plot =
    providedPlot ||
    (useContext(PlotContext) as { plot: PlotConfig<DrcBase> }).plot;

  const { doseColumn, responseColumn, groupByColumn } = plot.config;

  // Layer of data #1: points
  const data = getDrcScatterData(
    plot.data,
    doseColumn,
    responseColumn,
    groupByColumn
  );

  // Layer of data #2: curves
  const curveData = getDrcCurveData(plot.dataFormatted);

  return (
    <DoseResponseCurve
      data={data}
      curveData={curveData}
      margin={isSmallChart ? DEFAULT_SMALL_GRAPH_MARGIN : undefined}
      title={isSmallChart ? undefined : plot.name}
      titleSize={plot.config?.settings?.fontSize}
      datavizTheme={datavizTheme}
      isLegendEnabled={!isSmallChart}
    />
  );
};
