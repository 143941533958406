import React from 'react';
import styled from 'styled-components/macro';
import { getScreenCoords } from '../utils/topo';
import useWindowDimensions from '@as_core/hooks/useWindowDimensions';

import { XYCoordinate } from '@components/universe/types';
import LabeledVertex from "@components/universe/Vector/LabeledVertex";
import {labelColors} from "@components/universe/universe.config";

const debug = false;

const DetailHovered = ({
  transform,
  settings,
  pinnedCompounds,
  aesSettings,
  contextMenuOpen,
  hoveredVertexLabel,
}) => {
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const hoveredVertex = settings.hoveredVertex;

  function getLabelPosition(vertex: { x: any; y: any; }, label:string) {
    const pos = getScreenCoords([vertex.x, vertex.y], transform);
    const radius = 8 * transform.k;
    pos[0] = Math.min(pos[0] + radius, windowWidth - 15 * label.length);
    pos[1] = Math.max(pos[1], 40);
    return { x: pos[0], y: pos[1] };
  }

  let labelPosition: XYCoordinate = { x: 0, y: -100 };
  if (hoveredVertex) {
    labelPosition = getLabelPosition(hoveredVertex, hoveredVertexLabel);
  }
  debug && console.log("DetailHovered", labelPosition, hoveredVertex, hoveredVertexLabel);

  return (
    <>
      {hoveredVertex && !pinnedCompounds.data.includes(hoveredVertex.id) ? (
        <HoveredVertexContainer right={0}>
          <LabeledVertex
            labelColor={labelColors['hovered']}
            magnify={3}
            vertex={hoveredVertex}
            radius={6}
            vertexAesSettings={aesSettings[hoveredVertex.id]}
            settings={settings}
            selected={true}
            inGraph={false}
          />
        </HoveredVertexContainer>
      ) : (
        <></>
      )}
      {hoveredVertex && !contextMenuOpen ? (
        <HoveredVertexFloatingLabel
          x={labelPosition.x + 50}
          y={windowHeight - labelPosition.y - 100}
        >
          {hoveredVertexLabel}
        </HoveredVertexFloatingLabel>
      ) : (
        <></>
      )}
    </>
  );
};

export default DetailHovered;

const HoveredVertexFloatingLabel = styled.div<{ x: number; y: number }>`
  font-size: 16px;
  color: #39a9cb;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  border-radius: 10px;
  border: 2px solid ${(p) => p.theme.palette.accentSecondary};
  position: fixed;
  left: ${(p) => p.x}px;
  bottom: ${(p) => p.y}px;
  z-index: 10;
  padding: 2px 8px;
`;

const HoveredVertexContainer: any = styled.div<{ right?: number }>`
  position: fixed;
  flex-direction: column;
  justify-items: center;
  padding: 0;
  opacity: 1.0;
  height: 275px;
  width: 250px;
  top: 100px;
  right: ${(p) => p.right}px;
  z-index: 8;
`;
HoveredVertexContainer.defaultProps = {
  left: 50,
};
