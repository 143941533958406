import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const OrdersPastIcon = ({
    size = 24,
  }) => {
  const [style] = useContext(StyleContext);
  const color = style.name === "dark" ? "#BBBBBB" : "#2F2F2F";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={color} d="M19.2,2.5h-5.2c0.1-0.2,0.2-0.4,0.2-0.7c0-0.9-0.9-1.6-2.1-1.6S9.9,0.9,9.9,1.8c0,0.2,0.1,0.5,0.2,0.7H4.9
	c-1.4,0-2.6,1.2-2.6,2.6v16.3c0,1.4,1.2,2.6,2.6,2.6h14.3c1.4,0,2.6-1.2,2.6-2.6V5.1C21.8,3.6,20.6,2.5,19.2,2.5z M12,1.2
	c0.6,0,1.1,0.3,1.1,0.6S12.6,2.4,12,2.4s-1.1-0.3-1.1-0.6S11.4,1.2,12,1.2z M20.2,21.4c0,0.6-0.5,1.1-1.1,1.1H4.9
	c-0.6,0-1.1-0.5-1.1-1.1V5.1C3.8,4.5,4.2,4,4.9,4h14.3c0.6,0,1.1,0.5,1.1,1.1V21.4z"/>
      <path fill={colorHighlight} d="M10.5,16.4c-0.2,0-0.4-0.1-0.6-0.2l-3.1-2.1c-0.5-0.3-0.6-0.9-0.3-1.4c0.3-0.5,0.9-0.6,1.4-0.3l2.4,1.6l5.5-5.9
	c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L11.2,16C11,16.2,10.8,16.4,10.5,16.4z"/>
      <path fill={colorHighlight} d="M10.5,22c-0.2,0-0.4-0.1-0.6-0.2l-3.1-2.1c-0.5-0.3-0.6-0.9-0.3-1.4c0.3-0.5,0.9-0.6,1.4-0.3l2.4,1.6l5.5-5.9
	c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l-6.1,6.5C11,21.9,10.8,22,10.5,22z"/>
      <path fill={colorHighlight} d="M14.4,4.1l-4.1,4.3L8,6.8C7.5,6.4,6.9,6.6,6.6,7C6.3,7.5,6.4,8.1,6.8,8.4l3.1,2.1c0.2,0.1,0.4,0.2,0.6,0.2
	c0.3,0,0.5-0.1,0.7-0.3l5.9-6.3H14.4z"/>
    </svg>
  )
}

export default OrdersPastIcon;