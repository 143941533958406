import styled from 'styled-components/macro';
import { PlotEdit } from './plot-edit/PlotEdit';
import { PlotCreate } from './plot-create/PlotCreate';
import { useLocation, useParams } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { MenuContext } from '@stores/MenuContext';
import { PlotList } from './plot-list/PlotList';

const PgPlots = (props) => {
  const { className } = props;
  const { plotId } = useParams<'plotId'>();
  const location = useLocation();

  const isDrawerEditorOpen = location?.state?.isDrawerEditorOpen;

  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);

  useEffect(() => {
    if (activeMenuId !== 'plots') setActiveMenuId('plots');
  }, [activeMenuId]);

  // @ts-ignore
  const dataId = location?.state?.dataId;
  const dataType = location?.state?.dataType;
  const columns = location?.state?.columns;

  const getView = () => {
    return plotId === 'create' && dataId ? (
      <PlotCreate dataId={dataId} dataType={dataType} columns={columns} />
    ) : plotId ? (
      <PlotEdit plotId={plotId} isDrawerEditorOpen={isDrawerEditorOpen} />
    ) : (
      <PlotList />
    );
  };

  return <Container className={className}>{getView()}</Container>;
};

export default PgPlots;

const Container = styled.div`
  height: 100vh;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
`;
