import React, {useState, useEffect, useContext} from 'react';
import styled from 'styled-components/macro';
import {useDispatch} from 'react-redux';
import {useNavigate} from "react-router-dom";
import PageContainer from '@as_core/pages/PageContainer';
import DataFieldSelector from '@components/dataFields/DataFieldSelector';
import DataFieldEditor from '@components/dataFields/DataFieldEditor';
import {CompoundFieldT, removeField} from '@stores/fields';
import useCognito from "@as_core/account/useCognito";
import useFields from '@utils/useFields';
import DraggableModal from "@components/elements/DraggableModal";
import DataFieldNew from "@components/dataFields/DataFieldNew";
import {MenuContext} from "@stores/MenuContext";

const debug = false;
const PgFieldEditor = () => {
  const [fields, setFields] = useState<Array<CompoundFieldT>>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedField, setSelectedField] = useState<string>('');
  const [selectedFieldInfo, setSelectedFieldInfo] = useState<CompoundFieldT>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getToken } = useCognito();
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);
  if (activeMenuId !== 'fields') setActiveMenuId('fields');

  debug && console.log("PgFieldEditor | selectedField:", selectedField, " fields", fields);
  // load fields and set into a fields array
  const { getAllFields } = useFields();
  useEffect(() => {
    debug && console.log("PgFieldEditor | useEffect | loaded:", loaded);
    if (!loaded) {
      getAllFields(getToken()).then((response) => {
        setFields(response?.data);
        setLoaded(true);
      })
    }
  }, [loaded]);

  useEffect(() => {
    const newField = fields.find((f) => f?.field_id === selectedField);
    setSelectedFieldInfo(newField);
  }, [selectedField, fields]);

  const handleOnEdit = (id='') => {
    debug && console.log('PgFieldEditor | handleOnEdit | id', id);
    if (id !== '' && id !== selectedField) {
      setSelectedField(id);
    }
    setLoaded(false);
  }

  const handleFieldClick = (field_id) => {
    debug && console.log('PgFieldEditor | handleFieldClick | field_id:', field_id);
    if (field_id !== selectedField) {
      setSelectedField(field_id);
    } else {
      setSelectedField('');
    }
  }

  const handleFieldDelete = (id) => {
    debug && console.log('PgFieldEditor | handleFieldDelete | id:', id);
    const token = getToken();
    dispatch(removeField({ token, id: id }));
    if (!selectedFieldInfo?._source.includes('system')) {
      setSelectedField('');
    }
    setLoaded(false);
  }

  // RENDER
  return (
    <PageContainer title='Data Fields Editor' >
        <FieldContent>
          <StyledDataFieldSelector
            showBack={true}
            selectedField={selectedField}
            fields={fields}
            handleFieldClick={handleFieldClick}
            newField={true}
            newFieldActive={isModalOpen}
            newFieldClick={()=>setIsModalOpen(!isModalOpen)}
          />
          <EditorContainer>
            {
              selectedField !== '' ?
                <DataFieldEditor
                  dataFieldId={selectedField}
                  dataFieldInfo={selectedFieldInfo}
                  updateOnEdit={handleOnEdit}
                  handleDelete={handleFieldDelete}
                /> :
                <></>
            }
          </EditorContainer>
        </FieldContent>
      <DraggableModal
          title={"Add New Field"}
          modalOpen={isModalOpen}
          opacity={1.0}
          width={750}
          height={500}
          onCloseClick={()=>setIsModalOpen(false)}
      >
        <DataFieldNew
            updateOnEdit={handleOnEdit} // force refresh
            updateOnSave={()=>setIsModalOpen(false)} // close modal
        />
      </DraggableModal>
    </PageContainer>
  );
};

export default PgFieldEditor;

const FieldContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 60px);
  overflow-y: hidden;
`;

const StyledDataFieldSelector = styled(DataFieldSelector)`
  display: flex;
  width: 40%;
`;

const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 5px 0 0;
  min-width: 60%;
  max-width: 60%;
  height: calc(100vh - 90px);
  overflow-y: auto;
  overflow-x: hidden;
`;

const Spacer = styled.div`
  display: flex;
  height: 20px;
`;