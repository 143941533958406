import {GridCheckIcon} from '@mui/x-data-grid';
import SubscriptionPrice from "@business/subscriptions/components/SubscriptionPrice";

export type SubscriptionDataT = {
  allowedInvites: number,
  createdOn: string,
  expiresOn: string,
  orgId: string,
  type: string,
}

// TODO -- store in database
export const SubscriptionsData = {
  'columns': [
    {label: '', value: 'feature', width: 400, fontColor: 'primary'},
    {label:'Basic', value: 'basic', width: 125},
    {label:'Starter', value: 'starter', width: 170},
    {label:'Academic+', value: 'academic', width: 170},
    {label:'Commercial+', value: 'commercial', width: 170}
  ],
  'rows': [
    { 'feature': '',
      'basic': <SubscriptionPrice type='basic'/>,
      'starter': <SubscriptionPrice type='starter'/>,
      'academic': <SubscriptionPrice type='academic'/>,
      'commercial': <SubscriptionPrice type='commercial'/>,
    },
    { 'feature': 'Purchase compounds (US only) and NAM/Assay services',
      'basic': <GridCheckIcon/>,
      'starter': <GridCheckIcon/>,
      'academic': <GridCheckIcon/>,
      'commercial': <GridCheckIcon/>
    },
    { 'feature': 'Draw, save and export structures',
      'basic': <GridCheckIcon/>,
      'starter': <GridCheckIcon/>,
      'academic': <GridCheckIcon/>,
      'commercial': <GridCheckIcon/>
    },
    { 'feature': 'Compare your compounds to publicly-available databases',
      'basic': '20 / month',
      'starter': '100 / month',
      'academic': 'Unlimited',
      'commercial': 'Unlimited'
    },
    { 'feature': 'Access popular, visualized data sets',
      'basic': <GridCheckIcon/>,
      'starter': <GridCheckIcon/>,
      'academic': <GridCheckIcon/>,
      'commercial': <GridCheckIcon/>
    },
    { 'feature': 'Securely upload & store your compound structures',
      'basic': '',
      'starter': '10,000',
      'academic': 'Unlimited',
      'commercial': 'Unlimited'
    },
    { 'feature': 'Upload and store compound metadata (toxicology, annotations)',
      'starter': <GridCheckIcon/>,
      'academic': <GridCheckIcon/>,
      'commercial': <GridCheckIcon/>
    },
    { 'feature': 'Generate physical chemical properties',
      'basic': '',
      'starter': <GridCheckIcon/>,
      'academic': <GridCheckIcon/>,
      'commercial': <GridCheckIcon/>
    },
    { 'feature': 'Easy to user graphing and plotting tools',
      'starter': <GridCheckIcon/>,
      'academic': <GridCheckIcon/>,
      'commercial': <GridCheckIcon/>
    },
    { 'feature': 'Customizable and standardized table views',
      'starter': <GridCheckIcon/>,
      'academic': <GridCheckIcon/>,
      'commercial': <GridCheckIcon/>
    },
    { 'feature': 'Compound Universe - compare biological fingerprints',
      'academic': <GridCheckIcon/>,
      'commercial': <GridCheckIcon/>
    },
    { 'feature': 'Create your own compound Universe (e.g. Kinome)',
      'academic': <GridCheckIcon/>,
      'commercial': <GridCheckIcon/>
    },
    { 'feature': 'Variety of NAM fingerprint libraries for comparison',
      'academic': <GridCheckIcon/>,
      'commercial': <GridCheckIcon/>
    }
  ]
}
