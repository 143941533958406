import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import useWindowDimensions from '@as_core/hooks/useWindowDimensions';
import { RootState } from '../../../store';

interface MagnifiedSvgImageProps {
  imageOffset?: number;
  size?: number;
  offsetRight?: boolean;
}

const MagnifiedSvgImage = ({
                 imageOffset=50,
                 size=300,
                 offsetRight=true
      }:MagnifiedSvgImageProps) => {
  const { x, y, visible, svg_url } = useSelector(
    (state: RootState) => state.magnifiedSvgImage
  );
  const { height } = useWindowDimensions();

  let top = y;
  if (top > height-300) {
    top = y - 200
  }
  let left = x + imageOffset;
  if (!offsetRight) {
    left = x - 300;
  }
  //console.log("y, windowHeight:", top, height);

  return (
    <>
      {visible ? (
        <MoleculeImage top={top} left={left} size={size}>
          <img src={svg_url} alt=""/>
        </MoleculeImage>
      ) : (
        <React.Fragment />
      )}
    </>
  );
};

export default MagnifiedSvgImage;

const MoleculeImage = styled.div<{top: number, left: number, size: number}>`
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
  border: ${(p) => p.theme.borders.thin};
  position: fixed;
  border-radius: 5px;
  height: ${(p) => p.size}px;
  width: ${(p) => p.size}px;
  top: ${(p)=>p.top}px;
  left: ${(p)=>p.left}px;
  z-index: 3;
  box-shadow: 3px 0.1rem 0.2rem #000;
`;
