import React from 'react';
import * as XLSX from 'xlsx';
import { FileImportContext } from '@stores/FileImportContext';

export interface workbookT {
  filename: string;
  workbook: XLSX.WorkBook;
}

export interface ColumnRowsT {
  import: boolean;
  matchedCompoundUUID?: string;
}

export interface ColumnT {
  sheetName: string;
  sheetIdx: number;
  colName: string;
  colIdx: number;
  colType: string;
  selected: boolean;
  fieldFilter: string;
  field_id: string;
  data: any[];
  // rows: ColumnRowsT[];
}
export const ColumnsInit: ColumnT[] = [];

export interface LibraryT {
  id: string;
  name: string;
  selected: boolean;
}

export const LibrariesInit: LibraryT[] = [
  {
    name: 'My Library',
    id: 'user',
    selected: true,
  },
  {
    name: 'AsedaSciences',
    id: 'aseda',
    selected: true,
  },
];

// ###########################################################################
// Primary Interface
// ###########################################################################
export interface UseFileImportContextT {
  workbook: workbookT;
  setWorkbook: React.Dispatch<React.SetStateAction<workbookT>>;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  columns: ColumnT[];
  setColumns: React.Dispatch<React.SetStateAction<ColumnT[]>>;
  libraries: LibraryT[];
  setLibraries: React.Dispatch<React.SetStateAction<LibraryT[]>>;
  activeColumn: number;
  setActiveColumn: React.Dispatch<React.SetStateAction<number>>;
  importer: string;
  setImporter: React.Dispatch<React.SetStateAction<string>>;
  fileLoaded: boolean;
  setFileLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  requests: any;
  setRequests: React.Dispatch<React.SetStateAction<any>>;
}

const parseColumnNames = (workbook, sheetName) => {
  const regEx = new RegExp('^([A-Z]+)(1){1}$');
  let sheets = [];
  const worksheet = workbook.Sheets[sheetName];
  for (let key in worksheet) {
    // console.log("parseColumnNames | key", key);
    if (regEx.test(key) === true) {
      // console.log("parseColumnNames | key (passed RegExp", key);
      sheets.push(worksheet[key].v);
    }
  }
  return [...sheets];
};

function clampRange(range) {
  if (range.e.r >= 1 << 20) range.e.r = (1 << 20) - 1;
  if (range.e.c >= 1 << 14) range.e.c = (1 << 14) - 1;
  return range;
}
/*
  deletes `nrows` rows STARTING WITH `start_row`
  - ws         = worksheet object
  - start_row  = starting row (0-indexed) | default 0
  - nrows      = number of rows to delete | default 1
*/

const deleteRows = (
  ws: XLSX.WorkSheet,
  startRow: number = 0,
  nrows: number = 1
): void => {
  if (!ws) throw new Error('Operation expects a worksheet');
  const dense = Array.isArray(ws);
  const range = XLSX.utils.decode_range(ws['!ref']);
  const crefregex =
    /(^|[^._A-Z0-9])([$]?)([A-Z]{1,2}|[A-W][A-Z]{2}|X[A-E][A-Z]|XF[A-D])([$]?)([1-9]\d{0,5}|10[0-3]\d{4}|104[0-7]\d{3}|1048[0-4]\d{2}|10485[0-6]\d|104857[0-6])(?![_.(A-Za-z0-9])/g;

  const formulaCallback = ($0, $1, $2, $3, $4, $5) => {
    let row = XLSX.utils.decode_row($5);
    const col = XLSX.utils.decode_col($3);
    if (row >= startRow) {
      row -= nrows;
      if (row < startRow) return '#REF!';
    }
    return (
      $1 +
      ($2 === '$' ? $2 + $3 : XLSX.utils.encode_col(col)) +
      ($4 === '$' ? $4 + $5 : XLSX.utils.encode_row(row))
    );
  };

  // Move cells and update formulae
  if (dense) {
    for (let row = startRow + nrows; row <= range.e.r; ++row) {
      if (ws[row])
        ws[row].forEach(
          (cell) => (cell.f = cell.f.replace(crefregex, formulaCallback))
        );
      ws[row - nrows] = ws[row];
    }
    ws.length -= nrows;
    for (let row = 0; row < startRow; ++row) {
      if (ws[row])
        ws[row].forEach(
          (cell) => (cell.f = cell.f.replace(crefregex, formulaCallback))
        );
    }
  } else {
    for (let row = startRow + nrows; row <= range.e.r; ++row) {
      for (let col = range.s.c; col <= range.e.c; ++col) {
        const addr = XLSX.utils.encode_cell({ r: row, c: col });
        const newAddr = XLSX.utils.encode_cell({ r: row - nrows, c: col });
        if (!ws[addr]) {
          delete ws[newAddr];
          continue;
        }
        if (ws[addr].f)
          ws[addr].f = ws[addr].f.replace(crefregex, formulaCallback);
        ws[newAddr] = ws[addr];
      }
    }
    for (let row = range.e.r; row > range.e.r - nrows; --row) {
      for (let col = range.s.c; col <= range.e.c; ++col) {
        const addr = XLSX.utils.encode_cell({ r: row, c: col });
        delete ws[addr];
      }
    }
    for (let row = 0; row < startRow; ++row) {
      for (let col = range.s.c; col <= range.e.c; ++col) {
        const addr = XLSX.utils.encode_cell({ r: row, c: col });
        if (ws[addr] && ws[addr].f)
          ws[addr].f = ws[addr].f.replace(crefregex, formulaCallback);
      }
    }
  }

  range.e.r -= nrows;
  if (range.e.r < range.s.r) range.e.r = range.s.r;
  ws['!ref'] = XLSX.utils.encode_range(clampRange(range));

  // Handle merged cells
  if (ws['!merges']) {
    ws['!merges'].forEach((merge, idx) => {
      let mergerange;
      switch (typeof merge) {
        case 'string':
          mergerange = XLSX.utils.decode_range(merge);
          break;
        case 'object':
          mergerange = merge;
          break;
        default:
          throw new Error('Unexpected merge ref ' + merge);
      }
      if (mergerange.s.r >= startRow) {
        mergerange.s.r = Math.max(mergerange.s.r - nrows, startRow);
        if (mergerange.e.r < startRow + nrows) {
          delete ws['!merges'][idx];
          return;
        }
      } else if (mergerange.e.r >= startRow)
        mergerange.e.r = Math.max(mergerange.e.r - nrows, startRow);
      clampRange(mergerange);
      ws['!merges'][idx] = mergerange;
    });
    ws['!merges'] = ws['!merges'].filter((x) => !!x);
  }

  // Handle rows
  if (ws['!rows']) ws['!rows'].splice(startRow, nrows);
};

const useFileImport = () => {
  const context: UseFileImportContextT = React.useContext(FileImportContext);

  const readFileAsync = (inputFile: File): Promise<ArrayBuffer> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };
      reader.onload = () => {
        resolve(reader.result as ArrayBuffer);
      };
      reader.readAsArrayBuffer(inputFile);
    });
  };

  // cwestblog.com/2013/09/05/javascript-snippet-convert-number-to-column-name/
  const toColumnName = (num: number): string => {
    let ret, a, b;
    for (ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
      ret = String.fromCharCode((num % b) / a + 65) + ret;
    }
    return ret;
  };

  const getPreview = (
    wb: XLSX.WorkBook,
    sheetName: string,
    colIdx: number,
    rowIdx: number = -1
  ): string[] => {
    const range_orig = wb.Sheets[sheetName]['!ref'];
    const range_elems = range_orig.match(/[a-zA-Z]+|[0-9]+/gi);
    const colLetter = toColumnName(colIdx + 1);
    wb.Sheets[sheetName][
      '!ref'
    ] = `${colLetter}2:${colLetter}${range_elems[3]}`;
    const result = XLSX.utils.sheet_to_json(wb.Sheets[sheetName], {
      header: 1,
    }) as any[][];
    wb.Sheets[sheetName]['!ref'] = range_orig;

    const column = result.map((item) => item[0]);
    const previewColumn = column.slice(0, Math.min(column.length, 7));
    previewColumn.push('...');

    if (rowIdx === -1) {
      return previewColumn;
    } else {
      return previewColumn[rowIdx] ? [previewColumn[rowIdx]] : [''];
    }
  };

  const getRow = (
    wb: XLSX.WorkBook,
    sheetName: string,
    colIdx: number
  ): any[] => {
    const range_orig = wb.Sheets[sheetName]['!ref'];
    const range_elems = range_orig.match(/[a-zA-Z]+|[0-9]+/gi);
    const colLetter = toColumnName(colIdx + 1);
    wb.Sheets[sheetName][
      '!ref'
    ] = `${colLetter}2:${colLetter}${range_elems[3]}`;
    const result = XLSX.utils.sheet_to_json(wb.Sheets[sheetName], {
      header: 1,
    }) as any[][];
    wb.Sheets[sheetName]['!ref'] = range_orig;
    return result.map((item) => item[0]);
  };

  const getColumnType = (
    wb: XLSX.WorkBook,
    sheetName: string,
    colIdx: number
  ): string => {
    const typeMap = {
      b: 'boolean',
      e: 'error',
      n: 'number',
      d: 'date',
      s: 'string',
      z: 'blank',
    };
    const sheet = wb.Sheets[sheetName];
    const range_orig = sheet['!ref'];
    const range_elems = range_orig.match(/[a-zA-Z]+|[0-9]+/gi);
    const colLetter = toColumnName(colIdx + 1);
    const cellTypes: { [key: string]: number } = {};

    for (let i = Number(range_elems[1]) + 1; i < Number(range_elems[3]); i++) {
      const cellIdx = colLetter + i.toString();
      if (sheet[cellIdx] && sheet[cellIdx].t) {
        const cellType = typeMap[sheet[cellIdx].t];
        cellTypes[cellType] = (cellTypes[cellType] || 0) + 1;
      }
    }

    if (Object.keys(cellTypes).length > 1) {
      console.log(
        `Potential Issue: more than 1 cell type in column ${colLetter}, cellTypes:`,
        cellTypes
      );
    }
    return Object.keys(cellTypes)[0];
  };

  const getWorkbook = async (
    acceptedFiles: File[]
  ): Promise<workbookT | undefined> => {
    if (acceptedFiles.length > 1) {
      alert('One file at a time. First file will be used');
    }
    const file = acceptedFiles.pop();
    if (file) {
      try {
        const fileData = await readFileAsync(file);
        const workbook = XLSX.read(fileData, { type: 'array' });

        return { filename: file.name, workbook };
      } catch (err) {
        console.error(err);
      }
    }
  };

  const removePickedColumn = (columnIdx: number) => {
    context.setColumns((columns) => {
      // console.log('Column to be removed:', columnIdx);
      columns[columnIdx] = {
        ...columns[columnIdx],
        selected: false,
        fieldFilter: null,
        field_id: null,
      };

      // Intentionally not using getIdentityColumns function
      const identityColumns = columns.filter(
        (item) => item.fieldFilter === 'Identity'
      );

      if (identityColumns.length == 0 && context.activeStep > 1) {
        context.setActiveStep((curStep) => {
          return curStep - 1;
        });
      }
      return [...columns];
    });
  };

  const removePickedColumnNoCheck = (columnIdx: number): void => {
    context.setColumns((columns) =>
      updateColumnSelection(columns, columnIdx, {
        selected: false,
        fieldFilter: null,
        field_id: null,
      })
    );
  };

  const getIdentityColumns = React.useCallback(() => {
    return context.columns.filter((item) => item.fieldFilter === 'Identity');
  }, [context.columns]);

  const removeIdentityColumns = () => {
    context.setColumns((columns) => {
      return columns.map((column) => {
        if (column.fieldFilter === 'Identity') {
          column = {
            ...column,
            selected: false,
            fieldFilter: null,
            field_id: null,
          };
        }
        return column;
      });
    });
  };


  const mapFieldToActiveColumn = (field_id: string): void => {
    const activeColumn = context.activeColumn;
    context.setColumns((columns) => {
      const rows = getPreview(
        context.workbook.workbook,
        columns[activeColumn].sheetName,
        columns[activeColumn].colIdx
      ).map((): ColumnRowsT => ({ import: false }));
      const updatedColumn = { ...columns[activeColumn], field_id, rows };
      return updateColumnSelection(columns, activeColumn, updatedColumn);
    });
  };

  const mapFieldToActiveColumnMatch = (field_id, columnIdx) => {
    setActiveColumn(columnIdx);
    const activeColumn = context.activeColumn;
    // Grab rows from workbook column and map them in to the rows property
    // of the column object.  This will be used during the library search
    // and import process.
    context.setColumns((columns) => {
      const rows = getPreview(
        context.workbook.workbook,
        columns[columnIdx].sheetName,
        columns[columnIdx].colIdx
      ).map((): ColumnRowsT => ({ import: false }));
      const updColumn = { ...columns[columnIdx], field_id, rows };
      columns[columnIdx] = updColumn;
      return columns;
    });
  };

  const selectColumn = ({
    columnIdx,
    fieldFilter,
  }: {
    columnIdx: number;
    fieldFilter: string;
  }): void => {
    context.setColumns((columns) => {
      const updColumn = { ...columns[columnIdx], fieldFilter };
      columns[columnIdx] = updColumn;
      return columns;
    });
    setActiveColumn(columnIdx);
  };

  const updateColumnSelection = (
    columns: ColumnT[],
    columnIdx: number,
    updates: Partial<ColumnT>
  ): ColumnT[] => {
    return columns.map((column, idx) =>
      idx === columnIdx ? { ...column, ...updates } : column
    );
  };

  const getColumnIdx = (column) => {
    return context.columns.indexOf(column);
  };

  const setActiveColumn = (columnIdx: number): void => {
    context.setActiveColumn(columnIdx);
  };

  const getActiveColumn = () => {
    return context.columns[context.activeColumn];
  };
  const clearActiveColumn = (): void => {
    setActiveColumn(null);
  };

  const loadWorkbookAndColumns = (
    localWorkbook: workbookT,
    sheetIdx: number,
    rowOffset: number,
    defaultSelected: boolean
  ): void => {
    const sheetName = localWorkbook.workbook.SheetNames[sheetIdx];
    if (rowOffset > 0) {
      deleteRows(localWorkbook.workbook.Sheets[sheetName], 0, rowOffset);
    }
    const columns = parseColumnNames(localWorkbook.workbook, sheetName).map(
      (colName, colIdx): ColumnT => ({
        sheetName,
        sheetIdx,
        colName,
        colIdx,
        selected: defaultSelected,
        fieldFilter: null,
        field_id: null,
        colType: getColumnType(localWorkbook.workbook, sheetName, colIdx),
        data: getRow(localWorkbook.workbook, sheetName, colIdx),
      })
    );
    context.setFileLoaded(true);
    context.setWorkbook(localWorkbook);
    context.setColumns(columns);
  };

  const setColumnSelected = (field_id: string): void => {
    context.setColumns((columns) => {
      const index = columns.findIndex((column) => column.field_id === field_id);
      columns[index].selected = !columns[index].selected;
      return [...columns];
    });
  };

  const getColumnIndexByFieldId = (field_id: string): number => {
    return context.columns.findIndex((column) => column.field_id === field_id);
  };

  const removeRows = (
    sheetName: string,
    start: number,
    nrows: number
  ): void => {
    context.setWorkbook((wb) => {
      deleteRows(wb.workbook.Sheets[sheetName], start, nrows);
      return wb;
    });
  };

  const hasSelectedIdentity = (): boolean => {
    return context.columns.some(
      (column) =>
        column.fieldFilter === 'Identity' &&
        column.data.some((value) => value !== undefined)
    );
  };

  return {
    context,
    readFileAsync,
    getPreview,
    getRow,
    getWorkbook,
    loadWorkbookAndColumns,
    removePickedColumn,
    removePickedColumnNoCheck,
    removeIdentityColumns,
    getIdentityColumns,
    clearActiveColumn,
    mapFieldToActiveColumn,
    selectColumn,
    getColumnIdx,
    getActiveColumn,
    setColumnSelected,
    getColumnIndexByFieldId,
    removeRows,
    hasSelectedIdentity,
    mapFieldToActiveColumnMatch,
    setActiveColumn,
  };
};

export default useFileImport;
