import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const FilterIcon = ({
    size = 24,
    active = false
  }) => {
  //console.log("FilterIcon {active}", active);
  const [style] = useContext(StyleContext);
  let color = (style.name === "dark") ? "#F2F2F2" : "#8F8F8F";
  if (active) {
    color = style.palette.accentPrimary;
  }
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path fill={color} d="M19.5,11.5h-8.1c-0.2-0.9-1-1.6-2-1.6c-1,0-1.8,0.7-2,1.6H4.5C4.2,11.5,4,11.7,4,12c0,0.3,0.2,0.5,0.5,0.5h2.8
	c0.2,0.9,1,1.6,2,1.6c1,0,1.8-0.7,2-1.6h8.1c0.3,0,0.5-0.2,0.5-0.5C20,11.7,19.8,11.5,19.5,11.5z M9.3,13.1c-0.6,0-1.1-0.5-1.1-1.1
	c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1C10.4,12.6,9.9,13.1,9.3,13.1z"/>
          <path fill={color} d="M4,6.4c0,0.3,0.2,0.5,0.5,0.5h8.1c0.2,0.9,1,1.6,2,1.6c1,0,1.8-0.7,2-1.6h2.8c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5
	h-2.8c-0.2-0.9-1-1.6-2-1.6c-1,0-1.8,0.7-2,1.6H4.5C4.2,5.9,4,6.1,4,6.4z M13.6,6.4c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1
	c0,0.6-0.5,1.1-1.1,1.1C14.1,7.4,13.6,6.9,13.6,6.4z"/>
          <path fill={color} d="M3.7,17.6c0,0.3,0.2,0.5,0.5,0.5h8.1c0.2,0.9,1,1.6,2,1.6c1,0,1.8-0.7,2-1.6h2.8c0.3,0,0.5-0.2,0.5-0.5
	c0-0.3-0.2-0.5-0.5-0.5h-2.8c-0.2-0.9-1-1.6-2-1.6c-1,0-1.8,0.7-2,1.6H4.2C3.9,17.1,3.7,17.4,3.7,17.6z M13.2,17.6
	c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1C13.7,18.7,13.2,18.2,13.2,17.6z"/>
        </g>
      </svg>
    </>
  )
}

export default FilterIcon;