import React from 'react';
import styled from 'styled-components/macro';
import { Row } from './RowElements';
import { truncateString } from '@utils/misc';

interface Props  {
  rows: string[];
  title: string;
  className?: string;
}


const SimpleTable = (props: Props) => {
  const { rows, title } = props;
  const renderCellContent = (item) => {
    if (title === 'smiles') {
      return <HoverableCell title={item}>{truncateString(item, 15)}</HoverableCell>;
    }
    return <Cell>{truncateString(item, 25)}</Cell>;
  };
  return (
    <Container className={props?.className}>
      <Table>
        <tbody>
          {rows.map((item, itemIdx) => (
            <Row key={itemIdx}>
              {renderCellContent(item)}
            </Row>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};
export default SimpleTable;
const Container = styled.div`
  width: 100%;
  border-radius: 15px;
  padding: 10px 0;
  border: 1px solid ${(p) => p.theme.palette.textSecondary};
`;
const Table = styled.table`
  height: min-content;
  min-width: 100%;
`;
const Cell = styled.td`
  padding-left: ${(p) => p.theme.sizes.xsmall};
  padding-top: 0;
  height: 14px;
  vertical-align: middle;
  text-align: left;
  font-size: 12px;
  font-weight: ${(p) => p.theme.weights.light};
`;
const HoverableCell = styled(Cell)`
  cursor: pointer;
  &:hover {
    content: attr(title);
  }
`;