import React from 'react';
import styled from 'styled-components/macro';
import {
  getFieldName,
  getCompoundFieldValue,
} from '@components/elements/compound/utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { FieldSliceT } from '@stores/fields';

interface PropertiesTilePropsT {
  compound: any;
  fields: any;
}

const debug = false;
const PropertiesTile = (props: PropertiesTilePropsT) => {
  const { compound, fields } = props;
  const { fields: allFields } = useSelector(
    (state: RootState) => state.fields as FieldSliceT
  );
  debug &&
    console.log('PropertiesTile | compound:', compound, ' dataFields:', fields);
  return (
    <PropertiesContainer>
      {fields.map((f) => (
        <Item>
          <CellLabel>{getFieldName(f, allFields)}:</CellLabel>
          <CellValue>{getCompoundFieldValue(compound, f, allFields)}</CellValue>
        </Item>
      ))}
    </PropertiesContainer>
  );
};

export default PropertiesTile;

const PropertiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;
`;

const Item = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
`;

const CellLabel = styled.div`
  display: flex;
  padding-right: 5px;
  justify-content: end;
  width: 150px;
  font-size: ${(p) => p.theme.sizes.xsmall};
  color: ${(p) => p.theme.palette.textSecondary};
`;

const CellValue = styled.div`
  display: flex;
  width: 150px;
  font-size: ${(p) => p.theme.sizes.xsmall};
`;
