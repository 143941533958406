import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { CompoundFieldT, ValueRendererT } from '@stores/fields';
import { v4 as uuidv4 } from 'uuid';
import DataFieldEditor from './DataFieldEditor';

const initField = (fieldId): CompoundFieldT => ({
  _source: 'user',
  field_id: fieldId,
  group_id: '96730156-6a8c-46f5-8ad6-803b60cb5465',
  data_key: ['user', fieldId, 'value'].join('.'),
  group_name: '',
  group_order: 0,
  value_renderer: ValueRendererT.string,
  field_options: '',
  cfields: true,
  animal_tox_general: false,
  animal_tox_pharma: false,
  table: {
    resizable: true,
    sortable: false,
    align: 'left',
    width: 220,
    minWidth: 80,
  },
  category: 'Classification',
  symbol: '',
  short_name: '',
  long_name: '',
  short_description: '',
  long_description: '',
});

interface Props {
  className?: string;
  updateOnSave?: any;
  updateOnEdit?: any;
}

const debug = false;
const DataFieldNew = ({ updateOnSave, updateOnEdit, ...props }: Props) => {
  const uuid = uuidv4();
  const initFieldId = 'user_' + uuid.split('-')[0];
  const [fieldInfo] = useState(initField(initFieldId));
  debug && console.log('DataFieldNew | uuid:', uuid, ' initFieldId:', initFieldId,
    'fieldInfo:', fieldInfo);

  return (
    <Container className={props?.className}>
      <DataFieldEditor
          dataFieldInfo={fieldInfo}
          dataFieldId={initFieldId}
          updateOnSave={updateOnSave}
          updateOnEdit={updateOnEdit}
      />
    </Container>
  );
};

export default DataFieldNew;

const Container = styled.div`
  width: 100%;
  height: 470px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 15px;
  & ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  & ::-webkit-scrollbar-track {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
  & ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }
  & ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
`;



