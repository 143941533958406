import React from 'react';
import { Cell, Row } from '../shared/RowElements';

const GenericColumnRow = (props) => {
  const { column, colIdx } = props;
  // console.log('GenericColumnRow | column:', column, " colIdx:", colIdx);
  let data = column?.data;
  let previewLen = Math.min(4, data.length);
  let dataPreview = data.slice(0,previewLen).join(', ');

  return (
    <Row key={colIdx}>
      <Cell>{column?.colName}</Cell>
      <Cell>{column?.colType}</Cell>
      <Cell>{dataPreview}</Cell>
    </Row>
  );
};

export default GenericColumnRow;
