import { DEFAULT_TITLE_SIZE, DEFAULT_X_AXIS_STYLE } from '@dataviz/constants';
import { ResponsiveBoxPlot, BoxPlotDatum } from '@nivo/boxplot';
import { Margin, Theme } from '@nivo/core';
import { getDatavizTheme } from '@plotting/single-plot-view/plot-panel/plot.themes';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { getBoxplotTooltip } from './getBoxplotTooltip';

const DEFAULT_MARGIN = {
  top: 60,
  right: 60,
  bottom: 80,
  left: 220,
};

interface BoxplotProps {
  data: BoxPlotDatum[];
  groupByColumn: string;
  valueColumn: string;
  margin?: Margin;
  orientation?: 'horizontal' | 'vertical';
  title?: string;
  titleSize?: number;
  datavizTheme?: Theme;
  isLogScale?: boolean;
}

const Boxplot = ({
  data,
  groupByColumn,
  valueColumn,
  margin,
  orientation,
  title,
  datavizTheme,
  titleSize,
  isLogScale,
}: BoxplotProps) => {
  const { palette } = useContext(ThemeContext);
  const finalDatavizTheme = datavizTheme ?? getDatavizTheme({}, palette);

  const plotMargin = margin || DEFAULT_MARGIN;

  const plotTitleStyle = {
    fontFamily: finalDatavizTheme.fontFamily,
    fontSize: titleSize || DEFAULT_TITLE_SIZE,
    fill: finalDatavizTheme.textColor,
    textAnchor: 'middle',
  } as const;

  const PlotTitle = ({ innerWidth }) => {
    return (
      <text x={innerWidth / 2} y={-plotMargin.top / 2} style={plotTitleStyle}>
        {title}
      </text>
    );
  };

  const axisBottomConfig =
    orientation === 'horizontal'
      ? {
          ...DEFAULT_X_AXIS_STYLE,
          legend: valueColumn,
        }
      : undefined;

  const tooltip = getBoxplotTooltip(finalDatavizTheme);

  return (
    <ResponsiveBoxPlot
      data={data}
      groupBy={groupByColumn} //groupBy={(d) => String(d[groupByColumn])}
      value={valueColumn}
      margin={plotMargin}
      layout={orientation}
      animate={true}
      layers={[
        PlotTitle,
        'grid',
        'axes',
        'boxPlots',
        'markers',
        'legends',
        'annotations',
      ]}
      enableGridX={orientation === 'horizontal'}
      enableGridY={orientation === 'vertical'}
      minValue={0}
      // Colors
      whiskerColor={palette.textPrimary}
      medianColor={palette.textPrimary}
      borderColor={palette.textPrimary}
      colors={() => '#DB0B5B'}
      opacity={0.8}
      valueScale={
        isLogScale
          ? { type: 'log', base: 10, min: 'auto', max: 'auto' }
          : { type: 'linear' }
      }
      tooltip={tooltip}
      // Axis
      axisBottom={axisBottomConfig}
      // theme
      theme={{
        translation: undefined,
        grid: {
          line: {
            stroke: palette.backgroundTertiary,
            strokeWidth: 1.0,
          },
        },
        axis: {
          domain: {
            line: {
              strokeWidth: 0,
            },
          },
          ticks: {
            line: {
              stroke: palette.backgroundTertiary,
              strokeWidth: 1.0,
            },
            text: {
              fontSize: finalDatavizTheme.fontSize,
              fill: palette.textPrimary,
            },
          },
          legend: {
            text: {
              fontSize: Math.round(finalDatavizTheme.fontSize * 1.2),
              fill: palette.textPrimary,
            },
          },
        },
      }}
    />
  );
};

export default Boxplot;
