import React, { useState } from "react";
import { Button } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import EditIcon from "@mui/icons-material/Edit";
import PopUp from "@components/dataAnalytics/PopUp";
import "./DataBox.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

interface DataBoxProps {
  data?: any
}

const DataBox: React.FC<DataBoxProps> = ({ data }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const handleLinkClick = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = (shouldRedirect: boolean) => {
    setOpenPopup(false);
    if (shouldRedirect) {
      window.location.href = "/import/V2";
    }
  };

  return (
    <div className="container">
      <div className="flex-column">
        <div className="inline-flex">
          <DateRangeIcon
            className="icon"
            style={{ verticalAlign: "-4px", color: "#F2F3F4" }}
          />
          <span className="text1">{data.title}</span>
        </div>
        <div className="new-line">
          <span className="space"> </span>
          <span>
            <AccessTimeIcon
              style={{
                fontSize: "inherit",
                verticalAlign: "middle",
                color: "#F2F3F4",
              }}
            />{" "}
            {/* Icon before "date" */}
            <span className="gray-text">{data.date} </span>
          </span>
        </div>
        <div className="new-line">
          <span className="space"> </span>
          <span className="bold-text">{data.numRows}</span>
          <span className="small-space"></span>
          <span className="bold-text">{data.numColumns}</span>
        </div>
      </div>
      <div className="bottom-section">
        <Button
          variant="outlined"
          startIcon={<EditIcon fontSize="small" />}
          className="change-button"
          onClick={handleLinkClick}
          size="small"
        >
          Change
        </Button>
      </div>

      {openPopup && <PopUp onClose={handleClosePopup} open={openPopup} />}
    </div>
  );
};

export default DataBox;
