import _ from 'lodash';
import { Plots } from '@utils/api';
import { handleError } from '@utils/misc';
import { AxiosRequestConfig } from 'axios';
import { PlotQueryParamsT, PlotT } from '../../type';
import { ResponseT } from '@utils/api/base';
import { PlotBase, PlotConfig, PlotData } from './single-plot-view/plot.types';

const usePlots = () => {
  const getPlots = async (
    token: string,
    params: PlotQueryParamsT
  ): Promise<ResponseT<PlotT<null, null>[]>> => {
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await Plots.all(token, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getPlots ERRORS', resp.data.errors);
    }

    return resp.data;
  };

  const getPlot = async (
    token: string,
    plotId
  ): Promise<PlotConfig<PlotBase>> => {
    let resp;

    try {
      resp = await Plots.get(token, plotId);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.error('getPlot ERRORS', resp.data.errors);
      return null;
    }

    const { dataFormatted } = resp.data.data;

    const unpacked = {
      ...resp.data.data,
      config: JSON.parse(decodeURIComponent(_.get(resp, 'data.data.config'))),
      data: JSON.parse(decodeURIComponent(_.get(resp, 'data.data.data'))),
      dataFormatted:
        typeof dataFormatted === 'undefined' || dataFormatted === 'undefined'
          ? undefined
          : JSON.parse(
              decodeURIComponent(_.get(resp, 'data.data.dataFormatted'))
            ),
    };

    return unpacked;
  };

  const createPlot = async (
    token: string,
    plot: PlotConfig<PlotBase>
  ): Promise<PlotT<null, null>> => {
    let resp;
    const plotPacked = {
      ...plot,
      config: encodeURIComponent(JSON.stringify(plot.config)),
      data: encodeURIComponent(JSON.stringify(plot.data)),
      dataFormatted: encodeURIComponent(JSON.stringify(plot.dataFormatted)),
    };

    try {
      resp = await Plots.create(token, plotPacked);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('createPlot ERRORS', resp.data.errors);
    }

    return resp.data.data;
  };

  const updatePlot = async (
    token: string,
    plot: PlotConfig<PlotBase>
  ): Promise<PlotConfig<PlotBase>> => {
    let resp;
    const plotPacked = {
      ...plot,
      config: encodeURIComponent(JSON.stringify(plot.config)),
      data: encodeURIComponent(JSON.stringify(plot.data)),
      dataFormatted: encodeURIComponent(JSON.stringify(plot.dataFormatted)),
    };

    try {
      resp = await Plots.update(token, plotPacked);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('updatePlot ERRORS', resp.data.errors);
    }

    return resp.data.data;
  };

  const deletePlot = async (
    token: string,
    plotId
  ): Promise<PlotConfig<PlotBase>> => {
    let resp;

    try {
      resp = await Plots.delete(token, plotId);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('deletePlot ERRORS', resp.data.errors);
    }
  };

  return {
    getPlot,
    getPlots,
    createPlot,
    updatePlot,
    deletePlot,
  };
};

export default usePlots;
