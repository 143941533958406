import React from 'react';
import styled from 'styled-components/macro';
import ReactQuill from 'react-quill';
import 'quill/dist/quill.snow.css';
import { Notes } from '@utils/api';
import { Button } from '@mui/material';
import useCognito from "@as_core/account/useCognito";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ script: 'sub' }, { script: 'super' }],
    ['formula'],
    [{ color: [] }, { background: [] }],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
  ],
};

const formats = [ 'blockquote', 'bold', 'bullet', 'header', 'image',
  'indent', 'italic', 'link', 'list', 'script', 'strike', 'underline',
];

const LabNotes = (props) => {
  const { getToken } = useCognito();
  const { height, width, compoundId } = props;

  const [note, setNote] = React.useState('\n');
  const [success, setSuccess] = React.useState('');

  const getData = async (compoundId) => {
    const token = getToken();
    const apiPayload = await Notes.get(compoundId, token);
    if (apiPayload.data.data.length > 0) {
      const note_data = apiPayload.data.data[0].value
      setNote(note_data);
    }
  };

  React.useEffect(() => {
    getData(compoundId).then();
  }, []);

  const updateNote = async () => {
    const token = getToken();
    const payload = { uuid: compoundId, value: note };
    await Notes.update(payload, token);
    setSuccess("Note Saved: " + new Date().toLocaleString());
  };

  return (
    <Container height={height} width={width}>
      <Success>{success}</Success>
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        value={note}
        onChange={setNote} />
      <div><Button variant='contained' onClick={updateNote}>Save</Button></div>
    </Container>
  );
};

export default LabNotes;

const Success = styled.div`
  font-size: ${(p) => p.theme.sizes.small};
  font-family: ${(p) => p.theme.fonts.main};
  font-weight: ${(p) => p.theme.weights.default};
  color: ${(p) => p.theme.palette.textSecondary};
`

const Container = styled.div<{ height: number, width: number }>`
  height: ${(p) => p.height - 80}px;
  width: ${(p) => p.width - 10}px;
  padding: 5px;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  font-family: ${(p) => p.theme.fonts.main};

  .ql-toolbar {
    border-radius: ${(p) => p.theme.borderRadius};
    border: ${(p) => p.theme.borders.thinLight};
    border: none;
  }
  
  .ql-container {
    max-height: ${(p) => p.height - 160}px !important;
    border-radius: ${(p) => p.theme.borderRadius};
    border: none;
    background-color: ${(p) => p.theme.palette.backgroundSecondary};
    overflow-y: auto;
    & ::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    & ::-webkit-scrollbar-track {
      background: ${(p) => p.theme.palette.backgroundSecondary};
    }
    & ::-webkit-scrollbar-thumb {
      background: ${(p) => p.theme.palette.accentPrimary};
      border-radius: 5px;
    }
    & ::-webkit-scrollbar-thumb:hover {
      background: ${(p) => p.theme.palette.accentSecondary};
    }
  }
  
  .ql-editor .ql-blank {
    max-height: ${(p) => p.height - 155}px !important;
    padding: 5px;
    margin: 0;
    color: ${(props) => props.theme.palette.textTertiary};
    border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary };
  }
`;
