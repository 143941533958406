import React from 'react';
import styled from 'styled-components/macro';
import { Draggable } from 'react-beautiful-dnd';
import ItemDetail from './ItemDetail';
import { ExtraProps } from '../../../type';

const ItemDND = ({ index, state, columnId, fieldId }) => {
  // const isDragDisabled = React.useCallback(() => fieldId === index, [
  //   index,
  //   draggableId,
  // ]);

  return (
    <Draggable draggableId={fieldId} index={index} isDragDisabled={false}>
      {(provided, snapshot) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragDisabled={false}
          isDragging={snapshot.isDragging}
        >
          <ItemDetail
            state={state}
            columnId={columnId}
            fieldId={fieldId}
            isDragDisabled={false}
          />
        </Container>
      )}
    </Draggable>
  );
};

export default ItemDND;

const Container = styled.div<ExtraProps>`
  ${(props) => (props.isDragDisabled ? 'color: lightgrey;' : '')}
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary };
  border-radius: ${(p) => p.theme.borderRadius};
  margin-top: 3px;
  transition: background-color 0.2s ease;
  background-color: ${(p) =>
    p.isDragDisabled
      ? p.theme.palette.backgroundAlpha
      : p.isDragging
      ? p.theme.palette.accentPrimary
      : p.theme.palette.backgroundSecondary};

  &:focus {
    outline: none;
    border-color: red;
  }
`;
