import React from 'react';
import styled from 'styled-components/macro';

import { useNavigate } from 'react-router-dom';
import {FlexItem, FlexBanner} from '@components/layout/FlexStyles';
import {TextSmall, TextSmallItalics, TextSmallBold, TextSpace} from "@components/elements/TextStyles";
import TextButton from '@components/controls/TextButton';
import MenuButton from '@components/elements/MenuButton';
import GraphIcon from '@components/icons/graph.icon';
import TrashIcon from '@components/icons/trash.icon';
import TableIcon from '@components/icons/table.icon';
import KinomeUniverseIcon from "@components/icons/kinomeUniverse.icon";
import {truncateString} from "@utils/misc";
import DoseResponseIcon from "@components/icons/doseResponse.icon";
import {FlexBannerGroup} from "@as_core/elements/flexStyles";

const iconSet = {
  'Generic': <GraphIcon/>,
  'Target Panel': <KinomeUniverseIcon/>,
  'Dose Response': <DoseResponseIcon/>
}

const debug = false;
const UploadDocumentListItem = ({ dataSet, handleDeleteClick, handlePreviewClick }) => {
  debug && console.log('UploadDocumentListItem | dataSet:', dataSet);
  const navigate = useNavigate();

  // HANDLERS
  const _handleDeleteClick = (uuid) => {
    handleDeleteClick(uuid);
  };

  const handleCreatePlot = (uuid) => {
    navigate('/plots/create', { state: { dataId: uuid } });
  };

  const handleViewPlot = (plotId) => {
    navigate('/plots/' + plotId);
  };

  const handleViewUniverse = (universeId) => {
    navigate('/universe/user/' + universeId);
  };

  const getViewResultsButton = (dataSet) => {
    if (dataSet.plotId) {
      return(
        <TextButton
            text={'View Plot'}
            icon={<GraphIcon/>}
            width={150}
            height={28}
            margin={'0'}
            onClick={() => handleViewPlot(dataSet.plotId)}
        />
      )
    }
    if (dataSet.type === "Dose Response" || dataSet.type === "Generic") {
      return(
        <TextButton
            text={'Create Plot'}
            icon={<GraphIcon/>}
            width={150}
            height={28}
            margin={'0'}
            onClick={() => handleCreatePlot(dataSet.uuid)}
        />
      )
    }
    if (dataSet.type === "Target Panel") {
      if (dataSet.computationStatus === "Pending") {
        return(
          <TextSmallItalics color={'accent'}>Computation Pending</TextSmallItalics>
        )
      }
      return(
        <TextButton
          text={'View Universe'}
          icon={<KinomeUniverseIcon />}
          width={150}
          height={28}
          margin={'0'}
          onClick={() => handleViewUniverse(dataSet?.universeId)}
        />
      )
    }
  }

  const getDataDetails = (dataSet) => {
    return (
      <>
        {dataSet?.nColumns} columns x {dataSet?.nRows}{' '} rows
      </>
    );
  };
  const title =
    truncateString(dataSet?.name, 30) +
    ' (' +
    new Date(dataSet?.createdOn).toLocaleDateString() +
    ')';

  return (
      <DataSetContainer>
        <FlexBanner>
          <FlexBannerGroup>
            <FlexItem width={'30px'}>
              {iconSet[dataSet.type]}
            </FlexItem>
            <FlexItem width={'350px'}>
              <TextSmall color={'primary'}>{title}</TextSmall></FlexItem>
          </FlexBannerGroup>
          <FlexItem>
            <TextSmallBold color={'accentSecondary'}>{dataSet.type}</TextSmallBold>
            <TextSpace />
            <TextSmallItalics>({getDataDetails(dataSet)})</TextSmallItalics>
          </FlexItem>
          <FlexBannerGroup>
            <FlexItem width={'160px'}> { getViewResultsButton(dataSet) } </FlexItem>
            <FlexItem width={'160px'}>
              <TextButton
                text={'View Data'}
                icon={<TableIcon />}
                width={125}
                height={28}
                margin={'0'}
                onClick={() => handlePreviewClick(dataSet.uuid)}
              />
            </FlexItem>
            <FlexItem width={'50px'}>
              <MenuButton
                onClick={() => _handleDeleteClick(dataSet.uuid)}
                isActive={false}
                tooltipPlacement={'bottom'}
                icon={<TrashIcon />}
                margin={'0'}
                text={'Delete Data Set'}
              />
            </FlexItem>
          </FlexBannerGroup>
      </FlexBanner>
      </DataSetContainer>
  );
};

export default UploadDocumentListItem;

const DataSetContainer = styled.div`
  width: calc(100% - 10px);
  display: flex;
  padding: 0 5px;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.backgroundTertiary};
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
  margin-bottom: 5px;
`;

const Spacer = styled.div`
  width: 10px;
`;
