import React, { Fragment } from 'react';
import styled from 'styled-components/macro';

const CompareTileItem = ({ title }) => {
  return (
    <Fragment>
      <Row>
        <Left>-</Left>
        <Center>{title}</Center>
        <Right>-</Right>
      </Row>
    </Fragment>
  );
};

const CompareTile = () => {
  return (
    <TileContainer className='cpar-tile-container'>
      <CompareTileItem title='MW'></CompareTileItem>
      <Line />
      <CompareTileItem title='LogP'></CompareTileItem>
      <Line />
      <CompareTileItem title='LogD'></CompareTileItem>
      <Line />
      <CompareTileItem title='HBD'></CompareTileItem>
      <Line />
      <CompareTileItem title='HBA'></CompareTileItem>
      <Line />
      <CompareTileItem title='PSA'></CompareTileItem>
      <Line />
      <CompareTileItem title='Toxicity'></CompareTileItem>
      <Line />
      <CompareTileItem title='Indication'></CompareTileItem>
    </TileContainer>
  );
};

export default CompareTile;

const Line = styled.div`
  grid-column: 1 / -1;
  height: 1px;
  width: 100%;
  margin: 0;
  padding: 0;
  border-bottom: ${(p) => p.theme.borders.thinLight};
`;

const TileContainer = styled.div`
  width: 30vw;
  display: grid;
  grid-template-columns: 1fr;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};
  align-items: center;
  color: white;
  padding: ${(p) => p.theme.sizes.small};
`;

const Row = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, max-content);
  grid-auto-rows: 2rem;
  justify-content: space-between;
  justify-items: center;
  align-items: start;
  padding-left: 3rem;
  padding-right: 3rem;
`;
const Left = styled.div``;
const Center = styled.div``;
const Right = styled.div``;
