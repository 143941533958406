import { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useDropzone } from 'react-dropzone';
import styles from '../components-style/UploadComponents.module.css';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import cssVariables from '../components/constant_css';
import '../components-style/SelectableTable.scss';

const debug = false;
const UploadComponent = ({ onDrop }) => {
  const { acceptedFiles, getRootProps } = useDropzone();

  useEffect(() => {
    debug && console.log("UploadComponent | useEffect {acceptedFiles}:", acceptedFiles.length);
    if (acceptedFiles.length) {
      onDrop(acceptedFiles);
    }
  }, [acceptedFiles]);

  return (
    <Container className='containerInUploadComponent'>
      <div className={styles.parentContent}>
        <StyledDropZone {...getRootProps({ className: 'dropzone' })}>
          <div className={styles.iconUploadFolder}>
            <DriveFolderUploadIcon />
          </div>
          <p className={styles.textUpload}>
            Drag and drop files
            <br />
            -OR-
            <br />
          </p>
          <button className={styles.buttonUpload}>Click to Select files</button>
        </StyledDropZone>
      </div>
    </Container>
  );
};

export default UploadComponent;
const Container = styled.div`
  ${cssVariables};
`;

const StyledDropZone = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
`;
