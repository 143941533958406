import React from 'react';
import _ from 'lodash';

import { ValueRendererT } from '@stores/fields';
import GenericRenderer from './valueRenderers/GenericRenderer';
import MultiSelectRenderer from './valueRenderers/MultiSelectRenderer';
import ObjectlistRenderer from './valueRenderers/ObjectlistRenderer';
import SingleSelectRenderer from './valueRenderers/SingleSelectRenderer';

import {
  getFieldOptions,
  getCompoundFieldValue,
} from '@components/elements/compound/utils';

const debug = false;
const DisplayEditableValue = (props) => {
  const { compound, fieldId, allFields, handleChange, inputErrors } = props;

  const getRenderer = (fieldId, allFields) => {
    return _.get(allFields, `${fieldId}.value_renderer`, ValueRendererT.string);
  };

  const getValue = () => {
    debug && console.log('DisplayEditableValue | getValue() called');
    return getCompoundFieldValue(compound, fieldId, allFields);
  };

  const _handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    debug &&
      console.log(
        'DisplayEditableValue | event.target.value:',
        event.target.value
      );
  };

  return (
    <>
      {getRenderer(fieldId, allFields) === ValueRendererT.objectlist ? (
        <ObjectlistRenderer
          compound={compound}
          fields={allFields}
          fieldId={fieldId}
          handleChange={handleChange}
        />
      ) : getRenderer(fieldId, allFields) === ValueRendererT.singleselect ? (
        <SingleSelectRenderer
          getValue={getValue}
          getOptions={getFieldOptions(fieldId, allFields)}
          onChange={_handleChange}
        />
      ) : getRenderer(fieldId, allFields) === ValueRendererT.multiselect ? (
        <MultiSelectRenderer
          compound={compound}
          fields={allFields}
          fieldId={fieldId}
          handleChange={handleChange}
        />
      ) : (
        <GenericRenderer
          compound={compound}
          fields={allFields}
          fieldId={fieldId}
          handleChange={handleChange}
          error={inputErrors.find((error) => error.id === fieldId)}
        />
      )}
    </>
  );
};

export default DisplayEditableValue;
