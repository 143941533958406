import { useState, useEffect } from "react";
import styled from 'styled-components/macro';
import useRequests, {RequestT} from "../../hooks/useRequests";
import {RequestFormT} from "@subApps/orders/hooks/useRequestForms";
import React from "react";
import DownloadFile from '@as_core/controls/outputs/DownloadFile';
import useRequestForms from "@subApps/orders/hooks/useRequestForms";
import DataLoading from "@as_core/elements/DataLoading";
import TextButton from "@as_core/controls/buttons/TextButton/TextButton";
import ErrorMessages, {MessageItem} from "@as_core/elements/UserMessages";
import StationaryModal from "@as_core/elements/StationaryModal";
import UploadIcon from "@components/icons/upload.icon";
import RequestFormUpload from "@subApps/orders/components/Assays/RequestFormUpload";
import UserRequestToDo, {StatusMapT} from "@subApps/orders/components/Common/UserRequestToDo";
import RequestFormsTable from "@subApps/orders/components/Assays/RequestFormsTable";
import {LocalShipping} from "@mui/icons-material";

interface PropsI {
  request: RequestT;
  updateSelectedRequest: (r: RequestT)=>void;
  setUpdatedRequestForms: ()=>void;
}

const debug = false;
const RequestUserUpdate = (props: PropsI) => {
  const {request, updateSelectedRequest, setUpdatedRequestForms} = props;
  const { getRequestFormsByRequestId } = useRequestForms();
  const { updateRequest } = useRequests();
  const [requestForms, setRequestForms] = useState<RequestFormT[]>(null);
  const [requestFormsUpdated, setRequestFormsUpdated] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isFileUploadOpen, setIsFileUploadOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const confirmShipping = () => {
    setIsProcessing(true);
    updateRequest(request.id, {status: 'Shipped'})
      .then((request)=>{
        updateSelectedRequest(request);
        setIsProcessing(false);
        setUpdatedRequestForms();
      }
    )
  }

  debug && console.log('RequestUserUpdate | request:', request);
  useEffect(()=> {
    if (!isLoading) {
      setIsLoading(true);
      setIsLoaded(false);
      setRequestForms(null);
      getRequestFormsByRequestId(request.id).then((result) => {
        debug && console.log('RequestUserUpdate | result', result);
        setIsLoading(false);
        setIsLoaded(true);
        setRequestForms(result);
      });
    }
  }, [request, requestFormsUpdated]);

  const statusMap: StatusMapT[] = [
    {label: 'Upload Plate Map(s)', checked: requestForms !== null && requestForms.length !== 0},
    {label: 'Confirm Shipping', checked: request.status !== 'Open'},
  ]
  // TODO -- Refactor as too complicated logic
  return (
    <ViewContainer>
      <Row>
        <Header>User Request Actions</Header>
      </Row>
      <Row padding={'0'}>
        <UserRequestToDo statusMap={statusMap}/>
      </Row>
      { request.status === 'Open' && request.assigned_to && request.assigned_to !== '' ?
        <>
          <Row>
            <Label>Plate Map Template File: </Label>
            <Value><DownloadFile file={request.assigned_to + '.xlsx'} path={'/documents/templates/'}/></Value>
          </Row>
          { isLoaded && isLoading ?
            <DataLoading centered={false} width={20}/>
            : requestForms === null || requestForms.length === 0 ?
                <Row>
                  <TextButton
                    label = 'Upload Plate Map(s)'
                    icon={<UploadIcon />}
                    height={28}
                    width={165}
                    onClick={()=> setIsFileUploadOpen(prevState => !prevState)}
                    isDisabled={isFileUploadOpen}
                  />
                </Row>
              :
                <>
                  <Row>
                    <TextButton
                      label = 'Replace and Upload New Plate Map(s)'
                      icon={<UploadIcon />}
                      height={28}
                      width={290}
                      isMuted={true}
                      onClick={()=> setIsFileUploadOpen(prevState => !prevState)}
                      isDisabled={isFileUploadOpen}
                    />
                  </Row>
                  <Row>
                    <Label>Uploaded Plate Map File(s): </Label>
                    <Value>
                      <RequestFormsTable requestForms = {requestForms} />
                    </Value>
                  </Row>
                </>
          }
          <Row>
            <Centered>
              { !isProcessing ?
                <TextButton
                  label = 'Confirm Shipping of Compound Plates'
                  icon={<ShippingIcon />}
                  width={295}
                  height={28}
                  isDisabled={(requestForms === null || requestForms.length === 0)}
                  onClick={()=>confirmShipping()}
                />
              :
                <DataLoading />
              }
            </Centered>
          </Row>
        </>
        : request.status === 'Open' && request.assigned_to === '' ?
          <Row>
            <ErrorMessages border={false} messages={[
              <MessageItem>No action needed at this time.</MessageItem>,
              <MessageItem>Order still being processed.</MessageItem>
            ]}/>
          </Row>
        : isLoading?
          <DataLoading />
          :
          <Row>
            <Row>
              <Label>Uploaded Plate Map File(s): </Label>
              <Value>
                <RequestFormsTable requestForms = {requestForms} />
              </Value>
            </Row>
          </Row>
        }
      <StationaryModal
        title={'Upload your plate map'}
        opacity={0.95}
        modalOpen={isFileUploadOpen}
        height={450}
        onCloseClick={() => setIsFileUploadOpen(false)}
      >
        <RequestFormUpload
          key={'request_user_update_file_upload'}
          request={request}
          requestForms={requestForms}
          setRequestForms={setRequestForms}
          setUpdated={()=>setRequestFormsUpdated(prevState => !prevState)}
          handleClose={()=>setIsFileUploadOpen(false)}
        />
      </StationaryModal>
    </ViewContainer>
  );
};

export default RequestUserUpdate;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 400px;
  background: ${(p) => p.theme.palette.backgroundTertiary};
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  border-radius: 15px;
`;

const Row = styled.div<{padding?: string}>`
  display: flex;
  width: calc(100% - 10px);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: ${(p) => p.padding};
`;
Row.defaultProps = {
  padding: `3px 10px`
}

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 14px;
  font-style: italic;
  font-weight: bold;
  color: ${(p) => p.theme.palette.accentSecondary};
`;

const Label = styled.div`
  display: flex;
  width: 150px;
  text-align: end;
`;

const Value = styled(Label)`
  flex-direction: row;
  justify-content: center;
  min-width: 200px;
`;

const Centered = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const ShippingIcon = styled(LocalShipping)`
  display: flex;
  color: ${(p) => p.theme.palette.textPrimary};
`;
