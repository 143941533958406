import React from 'react';
import styled from 'styled-components/macro';
import {FlexItem} from "@components/layout/FlexStyles";

const ColorLegend = ({ scale, title, width=275, lowText="0.0", highText="1.0" }) => {
  return (
    <LegendContainer width={width}>
      <LegendTitle>{title}:</LegendTitle>
      <LegendLimits>{lowText}</LegendLimits>
      <FlexItem width={'75px'}>
        {scale && (
            <ColorScale startColor={scale.range()[0]} endColor={scale.range()[1]} />
        )}
      </FlexItem>
      <LegendLimits>{highText}</LegendLimits>
    </LegendContainer>
  );
};

export default ColorLegend;

const LegendContainer = styled.div<{width: number}>`
  display: flex;
  flex-direction: row;
  width: ${(p) => p.width }px;
  justify-content: flex-end;
  align-items: center;
  margin-left: 5px;
  padding: 0;
  height: 12px;
`;

const LegendTitle = styled.div`
  display: flex;
  font-size: 12px;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const LegendLimits = styled(LegendTitle)`
  color: ${(p) => p.theme.palette.textPrimary};
`;

const ColorScale: any = styled.div`
  width: 100%;
  background: ${({ startColor, endColor }: any) =>
    'linear-gradient(to right, ' + startColor + ', ' + endColor + ')'};
  height: 12px;
  border: none;
  border-radius: 2px;
`;
