import React, { useEffect, useState } from 'react';
import { TableDataT } from '@components/tables/DataTable';
import DataTable from '@components/tables/DataTable';

interface propsT {
  data: any;
}

const debug = false;
const PreviewDataTable = (props: propsT) => {
  const { data } = props;
  debug && console.log('PreviewDataTable | data', data);
  const [tableData, setTableData] = useState<TableDataT>({
    columns: [],
    rows: [],
  });

  useEffect(() => {
    const headers: Array<string> = [];
    data.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        if (!headers.includes(key.toString())) {
          headers.push(key.toString());
        }
      });
    });
    const newTableData: TableDataT = {
      columns: headers,
      rows: data,
    };
    setTableData(newTableData);
  }, [data]);

  debug && console.log('PreviewDataTable | props', props);

  console.log('tableData', tableData);
  return <DataTable tableData={tableData} />;
};

export default PreviewDataTable;
