import React from 'react';
import { useParams } from 'react-router-dom';
import PageContainer from '@as_core/pages/PageContainer';
import CompoundDetailData from "@components/compounds/view/CompoundDetailData";

const debug = false;

const PgCompoundsDetail = () => {
  const { id: searchKey } = useParams<{ id: string }>();

  debug && console.log('PgCompoundDetails {searchKey}', searchKey);

  return (
    <PageContainer title='Compound Details' >
      <CompoundDetailData searchKey={searchKey} />
    </PageContainer>
  );
};

export default PgCompoundsDetail;

