import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const HgncIcon = ({
    size = 24,
  }) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={color} d="M485.4,158.7c-7.3,7.4-47.2-1.6-89.1-43.8c-47.3-47.7-97-60.8-113.9-43.8l0,0l-36.2,36.4c0,0,0,0,0,0
	c-14.7,14.8-7.8,62.8,43.5,114.4c19.3,19.4,27.6,32.1,31.7,42c-11.7-6.8-25.6-16.9-41-32.4c-44.8-45.1-96-61.7-113.9-43.8
	l-36.2,36.5c0,0,0,0,0,0c-14.7,14.8-7.8,62.8,43.5,114.4c14.8,14.9,25.8,26.9,29.6,40.8c-11.3-6.7-24.4-16.6-39-31.2
	c-42.7-43-95.2-62.5-113.9-43.8s0,0,0,0L14.2,341c-8.2,8.2,4.3,20.5,12.4,12.3c7.3-7.4,47.2,1.6,89.1,43.8
	c44.1,44.4,95.7,62,113.9,43.8c18.1-18.3,0,0,0,0l36.2-36.5l0,0c14.7-14.8,7.8-62.8-43.5-114.4c-21.7-21.8-28.6-32.8-30.1-41.1
	c11.4,6.7,24.7,16.6,39.4,31.5c44,44.3,95.6,62.1,113.8,43.8c0,0,0,0,0,0l36.2-36.5c0,0,0,0,0,0c14.7-14.8,7.7-62.8-43.5-114.4
	c-20.1-20.3-27.2-30-29-40.4c11.1,6.7,24.1,16.5,38.4,30.9c47.3,47.6,96.9,60.8,113.9,43.8l0,0l36.2-36.5
	C505.9,162.8,493.6,150.5,485.4,158.7L485.4,158.7z M128.1,384.8c-27.9-28-58.1-46.1-83-50l17.8-17.9c5.7-5.8,43.4-2.2,89.1,43.8
	c22.4,22.6,42.3,34.9,57.4,41.8c0.1,0,0.1,0.1,0.2,0.1c4.3,1.9,8.3,3.5,11.7,4.7c4.8,1.6,9.4,2.7,13.8,3.4l-17.9,18
	C211.7,434.1,174.3,431.4,128.1,384.8L128.1,384.8z M209.9,302.3c46.4,46.7,49,84.3,43.5,89.8c-5.1,5.1-24.3-0.4-31.3-3.2
	c-0.6-24.6-15.4-41.7-36-62.5c-46.4-46.7-49-84.3-43.5-89.8h0l0,0c5.1-5.1,23.6,0.1,32.1,3.5C172.9,256.4,178.4,270.5,209.9,302.3
	L209.9,302.3z M244.1,268.1c-21.8-21.9-52.7-45.1-83.1-50l17.8-18c5.7-5.8,43.5-2.1,89.1,43.8c21.8,21.9,52.7,45.1,83.1,50l-17.9,18
	v0C327,318,288.9,313.2,244.1,268.1L244.1,268.1z M325.9,185.5c46.4,46.7,49,84.3,43.5,89.8c0,0,0,0,0,0l0,0
	c-4.6,4.6-21.3,0.3-27.5-1.8c-2.9-16.6-9.5-33.3-39.9-63.9c-46.4-46.7-49-84.3-43.5-89.8c0,0,0,0,0,0l0,0c5.3-5.2,24.1,0.2,32.9,3.8
	C290.6,144.9,298,157.5,325.9,185.5z M449.2,195.1L449.2,195.1c-5.7,5.8-43.4,2.2-89.1-43.8c-21.4-21.5-40.4-33.8-55.3-40.8
	c-2.8-1.1-13.8-6.9-27.8-9.2l17.8-17.9l0,0c5.7-5.8,43.5-2.1,89.1,43.8c27.9,28,58.1,46.1,83,50.1L449.2,195.1z"/>
        <path fill={colorHighlight} d="M241.6,114.9c0,0,16.5-9.3,67.7,17.9c11.1,6.7,24.1,16.5,38.4,30.9
	c47.3,47.6,96.9,60.8,113.9,43.8l0,0l36.2-36.5c8.2-8.2-4.2-20.5-12.4-12.3v0c-7.3,7.4-47.2-1.6-89.1-43.8
	c-47.3-47.7-97-60.8-113.9-43.8l0,0l-36.2,36.4C243.7,109.8,241.6,114.9,241.6,114.9z M449.2,195.1L449.2,195.1
	c-5.7,5.8-43.4,2.2-89.1-43.8c-21.4-21.5-40.4-33.8-55.3-40.8c-2.8-1.1-13.8-6.9-27.8-9.2l17.8-17.9l0,0c5.7-5.8,43.5-2.1,89.1,43.8
	c27.9,28,58.1,46.1,83,50.1L449.2,195.1z"/>
        <path fill={colorHighlight}  d="M126,231.1
	c0,0,16.5-9.3,67.7,17.9c11.1,6.7,24.1,16.5,38.4,30.9c47.3,47.6,96.9,60.8,113.9,43.8l0,0l36.2-36.5c8.2-8.2-4.2-20.5-12.4-12.3
	l0,0c-7.3,7.4-47.2-1.6-89.1-43.8c-47.3-47.7-97-60.8-113.9-43.8l0,0l-36.2,36.4C128,226,126,231.1,126,231.1z M333.6,311.3
	L333.6,311.3c-5.7,5.8-43.4,2.2-89.1-43.8c-21.4-21.5-40.4-33.8-55.3-40.8c-2.8-1.1-13.8-6.9-27.8-9.2l17.8-17.9l0,0
	c5.7-5.8,43.5-2.1,89.1,43.8c27.9,28,58.1,46.1,83,50.1L333.6,311.3z"/>
        <path fill={colorHighlight}  d="M237.4,395.5
	c-29.8-5.2-44.5-20.3-71.2-47.2c-47.3-47.7-97-60.8-113.9-43.8l0,0l-36.6,35c-2.5,2.2-4.2,4.3-4.2,6.8c0.1,1.8,0.2,8.1,7.6,9.6
	c6,1.2,7.5-4.5,13.2-4.6c11.5-0.3,27.9,4.9,46.9,14.9c11.1,6.7,24.1,16.5,38.4,30.9c47.3,47.6,96.9,60.8,113.9,43.8l0,0l36.9-39.1
	 M219.1,428.6L219.1,428.6c-5.7,5.8-43.4,2.2-89.1-43.8C108.6,363.3,89.5,351,74.7,344c-2.8-1.1-13.8-6.9-27.8-9.2l17.8-17.9l0,0
	c5.7-5.8,43.5-2.1,89.1,43.8c27.9,28,58.1,46.1,83,50.1L219.1,428.6z"/>
      </svg>
    </>
  )
}

export default HgncIcon;