import React from "react";
import {
  Divider,
  IconButton,
  Toolbar,
  Typography,
  ButtonBase,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import TableViewIcon from "@mui/icons-material/TableView";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CalculateIcon from "@mui/icons-material/Calculate";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
interface CustomToolbarProps {
  onFilterClick: () => void;
  onTableClick: () => void;
  onAllFiltersClick: () => void;
  onAddAggregateAndCalculate: () => void;
  hasDynamicComponents: boolean; // New prop
}

const CustomToolbar: React.FC<CustomToolbarProps> = ({
  onFilterClick,
  onTableClick,
  onAllFiltersClick,
  onAddAggregateAndCalculate,
  hasDynamicComponents,
}) => {
  return (
    <Toolbar
      sx={{
        justifyContent: "space-between",
        bgcolor: "background.paper",
        margin: "20px",
        borderRadius: "10px",
        padding: "0 20px",
        boxShadow: "0 4px 8px rgba(128, 128, 128, 0.5)",
      }}
    >
      <ButtonBase
        sx={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          justifyContent: "center",
          borderRadius: "10px",
          fontFamily: '"Roboto", "Helvetica","Arial",sans-serif',
          fontWeight: 400,
          fontSize: "1rem",
          lineHeight: "1.4375em",
          letterSpacing: "0.00938em",
          color: "rgba(0, 0, 0, 0.87)",
        }}
        onClick={onFilterClick}
      >
        <IconButton>
          <FilterListIcon />
        </IconButton>
        <Typography>Filter</Typography>
      </ButtonBase>

      <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
      <ButtonBase
        onClick={onAddAggregateAndCalculate}
        sx={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          justifyContent: "center",
          borderRadius: "10px",
          fontFamily: '"Roboto", "Helvetica","Arial",sans-serif',
          fontWeight: 400,
          fontSize: "1rem",
          lineHeight: "1.4375em",
          letterSpacing: "0.00938em",
          color: "rgba(0, 0, 0, 0.87)",
        }}
      >
        <IconButton>
          <CalculateIcon />
        </IconButton>
        <Typography>Add Aggregate And Calculate</Typography>
      </ButtonBase>
      <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />

      <ButtonBase
        sx={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          justifyContent: "center",
          borderRadius: "10px",
          fontFamily: '"Roboto", "Helvetica","Arial",sans-serif',
          fontWeight: 400,
          fontSize: "1rem",
          lineHeight: "1.4375em",
          letterSpacing: "0.00938em",
          color: "rgba(0, 0, 0, 0.87)",
        }}
        onClick={onTableClick}
      >
        <IconButton>
          <TableViewIcon />
        </IconButton>
        <Typography>Table</Typography>
      </ButtonBase>

      <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
      {!hasDynamicComponents && (
        <ButtonBase
          onClick={onAllFiltersClick}
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            justifyContent: "center",
            borderRadius: "10px",
            fontFamily: '"Roboto", "Helvetica","Arial",sans-serif',
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: "1.4375em",
            letterSpacing: "0.00938em",
            color: "rgba(0, 0, 0, 0.87)",
          }}
        >
          <IconButton>
            <SaveAltIcon />
          </IconButton>
          <Typography>Saved Work </Typography>
        </ButtonBase>
      )}
    </Toolbar>
  );
};

export default CustomToolbar;
